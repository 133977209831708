import { useState, useContext, useEffect } from 'react'
import './style.scss'
import utility from 'src/utils/utility'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import { AppSpan, AppText, CustomContainer, FlexRow, FormGroup, GridContainer, ScreenContainer } from 'src/style'
import { useNavigate } from 'react-router-dom'
import helpers from 'src/utils/helpers'
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md'

const ServiceDetails: React.FC = () => {
    const navigate = useNavigate()
    const {info: {selectedService}} = useContext(AppInfoContext)
    const [serviceDetails, setServiceDetails] = useState<any>(null)
    const [extractedServiceDetails, setExtractedServiceDetails] = useState<any>(null)

    useEffect(() => {
        setServiceDetails(selectedService)
        if (selectedService?.id) setExtractedServiceDetails(helpers.getServiceRoute_Banks(selectedService?.processingConfigurations))
    }, [selectedService])

    return (
        <>
            <ScreenContainer>
                <AppText textSize='1.4' cursor='pointer' >
                    <AppSpan textSize='1.4' cursor='pointer' hoverColor='#0D968F' onClick={() => navigate('/dashboard/services')}>Services </AppSpan>
                    <AppSpan textSize='1.4' cursor='pointer'>/ Service Details</AppSpan>
                </AppText>
                <CustomContainer topMargin='3'>
                    <FlexRow width='auto' sizeUnit=' ' justifyContent='flex-start' topMargin='2'>
                        <MdOutlineKeyboardArrowLeft 
                            style={{fontSize: '3rem', color: '#101828'}}
                            onClick={() => navigate(-1)} 
                        />
                        <AppText textSize='3' color='#101828' fontWeight='600' leftMargin='0.5'>
                            Service - {serviceDetails?.friendlyName}
                        </AppText>
                    </FlexRow>
                    <FlexRow wrap='wrap' width='auto' justifyContent='flex-start' alignItems='flex-start' bottomMargin='3'>
                        <CustomContainer 
                            topMargin='3' className='service-details-card'
                        >
                            <AppText color='#101828' textSize='1.8' fontWeight='600' bottomMargin='2'>
                                Service Details
                            </AppText>
                           <CustomContainer
                                minHeight='24.1' borderColor='#EAECF0'
                                shadow='0px 1px 2px rgba(16, 24, 40, 0.06)'
                                radius='0.8' bgColor='#ffffff' padding='2.2' topMargin='2'
                           >
                                <CustomContainer bottomMargin='2'>
                                    <AppText color='#333542' textSize='1.6' fontWeight='700'>Service</AppText>
                                    <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{serviceDetails?.friendlyName}</AppSpan>
                                </CustomContainer>
                                <CustomContainer bottomMargin='2'>
                                    <AppText color='#333542' textSize='1.6' fontWeight='700'>API</AppText>
                                    <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{selectedService?.serviceApi?.name}</AppSpan>
                                </CustomContainer>
                                <CustomContainer >
                                    <AppText color='#333542' textSize='1.6' fontWeight='700'>Service Description</AppText>
                                    <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{selectedService?.description}</AppSpan>
                                </CustomContainer>
                           </CustomContainer>
                        </CustomContainer>
                        <CustomContainer 
                            topMargin='3' className='service-details-card'
                        >
                            <AppText color='#101828' textSize='1.8' fontWeight='600'>
                                Process Configuration
                            </AppText>
                            <CustomContainer
                                minHeight='24.1' borderColor='#EAECF0'
                                shadow='0px 1px 2px rgba(16, 24, 40, 0.06)'
                                radius='0.8' bgColor='#ffffff' padding='2.2' topMargin='2'
                            >
                                <CustomContainer bottomMargin='2'>
                                    <AppText color='#333542' textSize='1.6' fontWeight='700'>Processing Route</AppText>
                                    <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{extractedServiceDetails?.processingRoutes}</AppSpan>
                                </CustomContainer>
                                <CustomContainer bottomMargin='2'>
                                    <AppText color='#333542' textSize='1.6' fontWeight='700'>Control Mode</AppText>
                                    <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{serviceDetails?.controlMode}</AppSpan>
                                </CustomContainer>
                            </CustomContainer>
                        </CustomContainer>
                    </FlexRow>

                    <CustomContainer 
                        topMargin='3' width='100' sizeUnit='%'
                    >
                        <AppText color='#101828' textSize='1.8' fontWeight='600'>
                            Threshold Configuration
                        </AppText>
                        <CustomContainer
                            minHeight='24.1' borderColor='#EAECF0'
                            shadow='0px 1px 2px rgba(16, 24, 40, 0.06)'
                            radius='0.8' bgColor='#ffffff' padding='2.2' topMargin='2'
                        >
                            <FlexRow wrap='wrap' justifyContent='space-between' alignItems='flex-start'>
                                <CustomContainer width='auto' sizeUnit='' rightMargin='4' bottomMargin='2.5'>
                                    <AppText textSize='1.8' color='#101828' fontWeight='700'>Service Limit</AppText>
                                    <CustomContainer bottomMargin='2' topMargin='2.5'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>API Call Count</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>2,000</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>Frequency</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>Weekly</AppSpan>
                                    </CustomContainer>
                                </CustomContainer>
                                <CustomContainer width='auto' sizeUnit='' rightMargin='4' bottomMargin='2.5'>
                                    <AppText textSize='1.8' color='#101828' fontWeight='700'>Service Transaction Limit</AppText>
                                    <CustomContainer bottomMargin='2' topMargin='2.5'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>Single Transaction Limit</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>12,000</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer bottomMargin='2'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>Amount</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>&#8358; 200,000</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>Cumulative Transaction Limit Frequency</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>Weekly</AppSpan>
                                    </CustomContainer>
                                </CustomContainer>
                                <CustomContainer width='auto' sizeUnit=''>
                                    <AppText textSize='1.8' color='#101828' fontWeight='700'>Account Transaction Limit</AppText>
                                    <CustomContainer bottomMargin='2' topMargin='2.5'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>Single Transaction Limit</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>12,000</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer bottomMargin='2'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>Amount</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>&#8358; 200,000</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>Cumulative Transaction Limit Frequency</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>Weekly</AppSpan>
                                    </CustomContainer>
                                </CustomContainer>
                            </FlexRow>
                        </CustomContainer>
                    </CustomContainer>

                    <CustomContainer 
                        topMargin='3' width='100' sizeUnit='%'
                    >
                        <AppText color='#101828' textSize='1.8' fontWeight='600'>
                            Billing Configuration
                        </AppText>
                        <CustomContainer
                            minHeight='24.1' borderColor='#EAECF0'
                            shadow='0px 1px 2px rgba(16, 24, 40, 0.06)'
                            radius='0.8' bgColor='#ffffff' padding='2.2' topMargin='2'
                        >
                            <FlexRow wrap='wrap' justifyContent='space-between' alignItems='flex-start'>
                                <CustomContainer width='auto' sizeUnit='' bottomMargin='2' rightMargin='3'>
                                    <AppText color='#333542' textSize='1.6' fontWeight='700'>Billing Type</AppText>
                                    <AppSpan color='#333542' fontWeight='300' textSize='1.4'>Branded</AppSpan>
                                </CustomContainer>
                                <FlexRow width='45' wrap='wrap' gap='1.5' justifyContent='flex-start' rightMargin='3'>
                                    <FormGroup width='13.4' sizeUnit='rem' bottomMargin='1'>
                                        <label>Min. API Call</label>
                                        <input 
                                            value={1}
                                            disabled
                                        />
                                    </FormGroup>
                                    <FormGroup width='13.4' sizeUnit='rem' bottomMargin='1'>
                                        <label>Max. API Call</label>
                                        <input 
                                            value={100}
                                            disabled
                                        />
                                    </FormGroup>
                                    <FormGroup width='13.4' sizeUnit='rem' bottomMargin='1'>
                                        <label>Fee per API Call</label>
                                        <input 
                                            value='N 100'
                                            disabled
                                        />
                                    </FormGroup>
                                    <FormGroup width='13.4' sizeUnit='rem'>
                                        <label>Min. API Call</label>
                                        <input 
                                            value={101}
                                            disabled
                                        />
                                    </FormGroup>
                                    <FormGroup width='13.4' sizeUnit='rem'>
                                        <label>Max. API Call</label>
                                        <input 
                                            value={500}
                                            disabled
                                        />
                                    </FormGroup>
                                    <FormGroup width='13.4' sizeUnit='rem'>
                                        <label>Fee per API Call</label>
                                        <input 
                                            value={'N 800'}
                                            disabled
                                        />
                                    </FormGroup>
                                </FlexRow>
                                <CustomContainer width='auto' sizeUnit='' bottomMargin='2'>
                                    <AppText color='#333542' textSize='1.6' fontWeight='700'>Service Provider Fee</AppText>
                                    <AppSpan color='#333542' fontWeight='300' textSize='1.4'>2,000</AppSpan>
                                </CustomContainer>
                            </FlexRow>
                        </CustomContainer>
                    </CustomContainer>

                    <CustomContainer 
                        topMargin='3' 
                        className='service-details-card'
                    >
                        <AppText color='#101828' textSize='1.8' fontWeight='600'>
                            Supporting Banks
                        </AppText>
                        <CustomContainer
                            minHeight='24.1' borderColor='#EAECF0'
                            shadow='0px 1px 2px rgba(16, 24, 40, 0.06)'
                            radius='0.8' bgColor='#ffffff' padding='2.2' topMargin='2'
                        >
                            {
                                extractedServiceDetails?.banks?.map((item: any, index: number) => {
                                    return (
                                        <CustomContainer bottomMargin='2' key={index}>
                                            <AppText color='#333542' textSize='1.6' fontWeight='700'>{item?.bank?.name}</AppText>
                                            <AppSpan color='#333542' fontWeight='300' textSize='1.4'>Constrain mode: {item?.constrainMode}</AppSpan>
                                        </CustomContainer>
                                    )
                                })
                            }
                        </CustomContainer>
                    </CustomContainer>
                </CustomContainer>
            </ScreenContainer>
        </>
    )
}

export default utility.routeData('services/service-details', 'Service Details', <ServiceDetails/>)