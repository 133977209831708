import { FaCopy } from "react-icons/fa"
import { FiChevronLeft } from "react-icons/fi"
import { SidePopupContainer, SidePopup, FlexRow, AppSpan, CustomContainer, AppText, Button, FlexColumn } from "src/style"

export const ReceiverAccountInfo: React.FC<any> = ({back, next, close}) => {

    function handleComplete() {
        next()
        close()
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    <AppSpan 
                        color='#000000' textSize="2.5" fontWeight="500" 
                        hoverColor="#0D968F" onClick={back}
                    >
                        <FiChevronLeft />
                    </AppSpan> 
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <AppText color='#101828' textSize="1.8" fontWeight="700" align="center">Pay With Bank Transfer</AppText>
                    <FlexColumn 
                        minHeight="28.4" topMargin="2.5" borderColor="#A0A4A8"
                        radius="0.8" padding="3"
                    >
                        <AppSpan textSize="3.2" color="#000000" fontFamily="Montserrat" fontWeight="600">Qore Bank</AppSpan>
                        <FlexRow width="auto" sizeUnit="" justifyContent="flex-start">
                            <AppSpan textSize="3.2" color="#000000" fontFamily="Montserrat" fontWeight="600">00912324453</AppSpan>
                            <AppSpan 
                                textSize="1.6" color="rgba(0,0,0, 0.5)" 
                                hoverColor="#0D968F" leftMargin="0.7"
                            >
                                <FaCopy />
                            </AppSpan>
                        </FlexRow>
                        <AppText 
                            textSize="1.6" color="#000000" fontFamily="Montserrat" 
                            align="center" topMargin="2"
                        >
                            Use this account for this transaction only Account expires in 30 minutes
                        </AppText>
                        <AppText 
                            textSize="3" fontFamily="Inter" color="#0D968F" 
                            fontWeight="600" align="center" topMargin="1.5"
                        >
                                29:59
                        </AppText>
                    </FlexColumn>
                    <Button 
                        width='100' sizeUnit="%" height='4' topMargin='2' 
                        borderColor='#0D968F' hoverBgColor='#fff' 
                        hoverColor='#0D968F' fontWeight="600"
                        onClick={handleComplete}
                    >
                        Complete
                    </Button>
                </CustomContainer>
            </SidePopup>
        </>
    )
}