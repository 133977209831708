import './style.scss'
import { useState, useContext } from 'react'
import utility from 'src/utils/utility'
import UserContext from 'src/provider/state-manager/userProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { AppSpan, AppText, Button, CustomContainer, FlexColumn, FlexRow, GridContainer, Input, ScreenContainer, SelectButton } from 'src/style';
import { Table, TransactionChart } from 'src/component';
import { FiSearch } from 'react-icons/fi'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { transactionColumns, data } from 'src/utils/tableLayout'
import { TransactionInfo } from 'src/popup';
import HomeIcon from 'src/assets/svg/home-nav-icon'
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';


const TransactionData: React.FC = () => {
    const navigate = useNavigate()
    const { type } = useParams()
    const {user : {userInfo}} = useContext(UserContext)
    const [txTime, setTxTime] = useState('12 months')
    const [details, setDetails] = useState<any>(null)
    const [navPosition, setNavPosition] = useState({status: 0})
    const [isHover, setIsHover] = useState({homeIcon: false, dashboard: false, tx: true})
    const [showTransactionInfo, setShowTransactionInfo] = useState(false)


    function handleViewDetails (data: any) {
        //alert(JSON.stringify(data))
        setDetails(data)
        setShowTransactionInfo(true)
    }

    return (
        <>
            <ScreenContainer>
                <FlexRow width='auto' sizeUnit='' gap='1.5' justifyContent='flex-start'>
                    <GridContainer
                        height='2.8' width='2.8' radius='0.6'
                        bgColor={isHover.homeIcon ? '#E7F6F5' : 'transparent'}
                        onMouseEnter={() => setIsHover({...isHover, homeIcon: true})}
                        onMouseLeave={() => setIsHover({...isHover, homeIcon: false})}
                        onClick={() => navigate('/dashboard/home')}
                    >
                        <HomeIcon isHover={isHover.homeIcon}  className='icon-component' />
                    </GridContainer>
                    <AppSpan textSize='2' color='#D0D5DD'> / </AppSpan>
                    <GridContainer 
                        height='2.8' width='9.2' radius='0.6'
                        bgColor={isHover.dashboard ? '#E7F6F5' : 'transparent'}
                        onMouseEnter={() => setIsHover({...isHover, dashboard: true})}
                        onMouseLeave={() => setIsHover({...isHover, dashboard: false})}
                    >
                        <AppText 
                            textSize='1.4' cursor='pointer' fontWeight='600'
                            color={isHover.dashboard ? '#0A756F' : '#667085'}
                            onClick={() => navigate('/dashboard/home')}
                        >
                            Dashboard
                        </AppText>
                    </GridContainer>
                    <AppSpan textSize='2' color='#D0D5DD'> / </AppSpan>
                    <GridContainer 
                        height='2.8' width='auto' sizeUnit='' leftPadding='0.8' rightPadding='0.8' radius='0.6'
                        bgColor='#E7F6F5'
                    >
                        <AppText 
                            textSize='1.4' cursor='pointer' fontWeight='600'
                            color='#0A756F'
                        >
                            {type}
                        </AppText>
                    </GridContainer>
                </FlexRow>

                <CustomContainer topMargin='5'>
                    <FlexRow justifyContent='flex-start'>
                        <MdOutlineKeyboardArrowLeft style={{fontSize: '3rem', color: '#101828'}} />
                        <AppText textSize='3' color='#101828' fontWeight='500' fontFamily='Inter' leftMargin='0.5'>
                            {type}
                        </AppText>
                    </FlexRow>
                    <FlexRow width='auto' sizeUnit='' gap='0.8' justifyContent='flex-start' topMargin='1.5'>
                        <GridContainer
                            width='9.8' height='3.6' radius='0.6'
                            bgColor={navPosition.status === 0 ? '#E7F6F5' : 'transparent'}
                            className='toggler'
                            onClick={() => setNavPosition({...navPosition, status: 0})}
                        >
                            <AppSpan 
                                fontWeight='600' cursor='pointer'
                                color={navPosition.status === 0 ? '#0A756F' : '#667085'}
                            >
                                Successful
                            </AppSpan>
                        </GridContainer>
                        <GridContainer
                            width='6.6' height='3.6' radius='0.6'
                            bgColor={navPosition.status != 0 ? '#E7F6F5' : 'transparent'}
                            className='toggler'
                            onClick={() => setNavPosition({...navPosition, status: 1})}
                        >
                            <AppSpan 
                                fontWeight='600' cursor='pointer'
                                color={navPosition.status != 0 ? '#0A756F' : '#667085'}
                            >
                                Failed
                            </AppSpan>
                        </GridContainer>
                    </FlexRow>
                    <CustomContainer 
                        minHeight='40' bgColor='#ffffff' topMargin='2' borderColor='#EAECF0'
                        padding='2.5' radius='0.8'
                        //shadow='0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)'
                        shadow='0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A'
                    >
                        <FlexRow justifyContent='space-between' bottomMargin='2.5' wrap='wrap'>
                            <AppText color='#101828' textSize='1.8' rightMargin='2' topMargin='1' fontWeight='600'>{type} Trends</AppText>
                            <Button
                                width='11' height='4' borderColor='#D0D5DD' fontWeight='600'
                                bgColor='transparent' color='#344054' titleSize='1.4' topMargin='1'
                            >
                                View report
                            </Button>
                        </FlexRow>

                        <FlexRow justifyContent='flex-start' bottomMargin='2' wrap='wrap'>
                            <SelectButton width="9.3" height="3.6">
                                <input type="radio" name="payment-duration" checked={txTime === '12 months'} onChange={() => setTxTime('12 months')}/>
                                <span></span>
                                <label>12 months</label>
                            </SelectButton>
                            <SelectButton width="9.3" height="3.6">
                                <input type="radio" name="payment-duration" checked={txTime === '3 months'} onChange={() => setTxTime('3 months')}/>
                                <span></span>
                                <label>3 months</label>
                            </SelectButton>
                            <SelectButton width="9.3" height="3.6">
                                <input type="radio" name="payment-duration" checked={txTime === '30 days'} onChange={() => setTxTime('30 days')}/>
                                <span></span>
                                <label>30 days</label>
                            </SelectButton>
                            <SelectButton width="9.3" height="3.6">
                                <input type="radio" name="payment-duration" checked={txTime === '7 days'} onChange={() => setTxTime('7 days')}/>
                                <span></span>
                                <label>7 days</label>
                            </SelectButton>
                            <SelectButton width="9.3" height="3.6">
                                <input type="radio" name="payment-duration" checked={txTime === '24 hours'} onChange={() => setTxTime('24 hours')}/>
                                <span></span>
                                <label>24 hours</label>
                            </SelectButton>
                        </FlexRow>

                        <CustomContainer height='22'>
                            <TransactionChart />
                        </CustomContainer>
                    </CustomContainer>

                    <CustomContainer topMargin='1' bottomMargin='3'>
                        <Table
                            tableColumns={transactionColumns(handleViewDetails)}
                            tableData={data}
                            customStyle
                            canSearch
                            canDownload
                        />
                    </CustomContainer>
                </CustomContainer>
            </ScreenContainer>
            { showTransactionInfo &&
                <TransactionInfo 
                    close={() => setShowTransactionInfo(false)} 
                    data={details}
                />
            }
        </>
    );
}

export default utility.routeData('transactionData/:type', 'Transaction Data', <TransactionData/>)