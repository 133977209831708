import './style.scss'
import React, { useState, useContext, useEffect } from 'react'
import utility from 'src/utils/utility'
import { AppSpan, AppText, Button, CustomContainer, Divider, FlexColumn, FlexRow, ScreenContainer } from 'src/style'
import { useNavigate } from 'react-router-dom'
import { Table } from 'src/component'
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import UserContext from 'src/provider/state-manager/userProvider'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import ApiContext from 'src/provider/API/call-service'
import { 
    productColumn, productData, availableServiceColumns, 
    availableServiceData, productsColumns, productsData 
} from 'src/utils/tableLayout'
import { EditBusiness } from 'src/popup'
import { BiCheck } from 'react-icons/bi'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import helpers from 'src/utils/helpers'


const BusinessDetails: React.FC = () => {
    const navigate = useNavigate()
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const {info: {selectedBusiness}} = useContext(AppInfoContext)
    const {user : {userInfo}} = useContext(UserContext)
    const [businessDetails, setBusinessDetails] = useState<any>(null)
    const [businessAdmin, setBusinessAdmin] = useState<any>(null)
    const [businessProducts, setBusinessProducts] = useState<any>(null)
    const [showEditBusiness, setShowEditBusiness] = useState(false)
    const [manageAccordion, setManageAccordion] = useState({isInterbank: false, isIntrabank: false})
    const userType = userInfo?.user?.institution?.institutionCategory.institutionCategoryType

    useEffect(() => {
        setBusinessDetails(selectedBusiness)
        if (selectedBusiness?.id) {
            !businessAdmin && handleFetchBusinessAdmin(selectedBusiness?.id)
            !businessProducts && handleFetchBusinessProducts(selectedBusiness?.code)
        }
    }, [selectedBusiness])

    async function handleFetchBusinessAdmin (id: number) {
        const response = await API.getBusinessAdmin(id)
        if (response) setBusinessAdmin(response)
    }

    async function handleFetchBusinessProducts (institutionCode: number) {
        const response = await API.getBusinessProducts(institutionCode)
        if (response) setBusinessProducts(helpers.addSerialNumberToItems(response))
    }

    async function handleBusinessActivation () {
        const response = businessDetails?.status != 'Active' ? 
            await API.activateBusiness(businessDetails?.id) 
            : await API.deactivateBusiness(businessDetails?.id)
        if (response) await API.getBusinessById(businessDetails?.id)
    }

    function handleAction (e: React.ChangeEvent<HTMLInputElement>) {
        alert(e.target.checked)
    }

    return (
        <>
            <ScreenContainer bottomPadding='5'>
                <AppText>
                    <AppSpan textSize='1.4' cursor='pointer'  onClick={() => navigate('/dashboard/business')}>Business /</AppSpan>
                    <AppSpan textSize='1.4' cursor='pointer'> Business Details </AppSpan>
                </AppText>
                <CustomContainer topMargin='3'>
                    <FlexRow justifyContent='space-between' className='business-details-title-wrap'>
                        <AppText textSize='3' color='#101828' topMargin='2' rightMargin='2' fontWeight='600'>Business Details - Qore</AppText>
                        <FlexRow width='auto' topMargin='1' className='actions-business-details-btn'>
                            { userType === 'ServiceProvider' &&
                                <Button
                                    width='17'
                                    height='4'
                                    radius='0.8'
                                    bgColor='#ffffff'
                                    hoverBgColor='#ffffff'
                                    borderColor='#D0D5DD'
                                    color='#344054'
                                    hoverColor='#0D968F'
                                    rightMargin='1.5'
                                    topMargin='1'
                                    fontWeight='600'
                                    onClick={handleBusinessActivation}
                                >
                                    {businessDetails?.status === 'Active' ? 'Deactivate Business' : 'Activate Business'}
                                </Button>
                            }
                            { userType === 'ServiceProvider' && // userType != 'ServiceProvider'
                                <Button
                                    width='14.9'
                                    height='4'
                                    radius='0.8'
                                    hoverBgColor='#ffffff'
                                    borderColor='#0D968F'
                                    hoverColor='#0D968F'
                                    fontWeight='600'
                                    topMargin='1'
                                    className='add-business-btn'
                                    onClick={() => navigate('/dashboard/business-keys-apis')}
                                >
                                    View Keys & APIs
                                </Button>
                            }
                        </FlexRow>  
                    </FlexRow>
                    
                    <FlexRow wrap='wrap' width='auto' justifyContent='flex-start' alignItems='flex-start'>
                        <CustomContainer 
                            topMargin='3'
                            className='business-details-card'
                        >
                            <FlexRow justifyContent='flex-start' wrap='wrap'>
                                <AppText 
                                    color='#101828' textSize='1.8' fontWeight='600' 
                                    bottomMargin='1.5' rightMargin='3'
                                >
                                    Business Details
                                </AppText>
                                <Button
                                    width='17.1' height='4' radius='0.8'
                                    bgColor='#ffffff' hoverBgColor='#ffffff' borderColor='#D0D5DD'
                                    color='#344054' hoverColor='#0D968F'
                                    bottomMargin='1.5' fontWeight='600'
                                    onClick={() => setShowEditBusiness(true)}
                                >
                                    Edit Business Details
                                </Button>
                            </FlexRow>
                            <CustomContainer
                                minHeight='25.9' borderColor='#EAECF0'
                                shadow='0px 1px 2px rgba(16, 24, 40, 0.06)'
                                radius='0.8' bgColor='#ffffff' padding='2.2' rightPadding='4'
                            >
                                <FlexRow justifyContent='space-between' wrap='wrap'>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='600'>Business</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{businessDetails?.name}</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='600'>Email Address</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{businessDetails?.email}</AppSpan>
                                    </CustomContainer>
                                </FlexRow>
                                <FlexRow justifyContent='space-between' wrap='wrap'>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='600'>Phone Number</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{businessDetails?.phoneNo}</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='600'>Status</AppText>
                                        <FlexRow 
                                            width='8' bgColor={businessDetails?.status === 'Active' ? '#ECFDF3' : '#FEF3F2'}
                                            topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
                                        >
                                            <AppSpan  
                                                color={businessDetails?.status === 'Active' ? '#12B76A' : '#B42318'}  
                                                textSize='1.5' topMargin='-0.2'
                                            >
                                                { businessDetails?.status === 'Active' ? <BiCheck/> : <i className='fa fa-times-thin' />}
                                            </AppSpan>
                                            <AppSpan 
                                                color={businessDetails?.status === 'Active' ? '#027A48' : '#B42318'}
                                                textSize='1.3' leftPadding='0.5' fontWeight='500'
                                            >
                                                {businessDetails?.status || 'Inactive'}
                                            </AppSpan>
                                        </FlexRow>
                                    </CustomContainer>
                                </FlexRow>
                                <FlexRow justifyContent='space-between' wrap='wrap'>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='600'>Business Address</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{businessDetails?.address}</AppSpan>
                                    </CustomContainer>
                                </FlexRow>
                            </CustomContainer>
                        </CustomContainer>
                        <CustomContainer 
                            topMargin='3' className='business-details-card'
                        >
                            <FlexColumn bottomMargin='1.3' height='4.5' alignItems='flex-start'>
                                <AppText color='#101828' textSize='1.8' fontWeight='600'>
                                    Business Admin Details
                                </AppText>
                            </FlexColumn>
                            <CustomContainer
                                minHeight='25.9' borderColor='#EAECF0'
                                shadow='0px 1px 2px rgba(16, 24, 40, 0.06)'
                                radius='0.8' bgColor='#ffffff' padding='2.2'
                            >
                                <FlexRow justifyContent='space-between' wrap='wrap'>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='600'>First Name</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{businessAdmin?.firstName}</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='600'>Last Name</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{businessAdmin?.lastName}</AppSpan>
                                    </CustomContainer>
                                </FlexRow>
                                <FlexRow justifyContent='space-between' wrap='wrap'>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='600'>Phone Number</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{businessAdmin?.phoneNo}</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='600'>Username</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{businessAdmin?.username}</AppSpan>
                                    </CustomContainer>
                                </FlexRow>
                                <FlexRow justifyContent='space-between' wrap='wrap'>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='600'>Email Address</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{businessAdmin?.email}</AppSpan>
                                    </CustomContainer>
                                </FlexRow>
                            </CustomContainer>
                        </CustomContainer>
                    </FlexRow>
                    <CustomContainer topMargin='3'>
                        <AppText color='#101828' textSize='1.8' fontWeight='600' bottomMargin='1'>Products</AppText>
                        <Table 
                            tableData={businessProducts}
                            tableColumns={productColumn}
                            customStyle
                        />
                    </CustomContainer>

                    {/* <CustomContainer topMargin='3'>
                        <AppText color='#101828' textSize='1.8' fontWeight='600'>Available Services</AppText>
                        <Divider margin='3' borderWidth='0.6' />
                        
                        <CustomContainer topMargin='-1'>
                            <FlexRow justifyContent='space-between' onClick={() => setManageAccordion({...manageAccordion, isIntrabank: !manageAccordion.isIntrabank})}>
                                <AppText color='#101828' textSize='1.8' fontWeight='600' cursor='pointer'>
                                    Direct Intrabank Transfer
                                </AppText>
                                <AppSpan 
                                    color='#98A2B3' textSize="2.5" fontWeight="500" 
                                    leftMargin='2'
                                >
                                    { manageAccordion.isIntrabank ? <FiChevronUp /> : <FiChevronDown />}
                                </AppSpan> 
                            </FlexRow>
                            { manageAccordion.isIntrabank &&
                                <CustomContainer 
                                    topMargin='0.7' 
                                    //className='services-accordion'
                                    //style={{height: manageAccordion.isIntrabank ? '20rem' : '0'}}
                                >
                                    <AppText color='#667085' fontWeight='500' textSize='1.6'>Connected Banks</AppText>
                                    <Table 
                                        tableData={availableServiceData}
                                        tableColumns={availableServiceColumns(handleAction)}
                                        customStyle
                                    />
                                </CustomContainer>
                            }
                        </CustomContainer>
                        <Divider margin='1' borderWidth='0.6' />
                        <CustomContainer topMargin='2'>
                            <FlexRow justifyContent='space-between' onClick={() => setManageAccordion({...manageAccordion, isInterbank: !manageAccordion.isInterbank})}>
                                <AppText color='#101828' textSize='1.8' fontWeight='600' cursor='pointer'>
                                    Direct Interbank Transfer
                                </AppText>
                                <AppSpan 
                                    color='#98A2B3' textSize="2.5" fontWeight="500" 
                                    leftMargin='2'
                                >
                                    { manageAccordion.isInterbank ? <FiChevronUp /> : <FiChevronDown />}
                                </AppSpan> 
                            </FlexRow>
                            { manageAccordion.isInterbank &&
                                <CustomContainer 
                                    topMargin='0.7' 
                                    //className='services-accordion'
                                    //style={{height: manageAccordion.isInterbank ? '20rem' : '0'}}
                                >
                                    <AppText color='#667085' fontWeight='500' textSize='1.6'>Connected Banks</AppText>
                                    <Table 
                                        tableData={availableServiceData}
                                        tableColumns={availableServiceColumns(handleAction)}
                                        customStyle
                                    />
                                </CustomContainer>
                            }
                        </CustomContainer>
                    </CustomContainer> */}
                </CustomContainer>
            </ScreenContainer>
            { showEditBusiness &&
                <EditBusiness 
                    close={() => setShowEditBusiness(false)}
                />
            }
        </>
    )
}

export default utility.routeData('business/business-details', 'Business Details', <BusinessDetails/>)