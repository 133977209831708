import './style.scss'
import { useState, useEffect, useContext } from 'react'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import UserContext from 'src/provider/state-manager/userProvider'
import { AppText, Button, CustomContainer, FlexRow, ScreenContainer } from 'src/style'
import { useNavigate } from 'react-router-dom'
import { Table } from 'src/component'
import { businessColumns, businessData } from 'src/utils/tableLayout'
import { BsPlus } from 'react-icons/bs'
import { AddBusiness } from 'src/popup'
import helpers from 'src/utils/helpers'


const Business: React.FC = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {user : {userInfo}} = useContext(UserContext)
    const {setInfoProperty} = useContext(AppInfoContext)
    const [businesses, setBusinesses] = useState<any>(businessData)
    const [showAddBusiness, setShowAddBusiness] = useState(false)
    const userType = userInfo?.user?.institution?.institutionCategory.institutionCategoryType

    useEffect(() => {
        if (userType) {
            if (userType != 'ServiceProvider') handleFetchBusinessByInstitutionCode()
            else handleFetchBusinesses()
        }
    }, [userType])

    async function handleFetchBusinesses() {
        const response = await API.getBusinesses()
        if (response) setBusinesses(helpers.addSerialNumberToItems(response))
    }

    async function handleFetchBusinessByInstitutionCode () {
        const response = API.getBusinesseByInstitutionCode()
        if (response) navigate('/dashboard/business/business-details')
    }

    async function handleViewDetails (data: any) {
        await setInfoProperty('selectedBusiness', data)
        navigate('/dashboard/business/business-details')
    }

    return (
        <>
            <ScreenContainer>
                <AppText 
                    textSize='1.4' cursor='pointer' 
                    onClick={() => navigate('/dashboard/business')}
                >
                    Business
                </AppText>
                <CustomContainer topMargin='3'>
                    <FlexRow justifyContent='space-between' className='business-title-wrap'>
                        <AppText textSize='3' color='#101828' topMargin='2' fontWeight='600'>Business Partner</AppText>
                        { userType === 'ServiceProvider' &&
                            <Button
                                width='16.8'
                                height='4'
                                radius='0.8'
                                hoverBgColor='#ffffff'
                                borderColor='#0D968F'
                                hoverColor='#0D968F'
                                fontWeight='600'
                                topMargin='2'
                                className='add-business-btn'
                                onClick={() => setShowAddBusiness(true)}
                            >
                                <BsPlus style={{fontSize: '1.5rem'}}/> 
                                Add New Business
                            </Button>
                        }
                    </FlexRow>
                    <AppText 
                        textSize='1.8' fontWeight='600' color='#101828' 
                        topMargin='2' bottomMargin='1'
                    >
                        View Business Partner List
                    </AppText>
                    <Table 
                        tableData={businesses}
                        tableColumns={businessColumns(handleViewDetails)}
                        customStyle
                        canSearch
                        keyField='sn'
                    />
                </CustomContainer>
            </ScreenContainer>
            { showAddBusiness &&
                <AddBusiness 
                    close={() => setShowAddBusiness(false)}
                    fetchBusiness={handleFetchBusinesses}
                />
            }
        </>
    )
}

export default utility.routeData('business', 'Business', <Business/>)