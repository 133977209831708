import { CustomContainer, FlexRow, Input } from 'src/style';
import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { FiSearch } from 'react-icons/fi';
import '../Table/style.scss'
import helpers from 'src/utils/helpers';

export const ServerSideTable: React.FC<any> = React.memo(({
  tableData, tableColumns, dataFetchMethod, totalRows, 
  pageNumber, callOnMount, noRadius, customStyle, canSearch
}) => {
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(pageNumber || 1);
    const [mockTableData, setMockTableData] = useState<any[]>([])

   useEffect(() =>{
      if (callOnMount) dataFetchMethod(pageNumber, perPage)
   }, [])

   useEffect(() => {
        setCurrentPage(1)
   }, [pageNumber])

  useEffect(() => {
    setMockTableData(tableData)
  }, [tableData])

  function handleDataSearching (e: React.ChangeEvent<HTMLInputElement>) {
    const filteredData = helpers.searchTableData(tableData, e.target.value)
    console.log({filteredData})
    setMockTableData(filteredData)
  }

   const handlePageChange = (page: any) => {
    dataFetchMethod(page, perPage);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: React.SetStateAction<number>, page: any) => {
    dataFetchMethod(page, newPerPage);
    setPerPage(newPerPage);
  };


  return (
    <>
       { tableData ?
            <>
             { canSearch &&
                  <CustomContainer
                      width='40' radius='0.8' borderColor='#D0D5DD' 
                      leftPadding='1.5' rightPadding='1.5' topMargin='2'
                      shadow='0px 1px 2px rgba(16, 24, 40, 0.05)'
                      bottomMargin='2' className='service-search-box'
                  >
                      <FlexRow>
                          <FiSearch style={{fontSize: '2rem'}} />
                          <Input 
                              height='4.4' leftPadding='1' 
                              placeholder='Search' 
                              onChange={handleDataSearching} 
                          />
                      </FlexRow>
                  </CustomContainer>
              }
              <div className={ noRadius ? "table-wrapper-no-radius" : "table-wrapper"}>
                <DataTable
                    columns={tableColumns}
                    data={mockTableData?.length ? mockTableData : tableData}
                    striped
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    //paginationResetDefaultPage={resetPagination}
                    paginationDefaultPage={currentPage}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    customStyles={customStyle ? customStyle  : undefined}
                    //paginationPerPage={2}
                    //paginationRowsPerPageOptions={[2, 4, 6, 8, 10]}
                    //selectableRows
                />
              </div>
            </>
            : null
        }
    </>
  )
})

