import { useState, useContext } from "react"
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import AppInfoContext from "src/provider/state-manager/appInfoProvider"
import ApiContext from "src/provider/API/call-service"
import { PopupStackIcon } from "src/assets/icons"
import { AppSpan, AppText, Button, CustomContainer, FlexRow, Form, FormGroup, SidePopup, SidePopupContainer } from "src/style"
import helpers from "src/utils/helpers"
import { appSettings } from "src/provider/config/constant"

export const EditBusiness: React.FC<any> = ({close}) => {
    const {notifier} = useContext(VisibilityContext)
    const {info: {selectedBusiness}} = useContext(AppInfoContext)
    const {API} = useContext(ApiContext)
    const [input, setInput] = useState({
        institutionName: selectedBusiness?.name || '', 
        institutionPhoneNo: selectedBusiness?.phoneNo || '', 
        institutionAddress: selectedBusiness?.address || '', 
        institutionEmail: selectedBusiness?.email || ''
    })

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
        if (e.target.name === 'institutionAddress' && e.target.value.length > appSettings.ADDRESS_CHARACTERS_LENGTH) return null
        if (e.target.name === 'institutionEmail') {
            if (e.target.value.includes(' ')) e.target.value = e.target.value.replace(/ /g, '')
        }
        setInput({...input, [e.target.name]: e.target.value})
    }

    async function handleSubmit(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        
        if (!helpers.validateEmail(input.institutionEmail)) return notifier.show('Invalid business email provided')
        else if (!helpers.validatePhoneNo(input.institutionPhoneNo)) return notifier.show('Invalid business phone number provided')
        else {
            const response = await API.editBusiness({...input, id: selectedBusiness?.id})
            if (response) API.getBusinessById(selectedBusiness?.id)
        }

        close()
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    <PopupStackIcon />  
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <AppText color='#101828' textSize="1.8" fontWeight="700">Edit Business Details</AppText>
                    <Form topMargin="2" onSubmit={handleSubmit}>
                        <FormGroup>
                            <label>Business Name</label>
                            <input 
                                placeholder="Business Name" 
                                name='institutionName'
                                value={input.institutionName}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Business Address</label>
                            <textarea 
                                placeholder="Business Address"
                                name='institutionAddress'
                                value={input.institutionAddress}
                                onChange={handleInput}
                                required
                            />
                            <AppSpan textSize="1.2" color='#B42318'>
                                Remaining {appSettings.ADDRESS_CHARACTERS_LENGTH - input.institutionAddress?.length} characters of {appSettings.ADDRESS_CHARACTERS_LENGTH}
                            </AppSpan>
                        </FormGroup>
                        <FormGroup>
                            <label>Phone Number</label>
                            <input 
                                type='number' 
                                placeholder="Phone Number" 
                                name='institutionPhoneNo'
                                value={input.institutionPhoneNo}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Email Address</label>
                            <input 
                                type='email' 
                                placeholder="Email Address" 
                                name='institutionEmail'
                                value={input.institutionEmail}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>
                        <FlexRow justifyContent="flex-end">
                            <Button 
                                width='8'
                                height='4'
                                radius='0.8'
                                hoverBgColor='#ffffff'
                                borderColor='#0D968F'
                                hoverColor='#0D968F'
                                fontWeight='600'
                                topMargin="1"
                            >
                                Save
                            </Button>
                        </FlexRow>
                    </Form>
                </CustomContainer>
            </SidePopup>
        </>
    )
}
    