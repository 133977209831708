import { useState, useEffect, useContext } from 'react'
import utility from 'src/utils/utility'
import { AppSpan, AppText, Button, CustomContainer, FlexRow, Input, ScreenContainer } from 'src/style'
import { useNavigate } from 'react-router-dom'
import UserContext from 'src/provider/state-manager/userProvider'
import { Table } from 'src/component'
import { manageRolesColumns, manageRolesData } from 'src/utils/tableLayout'
import ApiContext from 'src/provider/API/call-service'
import { BsPlus } from 'react-icons/bs'
import { AddRole, ViewRole } from 'src/popup'
import helpers from 'src/utils/helpers'


const ManageRoles: React.FC = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {user: {userInfo}} = useContext(UserContext)
    const [roles, setRoles] = useState<any>(null)
    const [selectedRole, setSelectedRole] = useState<any>(null)
    const [showAddRole, setShowAddRole] = useState(false)
    const [showViewRole, setShowViewRole] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        if (userInfo?.user?.institution) handleFetchRoles()
    }, [userInfo?.user?.institution])

    async function handleFetchRoles () {
        const response = await API.getRoles()
        if (response) setRoles(helpers.addSerialNumberToItems(response))
    }

    function handleViewDetails (data: any) {
        setSelectedRole(data)
        setShowViewRole(true)
    }

    function handleEditToggle (type: boolean) {
        setIsEdit(type)
        if (!type) setSelectedRole(null) 
    }

    return (
        <>
            <ScreenContainer>
                <AppText>
                    <AppSpan textSize='1.4' cursor='pointer'  onClick={() => navigate('/dashboard/users')}>Users /</AppSpan>
                    <AppSpan textSize='1.4' cursor='pointer'> Manage Roles </AppSpan>
                </AppText>
                <CustomContainer topMargin='3'>
                    <FlexRow justifyContent='space-between' className='business-title-wrap'>
                        <AppText textSize='3' color='#101828' topMargin='2' fontWeight='600'>Manage Roles</AppText>
                        <Button
                            width='13.7'
                            height='4'
                            radius='0.8'
                            hoverBgColor='#ffffff'
                            borderColor='#0D968F'
                            hoverColor='#0D968F'
                            fontWeight='600'
                            topMargin='2'
                            className='add-business-btn'
                            onClick={() => setShowAddRole(true)}
                        >
                            <BsPlus style={{fontSize: '1.5rem'}}/> 
                            Add New Role
                        </Button>
                    </FlexRow>
                    <Table 
                        tableData={roles}
                        tableColumns={manageRolesColumns(handleViewDetails)}
                        customStyle
                        canSearch
                    />
                </CustomContainer>
            </ScreenContainer>
            { showAddRole &&
                <AddRole 
                    close={() => setShowAddRole(false)}
                    getRoleMethod={handleFetchRoles}
                    data={selectedRole}
                    isEdit={isEdit}
                    setEdit={handleEditToggle}
                />
            }
            { showViewRole &&
                <ViewRole 
                    close={() => setShowViewRole(false)}
                    data={selectedRole}
                    showEdit={() => setShowAddRole(true)}
                    setEdit={handleEditToggle}
                />
            }
        </>
    )
}

export default utility.routeData('manage-user-roles', 'Manage Roles', <ManageRoles/>)