import { useState, useContext, useEffect } from 'react'
import utility from 'src/utils/utility'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import ApiContext from 'src/provider/API/call-service'
import { AppLabel, AppSpan, AppText, CustomContainer, FlexRow, ScreenContainer } from 'src/style'
import { useNavigate } from 'react-router-dom'
import helpers from 'src/utils/helpers'

const AuditLogDetails: React.FC = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {info: {selectedAuditLog}} = useContext(AppInfoContext)
    const [auditInfo, setAuditInfo] = useState<any>(selectedAuditLog)
    const [initiatorDetails, setInitiatorDetails] = useState<any>(null)
    const [beforeLog, setBeforeLog] = useState<any>(null)
    const [afterLog, setAfterLog] = useState<any>(null)

    const auditDate = helpers?.splitDateTime(auditInfo?.loggedAt || '0001-00-00T12:00:00')

    useEffect(() => {
        if (selectedAuditLog?.id) {
            handleGetAuditInfo(selectedAuditLog?.id)
            handleGetInitiatorDetails(selectedAuditLog?.initiatingUser)
        }
    }, [selectedAuditLog])

    async function handleGetAuditInfo (id: number) {
        const response = await API.getAuditItemById(id)
        if (response) {
            setAuditInfo(response)
            handleAuditItems(response)
        }
    }

    async function handleGetInitiatorDetails (email: string) {
        const response = await API.getUserByEmail(email)
        if (response) setInitiatorDetails(response)
    }

    function handleAuditItems (data: any) {
        const jsonBefore = data?.jsonBefore ? JSON.parse(data?.jsonBefore) : {}
        const jsonAfter = data?.jsonAfter ? JSON.parse(data?.jsonAfter) : {}

        data = {...data, jsonBefore, jsonAfter}
        let formattedBeforeLog = helpers.formattedAuditItems({informationType: data?.informationType, items: data?.jsonBefore}).groupedItems
        let formattedAfterLog = helpers.formattedAuditItems({informationType: data?.informationType, items: data?.jsonAfter}).groupedItems
        setBeforeLog(formattedBeforeLog)
        setAfterLog(formattedAfterLog)
        //console.log({formattedBeforeLog, formattedAfterLog})
    }

    return (
        <>
            <ScreenContainer>
                <AppText textSize='1.4' cursor='pointer' >
                    <AppSpan textSize='1.4' cursor='pointer' onClick={() => navigate('/dashboard/audit-logs')}>Audit Logs /</AppSpan>
                    <AppSpan textSize='1.4' cursor='pointer'> Audit Log Details</AppSpan>
                </AppText>
                <CustomContainer topMargin='3'>
                    <AppText textSize='3' color='#101828' topMargin='2' fontWeight='600'>Audit Trail - Ridwan Kolawole</AppText>
                    
                    <CustomContainer topMargin='4'>
                        <AppText 
                            color='#101828' textSize='1.8' fontWeight='600' 
                            bottomMargin='1.5' rightMargin='3'
                        >
                            Audit Details
                        </AppText>
                        <FlexRow
                            minHeight='11.7' borderColor='#EAECF0'
                            shadow='0px 1px 2px rgba(16, 24, 40, 0.06)'
                            radius='0.8' bgColor='#ffffff' padding='2.2'
                            justifyContent='flex-start' wrap='wrap' topMargin='1'
                        >
                            <CustomContainer width='auto' bottomMargin='1.7' rightMargin='7'>
                                <AppText color='#333542' textSize='1.6' fontWeight='700'>Firstname</AppText>
                                <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{initiatorDetails?.firstName}</AppSpan>
                            </CustomContainer>
                            <CustomContainer width='auto' bottomMargin='1.7' rightMargin='7'>
                                <AppText color='#333542' textSize='1.6' fontWeight='700'>Lastname</AppText>
                                <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{initiatorDetails?.lastName}</AppSpan>
                            </CustomContainer>
                            <CustomContainer width='auto' bottomMargin='1.7' rightMargin='7'>
                                <AppText color='#333542' textSize='1.6' fontWeight='700'>Username</AppText>
                                <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{initiatorDetails?.username}</AppSpan>
                            </CustomContainer>
                            <CustomContainer width='auto' bottomMargin='1.7' rightMargin='7'>
                                <AppText color='#333542' textSize='1.6' fontWeight='700'>Email Address</AppText>
                                <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{initiatorDetails?.email}</AppSpan>
                            </CustomContainer>
                            <CustomContainer width='auto' bottomMargin='1.7' rightMargin='7'>
                                <AppText color='#333542' textSize='1.6' fontWeight='700'>Phone Number</AppText>
                                <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{initiatorDetails?.phoneNo}</AppSpan>
                            </CustomContainer>
                            <CustomContainer width='auto' bottomMargin='1.7' rightMargin='7'>
                                <AppText color='#333542' textSize='1.6' fontWeight='700'>Date & Time</AppText>
                                <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{`${auditDate.slashedDate} ${auditDate.time}${auditDate.timeEnv}`}</AppSpan>
                            </CustomContainer>
                            <CustomContainer width='auto' bottomMargin='1.7' rightMargin='7'>
                                <AppText color='#333542' textSize='1.6' fontWeight='700'>IP Address</AppText>
                                <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{auditInfo?.ipAddress || '000.000.000.0'}</AppSpan>
                            </CustomContainer>
                        </FlexRow>
                    </CustomContainer>

                    <CustomContainer topMargin='2'>
                        <AppText 
                            color='#101828' textSize='1.8' fontWeight='600' 
                            rightMargin='3'
                        >
                            Operation: Add New User
                        </AppText>
                        <FlexRow wrap='wrap' width='auto' justifyContent='flex-start' alignItems='stretch' bottomMargin='3'>
                            <CustomContainer
                                minHeight='24.1' borderColor='#EAECF0'
                                topMargin='2'
                                shadow='0px 1px 2px rgba(16, 24, 40, 0.06)'
                                radius='0.8' bgColor='#ffffff' padding='2.2'
                                className='business-details-card'
                            >
                                <AppLabel color='#333542' textSize='1.6' fontWeight='700' bottomMargin='1.5'>
                                    Before Change
                                </AppLabel>
                                {
                                    beforeLog?.map((item: any, index: number) => {
                                        return (
                                            <FlexRow justifyContent='space-between' wrap='wrap' key={index}>
                                                <CustomContainer width='20' bottomMargin='1.7' rightMargin='1.5'>
                                                    <AppText color='#333542' textSize='1.6' fontWeight='700'>{item[0].displayName}</AppText>
                                                    <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{item[0].value}</AppSpan>
                                                </CustomContainer>
                                                { item[1] &&
                                                    <CustomContainer width='20' bottomMargin='1.7'>
                                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>{item[1].displayName}</AppText>
                                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{item[1].value}</AppSpan>
                                                </CustomContainer>
                                                }
                                            </FlexRow>
                                        )
                                    })
                                }
                            </CustomContainer>
                            <CustomContainer
                                minHeight='24.1' borderColor='#EAECF0'
                                topMargin='2' 
                                shadow='0px 1px 2px rgba(16, 24, 40, 0.06)'
                                radius='0.8' bgColor='#ffffff' padding='2.2'
                                className='business-details-card'
                            >
                                <AppLabel color='#333542' textSize='1.6' fontWeight='700' bottomMargin='1.5'>
                                    After Change
                                </AppLabel>
                                {
                                    afterLog?.map((item: any, index: number) => {
                                        return (
                                            <FlexRow justifyContent='space-between' wrap='wrap' key={index}>
                                                <CustomContainer width='20' bottomMargin='1.7' rightMargin='1.5'>
                                                    <AppText color='#333542' textSize='1.6' fontWeight='700'>{item[0].displayName}</AppText>
                                                    <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{item[0].value}</AppSpan>
                                                </CustomContainer>
                                                { item[1] &&
                                                    <CustomContainer width='20' bottomMargin='1.7'>
                                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>{item[1].displayName}</AppText>
                                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{item[1].value}</AppSpan>
                                                </CustomContainer>
                                                }
                                            </FlexRow>
                                        )
                                    })
                                }
                            </CustomContainer>
                        </FlexRow>
                    </CustomContainer>
                </CustomContainer>
            </ScreenContainer>
        </>
    )
}

export default utility.routeData('audit-log-details', 'Audit Log Details', <AuditLogDetails/>)