import { useState } from "react"
import { AppText, FixedContainer, FlexRow, Switch } from "src/style"

export const EnvironmentMode = () => {
    const [isLive, setIsLive] = useState(false) 

    return (
        <FixedContainer  top='3' right='2'>
            <FlexRow 
                width='12.3' height='4.6' borderColor='#EAECF0'
                radius='0.8' bgColor='#ffffff'
                shadow='0px 1px 2px 0px #1018280F'
            >
                <AppText textSize='1.8' fontWeight='600' color='#101828' rightMargin='1'>{isLive ? 'Live' : 'Test'}</AppText>
                <Switch
                    width='4.4' height='2.4' checkedBgColor='#0D968F'
                    sliderWidth='2' sliderHeight='2' 
                    checkedSliderBgColor='#ffffff' checkedSliderPosition='2.2'
                >
                    <input 
                        type="checkbox" 
                        checked={isLive} 
                        onChange={() => setIsLive(!isLive)} 
                    />
                    <div ></div>
                    <span></span>
                </Switch>
            </FlexRow>
        </FixedContainer>
    )
}