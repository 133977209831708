import { useState, useContext } from "react"
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import ApiContext from 'src/provider/API/call-service'
import { PopupProductIcon } from "src/assets/icons"
import { AppSpan, AppText, Button, CustomContainer, FlexRow, Form, FormGroup, FormGroupWithIcon, SidePopup, SidePopupContainer } from "src/style"
import { FiChevronDown } from "react-icons/fi"

export const EditProduct: React.FC<any> = ({close}) => {
    const {API} = useContext(ApiContext)
    const {info: {selectedProduct}} = useContext(AppInfoContext)
    const [input, setInput] = useState({
        name: selectedProduct?.name || '', 
        description: selectedProduct?.description || '',
        productPersonEmail: selectedProduct?.productPersonEmail || '',
        institutionCode: selectedProduct?.institutionCode
    })

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => setInput({...input, [e.target.name]: e.target.value})

    async function handleSubmit(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
       
        const response = await API.editProduct({...input, id: selectedProduct?.id})
        if (response) API.getProductById(selectedProduct?.id)
        close()
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    <PopupProductIcon />  
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <AppText color='#101828' textSize="1.8" fontWeight="700">Edit Product Details</AppText>
                    <Form topMargin="2" onSubmit={handleSubmit}>
                        <FormGroup>
                            <label>Product Name</label>
                            <input 
                                placeholder="Product Name" 
                                name='name'
                                value={input.name}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Product VP</label>
                            <input 
                                placeholder="Product VP Email" 
                                name='productPersonEmail'
                                value={input.productPersonEmail}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>
                        {/* <FormGroupWithIcon bottomMargin='1.8'>
                            <label>Product Status</label>
                            <div className="input-with-icon">
                                <select 
                                    name="status" 
                                    onChange={(e) => setInput({...input, status: e.target.value})}
                                    style={{color: input.status ? '#667085' : '#999'}}
                                >
                                    <option value="">Select Status</option>
                                    <option value="Senior Management">Active</option>
                                    <option value="Executive Director">Inactive</option>
                                </select>
                                <FiChevronDown className="input-with-icon-tag" />
                            </div>
                        </FormGroupWithIcon> */}
                        <FormGroup>
                            <label>Product Description</label>
                            <textarea 
                                placeholder="Product Description"
                                name='description'
                                value={input.description}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>
                        <FlexRow justifyContent="flex-end" topMargin="1">
                            <Button 
                                width='8'
                                height='4'
                                radius='0.8'
                                hoverBgColor='#ffffff'
                                borderColor='#0D968F'
                                hoverColor='#0D968F'
                                fontWeight='600'
                            >
                                Save
                            </Button>
                        </FlexRow>
                    </Form>
                </CustomContainer>
            </SidePopup>
        </>
    )
}
    