import './style.scss'
import { useState, useContext, useEffect } from 'react'
import utility from 'src/utils/utility'
import { AppText, Button, CustomContainer, FlexRow, Input, ScreenContainer } from 'src/style'
import { useNavigate } from 'react-router-dom'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import ApiContext from 'src/provider/API/call-service'
import { Table } from 'src/component'
import { bankColumns, bankPartnerColumns, bankData } from 'src/utils/tableLayout'
import {bankPartners} from 'src/utils/mockedData'
import { BsPlus } from 'react-icons/bs'
import { AddBankPartner } from 'src/popup'
import helpers from 'src/utils/helpers'

const BankPartner: React.FC = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {setInfoProperty} = useContext(AppInfoContext)
    const [bankPartners, setBankPartners] = useState<any>(null)
    const [showAddBankPartner, setShowAddBankPartner] = useState(false)

    useEffect(() => {
        handleFetchBankPartners()
    }, [])

    async function handleFetchBankPartners() {
        const response = await API.getBankPartners()
        if (response) setBankPartners(helpers.addSerialNumberToItems(response))
    }

    async function handleViewDetails (data: any) {
        await setInfoProperty('selectedBankPartner', data)
        navigate('/dashboard/bank-partner/details')
    }

    return (
        <>
            <ScreenContainer>
                <AppText 
                    textSize='1.4' cursor='pointer' 
                >
                    Bank Partner
                </AppText>
                <CustomContainer topMargin='3'>
                    <FlexRow justifyContent='space-between' className='business-title-wrap'>
                        <AppText textSize='3' color='#101828' topMargin='2' fontWeight='600'>Bank Partner</AppText>
                        <Button
                            width='19.7'
                            height='4'
                            radius='0.8'
                            hoverBgColor='#ffffff'
                            borderColor='#0D968F'
                            hoverColor='#0D968F'
                            fontWeight='600'
                            topMargin='2'
                            className='add-business-btn'
                            onClick={() => setShowAddBankPartner(true)}
                        >
                            <BsPlus style={{fontSize: '1.5rem'}}/> 
                            Add New Bank Partner
                        </Button>
                    </FlexRow>
                    <Table 
                        tableData={bankPartners}
                        tableColumns={bankPartnerColumns(handleViewDetails)}
                        customStyle
                        canSearch
                    />
                </CustomContainer>
            </ScreenContainer>
            { showAddBankPartner &&
                <AddBankPartner 
                    close={() => setShowAddBankPartner(false)}
                    updatedViewMethod={handleFetchBankPartners}
                />
            }
        </>
    )
}

export default utility.routeData('bank-partner', 'Bank Partner', <BankPartner/>)