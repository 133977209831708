import { useState, useContext, useEffect } from 'react'
import utility from 'src/utils/utility'
import UserContext from 'src/provider/state-manager/userProvider'
import ApiContext from 'src/provider/API/call-service'
import { AppSpan, AppText, Button, CustomContainer, FlexRow, Input, ScreenContainer } from 'src/style'
import { Table } from 'src/component'
import { approvalConfigurationColumns, approvalConfigurationData } from 'src/utils/tableLayout'
import { Set_EditRule } from 'src/popup'
import helpers from 'src/utils/helpers'


const ApprovalConfiguration: React.FC = () => {
    const {API} = useContext(ApiContext)
    const {user: {userInfo}} = useContext(UserContext)
    const [configurations, setConfigurations] = useState<any>(null)
    const [selectedData, setSelectedData] = useState<any>(null)
    const [showRule, setShowRule] = useState(false)

    useEffect(() => {
        if (userInfo) handleFetchApprovalConfigs()
    }, [userInfo])

    async function handleFetchApprovalConfigs () {
        const response = await API.getApprovalConfigs(userInfo?.user?.institution?.code)
        if (response) setConfigurations(helpers.addSerialNumberToItems(response))
    }

    function handleAction(data: any) {
        setSelectedData(data)
        setShowRule(true)
    }

    async function handleStatusSwitch(data: any) {
        const response = data.status === 'Active' ? await API.deactivateApprovalConfig(data?.id)
            : await API.activateApprovalConfig(data?.id)
        
            if (response) handleFetchApprovalConfigs()
    }


    return (
        <>
            <ScreenContainer>
                <AppText textSize='1.4' cursor='pointer' >
                    Approval Configuration
                </AppText>
                <CustomContainer topMargin='3'>
                    <FlexRow justifyContent='space-between' className='business-title-wrap'>
                        <AppText textSize='3' color='#101828' topMargin='2' fontWeight='600' rightMargin='2.5'>Service Approval Configuration</AppText>
                        {/* <Button
                            width='20'
                            height='4'
                            radius='0.8'
                            hoverBgColor='#ffffff'
                            borderColor='#0D968F'
                            hoverColor='#0D968F'
                            fontWeight='600'
                            topMargin='2'
                            className='add-business-btn'
                        >
                            <BsPlus style={{fontSize: '1.5rem'}}/> 
                            Add New Configuration
                        </Button> */}
                    </FlexRow>
                    <Table 
                        tableData={configurations}
                        tableColumns={approvalConfigurationColumns(handleAction, handleStatusSwitch)}
                        customStyle
                        canSearch
                    />
                </CustomContainer>
            </ScreenContainer>
            { showRule &&
                <Set_EditRule 
                    close={() => setShowRule(false)}
                    data={selectedData}
                    isEdit={ selectedData.status === 'Active' }
                    fetchUpdatedView={handleFetchApprovalConfigs}
                />
            }
        </>
    )
}

export default utility.routeData('approval-configuration', 'Approval Configuration', <ApprovalConfiguration/>)