import { useContext, useState } from "react"
import utility from "src/utils/utility"
import { FiChevronLeft } from "react-icons/fi"
import { SidePopupContainer, SidePopup, FlexRow, AppSpan, CustomContainer, AppText, GridContainer, Form, FormGroup, Button } from "src/style"

export const CardPayment: React.FC<any> = ({back, next, close}) => {
    const [input, setInput] = useState({amount: '', cardName: '', expiryDate: '', cardNumber: '', cvv: ''})

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'amount') {
            let val = e.target.value.replace(/[,.]/g, '')
            if (!isNaN(Number(val))) {
                setInput({...input, amount: val})
            }
        }
        else if (e.target.name === 'expiryDate') {
            let val = e.target.value.replace(/[,./]/g, '')
            if (/^\d+$/.test(val) && val.length <= 6) {
                if (val.length > 2) val = val.substring(0, 2) + '/' + val.substring(2, val.length)
                setInput({...input, expiryDate: val})
            }
        }
        else if (e.target.name === 'cvv') {
            if (e.target.value.length > 3) return false
            else setInput({...input, cvv: e.target.value})
        }
        else setInput({...input, [e.target.name]: e.target.value})
    }

    function handleNext(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        next()
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    <AppSpan 
                        color='#000000' textSize="2.5" fontWeight="500" 
                        hoverColor="#0D968F" onClick={back}
                    >
                        <FiChevronLeft />
                    </AppSpan> 
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <AppText color='#101828' textSize="1.8" fontWeight="700" bottomMargin="2">Pay With Card</AppText>
                    <AppSpan textSize="1.6" color="#101828" fontWeight="300">Kindly enter amount and card details</AppSpan>
                    <CustomContainer topMargin="2.5">
                        <Form onSubmit={handleNext}>
                            <FormGroup>
                                <label>Enter Amount</label>
                                <input 
                                    placeholder='Amount' 
                                    name='amount'
                                    autoComplete='off'
                                    value={input.amount ? utility.koboFormat(input.amount) : ''}
                                    onChange={handleInput}
                                />
                            </FormGroup>
                            <FlexRow gap="2" sizeUnit="%">
                                <FormGroup width="64.5" sizeUnit="%">
                                    <label>Card Name</label>
                                    <input 
                                        placeholder='Card Number' 
                                        name='cardName'
                                        value={input.cardName}
                                        onChange={handleInput}
                                    />
                                </FormGroup>
                                <FormGroup width="33.5" sizeUnit="%">
                                    <label>Expiry</label>
                                    <input 
                                        placeholder='00/23' 
                                        name='expiryDate'
                                        value={input.expiryDate}
                                        onChange={handleInput}
                                    />
                                </FormGroup>
                            </FlexRow>
                            <FlexRow gap="2" sizeUnit="%">
                                <FormGroup width="64.5" sizeUnit="%">
                                    <label>Card Number</label>
                                    <input 
                                        type='number'
                                        placeholder='Card Number' 
                                        name='cardNumber'
                                        value={input.cardNumber}
                                        onChange={handleInput}
                                    />
                                </FormGroup>
                                <FormGroup width="33.5" sizeUnit="%">
                                    <label>CVV</label>
                                    <input 
                                        placeholder='---' 
                                        type='number'
                                        name='cvv'
                                        value={input.cvv}
                                        onChange={handleInput}
                                    />
                                </FormGroup>
                            </FlexRow>
                            <GridContainer alignItems="end" topMargin="1">
                                <Button
                                    width='7.1'
                                    height='4'
                                    radius='0.8'
                                    hoverBgColor='#ffffff'
                                    borderColor='#0D968F'
                                    hoverColor='#0D968F'
                                    fontWeight='600'
                                >
                                    Pay
                                </Button>
                            </GridContainer>
                        </Form>
                    </CustomContainer>
                </CustomContainer>
            </SidePopup>
        </>
    )
}