import React, {useReducer} from 'react'
import { Action, KeyValuePayload } from '../../model'


let initialState = {
    userInfo: {}, userPreAuthInfo: {}
}

const UserContext = React.createContext<any>(null)

const UserReducer = (state: any, action: Action<KeyValuePayload>) => {
    switch(action.type){
        case "set-property": 
            return { ...state, [action.payload.key]: action.payload.value }
        case 'clear-data': 
            return { ...initialState }
    }
}

export const UserProvider = (props: any) => {
    const [state, dispatch] = useReducer(UserReducer, {
        ...initialState
    })

    async function setUserProperty (key: string, value: any) {
        let isString = typeof(value) === 'string' ? true : false
        let isNumber = typeof(value) === 'number' ? true : false
        localStorage.setItem(key, isString ? value : isNumber ? String(value) : JSON.stringify(value))
        await dispatch({type: "set-property", payload: {key, value }})
    }

    async function recoverUserData () {
        for (let item of Object.keys(initialState)) {
            let retrievedData = await localStorage.getItem(item)!
            retrievedData = ['number', 'string'].includes(typeof state[item]) ? retrievedData : ( !['null', 'undefined'].includes(retrievedData) ? JSON.parse(retrievedData): state[item])
            await setUserProperty(item, item === 'notifId' ? Number(retrievedData) : retrievedData)
        }
    }

    async function clearUserData () {
        await dispatch({type: "clear-data", payload: {key: '', value: ''}})
    }
  

    const stateActions = {
       setUserProperty,
       recoverUserData,
       clearUserData
    }

    return (
        <UserContext.Provider value={{user: state, ...stateActions}} >
            {props.children}
        </UserContext.Provider>
    )
}

export default UserContext