import React, { useState, useContext, useEffect } from 'react'
import utility from 'src/utils/utility'
import { AppSpan, AppText, Button, CustomContainer, FlexRow, ScreenContainer } from 'src/style'
import { useNavigate } from 'react-router-dom'
import { Table } from 'src/component'
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import UserContext from 'src/provider/state-manager/userProvider'
import ApiContext from 'src/provider/API/call-service'
import { productServicesColumns, productServicesBanksColumns, productServicesData } from 'src/utils/tableLayout'
import { AddBank, EditProduct, ServiceSetup } from 'src/popup'
import { BiCheck } from 'react-icons/bi'
import { BsPlus } from 'react-icons/bs'
import helpers from 'src/utils/helpers'



const ProductDetails: React.FC = () => {
    const navigate = useNavigate()
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const {info: {selectedProduct}} = useContext(AppInfoContext)
    const {user : {userInfo}} = useContext(UserContext)
    const [productDetails, setProductDetails] = useState<any>(null)
    const [productServices, setProductServices] = useState<any>(null)
    const [productServicesBanks, setProductServicesBanks] = useState<any>(null)
    const [showEditProduct, setShowEditProduct] = useState(false)
    const [showServiceSetup, setShowServiceSetup] = useState(false)
    const [showAddBank, setShowAddBank] = useState(false)
    const userType = userInfo?.user?.institution?.institutionCategory.institutionCategoryType

    useEffect(() => {
        setProductDetails(selectedProduct)
        if (selectedProduct?.services?.length) {
            let services = []
            let serviceBanks = []

            for (let item of selectedProduct?.services) {
                services.push({...item?.service, productServiceId: item?.id, status: item?.status})

                for (let bank of item?.serviceBanks) {
                    serviceBanks.push({...bank, name: bank?.bank?.name})
                }
            }
            console.log({services})
            setProductServices(helpers.addSerialNumberToItems(services))
            //const banks = helpers.getProductServiceBanks(services)
            setProductServicesBanks(helpers.addSerialNumberToItems(serviceBanks))
        }
    }, [selectedProduct])

    async function handleServiceAction (row: any) {
        if (userType != 'ServiceProvider') return notifier.show('You do no have access to activate/deactive product service', 'Access Validation')
        const response = row?.status != 'Active' ? 
            await API.activateProductService(productDetails?.id, row?.productServiceId)
            : await API.deactivateProductService(productDetails?.id, row?.productServiceId)
        
            if (response) API.getProductById(productDetails?.id)
    }

    async function handleBankAction (row: any) {
        if (userType != 'ServiceProvider') return notifier.show('You do no have access to activate/deactive connected banks', 'Access Validation')
        const response = row?.status != 'Active' ? 
            await API.activateProductServiceBank(productDetails?.id, row?.productServiceId, row?.id)
            : await API.deactivateProductServiceBank(productDetails?.id, row?.productServiceId, row?.id)
        
            if (response) API.getProductById(productDetails?.id)
    }

    async function handleProductActivation () {
        const response = productDetails?.status != 'Active' ? 
            await API.activateProduct(productDetails?.id) 
            : await API.deactivateProduct(productDetails?.id)
        if (response) API.getProductById(productDetails?.id)
    }

    async function handleSubmit(payload: any) {
        payload.productId = productDetails?.id
        const response = await API.addProductService(payload)
        if (response) API.getProductById(productDetails?.id)
        setShowServiceSetup(false)
    }

    return (
        <>
            <ScreenContainer bottomPadding='5'>
                <AppText>
                    <AppSpan textSize='1.4' cursor='pointer'  onClick={() => navigate('/dashboard/products')}>Product /</AppSpan>
                    <AppSpan textSize='1.4' cursor='pointer'> Product Details </AppSpan>
                </AppText>
                <CustomContainer topMargin='3'>
                    <FlexRow justifyContent='space-between' className='business-details-title-wrap'>
                        <AppText textSize='3' color='#101828' topMargin='2' rightMargin='2' fontWeight='600'>{productDetails?.name}</AppText>
                        <FlexRow width='auto' topMargin='1' className='actions-business-details-btn'>
                            <Button
                                width='10.8'
                                height='4'
                                radius='0.8'
                                bgColor='#ffffff'
                                hoverBgColor='#ffffff'
                                borderColor='#D0D5DD'
                                color='#344054'
                                hoverColor='#0D968F'
                                rightMargin='1.5'
                                topMargin='1'
                                fontWeight='600'
                                onClick={handleProductActivation}
                            >
                                {productDetails?.status === 'Active' ? 'Deactivate' : 'Activate'}
                            </Button>
                            { userType != 'ServiceProvider' &&
                                <Button
                                    width='8.3'
                                    height='4'
                                    radius='0.8'
                                    hoverBgColor='#ffffff'
                                    borderColor='#0D968F'
                                    hoverColor='#0D968F'
                                    fontWeight='600'
                                    topMargin='1'
                                    className='add-business-btn'
                                >
                                    Go Live
                                </Button>
                            }
                        </FlexRow>  
                    </FlexRow>
                    
                    <FlexRow justifyContent='flex-start' wrap='wrap' topMargin='2'>
                        <AppText 
                            color='#101828' textSize='1.8' fontWeight='600' 
                            bottomMargin='1.5' rightMargin='3'
                        >
                            Product Details
                        </AppText>
                        { userType === 'ServiceProvider' &&
                            <Button
                                width='17.1' height='4' radius='0.8'
                                bgColor='#ffffff' hoverBgColor='#ffffff' borderColor='#D0D5DD'
                                color='#344054' hoverColor='#0D968F'
                                bottomMargin='1.5' fontWeight='600'
                                onClick={() => setShowEditProduct(true)}
                            >
                                Edit Product Details
                            </Button>
                        }
                    </FlexRow>
                    <FlexRow
                        minHeight='11.7' borderColor='#EAECF0'
                        shadow='0px 1px 2px rgba(16, 24, 40, 0.06)'
                        radius='0.8' bgColor='#ffffff' padding='2.2'
                        justifyContent='flex-start' wrap='wrap' topMargin='1'
                    >
                        <CustomContainer width='auto' bottomMargin='1.7' rightMargin='7'>
                            <AppText color='#333542' textSize='1.6' fontWeight='700'>Product</AppText>
                            <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{productDetails?.name}</AppSpan>
                        </CustomContainer>
                        <CustomContainer width='auto' bottomMargin='1.7' rightMargin='7'>
                            <AppText color='#333542' textSize='1.6' fontWeight='700'>Product VP</AppText>
                            <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{productDetails?.productPersonEmail}</AppSpan>
                        </CustomContainer>
                        <CustomContainer width='auto' bottomMargin='1.7' rightMargin='7'>
                            <AppText color='#333542' textSize='1.6' fontWeight='700'>Status</AppText>
                            <FlexRow 
                                width='8' bgColor={productDetails?.status === 'Active' ? '#ECFDF3' : '#FEF3F2'}
                                topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
                            >
                                <AppSpan  
                                    color={productDetails?.status === 'Active' ? '#12B76A' : '#B42318'}  
                                    textSize='1.5' topMargin='-0.2'
                                >
                                    { productDetails?.status === 'Active' ? <BiCheck/> : <i className='fa fa-times-thin' />}
                                </AppSpan>
                                <AppSpan 
                                    color={productDetails?.status === 'Active' ? '#027A48' : '#B42318'}
                                    textSize='1.3' leftPadding='0.5' fontWeight='500'
                                >
                                    {productDetails?.status || 'Inactive'}
                                </AppSpan>
                            </FlexRow>
                        </CustomContainer>
                        <CustomContainer width='auto' bottomMargin='1.7' rightMargin='7'>
                            <AppText color='#333542' textSize='1.6' fontWeight='700'>Product Description</AppText>
                            <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{productDetails?.description}</AppSpan>
                        </CustomContainer>
                    </FlexRow>
                    <CustomContainer>
                        <FlexRow justifyContent='space-between' bottomMargin='1.5'>
                            <AppText color='#101828' textSize='1.8' fontWeight='600' topMargin='2'>Services</AppText>
                            <Button
                                width='16.8'
                                height='4'
                                radius='0.8'
                                hoverBgColor='#ffffff'
                                borderColor='#0D968F'
                                hoverColor='#0D968F'
                                fontWeight='600'
                                topMargin='2'
                                className='add-business-btn'
                                onClick={() => setShowServiceSetup(true)}
                            >
                                <BsPlus style={{fontSize: '1.8rem'}}/> 
                                Add New Service
                            </Button>
                        </FlexRow>
                        <Table 
                            tableData={productServices}
                            tableColumns={productServicesColumns(handleServiceAction)}
                            customStyle
                        />
                    </CustomContainer>
                    <CustomContainer>
                        <FlexRow justifyContent='space-between' bottomMargin='1.5'>
                            <AppText color='#101828' textSize='1.8' fontWeight='600' topMargin='2'>Connected Bank Details</AppText>
                            <Button
                                width='16.8'
                                height='4'
                                radius='0.8'
                                hoverBgColor='#ffffff'
                                borderColor='#0D968F'
                                hoverColor='#0D968F'
                                fontWeight='600'
                                topMargin='2'
                                className='add-business-btn'
                                onClick={() => setShowAddBank(true)}
                            >
                                <BsPlus style={{fontSize: '1.8rem'}}/> 
                                Add New Bank
                            </Button>
                        </FlexRow>
                        <Table 
                            tableData={productServicesBanks}
                            tableColumns={productServicesBanksColumns(handleBankAction)}
                            customStyle
                        />
                    </CustomContainer>
                </CustomContainer>
            </ScreenContainer>
            { showEditProduct &&
                <EditProduct 
                    close={() => setShowEditProduct(false)}
                />
            }
            { showServiceSetup &&
                <ServiceSetup 
                    close={() => setShowServiceSetup(false)}
                    back={() => setShowServiceSetup(false)}
                    isProductService={true}
                    submit={handleSubmit}
                />
            }
            { showAddBank &&
                <AddBank
                    close={() => setShowAddBank(false)}
                    services={productServices}
                    productId={productDetails?.id}
                />
            }
        </>
    )
}

export default utility.routeData('product-details', 'Product Details', <ProductDetails/>)