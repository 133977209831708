import { useContext, useEffect, useState } from 'react'
import utility from 'src/utils/utility'
import { AppSpan, AppText, CustomContainer, FlexRow, Input, ScreenContainer } from 'src/style'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import { Table, ServerSideTable } from 'src/component'
import { auditLogsColumns, auditLogsData } from 'src/utils/tableLayout'
import { FiSearch } from 'react-icons/fi'
import helpers from 'src/utils/helpers'


const AuditLogs: React.FC = () => {
    const navigate = useNavigate()
    const {setInfoProperty} = useContext(AppInfoContext)
    const {API} = useContext(ApiContext)
    const [auditData, setAuditData] = useState<any>(null)
    const [auditLogs, setAuditLogs] = useState<any>(null)

    useEffect(() => {
        handleFetchAuditLogs()
    }, [])

    async function handleFetchAuditLogs (pageNumber = 1, pageSize = 10) {
        const response = await API.getAuditLogs(pageNumber, pageSize)
        if (response) {
            setAuditData(response)
            setAuditLogs(helpers.addSerialNumberToItems(response?.items))
        }
    }

    async function handleViewDetails (data: any) {
        await setInfoProperty('selectedAuditLog', data)
        navigate('/dashboard/audit-log-details')
    }

    return (
        <>
            <ScreenContainer>
                <AppSpan textSize='1.4' cursor='pointer'> Audit Logs </AppSpan>
                <CustomContainer topMargin='3'>
                    <AppText textSize='3' color='#101828' topMargin='2' fontWeight='600'>Audit Logs</AppText>
                    <ServerSideTable
                        tableData={auditLogs} 
                        tableColumns={auditLogsColumns(handleViewDetails)} 
                        dataFetchMethod={ handleFetchAuditLogs}
                        totalRows={auditData?.totalCount}
                        customStyle
                        canSearch
                    />
                </CustomContainer>
            </ScreenContainer>
        </>
    )
}

export default utility.routeData('audit-logs', 'Audit Logs', <AuditLogs/>)