import { useState, useContext, useEffect } from 'react'
import utility from 'src/utils/utility'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import ApiContext from 'src/provider/API/call-service'
import { bankPartnerDetailsColumns, bankPartnerDetailsData } from 'src/utils/tableLayout'
import { bankPartnerAdminData, bankPartnerConfiguration } from 'src/utils/mockedData'
import { AppSpan, AppText, Button, CustomContainer, FlexColumn, FlexRow, ScreenContainer } from 'src/style'
import { useNavigate } from 'react-router-dom'
import { AddBankPartner, AddBankPartnerConfiguration } from 'src/popup'
import { BsPlus } from 'react-icons/bs'
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md'
import { BiCheck } from 'react-icons/bi'

const BankDetails: React.FC = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {info: {selectedBankPartner}, setInfoProperty} = useContext(AppInfoContext)
    const {decisionBox, notifier} = useContext(VisibilityContext)
    const [bankPartnerDetails, setBankPartnerDetails] = useState<any>(null)
    const [bankPartnerAdmin, setBankPartnerAdmin] = useState<any>(null)
    const [configuration, setConfiguration] = useState<any>(null)
    const [showConfiguration, setShowConfiguration] = useState(false)
    const [showEditPartner, setShowEditPartner] = useState(false)


    useEffect(() => {
        setBankPartnerDetails(selectedBankPartner)
        if (selectedBankPartner?.id) {
            !bankPartnerAdmin && handleFetchBankParnerAdmin(selectedBankPartner?.id)
            !configuration && handleFetchConfiguration(selectedBankPartner?.code)
        }
    }, [selectedBankPartner])

    async function handleFetchBankParnerAdmin (id: number) {
        const response = await API.getBankPartnerAdminById(id)
        if (response) setBankPartnerAdmin(response)
    }

    async function handleFetchConfiguration (code: number) {
        const response = await API.getBankByCode(code)
        if (response) setConfiguration(response)
    }

    async function handleFetchBankPartnerById () {
        const response = await API.getBankPartnerById(bankPartnerDetails.id)
        if (response) {
            setBankPartnerDetails(response)
            setInfoProperty('selectedBankPartner', response)
        }
    }

    async function handleBankActivation () {
        const response = bankPartnerDetails?.status != 'Active' ? 
            await API.activateBank(bankPartnerDetails?.code) 
            : await API.deactivateBank(bankPartnerDetails?.code)
        if (response) await API.getBankByCode(bankPartnerDetails?.code)
    }

    // function handleRemove (data: any) {
    //     decisionBox.show(
    //         'Are you sure you want to remove Bank?',
    //         handleRemoveDecision,
    //         null,
    //         'Yes, Remove'
    //     )
    // }

    // function handleRemoveDecision () {
    //     notifier.show('Successfully removed Bank', '')
    // }

    return (
        <>
            <ScreenContainer>
                <AppText textSize='1.4' cursor='pointer' >
                    <AppSpan textSize='1.4' cursor='pointer' hoverColor='#0D968F' onClick={() => navigate('/dashboard/bank-partner')}>Bank Partner </AppSpan>
                    <AppSpan textSize='1.4' cursor='pointer'>/ Bank Partner Details</AppSpan>
                </AppText>
                <CustomContainer topMargin='3'>
                    <FlexRow justifyContent='space-between' className='business-partner-details-wrap'>
                        <FlexRow width='auto' sizeUnit=' ' justifyContent='flex-start' topMargin='1'>
                            <MdOutlineKeyboardArrowLeft 
                                style={{fontSize: '3rem', color: '#101828'}}
                                onClick={() => navigate(-1)} 
                            />
                            <AppText textSize='3' color='#101828' fontWeight='500' fontFamily='Inter' leftMargin='0.5'>
                                Bank Partner Details
                            </AppText>
                        </FlexRow>
                        { !configuration &&
                            <Button
                                width='18'
                                height='4'
                                radius='0.8'
                                hoverBgColor='#ffffff'
                                borderColor='#0D968F'
                                hoverColor='#0D968F'
                                fontWeight='600'
                                topMargin='1'
                                className='add-business-btn'
                                onClick={() => setShowConfiguration(true)}
                            >
                                <BsPlus style={{fontSize: '1.5rem'}}/> 
                                Add Configuration
                            </Button>
                        }
                    </FlexRow>

                    <FlexRow wrap='wrap' width='auto' justifyContent='flex-start' alignItems='flex-start'>
                        <CustomContainer 
                            topMargin='3'
                            className='business-details-card'
                        >
                            <FlexRow justifyContent='flex-start' wrap='wrap'>
                                <AppText 
                                    color='#101828' textSize='1.8' fontWeight='600' 
                                    bottomMargin='1.5' rightMargin='3'
                                >
                                    Bank Partner Contact Details
                                </AppText>
                                <Button
                                    width='11' height='4' radius='0.8'
                                    bgColor='#ffffff' hoverBgColor='#ffffff' borderColor='#D0D5DD'
                                    color='#344054' hoverColor='#0D968F'
                                    bottomMargin='1.5' fontWeight='600'
                                    onClick={() => setShowEditPartner(true)}
                                >
                                    Edit Details
                                </Button>
                            </FlexRow>
                            <CustomContainer
                                minHeight='25.9' borderColor='#EAECF0'
                                shadow='0px 1px 2px rgba(16, 24, 40, 0.06)'
                                radius='0.8' bgColor='#ffffff' padding='2.2' rightPadding='4'
                            >
                                <FlexRow justifyContent='space-between' wrap='wrap'>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='600'>Partner Name</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{bankPartnerDetails?.name}</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='600'>Email Address</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{bankPartnerDetails?.email}</AppSpan>
                                    </CustomContainer>
                                </FlexRow>
                                <FlexRow justifyContent='space-between' wrap='wrap'>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='600'>Business Address</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{bankPartnerDetails?.address}</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='600'>Status</AppText>
                                        <FlexRow 
                                            width='8' bgColor={bankPartnerDetails?.status === 'Active' ? '#ECFDF3' : '#FEF3F2'}
                                            topPadding='0' bottomPadding='0' padding='0.8' radius='1.6' topMargin='0.5'
                                        >
                                            <AppSpan  
                                                color={bankPartnerDetails?.status === 'Active' ? '#12B76A' : '#B42318'}  
                                                textSize='1.5' topMargin='-0.2'
                                            >
                                                { bankPartnerDetails?.status === 'Active' ? <BiCheck/> : <i className='fa fa-times-thin' />}
                                            </AppSpan>
                                            <AppSpan 
                                                color={bankPartnerDetails?.status === 'Active' ? '#027A48' : '#B42318'}
                                                textSize='1.3' leftPadding='0.5' fontWeight='500'
                                            >
                                                {bankPartnerDetails?.status || 'Inactive'}
                                            </AppSpan>
                                        </FlexRow>
                                    </CustomContainer>
                                </FlexRow>
                                <CustomContainer width='17.5' bottomMargin='1.7'>
                                    <AppText color='#333542' textSize='1.6' fontWeight='600'>Phone Number</AppText>
                                    <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{bankPartnerDetails?.phoneNo}</AppSpan>
                                </CustomContainer>
                            </CustomContainer>
                        </CustomContainer>
                        <CustomContainer 
                            topMargin='3' className='business-details-card'
                        >
                            <FlexColumn bottomMargin='1.3' height='4.5' alignItems='flex-start'>
                                <AppText color='#101828' textSize='1.8' fontWeight='600'>
                                    Bank Partner Admin Details
                                </AppText>
                            </FlexColumn>
                            <CustomContainer
                                minHeight='25.9' borderColor='#EAECF0'
                                shadow='0px 1px 2px rgba(16, 24, 40, 0.06)'
                                radius='0.8' bgColor='#ffffff' padding='2.2'
                            >
                                <FlexRow justifyContent='space-between' wrap='wrap'>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='600'>First Name</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{bankPartnerAdmin?.firstName}</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='600'>Last Name</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{bankPartnerAdmin?.lastName}</AppSpan>
                                    </CustomContainer>
                                </FlexRow>
                                <FlexRow justifyContent='space-between' wrap='wrap'>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='600'>Phone Number</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{bankPartnerAdmin?.phoneNo}</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='600'>Username</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{bankPartnerAdmin?.username}</AppSpan>
                                    </CustomContainer>
                                </FlexRow>
                                <FlexRow justifyContent='space-between' wrap='wrap'>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='600'>Email Address</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{bankPartnerAdmin?.email}</AppSpan>
                                    </CustomContainer>
                                </FlexRow>
                            </CustomContainer>
                        </CustomContainer>
                    </FlexRow>

                    { configuration &&
                        <CustomContainer topMargin='5'>
                            <FlexRow justifyContent='flex-start' wrap='wrap'>
                                <AppText 
                                    color='#101828' textSize='1.8' fontWeight='600' 
                                    bottomMargin='1.5' rightMargin='3'
                                >
                                    Configuration Details
                                </AppText>
                                <Button
                                    width='11' height='4' radius='0.8'
                                    bgColor='#ffffff' hoverBgColor='#ffffff' borderColor='#D0D5DD'
                                    color='#344054' hoverColor='#0D968F'
                                    bottomMargin='1.5' fontWeight='600'
                                    onClick={() => setShowConfiguration(true)}
                                >
                                    Edit Details
                                </Button>
                            </FlexRow>
                            <CustomContainer
                                minHeight='12' borderColor='#EAECF0'
                                shadow='0px 1px 2px rgba(16, 24, 40, 0.06)'
                                radius='0.8' bgColor='#ffffff' padding='2.2'
                            >
                                <FlexRow justifyContent='flex-start' wrap='wrap' gap='4' alignItems='flex-start'>
                                    <CustomContainer width='20'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>Short Name</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{configuration?.shortName}</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer width='20'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>CBN Code</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{configuration?.cbnCode}</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer width='20'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>Recova Code</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{configuration?.recovaCode}</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer width='20'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>Prime Code</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{configuration?.primeCode}</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer width='20'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>Zone Code</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{configuration?.zoneCode}</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer width='20'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>BankOne Code</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{configuration?.bankOneCode}</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer width='20'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>Cluster Code</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{configuration?.clusterCode}</AppSpan>
                                    </CustomContainer>
                                </FlexRow>
                            </CustomContainer>
                        </CustomContainer>
                    }
                </CustomContainer>
            </ScreenContainer>
            { showConfiguration &&
                <AddBankPartnerConfiguration 
                    close={() => setShowConfiguration(false)}
                    data={configuration}
                    bankPartnerCode={bankPartnerDetails?.code}
                    updatedViewMethod={() => handleFetchConfiguration(bankPartnerDetails?.code)}
                />
            }
            { showEditPartner &&
                <AddBankPartner 
                    close={() => setShowEditPartner(false)}
                    data={bankPartnerDetails}
                    fetchBankPartner={handleFetchBankPartnerById}
                />
            }
        </>
    )
}

export default utility.routeData('bank-partner/details', 'Bank Details', <BankDetails/>)