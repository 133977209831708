import './style.scss'
import { useState, useContext, useEffect } from 'react'
import utility from 'src/utils/utility'
import UserContext from 'src/provider/state-manager/userProvider';
import ApiContext from 'src/provider/API/call-service';
import { useNavigate } from 'react-router-dom';
import { AppSpan, AppText, Button, CustomContainer, FlexColumn, FlexRow, GridContainer, ScreenContainer } from 'src/style';
import { TransactionInfo } from 'src/popup';
//import {serviceApiTx} from 'src/utils/mockedData'
import HomeIcon from 'src/assets/svg/home-nav-icon'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//import { Carousel } from 'react-responsive-carousel';


const Home: React.FC = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {user : {userInfo}} = useContext(UserContext)
    const [txTime, setTxTime] = useState('12 months')
    const [details, setDetails] = useState<any>(null)
    const [isHover, setIsHover] = useState({homeIcon: false, dashboard: true})
    const [navPosition, setNavPosition] = useState({serviceApiTx: 0, serviceTx: 0, bankPartner: 0, businessPartner: 0})
    const [serviceApiTx, setServiceApiTx] = useState<any>(null)
    const [serviceTx, setServiceTx] = useState<any>(null)
    const [businessTx, setBusinessTx] = useState<any>(null)
    const [bankPartnerTx, setBankPartnerTx] = useState<any>(null)

    useEffect(() => {
        handleFetchTransactions()
    }, [])

    async function handleFetchTransactions () {
        const [serviceApi, service, bankPartner, business] = await Promise.all([
            API.getServiceApiTransactions(),
            API.getServiceTransactions(),
            API.getBankPartnersTransactions(),
            API.getBusinessTransactions()
        ])

        if (serviceApi) setServiceApiTx(serviceApi)
        if (service) setServiceTx(service)
        if (bankPartner) setBankPartnerTx(bankPartner)
        if (business) setBusinessTx(business)
    }

    return (
        <>
            <ScreenContainer>
                <FlexRow width='auto' sizeUnit='' gap='1.5' justifyContent='flex-start'>
                    <GridContainer
                        height='2.8' width='2.8' radius='0.6'
                        bgColor={isHover.homeIcon ? '#E7F6F5' : 'transparent'}
                        onMouseEnter={() => setIsHover({...isHover, homeIcon: true})}
                        onMouseLeave={() => setIsHover({...isHover, homeIcon: false})}
                        onClick={() => navigate('/dashboard/home')}
                    >
                        <HomeIcon isHover={isHover.homeIcon}  className='icon-component' />
                    </GridContainer>
                    <AppSpan textSize='2' color='#D0D5DD'> / </AppSpan>
                    <GridContainer 
                        height='2.8' width='9.2' radius='0.6'
                        bgColor='#E7F6F5'
                    >
                        <AppText 
                            textSize='1.4' cursor='pointer' fontWeight='600'
                            color='#0A756F'
                            onClick={() => navigate('/dashboard/home')}
                        >
                            Dashboard
                        </AppText>
                    </GridContainer>
                </FlexRow>
                <CustomContainer topMargin='5'>
                    <AppText textSize='3' color='#101828' fontWeight='500' fontFamily='Inter'>Welcome Back, {userInfo?.user?.firstName}</AppText>
                    <CustomContainer topMargin='2'>
                        <CustomContainer
                            minHeight='20.8' bgColor='#FFFFFF' radius='0.8'
                            padding='1.5' shadow='0px 1px 2px 0px #1018280F'
                            borderColor='#EAECF0' leftPadding='2' rightPadding='2'
                        >
                            <FlexRow gap='2' justifyContent='space-between' alignItems='center' bottomMargin='1.5'>
                                <FlexRow width='auto' sizeUnit='' gap='6' wrap='wrap' justifyContent='flex-start' className='tx-type-label'>
                                    <AppText textSize='1.8' color='#101828' fontWeight='600'>Service API Transactions</AppText>
                                    <FlexRow width='auto' sizeUnit='' gap='0.8' className='tx-type-toggle-container'>
                                        <GridContainer
                                            width='9.8' height='3.6' radius='0.6'
                                            bgColor={navPosition.serviceApiTx === 0 ? '#E7F6F5' : 'transparent'}
                                            className='toggler'
                                            onClick={() => setNavPosition({...navPosition, serviceApiTx: 0})}
                                        >
                                            <AppSpan 
                                                fontWeight='600' cursor='pointer'
                                                color={navPosition.serviceApiTx === 0 ? '#0A756F' : '#667085'}
                                            >
                                                Successful
                                            </AppSpan>
                                        </GridContainer>
                                        <GridContainer
                                            width='6.6' height='3.6' radius='0.6'
                                            bgColor={navPosition.serviceApiTx != 0 ? '#E7F6F5' : 'transparent'}
                                            className='toggler'
                                            onClick={() => setNavPosition({...navPosition, serviceApiTx: 1})}
                                        >
                                            <AppSpan 
                                                fontWeight='600' cursor='pointer'
                                                color={navPosition.serviceApiTx != 0 ? '#0A756F' : '#667085'}
                                            >
                                                Failed
                                            </AppSpan>
                                        </GridContainer>
                                    </FlexRow>
                                </FlexRow>
                                <AppText 
                                    color='#0A756F' fontWeight='600' cursor='pointer'
                                    className='tx-view-all'
                                >
                                    View all
                                </AppText>
                            </FlexRow>
                            <GridContainer className='scrollable-container' topMargin='0.5'>
                                <FlexRow gap='2' justifyContent='flex-start' >
                                    {
                                        serviceApiTx?.map((item: any, index: number) => {
                                            let color = navPosition.serviceApiTx != 0 ? '#D92D20' : '#0D968F'
                                            return (
                                                <CustomContainer 
                                                    width='20.1' height='11.5' borderColor='#EAECF0' radius='0.8'
                                                    padding='1.5' topPadding='0.5' bottomPadding='0.5'
                                                    shadow='0px 1px 2px 0px #1018280F'
                                                    key={index}
                                                    onClick={() => navigate('/dashboard/transactionData/Service API Transactions')}
                                                >
                                                    <AppSpan >{utility.getCharactersToDispay(item.name, 22)}</AppSpan>
                                                    <FlexRow justifyContent='space-between' topMargin='2.5'>
                                                        <AppSpan textSize='1.2' fontWeight='600'>Count</AppSpan>
                                                        <AppText color={color} textSize='1.6' fontWeight='700'>
                                                            {navPosition.serviceApiTx === 0 ? item.totalTestCountSuccessful : item.totalTestCountFailed}
                                                        </AppText>
                                                    </FlexRow>
                                                    <FlexRow justifyContent='space-between'>
                                                        <AppSpan textSize='1.2' fontWeight='600'>Value</AppSpan>
                                                        <AppText color={color} textSize='1.6' fontWeight='700'>
                                                            {navPosition.serviceApiTx === 0 ? item.totalTestAmountSuccessful : item.totalTestAmountFailed}
                                                        </AppText>
                                                    </FlexRow>
                                                </CustomContainer>
                                            )
                                        })
                                    }
                                </FlexRow>
                            </GridContainer>
                        </CustomContainer>

                        <CustomContainer
                            minHeight='20.8' bgColor='#FFFFFF' radius='0.8'
                            padding='1.5' shadow='0px 1px 2px 0px #1018280F'
                            borderColor='#EAECF0' leftPadding='2' rightPadding='2' topMargin='2'
                        >
                            <FlexRow gap='2' justifyContent='space-between' alignItems='center' bottomMargin='1.5'>
                                <FlexRow width='auto' sizeUnit='' justifyContent='flex-start' gap='6' wrap='wrap' className='tx-type-label'>
                                    <AppText textSize='1.8' color='#101828' fontWeight='600'>Service Transactions</AppText>
                                    <FlexRow width='auto' sizeUnit='' gap='0.8'>
                                        <GridContainer
                                            width='9.8' height='3.6' radius='0.6'
                                            bgColor={navPosition.serviceTx === 0 ? '#E7F6F5' : 'transparent'}
                                            className='toggler'
                                            onClick={() => setNavPosition({...navPosition, serviceTx: 0})}
                                        >
                                            <AppSpan 
                                                fontWeight='600' cursor='pointer'
                                                color={navPosition.serviceTx === 0 ? '#0A756F' : '#667085'}
                                            >
                                                Successful
                                            </AppSpan>
                                        </GridContainer>
                                        <GridContainer
                                            width='6.6' height='3.6' radius='0.6'
                                            bgColor={navPosition.serviceTx != 0 ? '#E7F6F5' : 'transparent'}
                                            className='toggler'
                                            onClick={() => setNavPosition({...navPosition, serviceTx: 1})}
                                        >
                                            <AppSpan 
                                                fontWeight='600' cursor='pointer'
                                                color={navPosition.serviceTx != 0 ? '#0A756F' : '#667085'}
                                            >
                                                Failed
                                            </AppSpan>
                                        </GridContainer>
                                    </FlexRow>
                                </FlexRow>
                                <AppText 
                                    color='#0A756F' fontWeight='600' cursor='pointer'
                                    className='tx-view-all'
                                >
                                    View all
                                </AppText>
                            </FlexRow>
                            <GridContainer className='scrollable-container' topMargin='0.5'>
                                <FlexRow gap='2' justifyContent='flex-start' >
                                    {
                                        serviceTx?.map((item: any, index: number) => {
                                            let color = navPosition.serviceTx != 0 ? '#D92D20' : '#0D968F'
                                            return (
                                                <CustomContainer 
                                                    width='20.1' height='11.5' borderColor='#EAECF0' radius='0.8'
                                                    padding='1.5' topPadding='0.5' bottomPadding='0.5'
                                                    shadow='0px 1px 2px 0px #1018280F'
                                                    key={index}
                                                    onClick={() => navigate('/dashboard/transactionData/Service Transactions')}
                                                >
                                                    <AppSpan >{utility.getCharactersToDispay(item.name, 22)}</AppSpan>
                                                    <FlexRow justifyContent='space-between' topMargin='2.5'>
                                                        <AppSpan textSize='1.2' fontWeight='600'>Count</AppSpan>
                                                        <AppText color={color} textSize='1.6' fontWeight='700'>
                                                            {navPosition.serviceTx === 0 ? item.totalTestCountSuccessful : item.totalTestCountFailed}
                                                        </AppText>
                                                    </FlexRow>
                                                    <FlexRow justifyContent='space-between'>
                                                        <AppSpan textSize='1.2' fontWeight='600'>Value</AppSpan>
                                                        <AppText color={color} textSize='1.6' fontWeight='700'>
                                                            {navPosition.serviceTx === 0 ? item.totalTestAmountSuccessful : item.totalTestAmountFailed}
                                                        </AppText>
                                                    </FlexRow>
                                                </CustomContainer>
                                            )
                                        })
                                    }
                                </FlexRow>
                            </GridContainer>
                        </CustomContainer>

                        <CustomContainer
                            minHeight='20.8' bgColor='#FFFFFF' radius='0.8'
                            padding='1.5' shadow='0px 1px 2px 0px #1018280F'
                            borderColor='#EAECF0' leftPadding='2' rightPadding='2' topMargin='2'
                        >
                            <FlexRow gap='2' justifyContent='space-between' alignItems='center' bottomMargin='1.5'>
                                <FlexRow width='auto' sizeUnit='' justifyContent='flex-start' gap='6' wrap='wrap' className='tx-type-label'>
                                    <AppText textSize='1.8' color='#101828' fontWeight='600'>Bank Partner Transactions</AppText>
                                    <FlexRow width='auto' sizeUnit='' gap='0.8'>
                                        <GridContainer
                                            width='9.8' height='3.6' radius='0.6'
                                            bgColor={navPosition.bankPartner === 0 ? '#E7F6F5' : 'transparent'}
                                            className='toggler'
                                            onClick={() => setNavPosition({...navPosition, bankPartner: 0})}
                                        >
                                            <AppSpan 
                                                fontWeight='600' cursor='pointer'
                                                color={navPosition.bankPartner === 0 ? '#0A756F' : '#667085'}
                                            >
                                                Successful
                                            </AppSpan>
                                        </GridContainer>
                                        <GridContainer
                                            width='6.6' height='3.6' radius='0.6'
                                            bgColor={navPosition.bankPartner != 0 ? '#E7F6F5' : 'transparent'}
                                            className='toggler'
                                            onClick={() => setNavPosition({...navPosition, bankPartner: 1})}
                                        >
                                            <AppSpan 
                                                fontWeight='600' cursor='pointer'
                                                color={navPosition.bankPartner != 0 ? '#0A756F' : '#667085'}
                                            >
                                                Failed
                                            </AppSpan>
                                        </GridContainer>
                                    </FlexRow>
                                </FlexRow>
                                <AppText 
                                    color='#0A756F' fontWeight='600' cursor='pointer'
                                    className='tx-view-all'
                                >
                                    View all
                                </AppText>
                            </FlexRow>
                            <GridContainer className='scrollable-container' topMargin='0.5'>
                                <FlexRow gap='2' justifyContent='flex-start' >
                                    {
                                        bankPartnerTx?.map((item: any, index: number) => {
                                            let color = navPosition.bankPartner != 0 ? '#D92D20' : '#0D968F'
                                            return (
                                                <CustomContainer 
                                                    width='20.1' height='11.5' borderColor='#EAECF0' radius='0.8'
                                                    padding='1.5' topPadding='0.5' bottomPadding='0.5'
                                                    shadow='0px 1px 2px 0px #1018280F'
                                                    key={index}
                                                    onClick={() => navigate('/dashboard/transactionData/Bank Partner Transactions')}
                                                >
                                                    <AppSpan >{utility.getCharactersToDispay(item.name, 22)}</AppSpan>
                                                    <FlexRow justifyContent='space-between' topMargin='2.5'>
                                                        <AppSpan textSize='1.2' fontWeight='600'>Count</AppSpan>
                                                        <AppText color={color} textSize='1.6' fontWeight='700'>
                                                            {navPosition.bankPartner === 0 ? item.totalTestCountSuccessful : item.totalTestCountFailed}
                                                        </AppText>
                                                    </FlexRow>
                                                    <FlexRow justifyContent='space-between'>
                                                        <AppSpan textSize='1.2' fontWeight='600'>Value</AppSpan>
                                                        <AppText color={color} textSize='1.6' fontWeight='700'>
                                                            {navPosition.bankPartner === 0 ? item.totalTestAmountSuccessful : item.totalTestAmountFailed}
                                                        </AppText>
                                                    </FlexRow>
                                                </CustomContainer>
                                            )
                                        })
                                    }
                                </FlexRow>
                            </GridContainer>
                        </CustomContainer>

                        <CustomContainer
                            minHeight='20.8' bgColor='#FFFFFF' radius='0.8'
                            padding='1.5' shadow='0px 1px 2px 0px #1018280F'
                            borderColor='#EAECF0' leftPadding='2' rightPadding='2' topMargin='2'
                        >
                            <FlexRow gap='2' justifyContent='space-between' alignItems='center' bottomMargin='1.5'>
                                <FlexRow width='auto' sizeUnit='' justifyContent='flex-start' gap='6' wrap='wrap' className='tx-type-label'>
                                    <AppText textSize='1.8' color='#101828' fontWeight='600'>Business Partner Transactions</AppText>
                                    <FlexRow width='auto' sizeUnit='' gap='0.8'>
                                        <GridContainer
                                            width='9.8' height='3.6' radius='0.6'
                                            bgColor={navPosition.businessPartner === 0 ? '#E7F6F5' : 'transparent'}
                                            className='toggler'
                                            onClick={() => setNavPosition({...navPosition, businessPartner: 0})}
                                        >
                                            <AppSpan 
                                                fontWeight='600' cursor='pointer'
                                                color={navPosition.businessPartner === 0 ? '#0A756F' : '#667085'}
                                            >
                                                Successful
                                            </AppSpan>
                                        </GridContainer>
                                        <GridContainer
                                            width='6.6' height='3.6' radius='0.6'
                                            bgColor={navPosition.businessPartner != 0 ? '#E7F6F5' : 'transparent'}
                                            className='toggler'
                                            onClick={() => setNavPosition({...navPosition, businessPartner: 1})}
                                        >
                                            <AppSpan 
                                                fontWeight='600' cursor='pointer'
                                                color={navPosition.businessPartner != 0 ? '#0A756F' : '#667085'}
                                            >
                                                Failed
                                            </AppSpan>
                                        </GridContainer>
                                    </FlexRow>
                                </FlexRow>
                                <AppText 
                                    color='#0A756F' fontWeight='600' cursor='pointer'
                                    className='tx-view-all'
                                >
                                    View all
                                </AppText>
                            </FlexRow>
                            <GridContainer className='scrollable-container' topMargin='0.5'>
                                <FlexRow gap='2' justifyContent='flex-start' >
                                    {
                                        businessTx?.map((item: any, index: number) => {
                                            let color = navPosition.businessPartner != 0 ? '#D92D20' : '#0D968F'
                                            return (
                                                <CustomContainer 
                                                    width='20.1' height='11.5' borderColor='#EAECF0' radius='0.8'
                                                    padding='1.5' topPadding='0.5' bottomPadding='0.5'
                                                    shadow='0px 1px 2px 0px #1018280F'
                                                    key={index}
                                                    onClick={() => navigate('/dashboard/transactionData/Business Partner Transactions')}
                                                >
                                                    <AppSpan >{utility.getCharactersToDispay(item.name, 22)}</AppSpan>
                                                    <FlexRow justifyContent='space-between' topMargin='2.5'>
                                                        <AppSpan textSize='1.2' fontWeight='600'>Count</AppSpan>
                                                        <AppText color={color} textSize='1.6' fontWeight='700'>
                                                            {navPosition.businessPartner === 0 ? item.totalTestCountSuccessful : item.totalTestCountFailed}
                                                        </AppText>
                                                    </FlexRow>
                                                    <FlexRow justifyContent='space-between'>
                                                        <AppSpan textSize='1.2' fontWeight='600'>Value</AppSpan>
                                                        <AppText color={color} textSize='1.6' fontWeight='700'>
                                                            {navPosition.businessPartner === 0 ? item.totalTestAmountSuccessful : item.totalTestAmountFailed}
                                                        </AppText>
                                                    </FlexRow>
                                                </CustomContainer>
                                            )
                                        })
                                    }
                                </FlexRow>
                            </GridContainer>
                        </CustomContainer>
                    </CustomContainer>
                </CustomContainer>
            </ScreenContainer>
        </>
    );
}

export default utility.routeData('home', 'Dashboard Home', <Home/>)