import { ReactElement } from "react"
import { RouteData } from "src/model"
import access from "./localAccess"

const routeData = (path: string, name: string, component: ReactElement<any, any>): RouteData => {
    return { 
        routeProps: {
            path,
            element: component
        },
        name
    }
}

const toTitleCase = (text: string) => {
    text = text.toLowerCase()
    return text[0].toUpperCase() + text.substring(1, text.length)
}

const splitCamelCase = (text: string, isTitleCase?: boolean) => {
    let result = text.replace(/([a-z])([A-Z])/g, '$1 $2')
    result = isTitleCase ? result[0].toUpperCase() + result.substring(1) : result 
    return result
}

const getPageTitle = () => {
    let route = toTitleCase(window.location.href.split('/').pop()!)
    let pageTitle = route ? route.split('-').join(' ') : 'Hydra'
    return pageTitle
}

export const erroMessage = (text: any) => {
    const isOnline = access.getInternetStatus()
    return !isOnline ? 'No Internet Connection' : `Message: ${text || 'An error occurred'}`
}

export const requestMessage = (resp: any, text = '') => {
    return resp.message || text || 'Something went wrong'
}

export const catchErrMsg = (err: any) => {
    let message = err?.response?.data?.message
    
    if (err.response?.status === 500) message = 'Something went wrong (Server)'
    return message 
}

const koboFormat = (val: number|string) => {
    let formattedValue = (Number(val) / 100)
    return Number(formattedValue).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export const handleTypeNumberControl = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const regex = new RegExp(/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/)
    if (e.which === 38 || e.which === 40 || !e.key.match(regex)) {
        e.preventDefault();
    }
}

const getCurrentDate = () => {
    const date = new Date();

    let currentDay = String(date.getDate()).padStart(2, '0');
    let currentMonth = String(date.getMonth()+1).padStart(2,"0");
    let currentYear = date.getFullYear();

    let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
    return currentDate
}

export const loglite = (data: any, description?: string) => {
    if (description) console.log(`====== ${description} =======`)
    console.log(data)
}

const getCharactersToDispay = (character = '', acceptedCharacterLength = 20) => {
    let result = character

    if (character.length > acceptedCharacterLength) {
        result = `${result.substring(0, acceptedCharacterLength)}...`
    }

    return result
}

export default {
    routeData,
    toTitleCase,
    getPageTitle,
    requestMessage,
    catchErrMsg,
    erroMessage,
    splitCamelCase,
    koboFormat,
    getCurrentDate,
    getCharactersToDispay
}