import { useState, useEffect, useContext } from 'react'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import UserContext from 'src/provider/state-manager/userProvider'
import { AppText, Button, CustomContainer, FlexRow, Input, ScreenContainer } from 'src/style'
import { useNavigate } from 'react-router-dom'
import { ServerSideTable } from 'src/component'
import { productsColumns, productsData } from 'src/utils/tableLayout'
import { BsPlus } from 'react-icons/bs'
import { AddProduct } from 'src/popup'
import { FiSearch } from 'react-icons/fi'
import helpers from 'src/utils/helpers'


const Products: React.FC = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {setInfoProperty} = useContext(AppInfoContext)
    const {user : {userInfo}} = useContext(UserContext)
    const [products, setProducts] = useState<any>(null)
    const [productItems, setProductItems] = useState<any>(null)
    const [showAddProduct, setShowAddProduct] = useState(false)
    const userType = userInfo?.user?.institution?.institutionCategory.institutionCategoryType

    useEffect(() => {
        handleFetchProducts()
    }, [])

    async function handleFetchProducts (pageNumber = 1, pageSize = 10) {
        const response = await API.getProducts(pageNumber, pageSize)
        if (response) {
            setProducts(response)
            setProductItems(helpers.addSerialNumberToItems(response?.items))
        }
    }

    async function handleViewDetails (data: any) {
        await setInfoProperty('selectedProduct', data)
        navigate('/dashboard/product-details')
    }

    return (
        <>
            <ScreenContainer>
                <AppText 
                    textSize='1.4' cursor='pointer' 
                    onClick={() => navigate('/dashboard/products')}
                >
                    Products
                </AppText>
                <CustomContainer topMargin='3'>
                    <FlexRow justifyContent='space-between' className='business-title-wrap'>
                        <AppText textSize='3' color='#101828' topMargin='2' fontWeight='600'>Products</AppText>
                        { userType === 'ServiceProvider' &&
                            <Button
                                width='16.2'
                                height='4'
                                radius='0.8'
                                hoverBgColor='#ffffff'
                                borderColor='#0D968F'
                                hoverColor='#0D968F'
                                fontWeight='600'
                                topMargin='2'
                                className='add-business-btn'
                                onClick={() => setShowAddProduct(true)}
                            >
                                <BsPlus style={{fontSize: '1.5rem'}}/> 
                                Add New Product
                            </Button>
                        }
                    </FlexRow>
                    <ServerSideTable
                        tableData={productItems} 
                        tableColumns={productsColumns(handleViewDetails)} 
                        dataFetchMethod={ handleFetchProducts}
                        totalRows={products?.totalCount}
                        customStyle
                        canSearch
                       // pageNumber={1}
                    />
                </CustomContainer>
            </ScreenContainer>
            { showAddProduct &&
                <AddProduct 
                    close={() => setShowAddProduct(false)}
                    fetchUpdateMethod={handleFetchProducts}
                />
            }
        </>
    )
}

export default utility.routeData('products', 'Products', <Products/>)