import { useEffect, useState, useContext } from 'react'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import { AppText, Button, CustomContainer, FlexRow, ScreenContainer } from 'src/style'
import { useNavigate } from 'react-router-dom'
import { Table } from 'src/component'
import { serviceAPIsColumns, serviceAPIsData } from 'src/utils/tableLayout'
import { AddServiceAPI } from 'src/popup'
import { BsPlus } from 'react-icons/bs'
import helpers from 'src/utils/helpers'


const ServiceAPIs: React.FC = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const [services, setServices] = useState<any>()
    const [showAddService, setShowAddService] = useState(false)

    useEffect(() => {
        handleFetchServiceAPIs()
    }, [])

    async function handleFetchServiceAPIs() {
        const response = await API.getAllServiceAPIs()
        if (response) setServices(helpers.addSerialNumberToItems(response))
    }



    return (
        <>
            <ScreenContainer>
                <AppText textSize='1.4' cursor='pointer' >
                    Service APIs
                </AppText>
                <CustomContainer topMargin='3'>
                <FlexRow justifyContent='space-between' className='business-title-wrap'>
                        <AppText textSize='3' color='#101828' topMargin='2' fontWeight='600'>Service APIs</AppText>
                        <Button
                            width='14.3'
                            height='4'
                            radius='0.8'
                            hoverBgColor='#ffffff'
                            borderColor='#0D968F'
                            hoverColor='#0D968F'
                            fontWeight='600'
                            topMargin='2'
                            className='add-business-btn'
                            onClick={() => setShowAddService(true)}
                        >
                            <BsPlus style={{fontSize: '1.5rem'}}/> 
                            Add Service API
                        </Button>
                    </FlexRow>
                    <Table 
                        tableData={services}
                        tableColumns={serviceAPIsColumns}
                        customStyle
                        canSearch
                    />
                </CustomContainer>
            </ScreenContainer>
            { showAddService &&
                <AddServiceAPI 
                    close={() => setShowAddService(false)}
                    fetchServiceAPIs={handleFetchServiceAPIs}
                />
            }
        </>
    )
}

export default utility.routeData('service-apis', 'Service APIS', <ServiceAPIs/>)