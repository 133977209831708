import { useState, useContext, useEffect } from "react"
import { PopupStackIcon } from "src/assets/icons"
import { SidePopupContainer, SidePopup, FlexRow, AppSpan, AppText, CustomContainer, Form, FormGroup, Button, FormGroupWithIcon } from "src/style"
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import ApiContext from "src/provider/API/call-service"
import { FiChevronDown } from "react-icons/fi"

export const AddServiceAPI: React.FC<any> = ({fetchServiceAPIs, close}) => {
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const [input, setInput] = useState({name: '', serviceApiType: ''})
    const [serviceApiTypes, setServiceApiTypes] = useState<any>(null)

    useEffect(() => {
        handleFetchServiceAPITypes()
    }, [])

    async function handleFetchServiceAPITypes() {
        const response = await API.getAllServiceAPITypes()
        if (response) setServiceApiTypes(response)
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>) => setInput({...input, [e.target.name]: e.target.value})

    async function handleSubmit (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()

        const response = await API.createServiceAPI(input)
        if (fetchServiceAPIs) fetchServiceAPIs()
        close()
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    <PopupStackIcon/> 
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <AppText color='#101828' textSize="1.8" fontWeight="700">Add New Service API</AppText>
                    <Form topMargin="2" onSubmit={handleSubmit}>
                        <FormGroup>
                            <label>Name</label>
                            <input 
                                placeholder="Service API Name" 
                                name='name'
                                value={input.name}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>
                        <FormGroupWithIcon bottomMargin='2.5'>
                                <label>Service API Type</label>
                                <div className="input-with-icon">
                                    <select 
                                        name="serviceApiType" 
                                        onChange={handleInput}
                                        style={{color: input.serviceApiType ? '#667085' : '#999'}}
                                        value={input.serviceApiType}
                                        required
                                    >
                                        <option value="">Select Service Api Type</option>
                                        {
                                            serviceApiTypes?.map((item: any, index: number) => {
                                                return (
                                                    <option value={item.value} key={index}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <FiChevronDown className="input-with-icon-tag" />
                                </div>
                            </FormGroupWithIcon>
                        <FlexRow justifyContent="flex-end">
                            <Button 
                                width='8'
                                height='4'
                                radius='0.8'
                                hoverBgColor='#ffffff'
                                borderColor='#0D968F'
                                hoverColor='#0D968F'
                                fontWeight='600'
                                topMargin="1"
                            >
                                Save
                            </Button>
                        </FlexRow>
                    </Form>
                </CustomContainer>
            </SidePopup>
        </>
    )
}