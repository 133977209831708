import { useState } from "react"
import helpers from "src/utils/helpers"
import { FiSearch } from "react-icons/fi"
import { ReactComponent as BillingIcon } from "src/assets/svg/billing-icon.svg"
import { SidePopupContainer, SidePopup, FlexRow, AppSpan, CustomContainer, AppText, Input } from "src/style"

export const FundHistory: React.FC<any> = ({data, close}) => {
    const [mockFundingHistory, setMockFundingHistory] = useState(data)

    function handleDataSearching (e: React.ChangeEvent<HTMLInputElement>) {
        const filteredData = helpers.searchTableData(data, e.target.value)
        console.log({filteredData})
        setMockFundingHistory(filteredData)
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    <BillingIcon />  
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <AppText color='#101828' textSize="1.8" fontWeight="700">Wallet Funding History</AppText>
                    <CustomContainer
                        radius='0.8' borderColor='#D0D5DD' 
                        leftPadding='1.5' rightPadding='1.5' topMargin="2"
                        shadow='0px 1px 2px rgba(16, 24, 40, 0.05)'
                        className='service-search-box'
                    >
                        <FlexRow>
                            <FiSearch style={{fontSize: '2rem'}} />
                            <Input 
                                height='4' leftPadding='1' 
                                placeholder='Search' 
                                onChange={handleDataSearching} 
                            />
                        </FlexRow>
                    </CustomContainer>
                    <CustomContainer topMargin='2'>
                        {
                            mockFundingHistory?.map((item: any, index: number) => {
                                return (
                                    <FlexRow 
                                        justifyContent='space-between' topPadding='1.2'
                                        bottomPadding='1.2' borderColor='#EAECF0' key={index}
                                        style={{borderLeft: 'none', borderRight: 'none'}}
                                    >
                                        <CustomContainer width='auto' sizeUnit=''>
                                            <AppSpan color='#101828' fontWeight='600'>{item.transactionType}</AppSpan>
                                            <AppText color='#101828'>{item.date}</AppText>
                                        </CustomContainer>
                                        <AppSpan color='#039855'>+ N{item.amount}</AppSpan>
                                    </FlexRow>
                                )
                            })
                        }
                    </CustomContainer>
                </CustomContainer>
            </SidePopup>
        </>
    )
}