import { useState, useContext } from 'react'
import utility from 'src/utils/utility'
import { useNavigate } from 'react-router-dom'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import ApiContext from 'src/provider/API/call-service'
import UserContext from 'src/provider/state-manager/userProvider'
import { 
    AppLink, AppText, Button, CenterContainer, 
    CustomContainer, FlexColumn, FlexRow, Form, FormGroup
} from 'src/style'
import { HydraLogo } from 'src/assets/icons'
import { MainBgImage } from 'src/component'
import helpers from 'src/utils/helpers'


const Identification: React.FC = () => {
    const navigate = useNavigate()
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const [email, setEmail] = useState('')

    async function handleSendVerificationCode (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        if (!helpers.validateEmail(email)) notifier.show('Kindly provide a valid email')
        else {
            const response = await API.getCodeForPasswordReset(email)
            if (response) navigate('/forget-password/new-password')
        }
    }

    return (
        <CustomContainer height='100' hUnit='%' overflow='auto'>
            <MainBgImage />
            <CenterContainer 
                width='45' topMargin='5' 
                bottomMargin='2' className='login-content'
            >
                <FlexColumn>
                    <FlexRow width='auto' sizeUnit=' '>
                        <HydraLogo/>
                        <AppText color='#555554' textSize='1.9' fontWeight='700' leftMargin='1' fontFamily='Nunito'>hydra</AppText>
                    </FlexRow>
                    <FlexColumn topMargin='2'>
                        <AppText color='#101828' fontWeight='600' textSize='3' fontFamily='Inter'>Forgot Password</AppText>
                    </FlexColumn>
                    <Form topMargin='3' onSubmit={handleSendVerificationCode}>
                        <CustomContainer 
                            topPadding='4' leftPadding='4' bottomPadding='4'
                            rightPadding='4' bgColor='#FFFFFF' radius='1.2'
                            shadow='0px 4px 8px 0px rgba(16, 24, 40, 0.1)'
                        >
                            <FormGroup>
                                <label>Email</label>
                                <input 
                                    placeholder='Enter Your email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </FormGroup>
                            <Button 
                                width='100' sizeUnit='%' topMargin='1' 
                                borderColor='#0D968F' hoverBgColor='#fff' 
                                hoverColor='#0D968F' titleSize='1.6'
                            >
                                Next
                            </Button>
                        </CustomContainer>
                        <AppText align='center' topMargin='3'>
                            <AppLink 
                                color='#1E1E1E' decoration='none' hoverColor='#0D968F' fontWeight='600'
                                onClick={() => navigate('/login')}
                            > 
                                Back to Login
                            </AppLink>
                        </AppText>
                    </Form>
                </FlexColumn>
            </CenterContainer>
        </CustomContainer>
    )
}

export default utility.routeData('forget-password/identification', 'User Identification', <Identification/>)