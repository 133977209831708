import * as IoIcons from 'react-icons/io'
import { SideBarIcons } from './style'
import * as MdIcons from 'react-icons/md'


export const sideBarData = [
    {
        title: 'Dashboard',
        path: '/dashboard/home',
        accessBy: 'all',
        icon: <SideBarIcons><MdIcons.MdOutlineSpaceDashboard/></SideBarIcons>
    },
    {
        title: 'Bank Partner',
        path: '/dashboard/bank-partner',
        accessBy: 'ServiceProvider',
        icon: <SideBarIcons><MdIcons.MdOutlineAccountBalance/></SideBarIcons>
    },
    {
        title: 'Business Partner',
        path: '/dashboard/business',
        accessBy: 'all',
        icon: <SideBarIcons><MdIcons.MdOutlineCases/></SideBarIcons>,
    },
    // {
    //     title: 'Business Partner',
    //     path: '/dashboard/business-partner',
    //     accessBy: 'all',
    //     icon: <SideBarIcons><MdIcons.MdOutlineViewList/></SideBarIcons>,
    // },
    {
        title: 'Services',
        path: '',
        accessBy: 'ServiceProvider',
        icon: <SideBarIcons><MdIcons.MdOutlineWorkOutline /></SideBarIcons>,
        iconClosed: <IoIcons.IoIosArrowDown/>,
        iconOpened: <IoIcons.IoIosArrowUp/>,
        subNav: [
            {
                title: 'View Services',
                path: '/dashboard/services',
                accessBy: 'ServiceProvider',
                icon: <i className="fa fa-resistance sub-nav-icons"></i>
            },
            {
                title: 'Service Approval',
                path: '/dashboard/service-approval',
                accessBy: 'ServiceProvider',
                icon: <i className="fa fa-resistance sub-nav-icons"></i>
            },
            {
                title: 'Configuration',
                path: '/dashboard/approval-configuration',
                accessBy: 'all',
                icon: <i className="fa fa-resistance sub-nav-icons"></i>
            },
            // {
            //     title: 'Service APIs',
            //     path: '/dashboard/service-apis', service-approval
            //     accessBy: 'ServiceProvider',
            //     icon: <i className="fa fa-resistance sub-nav-icons"></i>
            // },
        ]
    },
    {
        title: 'Products',
        path: '/dashboard/products',
        accessBy: 'all',
        icon: <SideBarIcons><MdIcons.MdOutlineInventory2/></SideBarIcons>
    },
    {
        title: 'Transactions',
        path: '',
        icon: <SideBarIcons><MdIcons.MdOutlineTableRows/></SideBarIcons>,
        iconClosed: <IoIcons.IoIosArrowDown/>,
        iconOpened: <IoIcons.IoIosArrowUp/>,
        accessBy: 'all',
        subNav: [
            {
                title: 'Business Transactions',
                path: '/dashboard/business-transactions',
                accessBy: 'ServiceProvider',
                icon: <i className="fa fa-resistance sub-nav-icons"></i>
            },
            {
                title: 'Service Transactions',
                path: '/dashboard/service-transactions',
                accessBy: 'ServiceProvider',
                icon: <i className="fa fa-resistance sub-nav-icons"></i>
            },
            {
                title: 'Product Transactions',
                path: '/dashboard/product-transactions',
                accessBy: 'ApiConsumer',
                icon: <i className="fa fa-resistance sub-nav-icons"></i>
            },
        ]
    },
    // {
    //     title: 'Approvals',
    //     path: '',
    //     icon: <SideBarIcons><MdIcons.MdOutlineWorkOutline /></SideBarIcons>,
    //     iconClosed: <IoIcons.IoIosArrowDown/>,
    //     iconOpened: <IoIcons.IoIosArrowUp/>,
    //     accessBy: 'all',
    //     subNav: [
    //         {
    //             title: 'Approval Requests',
    //             path: '/dashboard/approval-requests',
    //             accessBy: 'all',
    //             icon: <i className="fa fa-resistance sub-nav-icons"></i>
    //         },
    //         {
    //             title: 'Approval Timelines',
    //             path: '/dashboard/approval-timelines',
    //             accessBy: 'all',
    //             icon: <i className="fa fa-resistance sub-nav-icons"></i>
    //         },
    //     ]
    // },
    {
        title: 'Users',
        path: '',
        icon: <SideBarIcons><MdIcons.MdOutlinePeopleAlt/></SideBarIcons>,
        iconClosed: <IoIcons.IoIosArrowDown/>,
        iconOpened: <IoIcons.IoIosArrowUp/>,
        accessBy: 'all',
        subNav: [
            {
                title: 'View Users',
                path: '/dashboard/users',
                accessBy: 'all',
                icon: <i className="fa fa-resistance sub-nav-icons"></i>
            },
            {
                title: 'Manage Roles',
                path: '/dashboard/manage-user-roles',
                accessBy: 'all',
                icon: <i className="fa fa-resistance sub-nav-icons"></i>
            },
        ]
    },
    {
        title: 'Audit Logs',
        path: '/dashboard/audit-logs',
        accessBy: 'all',
        icon: <SideBarIcons><MdIcons.MdOutlineEventNote/></SideBarIcons>
    },
    {
        title: 'Billing',
        path: '',
        icon: <SideBarIcons><MdIcons.MdOutlineEventNote/></SideBarIcons>,
        iconClosed: <IoIcons.IoIosArrowDown/>,
        iconOpened: <IoIcons.IoIosArrowUp/>,
        accessBy: 'all',
        subNav: [
            {
                title: 'Billing Profile',
                path: '/dashboard/billing-profile',
                accessBy: 'all',
                icon: <i className="fa fa-resistance sub-nav-icons"></i>
            },
            {
                title: 'Billing Configuration',
                path: '/dashboard/billing-configuration',
                accessBy: 'all',
                icon: <i className="fa fa-resistance sub-nav-icons"></i>
            },
        ]
    },
    // {
    //     title: 'Billing Configuration',
    //     path: '/dashboard/billing-configuration',
    //     accessBy: 'all',
    //     icon: <SideBarIcons><MdIcons.MdOutlineArticle/></SideBarIcons>,
    // }
]