import './style.scss'
import {useState, useContext, useEffect} from 'react'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { useNavigate } from 'react-router-dom'
import { BiCheck } from 'react-icons/bi'
import { 
    AppSpan, AppText, Button, CenterContainer, 
    CustomContainer, FlexColumn, FlexRow, Form, FormGroup
} from 'src/style'
import { HydraLogo } from 'src/assets/icons'
import { MainBgImage } from 'src/component'
import helpers from 'src/utils/helpers'


const SignIn: React.FC = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {notifier} = useContext(VisibilityContext)
    const [isRemember, setIsRemember] = useState(localStorage.getItem('isRememberMe') || false)
    const [input, setInput] = useState({email: '', password: ''})

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => setInput({...input, [e.target.name]: e.target.value})

    function handleRememberMe () {
        const rememberMe = isRemember ? '' : 'true'
        localStorage.setItem('isRememberMe', rememberMe)
        setIsRemember(!isRemember)
    }

    async function handleLogin(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        const {isValidated, message} = helpers.inputValidator(input)
        if (!isValidated) return notifier.show(message)
        if (!helpers.validateEmail(input.email)) return notifier.show('Invalid email provided')

        const response = await API.login(input)
        if (response) navigate('/dashboard/home')
    }

    return (
        <CustomContainer height='100' hUnit='%' overflow='auto'>
            <MainBgImage />
            <CenterContainer 
                width='45' topMargin='5'
                bottomMargin='2' className='login-content'
            >
                <FlexColumn>
                    <FlexRow width='auto' sizeUnit=' '>
                        <HydraLogo/>
                        <AppText color='#555554' textSize='1.9' fontWeight='700' leftMargin='1' fontFamily='Nunito'>hydra</AppText>
                    </FlexRow>
                    <FlexColumn topMargin='2'>
                        <AppText color='#101828' fontWeight='600' textSize='3' fontFamily='Inter'>Log in to your account</AppText>
                        <AppSpan textSize='1.6' topPadding='0.7'>Welcome back! Please enter your details.</AppSpan>
                    </FlexColumn>
                    <Form topMargin='2.5' onSubmit={handleLogin}>
                        <CustomContainer 
                            topPadding='4' bottomPadding='4' leftPadding='4' 
                            rightPadding='4' bgColor='#FFFFFF' radius='1.2'
                            shadow='0px 4px 8px 0px rgba(16, 24, 40, 0.1)'
                            bottomMargin='3'
                        >
                            <FormGroup>
                                <label>Email</label>
                                <input 
                                    placeholder='Enter your email' 
                                    name='email'
                                    value={input.email}
                                    onChange={handleInput}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Password</label>
                                <input 
                                    type='password' 
                                    placeholder='************' 
                                    name='password'
                                    value={input.password}
                                    onChange={handleInput}
                                />
                            </FormGroup>
                            <FlexRow justifyContent='flex-end' className='rememberme-fPassword'>
                                {/* <FlexRow width='auto' sizeUnit=' ' justifyContent='flex-start'>
                                    <div 
                                        className={`remember-checkbox ${isRemember && 'isremember-me'}`}
                                        onClick={handleRememberMe}
                                    >
                                        {isRemember ? <BiCheck className='checkbox-icon' /> : null}
                                    </div>
                                    <AppText color='#344054' textSize='1.4' fontWeight='600' leftPadding='1'>Remember for 30 days</AppText>
                                </FlexRow> */}
                                <AppSpan 
                                    color='#344054' textSize='1.4' fontWeight='600' 
                                    className='forget-password' cursor='pointer'
                                    hoverColor='#0D968F'
                                    onClick={() => navigate('/forget-password/identification')}
                                >
                                    Forgot Password
                                </AppSpan>
                            </FlexRow>
                            <Button 
                                width='100' sizeUnit='%' topMargin='3' 
                                borderColor='#0D968F' hoverBgColor='#fff' 
                                hoverColor='#0D968F' titleSize='1.6'
                            >
                                Sign in
                            </Button>
                        </CustomContainer>
                        {/* <AppText align='center' topMargin='3'>Don’t have an account? 
                            <AppLink color='#1E1E1E' decoration='none' hoverColor='#0D968F' fontWeight='600'> Sign up</AppLink>
                        </AppText> */}
                    </Form>
                </FlexColumn>
            </CenterContainer>
        </CustomContainer>
    )
}

export default utility.routeData('login', 'SignIn', <SignIn/>)