import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { StyleProps } from 'src/model'

export const SideBarLink = styled(NavLink)<StyleProps>`
   list-style: none;
   text-decoration: none !important;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   padding-right: 3rem;
   padding-top: 0.3rem;
   padding-bottom: 0.3rem;
   margin-bottom: 1rem;
   font-size: 1.5rem;
   transition: all 0.2s;
   color: ${({isclicked}) => !!isclicked ? '#fff' : '#667085'};
   background: ${({isclicked}) => !!isclicked ? '#0D968F' : 'default'};
   border-radius: ${({isclicked}) => !!isclicked ? '0.8rem' : 'none'};
   &:hover {
        background: #0D968F;
        color: #ffffff;
        border-radius: 0.8rem
    }
    &:hover div >i {
        color: #ffffff
    }
`
export const SideBarLinkWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: .5rem 1.5rem;
    font-weight: 400;
    transition: all 0.1s;
    @media (max-width: 500px) {
        font-size: 1.7rem;
    }
`
export const SideBarLabel = styled.span`
    margin-left: 1rem;
`
export const ArrowIconWrapper = styled.div`
    font-size: 1.6rem;
    @media (max-width: 500px) {
        font-size: 1.9rem;
    }
`
export const SubNavWrapper = styled.div<StyleProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 2rem;
    padding: .5rem 1.5rem;
    font-weight: 400;
    font-size: 1.4rem;

    @media (max-width: 500px) {
        font-size: 1.7rem;
    }
    
`
export const SideBarIcons = styled.i<StyleProps>`
    box-sizing: border-box;
    font-size: ${({size}) => size || 2}rem;
    margin-top: -0.5rem;
`