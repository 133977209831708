import { useState } from "react"
import { handleTypeNumberControl } from "src/utils/utility"
import { FiChevronLeft } from "react-icons/fi"
import { SidePopupContainer, SidePopup, FlexRow, AppSpan, CustomContainer, AppText, GridContainer, Form, FormGroup, Button, PinLabel, AppLink } from "src/style"

export const OtpValidation: React.FC<any> = ({back, next, close}) => {
    const [txPin, setTxPin] = useState('')

    const handlePin = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length < 5) {
            setTxPin(e.target.value)
        }
    }

    function handleSubmit(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        next()
        close()
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    <AppSpan 
                        color='#000000' textSize="2.5" fontWeight="500" 
                        hoverColor="#0D968F" onClick={back}
                    >
                        <FiChevronLeft />
                    </AppSpan> 
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <GridContainer>
                        <AppText color='#101828' textSize="1.8" fontWeight="700" bottomMargin="2">Please check your email</AppText>
                        <AppSpan textSize="1.6" color="#101828" fontWeight="300">We've sent a code to olivia@gmail.com</AppSpan>
                    </GridContainer>
                    <Form onSubmit={handleSubmit} topMargin='2'>
                        <GridContainer 
                            width="80" sizeUnit="%"
                            style={{margin: '0 auto'}}
                            className='otp-container'
                        >
                            <FlexRow 
                                width="auto" sizeUnit="" gap='1.4'
                            >
                                <input 
                                    className='display-hidden' id='pin' 
                                    name="txPin" value={txPin} type='number'
                                    onChange={handlePin} 
                                    onKeyDown={handleTypeNumberControl}
                                    onKeyUp={handleTypeNumberControl}
                                    autoFocus={true} autoComplete="off"
                                />
                                <PinLabel style={{paddingTop: '1rem'}} className={`${txPin.length === 0 ? 'active-pin-box' : ''}`} htmlFor='pin' >{txPin[0] ? '*' : ''}</PinLabel>
                                <PinLabel style={{paddingTop: '1rem'}} className={`${txPin.length === 1 ? 'active-pin-box' : ''}`} htmlFor='pin' >{txPin[1] ? '*' : ''}</PinLabel>
                                <PinLabel style={{paddingTop: '1rem'}} className={`${txPin.length === 2 ? 'active-pin-box' : ''}`} htmlFor='pin' >{txPin[2] ? '*' : ''}</PinLabel>
                                <PinLabel style={{paddingTop: '1rem'}} className={`${txPin.length >= 3 ? 'active-pin-box' : ''}`} htmlFor='pin' >{txPin[3] ? '*' : ''}</PinLabel>
                            </FlexRow>
                            <GridContainer alignItems="start" className="resend-otp">
                                <AppSpan fontWeight='300'>
                                    Didn’t get a code? 
                                <   AppLink hoverColor="#0D968F" leftMargin="0.5">Click to resend</AppLink>
                                </AppSpan>
                            </GridContainer>
                        </GridContainer>
                        <Button 
                            width='100' sizeUnit="%" height='4' topMargin='2' 
                            borderColor='#0D968F' hoverBgColor='#fff' 
                            hoverColor='#0D968F' fontWeight="600"
                        >
                            Complete
                        </Button>
                    </Form>
                </CustomContainer>
            </SidePopup>
        </>
    )
}