import { useState, useContext, useEffect } from "react"
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import ApiContext from "src/provider/API/call-service"
import { FiChevronDown, FiChevronLeft, FiChevronUp } from "react-icons/fi"
import { PopupUserIcon } from "src/assets/icons"
import { AppSpan, AppText, Button, CustomContainer, Divider, FlexRow, Form, FormGroup, SidePopup, SidePopupContainer } from "src/style"
import { CheckboxSelection } from "src/component"
import helpers from "src/utils/helpers"

export const AddRole: React.FC<any> = ({data, getRoleMethod, isEdit, setEdit, close}) => {
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const [isFunctions, setIsFunctions] = useState(false)
    const [manageAccordion, setManageAccordion] = useState<any>({})
    const [input, setInput] = useState({name: data?.name || '', description: data?.description || ''})
    const [tasks, setTasks] = useState<any[]>([])
    const [assignedTaskIDs, setAssignedTaskIDs] = useState<number[]>([])

    useEffect(() => {
        handleFetchTasks()
        if (data) {
            const ids: number[] = []
            for (let item of data?.assignedTasks) ids.push(item.id)

            setAssignedTaskIDs(ids)
        }

        return () => setEdit(false)
    }, [])

    async function handleFetchTasks () {
        const response = await API.getTasks()
        if (response) {
            setTasks(helpers.groupTasks(response))
        }
    }

    function handleInput (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) {
        setInput({...input, [e.target.name]: e.target.value})
    }

    function handleSelectedTasks (val: boolean, id: number) {
        if (val) setAssignedTaskIDs([...assignedTaskIDs, id])
        else {
            let filteredTaskIds = [...assignedTaskIDs].filter((item) => item != id)
            setAssignedTaskIDs(filteredTaskIds)
        }
    }

    function handleSelectAll (val: boolean, data: any[]) {
        const ids: number[] = []
        for (let item of data) ids.push(item.id)

        if (val) setAssignedTaskIDs([...assignedTaskIDs, ...ids])
        else {
            let filteredTaskIds = [...assignedTaskIDs].filter((item) => !ids.includes(item))
            setAssignedTaskIDs(filteredTaskIds)
        }
    }

    function handleNext(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        setIsFunctions(true)
    }

    async function handleSubmit(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        let uniqueIds = assignedTaskIDs.filter((element, index) => {
            return assignedTaskIDs.indexOf(element) === index;
        });

        const response = !isEdit ? await API.createNewRole({...input, assignedTaskIDs: uniqueIds})
            : await API.updateRole({...input, assignedTaskIDs: uniqueIds, id: data?.id})

        if (response) getRoleMethod()
        close()
    }

    function getIds (data: any[]) {
        const ids = []
        for (let item of data) {
            ids.push(item?.id)
        }

        return ids
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    { !isFunctions ? 
                        <PopupUserIcon/> 
                        : 
                        <AppSpan 
                            color='#000000' textSize="2.5" fontWeight="500" 
                            hoverColor="#0D968F" onClick={() => setIsFunctions(false)}
                        >
                            <FiChevronLeft />
                        </AppSpan> 
                    }
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                {   !isFunctions &&
                    <CustomContainer topMargin="3">
                        <AppText color='#101828' textSize="1.8" fontWeight="700">{isEdit ? 'Edit Role' : 'Add New Role'}</AppText>
                        <Form topMargin="2" onSubmit={handleNext}>
                            <FormGroup>
                                <label>Enter Role Name</label>
                                <input 
                                    placeholder="Role" 
                                    name='name'
                                    value={input.name}
                                    onChange={handleInput}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Enter Description</label>
                                <textarea 
                                    placeholder="Description"
                                    name='description'
                                    value={input.description}
                                    onChange={handleInput}
                                    required
                                />
                            </FormGroup>
                            <FlexRow justifyContent="flex-end" topMargin="1">
                                <Button 
                                    width='8'
                                    height='4'
                                    radius='0.8'
                                    hoverBgColor='#ffffff'
                                    borderColor='#0D968F'
                                    hoverColor='#0D968F'
                                    fontWeight='600'
                                >
                                    Next
                                </Button>
                            </FlexRow>
                        </Form>
                    </CustomContainer>
                }
                { isFunctions &&
                    <CustomContainer topMargin="3" data-aos="zoom-in">
                        <AppText color='#101828' textSize="1.8" fontWeight="700">{isEdit ? 'Edit Role Functions' : 'Add Role Functions'}</AppText>
                        <CustomContainer topMargin="1">
                            
                            <Divider margin='1.5' borderWidth='0.6' />
                            {
                                tasks?.map((data: any, index: number) => {
                                    let ids = getIds(data?.items)
                                    return (
                                        <CustomContainer key={index}>
                                            <FlexRow justifyContent='space-between' onClick={() => setManageAccordion({...manageAccordion, [data.groupName]: !manageAccordion[data.groupName]})}>
                                                <AppText color='#101828' textSize='1.6' fontWeight='400' cursor='pointer'>
                                                    {data.groupName}
                                                </AppText>
                                                <AppSpan 
                                                    color='#98A2B3' textSize="2.5" fontWeight="500" 
                                                    leftMargin='2'
                                                >
                                                    { manageAccordion[data.groupName] ? <FiChevronUp /> : <FiChevronDown />}
                                                </AppSpan> 
                                            </FlexRow>
                                            { manageAccordion[data.groupName] &&
                                                <CustomContainer topMargin='0.7'>
                                                    <FlexRow justifyContent="space-between" wrap="wrap">
                                                        <CheckboxSelection 
                                                            width='100' 
                                                            name='Select All'
                                                            fontWeight='500'
                                                            customClass='checkbox-selection-mobile'
                                                            value={ ids.every(item => assignedTaskIDs.includes(item)) }
                                                            output={(val: any) => handleSelectAll(val, data.items)}
                                                        />
                                                        {
                                                            data.items?.map((item: any, subIndex: number) => {
                                                                return (
                                                                    <CheckboxSelection 
                                                                        key={subIndex}
                                                                        width='49' 
                                                                        name={item.displayName}
                                                                        fontWeight='500'
                                                                        customClass='checkbox-selection-mobile'
                                                                        value={assignedTaskIDs.includes(item.id)}
                                                                        output={(val: any) => handleSelectedTasks(val, item.id)}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </FlexRow>
                                                </CustomContainer>
                                            }
                                            <Divider margin='1.5' borderWidth='0.6' />
                                        </CustomContainer>
                                    )
                                })
                            }

                            <FlexRow justifyContent="flex-end" topMargin="3">
                                <Button 
                                    width='8'
                                    height='4'
                                    radius='0.8'
                                    hoverBgColor='#ffffff'
                                    borderColor='#0D968F'
                                    hoverColor='#0D968F'
                                    fontWeight='600'
                                    onClick={handleSubmit}
                                >
                                    Save
                                </Button>
                            </FlexRow>
                        </CustomContainer>
                    </CustomContainer>
                }
            </SidePopup>
        </>
    )
}
    