import { appSettings } from "src/provider/config/constant";
import { ValidatorResponse } from "src/model"
import utility from "./utility";

const inputValidator = (data: any, exemptedPropertes?: string[]): ValidatorResponse => {
    var result = <ValidatorResponse>{ isValidated: true }
    for (let key in data) {
        if (!data[key] && !exemptedPropertes?.includes(key)) {
            result.isValidated = false;
            result.message = `Input: Kindly provide 
            "${utility.splitCamelCase(key, true)}"`
            break;
        }
    }
    //console.log({result})
    return result
} 

const searchTableData = (tableData: any, searchText: string) => {
    let result = []

    for (let obj of tableData) {
        for (let key in obj) {
            if (String(obj[key]).toLowerCase().includes(searchText.toLowerCase())) {
                result.push(obj)
                break;
            }
        }
    }

    if (result.length < 1) {
        result = tableData
    }

    return result
}

const validateEmail = (email: string): boolean => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
}

const addSerialNumberToItems = (data: any) => {
    let index = 1
    for (let item of data) {
        item.sn = index
        index++
    }

    return data
}

const getProcessingRoutes = (data: any) => {
    let result = []
    for (let item of data) {
        result.push(item?.processingRoute)
    }

    return result.join(', ')
}

const getServiceRoute_Banks = (data: any) => {
    let processingRoutes = []
    let banks = []

    for (let item of data) {
        processingRoutes.push(item?.processingRoute)
        for (let bank of item?.supportingBanks) {
            banks.push(bank)
        }
    }

    return {
        processingRoutes: processingRoutes.join(', '),
        banks
    }
}

const getProductServiceBanks = (data: any) => {
    let banks = []

    for (let item of data) {
        for (let route of item?.processingConfigurations) {
            for (let bank of route?.supportingBanks) {
                banks.push({
                    ...bank, productServiceId: item?.productServiceId, 
                    name: bank?.bank?.name, status: bank?.bank?.status
                })
            }
        }
    }

    return banks
}

const groupTasks = (data: any[]) => {
    const groupedData = <any>{}
    const result = []

    for (let item of data) {
        if (groupedData.hasOwnProperty(item.grouping)) {
            groupedData[item.grouping] = [...groupedData[item.grouping], item]
        }
        else groupedData[item.grouping] = [item]
    }

    for (let item of Object.keys(groupedData)) {
        const obj = {
            groupName: item,
            items: groupedData[item]
        }

        result.push(obj)
    }

    return result
}

const validatePhoneNo = (phoneNo: string) => {
    if (phoneNo.startsWith('+234') || phoneNo.startsWith('234')) {
        phoneNo = phoneNo.replace('+', '')
        phoneNo = phoneNo.replace('234', '0')
    }
    if ((['070', '080', '081', '090', '091'].includes(phoneNo.substring(0, 3))) && phoneNo.length === 11)
        return true;
    else return false
}

const formatSeconds = (seconds: number|string) => {
    seconds = String(seconds)
    return `0:${seconds.length < 2 ? `0${seconds}` : seconds}`
}

const splitDateTime = (data: string) => {
    let [date, time] = data.split('T')
    let [year, month, day] = date.split('-')
    let [hours, minutes] = time.split(':')

    month = (month.length > 1 && month[0] === '0') ? month[1] : month
    const monthShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const formattedHours = (hours.length > 1 && hours[0] === '0') ? hours[1] : hours

    return {
        date: `${day} ${monthShort[Number(month)-1]} ${year}`,
        slashedDate: `${day}/${month}/${year}`,
        time: `${hours}:${minutes}`,
        timeEnv: `${Number(formattedHours) < 12 ? 'am' : 'pm'}`,
        completeTime: `${hours}:${minutes}${Number(formattedHours) < 12 ? 'am' : 'pm'}`
    }

}

const auditLogsProperties = <any>{
    UserRole: [
        {displayName: 'Permissions', property: 'AssignedTasks'},
        {displayName: 'Description', property: 'Description'},
        {displayName: 'Name', property: 'Name'},
        {displayName: 'Status', property: 'Status'}
    ],
    USER: [
        {displayName: 'Address', property: 'Address'},
        {displayName: 'Email', property: 'Email'},
        {displayName: 'First Name', property: 'FirstName'},
        {displayName: 'Gender', property: 'Gender'},
        {displayName: 'Last Name', property: 'LastName'},
        {displayName: 'Phone Number', property: 'PhoneNo'},
        {displayName: 'Username', property: 'Username'},
    ]
}

const formattedAuditItems = (data: any) => {
    const properties = auditLogsProperties[data?.informationType]
    const auditItems = data?.items
    const formattedItems = []
    let counterContainer = []
    let groupedItems: any[] = []

    if (properties) {
        for (let item of properties) {
            let value = auditItems[item.property] || 'null'
    
            if (data?.informationType === 'UserRole' && item.displayName === 'Permissions' && auditItems[item.property]?.length) {
                value = ''
                for (let info of auditItems[item.property]) {
                    value = `${value && `${value}, `}${info.DisplayName}`
                }
            }
    
            formattedItems.push({
                displayName: item.displayName,
                value
            })
    
            counterContainer.push({
                displayName: item.displayName,
                value
            })
    
            if (counterContainer.length === 2) {
                groupedItems = [...groupedItems, counterContainer]
                counterContainer = []
            }
        }
    }

    return {
        formattedItems,
        groupedItems
    }
}

const setSessionExpiryTime = () => {
    const date = new Date()
    //date.setTime( date.getTime() - new Date().getTimezoneOffset() * 60 * 1000 );
    date.setMinutes(date.getMinutes() + appSettings.SESSION_EXPIRATION_LENGTH)
    localStorage.setItem('session-expiry-time', date.toString())
}

const handleDate = (date: string) => {
    let formattedDate = splitDateTime(date);
    return `${formattedDate?.slashedDate} ${formattedDate?.time}${formattedDate?.timeEnv}`
}


export default {
    inputValidator,
    searchTableData,
    validateEmail,
    addSerialNumberToItems,
    getProcessingRoutes,
    getServiceRoute_Banks,
    getProductServiceBanks,
    groupTasks,
    validatePhoneNo,
    formatSeconds,
    splitDateTime,
    formattedAuditItems,
    setSessionExpiryTime,
    handleDate
}