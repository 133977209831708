import Home from './home'
import BankPartner from './bankPartner'
import BankDetails from './bankPartnerDetails'
import Business from './business'
import BusinessDetails from './businessDetails'
import APIKeys from './apiKeys'
import Services from './services'
import ServiceDetails from './serviceDetails'
import ApprovalRequests from './approvalRequests'
import ApprovalTimelines from './approvalTimelines'
import Products from './products'
import ProductsDetails from './productDetails'
import Users from './users'
import ManageRoles from './manageRoles'
import AuditLogs from './auditLogs'
import AuditLogDetails from './auditLogDetails'
import BusinessTransactions from './transactions/business/businessTransactions'
import BusinessTxDetails from './transactions/business/businessTxDetails'
import ServiceTransactions from './transactions/service/serviceTransactions'
import ServiceTxDetails from './transactions/service/serviceTxDetails'
import ProductTransactions from './transactions/productTransactions'
import ServiceAPIs from './serviceAPIs'
import ApprovalConfiguration from './approvalConfiguration'
import BillingConfiguration from './billingConfiguration'
import BusinessPartner from './businessPartner'
import TransactionData from './transactionData'
import ServiceApproval from './serviceApproval'
import BillingProfile from './billingProfile'

export default [
    Home,
    BankPartner,
    BankDetails,
    Business,
    BusinessDetails,
    APIKeys,
    Services,
    ServiceDetails,
    ApprovalRequests,
    ApprovalTimelines,
    Products,
    ProductsDetails,
    Users,
    ManageRoles,
    AuditLogs,
    AuditLogDetails,
    BusinessTransactions,
    BusinessTxDetails,
    ServiceTransactions,
    ServiceTxDetails,
    ProductTransactions,
    ServiceAPIs,
    ApprovalConfiguration,
    BillingConfiguration,
    BusinessPartner,
    TransactionData,
    ServiceApproval,
    BillingProfile
]