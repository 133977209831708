import { images } from 'src/provider/config/constant'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import UserContext from 'src/provider/state-manager/userProvider';
import {SideBarMenu} from './sidebarMenu'
import { sideBarData } from './sidebarData'
import { FiLogOut } from 'react-icons/fi'
import { AppSpan, AppText, Circle, CenterContainer, CustomContainer, Divider, FlexColumn, FlexRow, Icon, SideBarContainer, SideBarShadow } from "src/style"
import { HydraLogo, UserImg } from 'src/assets/icons'

export const SideBar: React.FC = () => {
    const navigate = useNavigate()
    const {visibility, showSideBar, decisionBox} = useContext(VisibilityContext)
    const {user : {userInfo}} = useContext(UserContext)
    const {logout} = useContext(AppInfoContext)
    const userType = userInfo?.user?.institution?.institutionCategory.institutionCategoryType

    function handlePrepToLogout () {
        decisionBox.show(
            'Are you sure you want to logout?',
            handleLogout,
            null,
            'Yes, Logout'
        )
    }

    async function handleLogout () {
        await logout()
        navigate('/')
    }


    return (
        <>
            <SideBarShadow isSideBar={visibility.isSideBar} onClick={() => showSideBar(false)}/>
            <SideBarContainer isSideBar={visibility.isSideBar}>
                <FlexColumn 
                    minHeight='100' mnHUnit='%'
                    justifyContent='space-between'
                >
                <CustomContainer className='sidebar-menu-wrapper'>
                    <FlexRow justifyContent='flex-start' leftMargin='1.5' bottomMargin='5'>
                        {/* <Icon src={images.HYDRA_LOGO} rightMargin='1'/> */}
                        <HydraLogo/>
                        <AppText color='#555554' textSize='1.9' fontWeight='700' leftMargin='1' fontFamily='Nunito'>hydra</AppText>
                    </FlexRow>
                    {
                        sideBarData.map((menu, index) => {
                            if ((menu.accessBy === 'ApiConsumer' && userType != 'ServiceProvider') || 
                                userType === menu.accessBy || menu.accessBy === 'all'
                            ) return <SideBarMenu item={menu} key={index} />
                        })
                    }
                </CustomContainer>
                <CustomContainer topMargin='2'>
                    <CustomContainer 
                        topPadding='1.3' bottomPadding='1.3' bgColor='#F9FAFB'
                        leftPadding='2' radius='0.8' minHeight='6.4' overflow='auto'
                        className='business-name-container'
                    >
                        <AppText textSize='2' width='20' color='#58595B' fontWeight='700'>
                            {userInfo?.displayName}
                        </AppText>
                    </CustomContainer>
                    <Divider margin='1' bgColor='#EAECF0' />
                    <FlexRow justifyContent='space-between'>
                        <FlexRow width='auto' sizeUnit=' '>
                            <Circle size='5'>
                                {/* <Icon src={images.USER}/> */}
                                <UserImg/>
                            </Circle>
                            <CustomContainer width='auto' sizeUnit=' ' leftMargin='1'>
                                <AppText fontWeight='700' fontFamily='Poppins' >{userInfo?.user?.firstName} {userInfo?.user?.lastName}</AppText>
                                <AppSpan textSize='1.4' fontWeight='400' fontFamily='Poppins' color='#A098AE'>User</AppSpan>
                            </CustomContainer>
                        </FlexRow>
                        <FiLogOut className='logout-icon' onClick={handlePrepToLogout} />
                    </FlexRow>
                </CustomContainer>
               </FlexColumn>
            </SideBarContainer>
        </>
    )
}