import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { TailSpin } from  'react-loader-spinner'
import './style.scss'
import { AppText, FlexRow, Icon } from 'src/style';
import { HydraLogo } from 'src/assets/icons';
import { images } from "src/provider/config/constant";

export const Loader: React.FC = () => {
    return (
        <div className="loader-container">
            <div className='loader-wrapper'>
                <FlexRow width='auto' sizeUnit='' bottomMargin='10'>
                    <HydraLogo className='loader-logo'/>
                    <AppText color='#555554' textSize='4' fontWeight='700' leftMargin='1' fontFamily='Nunito'>hydra</AppText>
                </FlexRow>
                {/* <TailSpin 
                    color="#0D968F" 
                    height={50} 
                    width={50} 
                /> */}
                <Icon src={images.LOADER} topMargin='-7' />
            </div>
        </div>
    )
}