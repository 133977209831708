import { useState } from 'react'
import utility from 'src/utils/utility'
import { AppSpan, AppText, CustomContainer, FlexRow, ScreenContainer } from 'src/style'
import { useNavigate } from 'react-router-dom'
import { Table } from 'src/component'
import { productTxColumn, productTxData, transactionColumns, data } from 'src/utils/tableLayout'
import { TransactionInfo } from 'src/popup'
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md'

const BusinessTxDetails: React.FC = () => {
    const navigate = useNavigate()
    const [details, setDetails] = useState<any>(null)
    const [showInfo, setShowInfo] = useState(false)

    function handleViewDetails (data: any) {
        //alert(JSON.stringify(data))
        setDetails(data)
        setShowInfo(true)
    }

    return (
        <>
            <ScreenContainer>
                <AppText textSize='1.4' cursor='pointer' >
                    <AppSpan textSize='1.4' cursor='pointer' hoverColor='#0D968F' onClick={() => navigate('/dashboard/business-transactions')}>Business Transactions </AppSpan>
                    <AppSpan textSize='1.4' cursor='pointer'>/ Transaction Details</AppSpan>
                </AppText>
                <CustomContainer topMargin='3'>
                    <FlexRow width='auto' sizeUnit=' ' justifyContent='flex-start' topMargin='2'>
                        <MdOutlineKeyboardArrowLeft 
                            style={{fontSize: '3rem', color: '#101828'}}
                            onClick={() => navigate(-1)} 
                        />
                        <AppText textSize='3' color='#101828' fontWeight='600' leftMargin='0.5'>
                            Business Transaction Details
                        </AppText>
                    </FlexRow>
                    <CustomContainer>
                        <FlexRow wrap='wrap' width='auto' justifyContent='flex-start' alignItems='flex-start' bottomMargin='3'>
                            <CustomContainer
                                minHeight='18.4' borderColor='#EAECF0'
                                topMargin='2'
                                shadow='0px 1px 2px rgba(16, 24, 40, 0.06)'
                                radius='0.8' bgColor='#ffffff' padding='2.2'
                                rightPadding='3' leftPadding='3'
                                className='service-details-card'
                            >
                                <FlexRow justifyContent='space-between' wrap='wrap'>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>Business</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>Qore</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>Email Address</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>zone@appzone.com</AppSpan>
                                    </CustomContainer>
                                </FlexRow>
                                <FlexRow justifyContent='space-between' wrap='wrap'>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>Product Description</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>12b, Admiralty way, Lekki, Zone 1, Abuja</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>Phone Number</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>07087263719</AppSpan>
                                    </CustomContainer>
                                </FlexRow>
                            </CustomContainer>
                            <CustomContainer
                                minHeight='20' borderColor='#EAECF0'
                                topMargin='2' padding='2.2'
                                shadow='0px 1px 2px rgba(16, 24, 40, 0.06)'
                                radius='0.8' bgColor='#ffffff'
                                leftPadding='3' className='service-details-card'
                            >
                                <FlexRow justifyContent='space-between' wrap='wrap'>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>Transaction Count</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>87,000</AppSpan>
                                    </CustomContainer>
                                    <CustomContainer width='17.5' bottomMargin='1.7'>
                                        <AppText color='#333542' textSize='1.6' fontWeight='700'>Transaction Volume</AppText>
                                        <AppSpan color='#333542' fontWeight='300' textSize='1.4'>12,903,456</AppSpan>
                                    </CustomContainer>
                                </FlexRow>
                            </CustomContainer>
                        </FlexRow>
                    </CustomContainer>

                    <CustomContainer>
                        <AppText color='#101828' textSize='1.8' fontWeight='600' bottomMargin='1'>Product Transactions</AppText>
                        <Table 
                            tableData={productTxData}
                            tableColumns={productTxColumn}
                            customStyle
                        />
                    </CustomContainer>

                    <CustomContainer topMargin='2'>
                        <AppText color='#101828' textSize='1.8' fontWeight='600' bottomMargin='1'>Customer Transactions</AppText>
                        <Table 
                            tableData={data}
                            tableColumns={transactionColumns(handleViewDetails)}
                            customStyle
                        />
                    </CustomContainer>
                </CustomContainer>
            </ScreenContainer>
            { showInfo &&
                <TransactionInfo 
                    close={() => setShowInfo(false)} 
                    data={details}
                />
            }
        </>
    )
}

export default utility.routeData('business-transactions/business-tx-details', 'Business Transaction Details', <BusinessTxDetails/>)