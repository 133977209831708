import { Chart as ChartJS, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2'
import gradient from 'chartjs-plugin-gradient';
ChartJS.register(...registerables);
ChartJS.register(gradient);


export const TransactionChart = () => {
    return (
        <Line 
            data={{
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
                datasets: [
                    {
                        label: 'Direct Debit (Source)',
                        data: [23, 35, 50, 38, 35, 37, 70, 62, 45, 52, 24, 33],
                        //backgroundColor: 'rgba(127, 86, 217, 0.1)',
                        gradient: {
                            backgroundColor: {
                              axis: 'y',
                              colors: {
                                0: 'rgba(127, 86, 217, 0)',
                                95.5: 'rgba(127, 86, 217, 0.1)',
                              }
                            }
                        },
                        borderColor: '#0D968F',
                        borderWidth: 2,
                        pointRadius: 0,
                        fill: true
                    },
                    // {
                    //     label: 'Total Loan Repaid',
                    //     data: [30, 30, 50, 58, 42, 55, 34, 65, 55, 28, 64, 39],
                    //     //backgroundColor: 'rgba(127, 86, 217, 0.1)',
                    //     gradient: {
                    //         backgroundColor: {
                    //           axis: 'y',
                    //           colors: {
                    //             0: 'rgba(127, 86, 217, 0)',
                    //             95.5: 'rgba(127, 86, 217, 0.1)',
                    //           }
                    //         }
                    //     },
                    //     borderColor: '#3EB7B1',
                    //     borderWidth: 2,
                    //     pointRadius: 0,
                    //     fill: true
                    // }
                ]
            }}
            height="90%"
            width="100%"
            options={{
                responsive: true,
                maintainAspectRatio: false, 
                plugins: {
                    legend: {
                        align: 'end',
                        labels: {
                            usePointStyle: true,
                            boxWidth: 8,
                            boxHeight: 8,
                            color: '#667085',
                            font: {
                                size: 14
                            }
                        },
                        display: true
                    }
                },
                elements: {
                    line: {
                        tension: 0.5
                    }
                },
                scales: {
                    y: {  
                        display: true,
                        ticks: {
                            maxTicksLimit: 8
                        },
                        grid: {
                            color: "rgba(102,112,133, 0.1)",
                        },
                        title: {
                            display: true,
                            text: 'Transaction Count',
                            color: '#667085',
                            font: {
                                size: 12
                            }
                        }
                    },
                    x: {  
                        grid: {
                            drawOnChartArea: false,
                        },
                        ticks: {
                            color: "#667085", 
                        },
                        title: {
                            display: true,
                            text: 'Month',
                            color: '#667085',
                            font: {
                                size: 12
                            }
                        }
                    }
                }
            }}
        />
    )
}
