import { useState, useContext, useEffect } from "react"
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import ApiContext from "src/provider/API/call-service"
import { PopupUserIcon } from "src/assets/icons"
import { AppSpan, AppText, Button, CustomContainer, FlexRow, Form, FormGroup, FormGroupWithIcon, SidePopup, SidePopupContainer, Switch } from "src/style"
import { FiChevronDown } from "react-icons/fi"
import helpers from "src/utils/helpers"

export const AddUser: React.FC<any> = ({getUsersMethod, close}) => {
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const [input, setInput] = useState({
        userRoleId: 0, firstName: '', lastName: '', 
        email: '', phoneNo: '', username: ''
    })
    const [roles, setRoles] = useState<any>(null)

    useEffect(() => {
        handleFetchRoles()
    }, [])
 
    function handleInput (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) {
        if (e.target.name === 'email' || e.target.name === 'phoneNo') {
            if (e.target.value.includes(' ')) e.target.value = e.target.value.replace(/ /g, '')
        }
        setInput({...input, [e.target.name]: e.target.value})
    }

    async function handleFetchRoles () {
        const response = await API.getRoles()
        setRoles(response)
    }

    async function handleSubmit(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()

        if (!helpers.validateEmail(input.email)) return notifier.show('Invalid email provided', 'Validation Response')
        
        const response = await API.createNewUser(input)
        if (response) await getUsersMethod()
        close()
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    <PopupUserIcon/> 
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <AppText color='#101828' textSize="1.8" fontWeight="700">Add New User</AppText>
                    <Form topMargin="2" onSubmit={handleSubmit}>
                        <FormGroup>
                            <label>Enter First Name</label>
                            <input 
                                placeholder="First Name" 
                                name='firstName'
                                value={input.firstName}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Enter Last Name</label>
                            <input 
                                placeholder="Last Name"
                                name='lastName'
                                value={input.lastName}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Enter Email Address</label>
                            <input 
                                type='email' 
                                placeholder="Email Address" 
                                name='email'
                                value={input.email}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Enter Phone Number</label>
                            <input 
                                type='number' 
                                placeholder="Phone Number" 
                                name='phoneNo'
                                value={input.phoneNo}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Enter Username</label>
                            <input 
                                placeholder="Username" 
                                name='username'
                                value={input.username}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>
                        {/* <FormGroupWithIcon bottomMargin='1.8'>
                            <label>Select Gender</label>
                            <div className="input-with-icon">
                                <select 
                                    name="gender" 
                                    value={input.gender}
                                    onChange={handleInput}
                                    style={{color: input.gender ? '#667085' : '#999'}}
                                    required
                                >
                                    <option value="">Gender</option>
                                    <option value='Male'>Male</option>
                                    <option value='Female'>Female</option>
                                </select>
                                <FiChevronDown className="input-with-icon-tag" />
                            </div>
                        </FormGroupWithIcon> */}
                        <FormGroupWithIcon bottomMargin='1.8'>
                            <label>Select User Role</label>
                            <div className="input-with-icon">
                                <select 
                                    name="role" 
                                    value={input.userRoleId}
                                    onChange={(e) => setInput({...input, userRoleId: Number(e.target.value)})}
                                    style={{color: input.userRoleId ? '#667085' : '#999'}}
                                    required
                                >
                                    <option value="">Select Role</option>
                                    {
                                        roles?.map((item: any, index: number) => {
                                            return (
                                                <option value={item.id} key={index}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <FiChevronDown className="input-with-icon-tag" />
                            </div>
                        </FormGroupWithIcon>
                        {/* <FlexRow justifyContent="flex-start">
                            <AppText color='#344054' fontWeight="600" rightMargin="1.8">Business Admin</AppText>
                            <Switch checkedBgColor='#0D968F' checkedSliderBgColor='#ffffff'>
                                <input 
                                    type="checkbox" checked={input.isBusnessAdmin} 
                                    onChange={() => setInput({...input, isBusnessAdmin: !input.isBusnessAdmin})} 
                                />
                                <div ></div>
                                <span></span>
                            </Switch>
                        </FlexRow> */}
                        <FlexRow justifyContent="flex-end">
                            <Button 
                                width='8'
                                height='4'
                                radius='0.8'
                                hoverBgColor='#ffffff'
                                borderColor='#0D968F'
                                hoverColor='#0D968F'
                                fontWeight='600'
                                topMargin="1"
                            >
                                Save
                            </Button>
                        </FlexRow>
                    </Form>
                </CustomContainer>
            </SidePopup>
        </>
    )
}
    