import './style.scss'
import { useState, useContext, useEffect } from 'react'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import { useNavigate } from 'react-router-dom'
import { 
    AppLabel,
    AppLink,
    AppSpan,
    AppText, Button, CenterContainer, 
    CustomContainer, FlexColumn, FlexRow, Form, FormGroup
} from 'src/style'
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import { HydraLogo } from 'src/assets/icons'
import { MainBgImage } from 'src/component'
import helpers from 'src/utils/helpers'


const NewPassword: React.FC = () => {
    const navigate = useNavigate()
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const [verificationCode, setVerificationCode] = useState('')
    const [input, setInput] = useState({newPassword: '', confirmPassword: ''})
    var [time, setTime] = useState<any>(null)
    var [count, setCount] = useState<any>(0)

    useEffect(() => {
        if (count === 30) handleResendOtp()
    }, [count])

    useEffect(() => {
        return () => { clearInterval(time) }
    }, [])

    function handleResendCounter () {
        if (count === 0) {
            time =  setInterval(async () => {
                setCount((count: number) => {
                    if (count === 30) {
                        clearInterval(time)
                        return 0
                    }
                    else return count + 1
                });
            }, 1000)
        }
    }

    function handleResendOtp () {
        API.getCodeForPasswordReset()
    }

    const handleCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length < 7) {
            setVerificationCode(e.target.value)
        }
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => setInput({...input, [e.target.name]: e.target.value})

    async function handlePasswordChange(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        
        const {isValidated, message} = helpers.inputValidator({...input, verificationCode})
        if (!isValidated) return notifier.show(message)

        if (input.newPassword !== input.confirmPassword) notifier.show('Password confirmation failed')
        else {
            const response = await API.resetPassword(verificationCode, input.newPassword)
            if (response) navigate('/login')
        }
    }

    return (
        <CustomContainer height='100' hUnit='%' overflow='auto'>
            <MainBgImage />
            <CenterContainer 
                width='45' topMargin='5' 
                bottomMargin='2' className='login-content'
            >
                <FlexColumn>
                    <FlexRow width='auto' sizeUnit=' '>
                        <HydraLogo/>
                        <AppText color='#555554' textSize='1.9' fontWeight='700' leftMargin='1' fontFamily='Nunito'>hydra</AppText>
                    </FlexRow>
                    <FlexColumn topMargin='2'>
                        <AppText color='#101828' fontWeight='600' textSize='3' fontFamily='Inter'>Forgot Password</AppText>
                    </FlexColumn>
                    <Form topMargin='3' onSubmit={handlePasswordChange}>
                        <CustomContainer 
                            topPadding='3' bottomPadding='3' leftPadding='4' 
                            rightPadding='4' bgColor='#FFFFFF' radius='1.2'
                            shadow='0px 4px 8px 0px rgba(16, 24, 40, 0.1)'
                        >
                            <FlexRow justifyContent="space-between" width="auto" sizeUnit="" topMargin='0.5'>
                                <input 
                                    className='display-hidden' id='code' 
                                    name="verificationCode" value={verificationCode} 
                                    onChange={handleCode} 
                                    autoFocus={true} autoComplete="off"
                                />
                                <label className="code-verification" htmlFor='code' >{verificationCode[0] || ''}</label>
                                <label className="code-verification" htmlFor='code' >{verificationCode[1] || ''}</label>
                                <label className="code-verification" htmlFor='code' >{verificationCode[2] || ''}</label>
                                <label className="code-verification" htmlFor='code' >{verificationCode[3] || ''}</label>
                                <label className="code-verification" htmlFor='code' >{verificationCode[4] || ''}</label>
                                <label className="code-verification" htmlFor='code' >{verificationCode[5] || ''}</label>
                            </FlexRow>
                            <AppText textSize='1.4' fontWeight='300'>
                                Didn't get a code?
                                <AppLink 
                                    textSize='1.4' fontWeight='300' 
                                    hoverColor={count === 0 ? '#0D968F' : '#667085'} leftPadding='0.5'
                                    onClick={handleResendCounter}
                                > 
                                    Click to resend
                                </AppLink>
                            </AppText>
                            <AppText textSize='1.3' fontWeight='300' topMargin='1'>
                                Code will resend in 
                                <AppSpan leftPadding="0.6" rightPadding="0.4" textSize='1.4' fontWeight='600' color='#0D968F'>
                                    {helpers.formatSeconds(30-count)}
                                </AppSpan>
                                sec
                            </AppText>
                            <FormGroup topMargin='2.5'>
                                <label>Enter New Password</label>
                                <input 
                                    type='password' 
                                    placeholder='***************' 
                                    value={input.newPassword}
                                    name='newPassword'
                                    onChange={handleInput}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Confirm New Password</label>
                                <input 
                                    type='password' 
                                    placeholder='***************' 
                                    value={input.confirmPassword}
                                    name='confirmPassword'
                                    onChange={handleInput}
                                />
                            </FormGroup>
                            <Button 
                                width='100' sizeUnit='%' topMargin='1' 
                                borderColor='#0D968F' hoverBgColor='#fff' 
                                hoverColor='#0D968F' titleSize='1.6'
                            >
                                Complete
                            </Button>
                        </CustomContainer>
                        <AppText align='center' topMargin='3'>
                            <AppLink 
                                color='#1E1E1E' decoration='none' hoverColor='#0D968F' fontWeight='600'
                                onClick={() => navigate('/login')}
                            > 
                                Back to Login
                            </AppLink>
                        </AppText>
                    </Form>
                </FlexColumn>
            </CenterContainer>
        </CustomContainer>
    )
}

export default utility.routeData('forget-password/new-password', 'Email Verificaion and New Password', <NewPassword/>)