import { BiCheck } from 'react-icons/bi';
import { IoCopyOutline } from 'react-icons/io5';
import { TiTimes } from 'react-icons/ti'
import { AppLink, AppSpan, AppText, CustomContainer, FlexRow, Switch } from 'src/style';
import helpers from '../helpers';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import './style.scss';
import utility from '../utility';


export function transactionColumns (handleAction?: any) {
    return [
        {
            name: <p className="table-column-title">Date & Time</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.date}</AppText>,
        },
        {
            name: <p className="table-column-title">Product</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.product}</AppText>,
        },
        {
            name: <p className="table-column-title">Route</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.route}</AppText>,
        },
        {
            name: <p className="table-column-title">Amount</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.amount}</AppText>
        },
        {
            name: <p className="table-column-title">Status</p>,
            width: '20rem',
            selector:(row: any) => (
                <FlexRow 
                    width='auto' bgColor={row.status === 'Successful' ? '#ECFDF3' : '#FEF3F2'} 
                    topPadding='0' bottomPadding='0' padding='0.8' radius='1.6' 
                >
                    <AppSpan 
                        // className='fa fa-check' 
                        color={row.status === 'Successful' ? '#12B76A' : '#B42318'} 
                        textSize='1.5' topMargin='-0.3'
                    >
                        { row.status === 'Successful' ? <BiCheck/> : <i className='fa fa-times-thin' />}
                    </AppSpan>
                    <AppSpan 
                        color={row.status === 'Successful' ? '#027A48' : '#B42318'} 
                        textSize='1.2' leftPadding='0.5' fontWeight='600'
                    >
                        {row.status}
                    </AppSpan>
                </FlexRow>
            ),
        },
        {
            name: <p className="table-column-title"></p>,
            width: '14rem',
            wrap: true,
            selector:(row: any) => 
                <AppLink 
                    color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
                    textSize='1.4' fontWeight='600' 
                    onClick={() => handleAction(row)}
                >
                    View Details
                </AppLink>,
        },
    ]
}

export const data = [
    {
        product: 'Cluster',
        date: '25/10/2022 09:00am',
        route: 'Lend',
        amount: '1,200',
        status: 'Successful'
    },
    {
        product: 'BankOne',
        date: '25/10/2022 09:00am',
        route: 'Lend',
        amount: '1,200',
        status: 'Pending'
    },
    {
        product: 'Hydra',
        date: '25/10/2022 09:00am',
        route: 'Lend',
        amount: '1,200',
        status: 'Successful'
    },
    {
        product: 'Primus Plus',
        date: '25/10/2022 09:00am',
        route: 'Lend',
        amount: '1,200',
        status: 'Successful'
    },
]

//==========Businesses ===================================

export function businessColumns (handleAction?: any) {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => row.sn,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Business</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.name}</AppText>,
            minWidth: '20rem',
            wrap: true,
        },
        {
            name: <p className="table-column-title">Business Address</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.address}</AppText>,
            wrap: true,
            minWidth: '22rem'
        },
        {
            name: <p className="table-column-title">Phone Number</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.phoneNo}</AppText>,
            minWidth: '15rem',
        },
        {
            name: <p className="table-column-title">Email</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.email}</AppText>,
            wrap: true,
            minWidth: '20rem'
        },
        {
            name: <p className="table-column-title">Status</p>,
            minWidth: '15rem',
            selector:(row: any) => (
                <FlexRow 
                    width='auto' bgColor={row.status === 'Active' ? '#ECFDF3' : '#FEF3F2'} 
                    topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
                >
                    <AppSpan 
                        // className='fa fa-check' 
                        color={row.status === 'Active' ? '#12B76A' : '#B42318'} 
                        textSize='1.5' topMargin='-0.2'
                    >
                        { row.status === 'Active' ? <BiCheck/> : <i className='fa fa-times-thin' />}
                    </AppSpan>
                    <AppSpan 
                        color={row.status === 'Active' ? '#12B76A' : '#B42318'} 
                        textSize='1.2' leftPadding='0.5' fontWeight='600'
                    >
                        {row.status || 'Successful'}
                    </AppSpan>
                </FlexRow>
            ),
        },
        {
            name: <p className="table-column-title"></p>,
            width: '14rem',
            wrap: true,
            selector:(row: any) => 
                <AppLink 
                    color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
                    textSize='1.4' fontWeight='600' 
                    onClick={() => handleAction(row)}
                >
                    View Details
                </AppLink>,
        },
    ]
}

export const businessData = [
    {
        sn: 1,
        name: 'Qore',
        address: 'Ga-akanbi, olorunsogo',
        phoneNo: '07087994127',
        email: 'newcore@gmail.com',
        status: 'Active'
    },
    {
        sn: 2,
        name: 'Crendly',
        address: 'Ga-akanbi, olorunsogo',
        phoneNo: '07087994127',
        email: 'newcore@gmail.com',
        status: 'Inactive'
    },
    {
        sn: 3,
        name: 'Facebook',
        address: 'Ga-akanbi, olorunsogo',
        phoneNo: '07087994127',
        email: 'newcore@gmail.com',
        status: 'Active'
    },
    {
        sn: 4,
        name: 'Whatsapp',
        address: 'Ga-akanbi, olorunsogo',
        phoneNo: '07087994127',
        email: 'newcore@gmail.com',
        status: 'Active'
    },
    {
        sn: 5,
        name: 'Twitter',
        address: 'Ga-akanbi, olorunsogo',
        phoneNo: '07087994127',
        email: 'newcore@gmail.com',
        status: 'Inactive'
    },
]


// =====================PRODUCTS ======================

export const productColumn = [
    {
        name: <p className="table-column-title">S/N</p>,
        width: '8rem',
        selector: (row: any) => row.sn,
        sortable: true
    },
    {
        name: <p className="table-column-title">Product</p>,
        width: '20rem',
        selector:(row: any) => <AppText textSize='1.4'>{row.name}</AppText>,
    },
    {
        name: <p className="table-column-title">Product Description</p>,
        //width: '60rem',
        selector:(row: any) => row.description,
        wrap: true
    },
    {
        name: <p className="table-column-title">Status</p>,
        width: '20rem',
        selector:(row: any) => (
            <FlexRow 
                width='auto' bgColor={row.status === 'Active' ? '#ECFDF3' : '#FEF3F2'} 
                topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
            >
                <AppSpan 
                    // className='fa fa-check' 
                    color={row.status === 'Active' ? '#12B76A' : '#B42318'} 
                    textSize='1.5' topMargin='-0.2'
                >
                    { row.status === 'Active' ? <BiCheck/> : <i className='fa fa-times-thin' />}
                </AppSpan>
                <AppSpan 
                    color={row.status === 'Active' ? '#12B76A' : '#B42318'} 
                    textSize='1.2' leftPadding='0.5' fontWeight='600'
                >
                    {row.status}
                </AppSpan>
            </FlexRow>
        ),
    }
]


export const productData = [
    {
        sn: 1,
        name: 'Qore',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        status: 'Active'
    },
    {
        sn: 2,
        name: 'Hydra',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        status: 'Active'
    },
    {
        sn: 3,
        name: 'Crendly',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        status: 'Inactive'
    },
]


// ================== AVAILABLE SERVICES =====================

export function availableServiceColumns (handleAction?: any) {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => row.sn,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Bank</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.bank}</AppText>,
        },
        {
            name: <p className="table-column-title">Processing Mode</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.processingMode}</AppText>
        },
        {
            name: <p className="table-column-title">Action</p>,
            selector:(row: any) => (
                <Switch >
                    <input type="checkbox" onChange={handleAction} />
                    <div ></div>
                    <span></span>
                </Switch>
            )
        },
        {
            name: <p className="table-column-title">Status</p>,
            selector:(row: any) => (
                <FlexRow 
                    width='auto' bgColor={row.status === 'Active' ? '#ECFDF3' : '#FEF3F2'} 
                    topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
                >
                    <AppSpan 
                        color={row.status === 'Active' ? '#12B76A' : '#B42318'} 
                        textSize='1.5' topMargin='-0.2'
                    >
                        { row.status === 'Active' ? <BiCheck/> : <i className='fa fa-times-thin' />}
                    </AppSpan>
                    <AppSpan 
                        color={row.status === 'Active' ? '#12B76A' : '#B42318'} 
                        textSize='1.2' leftPadding='0.5' fontWeight='600'
                    >
                        {row.status}
                    </AppSpan>
                </FlexRow>
            ),
        }
    ]
}


export const availableServiceData = [
    {
        sn: 1,
        bank: 'Sterling Bank',
        processingMode: 'Flexible',
        action: true,
        status: 'Active'
    },
    {
        sn: 2,
        bank: 'Guaranty Trust Bank',
        processingMode: 'Constraint: Source',
        action: false,
        status: 'Inactive'
    },
]

// ================== API PRODUCTS COLUMNS =============================

export function apiProductColumns (handleCopy: any, handleAction: any) {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => row.sn,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Product</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.name}</AppText>,
            wrap: true,
            width: '20rem'
        },
        {
            name: <p className="table-column-title">Product Code</p>,
            width: '25rem',
            selector:(row: any) => (
                <FlexRow width='auto'>
                    <CopyToClipboard 
                        text={row.key}
                        onCopy={() => handleCopy(row.key, 'Product code')}
                    >
                        <AppSpan 
                            color='#344054' fontWeight='600' textSize='2' hoverColor='#0D968F'
                            //onClick={() => handleCopy(row.key, 'Product code')}
                        >
                            <AppSpan color='#344054' rightPadding='1' cursor='pointer'>
                                {utility.getCharactersToDispay(row.key, 15)}
                            </AppSpan>
                            <IoCopyOutline />
                        </AppSpan>
                    </CopyToClipboard>
                </FlexRow>
            ),
        },
        {
            name: <p className="table-column-title">Action</p>,
            selector:(row: any) => 
                <AppLink 
                    color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
                    textSize='1.4' fontWeight='600' 
                    onClick={() => handleAction(row)}
                >
                    View Payload Structure
                </AppLink>,
        },
    ]
}


export const apiProductData = [
    {
        sn: 1,
        product: 'Cluster',
        productCode: '000123',
    },
    {
        sn: 2,
        product: 'Crendly',
        productCode: '000123',
    },
    {
        sn: 3,
        product: 'Recova',
        productCode: '000123',
    },
]


// ===================== SERVICES =====================

export function servicesColumns (handleAction?: any) {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => row.sn,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Services</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.friendlyName}</AppText>,
            wrap: true,
            minWidth: '25rem'
        },
        {
            name: <p className="table-column-title">API</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.serviceApi?.name}</AppText>,
            wrap: true,
            minWidth: '15rem'
        },
        {
            name: <p className="table-column-title">Processing Route</p>,
            selector:(row: any) => <AppText textSize='1.4'>{helpers.getProcessingRoutes(row.processingConfigurations)}</AppText>,
            wrap: true,
            minWidth: '18rem'
        },
        {
            name: <p className="table-column-title">Description</p>,
            minWidth: '30rem',
            wrap: true,
            selector:(row: any) => row.description
        },
        {
            name: <p className="table-column-title"></p>,
            width: '14rem',
            wrap: true,
            selector:(row: any) => 
                <AppLink 
                    color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
                    textSize='1.4' fontWeight='600' 
                    onClick={() => handleAction(row)}
                >
                    View Details
                </AppLink>,
        },
    ]
}

export const servicesData = [
    {
        sn: 1,
        friendlyName: 'Qore',
        serviceApi: {
            name: 'Qore API'
        },
        processingConfigurations: [
            {processingRoute: 'BankOne'}
        ],
        description: '12b, Admiralty way, Lekk1 Zone 1, Abuja'
    },
    {
        sn: 2,
        friendlyName: 'Qore',
        serviceApi: {
            name: 'Qore API'
        },
        processingConfigurations: [
            {processingRoute: 'BankOne'}
        ],
        description: '12b, Admiralty way, Lekk1 Zone 1, Abuja'
    },
    {
        sn: 3,
        friendlyName: 'Qore',
        serviceApi: {
            name: 'Qore API'
        },
        processingConfigurations: [
            {processingRoute: 'BankOne'}
        ],
        description: '12b, Admiralty way, Lekk1 Zone 1, Abuja'
    },
    {
        sn: 4,
        friendlyName: 'Qore',
        serviceApi: {
            name: 'Qore API'
        },
        processingConfigurations: [
            {processingRoute: 'BankOne'}
        ],
        description: '12b, Admiralty way, Lekk1 Zone 1, Abuja'
    },
    {
        sn: 5,
        friendlyName: 'Qore',
        serviceApi: {
            name: 'Qore API'
        },
        processingConfigurations: [
            {processingRoute: 'BankOne'}
        ],
        description: '12b, Admiralty way, Lekk1 Zone 1, Abuja'
    }
]


// ========================= SERVICE APPROVAL =========================

export function approvalRequestsColumns (handleAction?: any) {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => row.sn,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Initiating User</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.initiatingUser}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title">Services</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.services}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title">Bank</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.bank}</AppText>
        },
        {
            name: <p className="table-column-title">Control Mode</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.controlMode}</AppText>
        },
        {
            name: <p className="table-column-title">Business</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.business}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title"></p>,
            width: '14rem',
            wrap: true,
            selector:(row: any) => 
                <AppLink 
                    color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
                    textSize='1.4' fontWeight='600' 
                    onClick={() => handleAction(row)}
                >
                    View Details
                </AppLink>,
        },
    ]
}

export const approvalRequestsData = [
    {
        sn: 1,
        initiatingUser: 'Femi Oladele',
        services: 'Direct Intrabank',
        bank: 'Zenit',
        controlMode: 'Flexible',
        business: 'Qore'
    },
    {
        sn: 2,
        services: 'Bank Transfer',
        bank: 'Access',
        controlMode: 'Constrained',
        business: 'Zone',
        initiatingUser: 'Ridwan Kolawole',
    },
    {
        sn: 3,
        services: 'Direct Intrabank',
        bank: 'Zenit',
        controlMode: 'Flexible',
        business: 'Appzone',
        initiatingUser: 'Kingsley Eze',
    },
    {
        sn: 4,
        services: 'Bank Transfer',
        bank: 'Access',
        controlMode: 'Constrained',
        business: 'Qore',
        initiatingUser: 'Femi Oladele',
    },
]


// ================== APPROVAL REQUEST =================

export function approvalRequestsNewColumns (handleAction?: any) {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => row.sn,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Initiating User</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.initiator}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title">Entity Name</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.entityName}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title">Approval Type</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.approvalType}</AppText>
        },
        {
            name: <p className="table-column-title">Approval Status</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.approvalStatus}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title">Date Requested</p>,
            selector:(row: any) => <AppText textSize='1.4'>{helpers.handleDate(row.requestedOn)}</AppText>
        },
        {
            name: <p className="table-column-title"></p>,
            width: '14rem',
            wrap: true,
            selector:(row: any) => 
                <AppLink 
                    color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
                    textSize='1.4' fontWeight='600' 
                    onClick={() => handleAction(row)}
                >
                    View Details
                </AppLink>,
        },
    ]
}

export const approvalRequestNewData = [
    {
        sn: 1,
        initiator: 'Khaleel Dalegan',
        entityName: 'Kwara State University',
        approvalType: 'Service',
        approvalStatus: 'Pending',
        requestedOn: '2023-02-08T18:13:33.654Z',
        currentApproverEmail: 'o.jibola@gmail.com',
        redirectUri: 'https://google.com'
    },
    {
        sn: 2,
        initiator: 'Khaleel Dalegan',
        entityName: 'Kwara State University',
        approvalType: 'Service',
        approvalStatus: 'Pending',
        requestedOn: '2023-02-08T18:13:33.654Z'
    },
    {
        sn: 3,
        initiator: 'Khaleel Dalegan',
        entityName: 'Kwara State University',
        approvalType: 'Service',
        approvalStatus: 'Pending',
        requestedOn: '2023-02-08T18:13:33.654Z'
    },
    {
        sn: 4,
        initiator: 'Khaleel Dalegan',
        entityName: 'Kwara State University',
        approvalType: 'Service',
        approvalStatus: 'Pending',
        requestedOn: '2023-02-08T18:13:33.654Z'
    }
]

// ================== PRODUCTS ==========================

export function productsColumns (handleAction?: any) {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => row.sn,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Product Name</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.name}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title">Product VP</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.productPersonEmail}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title">Product Description</p>,
            width: '30rem',
            selector:(row: any) => row.description
        },
        {
            name: <p className="table-column-title"></p>,
            width: '14rem',
            wrap: true,
            selector:(row: any) => 
                <AppLink 
                    color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
                    textSize='1.4' fontWeight='600' 
                    onClick={() => handleAction(row)}
                >
                    View Details
                </AppLink>,
        },
    ]
}


export const productsData = [
    {
        sn: 1,
        name: 'Lend',
        productPersonEmail: 'Ridwan Kolawole',
        description: '12b, Admiralty way, Lekk1 Zone 1, Abuja'
    },
    {
        sn: 2,
        name: 'Cluster',
        productPersonEmail: 'Emmanuel Eguakhide',
        description: '12b, Admiralty way, Lekk1 Zone 1, Abuja'
    },
    {
        sn: 3,
        name: 'BankOne',
        productPersonEmail: 'John Doe',
        description: '12b, Admiralty way, Lekk1 Zone 1, Abuja'
    },
    {
        sn: 4,
        name: 'Recova',
        productPersonEmail: 'Isaac Newton',
        description: '12b, Admiralty way, Lekk1 Zone 1, Abuja'
    },
    {
        sn: 5,
        name: 'Crendly',
        productPersonEmail: 'Alex Ferguson',
        description: '12b, Admiralty way, Lekk1 Zone 1, Abuja'
    }
]

// =========================== PRODUCT SERVICES ========================

export function productServicesColumns (handleAction?: any) {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => row.sn,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Service</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.friendlyName}</AppText>,
            minWidth: '20rem',
            wrap: true,
        },
        {
            name: <p className="table-column-title">Status</p>,
            selector:(row: any) => (
                <FlexRow 
                    width='auto' bgColor={row.status === 'Active' ? '#ECFDF3' : '#FEF3F2'} 
                    topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
                >
                    <AppSpan 
                        color={row.status === 'Active' ? '#12B76A' : '#B42318'} 
                        textSize='1.5' topMargin='-0.2'
                    >
                        { row.status === 'Active' ? <BiCheck/> : <i className='fa fa-times-thin' />}
                    </AppSpan>
                    <AppSpan 
                        color={row.status === 'Active' ? '#12B76A' : '#B42318'} 
                        textSize='1.2' leftPadding='0.5' fontWeight='600'
                    >
                        {row.status}
                    </AppSpan>
                </FlexRow>
            ),
        },
        {
            name: <p className="table-column-title">Action</p>,
            selector:(row: any) => (
                <Switch >
                    <input type="checkbox" checked={row.status === 'Active' ? true : false} onChange={() => handleAction(row)} />
                    <div ></div>
                    <span></span>
                </Switch>
            )
        },
        // {
        //     name: <p className="table-column-title"></p>,
        //     width: '14rem',
        //     wrap: true,
        //     selector:(row: any) => 
        //         <AppLink 
        //             color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
        //             textSize='1.4' fontWeight='600' 
        //             //onClick={() => handleAction(row)}
        //         >
        //             View Details
        //         </AppLink>,
        // },
    ]
}


export const productServicesData = [
    {
        sn: 1,
        friendlyName: 'Direct Intrabank Transfer',
        action: true,
        status: 'Active'
    },
    {
        sn: 2,
        friendlyName: 'Direct Interbank Transfer',
        action: false,
        status: 'Inactive'
    },
]


// ======================== PRODUCT SERVICE BANKS =============================

export function productServicesBanksColumns (handleAction?: any) {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => row.sn,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Bank</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.name}</AppText>,
            sortable: true,
            minWidth: '20rem',
            wrap: true,
        },
        {
            name: <p className="table-column-title">Status</p>,
            selector:(row: any) => (
                <FlexRow 
                    width='auto' bgColor={row.status === 'Active' ? '#ECFDF3' : '#FEF3F2'} 
                    topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
                >
                    <AppSpan 
                        color={row.status === 'Active' ? '#12B76A' : '#B42318'} 
                        textSize='1.5' topMargin='-0.2'
                    >
                        { row.status === 'Active' ? <BiCheck/> : <i className='fa fa-times-thin' />}
                    </AppSpan>
                    <AppSpan 
                        color={row.status === 'Active' ? '#12B76A' : '#B42318'} 
                        textSize='1.2' leftPadding='0.5' fontWeight='600'
                    >
                        {row.status}
                    </AppSpan>
                </FlexRow>
            ),
        },
        {
            name: <p className="table-column-title">Action</p>,
            selector:(row: any) => (
                <Switch >
                    <input type="checkbox" checked={row.status === 'Active' ? true : false} onChange={() => handleAction(row)} />
                    <div ></div>
                    <span></span>
                </Switch>
            )
        },
        // {
        //     name: <p className="table-column-title"></p>,
        //     width: '14rem',
        //     wrap: true,
        //     selector:(row: any) => 
        //         <AppLink 
        //             color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
        //             textSize='1.4' fontWeight='600' 
        //             //onClick={() => handleAction(row)}
        //         >
        //             View Details
        //         </AppLink>,
        // },
    ]
}



// ============================= USERS ================================

export const usersColumns = [
    {
        name: <p className="table-column-title">S/N</p>,
        selector: (row: any) => row.sn,
        sortable: true,
        width: '8rem'
    },
    {
        name: <p className="table-column-title">Firstname</p>,
        selector:(row: any) => <AppText textSize='1.4'>{row.firstName}</AppText>,
        wrap: true,
    },
    {
        name: <p className="table-column-title">Lastname</p>,
        selector:(row: any) => <AppText textSize='1.4'>{row.lastName}</AppText>,
        wrap: true,
    },
    {
        name: <p className="table-column-title">Phone Number</p>,
        selector:(row: any) => <AppText textSize='1.4'>{row.phoneNo}</AppText>,
        width: '15rem'
    },
    {
        name: <p className="table-column-title">Email Address</p>,
        selector:(row: any) => <AppText textSize='1.4'>{row.email}</AppText>,
        minWidth: '24rem',
        wrap: true,
    },
    {
        name: <p className="table-column-title">Username</p>,
        selector:(row: any) => <AppText textSize='1.4'>{row.username}</AppText>,
        wrap: true,
    },
    {
        name: <p className="table-column-title">User Role</p>,
        selector:(row: any) => <AppText textSize='1.4'>{row?.role?.name}</AppText>,
        wrap: true,
    },
    {
        name: <p className="table-column-title">Status</p>,
        selector:(row: any) => (
            <FlexRow 
                width='auto' bgColor={row.status === 'Active' ? '#ECFDF3' : '#FEF3F2'} 
                topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
            >
                <AppSpan 
                    color={row.status === 'Active' ? '#12B76A' : '#B42318'} 
                    textSize='1.5' topMargin='-0.2'
                >
                    { row.status === 'Active' ? <BiCheck/> : <i className='fa fa-times-thin' />}
                </AppSpan>
                <AppSpan 
                    color={row.status === 'Active' ? '#12B76A' : '#B42318'} 
                    textSize='1.2' leftPadding='0.5' fontWeight='600'
                >
                    {row.status}
                </AppSpan>
            </FlexRow>
        ),
    }
]


export const usersData = [
    {
        sn: 1,
        firstName: 'Ridwan',
        lastName: 'Kolawole',
        phoneNo: '07025262718',
        email: 'appzone@gmail.com',
        username: 'Rhydhur',
        role: {name: 'Admin'},
        status: 'Active'
    },
    {
        sn: 2,
        firstName: 'Haleemah',
        lastName: 'Olanrewaju',
        phoneNo: '07025262718',
        email: 'appzone@gmail.com',
        username: 'Leemah',
        role: {name: 'Admin'},
        status: 'Inactive'
    },
    {
        sn: 3,
        firstName: 'john',
        lastName: 'Doew',
        phoneNo: '07025262718',
        email: 'appzone@gmail.com',
        username: 'Doe',
        role: {name: 'Admin'},
        status: 'Active'
    },
    {
        sn: 4,
        firstName: 'Elnino',
        lastName: 'Fernandez',
        phoneNo: '07025262718',
        email: 'appzone@gmail.com',
        username: 'Nino',
        role: {name: 'Admin'},
        status: 'Active'
    }
]


// =============================== MANAGE ROLES =============================

export function manageRolesColumns (handleAction?: any) {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => row.sn,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Role</p>,
            width: '18rem',
            selector:(row: any) => <AppText textSize='1.4'>{row.name}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title">Description</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.description}</AppText>,
            width: '40rem',
            wrap: true
        },
        {
            name: <p className="table-column-title">Permissions</p>,
            width: '60rem',
            wrap: true,
            selector:(row: any) => (
                <FlexRow justifyContent='flex-start' wrap='wrap'>
                    {
                        row?.assignedTasks?.map((item: any, index: number) => {
                            return (
                                <CustomContainer 
                                    key={index} rightMargin='1.5' radius='1.6'
                                    padding='1' topPadding='0.5' bottomPadding='0.5'
                                    bgColor={index % 2 === 0 ? '#E7F6F5' : '#EEF4FF'}
                                    width='auto' bottomMargin='0.7'
                                >
                                    <AppText 
                                        textSize='1.2' fontWeight='500'
                                        color={index % 2 === 0 ? '#0A756F' : '#3538CD'}
                                    >
                                        {item.displayName}
                                    </AppText>
                                </CustomContainer>
                            )
                        })
                    }
                </FlexRow>
            )
        },
        {
            name: <p className="table-column-title"></p>,
            width: '14rem',
            wrap: true,
            selector:(row: any) => 
                <AppLink 
                    color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
                    textSize='1.4' fontWeight='600' 
                    onClick={() => handleAction(row)}
                >
                    View Details
                </AppLink>
        },
    ]
}

export const manageRolesData = [
    {
        sn: 1,
        name: 'Hydra Admin',
        description: 'Lorem Ipsum',
        permissions: [{displayName: 'All Access'}]
    },
    {
        sn: 2,
        name: 'Admin',
        description: 'Lorem Ipsum',
        permissions: [{displayName: 'Add Business'}, {displayName: 'View Business'}, {displayName: 'Activate Business'}]
    },
    {
        sn: 3,
        name: 'User',
        description: 'Lorem Ipsum',
        permissions: [{displayName: 'Update Service'}, {displayName: 'Add New Service'}]
    }
]

// ===================== AUDIT LOGS =======================

export function auditLogsColumns (handleAction?: any) {

    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => row.sn,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Date & Time</p>,
            selector:(row: any) => 
                <AppText textSize='1.4'>{helpers.handleDate(row.loggedAt)}</AppText>,
            minWidth: '18rem',
            wrap: true
        },
        {
            name: <p className="table-column-title">User</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.initiatingUser}</AppText>,
            minWidth: '22rem',
            wrap: true
        },
        {
            name: <p className="table-column-title">Operation</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.informationType}</AppText>,
            minWidth: '18rem',
            wrap: true
        },
        {
            name: <p className="table-column-title">IP Address</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.ipAddress || '000.000.000.0'}</AppText>,
            minWidth: '18rem',
            wrap: true
        },
        {
            name: <p className="table-column-title"></p>,
            minWidth: '14rem',
            wrap: true,
            selector:(row: any) => 
                <AppLink 
                    color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
                    textSize='1.4' fontWeight='600' 
                    onClick={() => handleAction(row)}
                >
                    View Details
                </AppLink>,
        },
    ]
}


export const auditLogsData = [
    {
        sn: 1,
        loggedAt: '14/09/22 09:00am',
        initiatingUser: 'Ridwan Kolawole',
        informationType: 'Add New Service',
        ipAddress: '192.166.172.1'
    },
    {
        sn: 2,
        loggedAt: '14/09/22 09:00am',
        initiatingUser: 'Ridwan Kolawole',
        informationType: 'Add New Service',
        ipAddress: '192.166.172.1'
    },
    {
        sn: 3,
        loggedAt: '14/09/22 09:00am',
        initiatingUser: 'Ridwan Kolawole',
        informationType: 'Add New Service',
        ipAddress: '192.166.172.1'
    },
    {
        sn: 4,
        loggedAt: '14/09/22 09:00am',
        initiatingUser: 'Ridwan Kolawole',
        informationType: 'Add New Service',
        ipAddress: '192.166.172.1'
    }
]

// ======================== BANK PARTNERS =========================

export function bankColumns (handleAction?: any) {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => row.sn,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Name</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.name}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title">Short Name</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.shortName}</AppText>
        },
        {
            name: <p className="table-column-title">CBN Code</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.cbnCode}</AppText>
        },
        {
            name: <p className="table-column-title">Recova Code</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.recovaCode}</AppText>
        },
        {
            name: <p className="table-column-title">Prime Code</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.primeCode}</AppText>
        },
        {
            name: <p className="table-column-title">Zone Code</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.zoneCode}</AppText>
        },
        {
            name: <p className="table-column-title">BankOne Code</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.bankOneCode}</AppText>
        },
        {
            name: <p className="table-column-title">Cluster Code</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.clusterCode}</AppText>
        },
        {
            name: <p className="table-column-title"></p>,
            width: '14rem',
            wrap: true,
            selector:(row: any) => 
                <AppLink 
                    color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
                    textSize='1.4' fontWeight='600' 
                    onClick={() => handleAction(row)}
                >
                    View Details
                </AppLink>,
        },
    ]
}

export const bankData = [
    {
        sn: 1,
        name: 'Polaris',
        shortName: 'Polaris',
        cbnCode: '042',
        recovaCode: '0251',
        primeCode: '362',
        zoneCode: '16262',
        bankoneCode: '0383',
        clusterCode: '6723782378'
    },
    {
        sn: 2,
        name: 'Guaranty Trust limited',
        shortName: 'GT',
        cbnCode: '040',
        recovaCode: '0251',
        primeCode: '362',
        zoneCode: '16262',
        bankoneCode: '67326723',
        clusterCode: '6723782378'
    },
    {
        sn: 3,
        name: 'Zenith',
        shortName: 'ZBN',
        cbnCode: '033',
        recovaCode: '0251',
        primeCode: '362',
        zoneCode: '16262',
        bankoneCode: '673673',
        clusterCode: '6723782378'
    },
    {
        sn: 4,
        name: 'Access',
        shortName: 'ACC',
        cbnCode: '055',
        recovaCode: '0251',
        primeCode: '362',
        zoneCode: '16262',
        bankoneCode: '78378378',
        clusterCode: '6723782378'
    },
]


// =========================== BANK PARTNER DETAILS =========================

export function bankPartnerDetailsColumns (handleAction?: any) {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => row.sn,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Business</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.business}</AppText>,
        },
        {
            name: <p className="table-column-title">Total Transaction</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.totalTransaction}</AppText>
        },
        {
            name: <p className="table-column-title"></p>,
            width: '15rem',
            wrap: true,
            selector:(row: any) => 
                <AppLink 
                    color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
                    textSize='1.4' fontWeight='500' 
                    onClick={() => handleAction(row)}
                >
                    Remove Bank
                </AppLink>,
        },
    ]
}


export const bankPartnerDetailsData = [
    {
        sn: 1,
        business: 'Qore',
        totalTransaction: '180,000,000'
    },
    {
        sn: 2,
        business: 'Lend',
        totalTransaction: '200,000,000'
    },
    {
        sn: 3,
        business: 'Prime',
        totalTransaction: '230,000,000'
    }
]


// ======================== BUSINESS TRANSACTIONS ========================

export function businessTxColumns (handleAction?: any) {
    return [
        {
            name: <p className="table-column-title">Date & Time</p>,
            width: '15rem',
            selector:(row: any) => <AppText textSize='1.4'>{row.date}</AppText>,
        },
        {
            name: <p className="table-column-title">Business</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.business}</AppText>,
        },
        {
            name: <p className="table-column-title">Product</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.product}</AppText>,
        },
        {
            name: <p className="table-column-title">Services</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.services}</AppText>,
        },
        {
            name: <p className="table-column-title">Transaction Count</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.transactionCount}</AppText>,
        },
        {
            name: <p className="table-column-title">Transaction Volume</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.transactionVolume}</AppText>,
        },
        {
            name: <p className="table-column-title"></p>,
            width: '14rem',
            wrap: true,
            selector:(row: any) => 
                <AppLink 
                    color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
                    textSize='1.4' fontWeight='600' 
                    onClick={() => handleAction(row)}
                >
                    View Details
                </AppLink>,
        },
    ]
}


export const businessTxData = [
    {
        date: '25/10/2022 09:00am',
        business: 'Polaris',
        product: '4',
        services: '8',
        transactionCount: '2,150',
        transactionVolume: '12,790,920'
    },
    {
        date: '25/10/2022 09:00am',
        business: 'Access',
        product: '4',
        services: '8',
        transactionCount: '1,269',
        transactionVolume: '12,790,920'
    },
    {
        date: '25/10/2022 09:00am',
        business: 'Fidelity',
        product: '4',
        services: '8',
        transactionCount: '1,269',
        transactionVolume: '12,790,920'
    },
    {
        date: '25/10/2022 09:00am',
        business: 'Zenith',
        product: '4',
        services: '8',
        transactionCount: '1,269',
        transactionVolume: '12,790,920'
    }
]

// ======================= BUSINESS TRANSACTIONS DETAILS =======================

export const productTxColumn = [
    {
        name: <p className="table-column-title">S/N</p>,
        selector: (row: any) => row.sn,
        sortable: true,
        width: '8rem'
    },
    {
        name: <p className="table-column-title">Product</p>,
        selector:(row: any) => <AppText textSize='1.4'>{row.productName}</AppText>,
    },
    {
        name: <p className="table-column-title">Transaction Count</p>,
        selector:(row: any) => <AppText textSize='1.4'>{row.transactionCount}</AppText>,
    },
    {
        name: <p className="table-column-title">Transaction Volume</p>,
        selector:(row: any) => <AppText textSize='1.4'>{row.transactionVolume}</AppText>,
    },
    {
        name: <p className="table-column-title">Status</p>,
        selector:(row: any) => (
            <FlexRow 
                width='auto' bgColor={row.status === 'Active' ? '#ECFDF3' : '#FEF3F2'} 
                topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
            >
                <AppSpan 
                    // className='fa fa-check' 
                    color={row.status === 'Active' ? '#12B76A' : '#B42318'} 
                    textSize='1.5' topMargin='-0.2'
                >
                    { row.status === 'Active' ? <BiCheck/> : <i className='fa fa-times-thin' />}
                </AppSpan>
                <AppSpan 
                    color={row.status === 'Active' ? '#12B76A' : '#B42318'} 
                    textSize='1.2' leftPadding='0.5' fontWeight='600'
                >
                    {row.status}
                </AppSpan>
            </FlexRow>
        ),
    }
]

export const productTxData = [
    {
        sn: 1,
        productName: 'Cluster',
        transactionCount: '234',
        transactionVolume: '12,000',
        status: 'Active'
    },
    {
        sn: 2,
        productName: 'BankOne',
        transactionCount: '234',
        transactionVolume: '12,000',
        status: 'Inactive'
    }
]


// ======================== SERVICE TRANSACTIONS =============================

export function serviceTxColumns (handleAction?: any) {
    return [
        {
            name: <p className="table-column-title">Date & Time</p>,
            width: '20rem',
            selector:(row: any) => <AppText textSize='1.4'>{row.date}</AppText>,
        },
        {
            name: <p className="table-column-title">Service</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.serviceName}</AppText>,
        },
        {
            name: <p className="table-column-title">Transaction Count</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.transactionCount}</AppText>,
        },
        {
            name: <p className="table-column-title">Transaction Volume</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.transactionVolume}</AppText>,
        },
        {
            name: <p className="table-column-title"></p>,
            width: '14rem',
            wrap: true,
            selector:(row: any) => 
                <AppLink 
                    color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
                    textSize='1.4' fontWeight='600' 
                    onClick={() => handleAction(row)}
                >
                    View Details
                </AppLink>,
        },
    ]
}


export const serviceTxData = [
    {
        date: '25/10/2022 09:00am',
        serviceName: 'Polaris',
        transactionCount: '2,150',
        transactionVolume: '12,790,920'
    },
    {
        date: '25/10/2022 09:00am',
        serviceName: 'Access',
        transactionCount: '1,269',
        transactionVolume: '12,790,920'
    },
    {
        date: '25/10/2022 09:00am',
        serviceName: 'Fidelity',
        transactionCount: '1,269',
        transactionVolume: '12,790,920'
    },
    {
        date: '25/10/2022 09:00am',
        serviceName: 'Zenith',
        transactionCount: '1,269',
        transactionVolume: '12,790,920'
    }
]


// ===================== SERVICE TRANSACTION DETAILS =================

export const serviceRouteTxColumn = [
    {
        name: <p className="table-column-title">S/N</p>,
        selector: (row: any) => row.sn,
        sortable: true,
        width: '8rem'
    },
    {
        name: <p className="table-column-title">Route</p>,
        selector:(row: any) => <AppText textSize='1.4'>{row.route}</AppText>,
    },
    {
        name: <p className="table-column-title">Bank</p>,
        selector:(row: any) => <AppText textSize='1.4'>{row.bank}</AppText>,
    },
    {
        name: <p className="table-column-title">Transaction Count</p>,
        selector:(row: any) => <AppText textSize='1.4'>{row.transactionCount}</AppText>,
    },
    {
        name: <p className="table-column-title">Transaction Volume</p>,
        selector:(row: any) => <AppText textSize='1.4'>{row.transactionVolume}</AppText>,
    },
    {
        name: <p className="table-column-title">Status</p>,
        selector:(row: any) => (
            <FlexRow 
                width='auto' bgColor={row.status === 'Active' ? '#ECFDF3' : '#FEF3F2'} 
                topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
            >
                <AppSpan 
                    // className='fa fa-check' 
                    color={row.status === 'Active' ? '#12B76A' : '#B42318'} 
                    textSize='1.5' topMargin='-0.2'
                >
                    { row.status === 'Active' ? <BiCheck/> : <i className='fa fa-times-thin' />}
                </AppSpan>
                <AppSpan 
                    color={row.status === 'Active' ? '#12B76A' : '#B42318'} 
                    textSize='1.2' leftPadding='0.5' fontWeight='600'
                >
                    {row.status}
                </AppSpan>
            </FlexRow>
        ),
    }
]

export const serviceRouteTxData = [
    {
        sn: 1,
        route: 'Cluster',
        bank: 'Access',
        transactionCount: '234',
        transactionVolume: '12,000',
        status: 'Active'
    },
    {
        sn: 2,
        route: 'BankOne',
        bank: 'Polaris',
        transactionCount: '234',
        transactionVolume: '12,000',
        status: 'Inactive'
    }
]


export const serviceBusinessTxColumns = [
    {
        name: <p className="table-column-title">Date & Time</p>,
        selector:(row: any) => <AppText textSize='1.4'>{row.date}</AppText>,
    },
    {
        name: <p className="table-column-title">Business</p>,
        selector:(row: any) => <AppText textSize='1.4'>{row.businessName}</AppText>,
    },
    {
        name: <p className="table-column-title">Transaction Count</p>,
        selector:(row: any) => <AppText textSize='1.4'>{row.transactionCount}</AppText>,
    },
    {
        name: <p className="table-column-title">Transaction Volume</p>,
        selector:(row: any) => <AppText textSize='1.4'>{row.transactionVolume}</AppText>,
    }
]

export const serviceBusinessTxData = [
    {
        date: '25/10/2022 09:00am',
        businessName: 'Polaris',
        transactionCount: '2,150',
        transactionVolume: '12,790,920'
    },
    {
        date: '25/10/2022 09:00am',
        businessName: 'Access',
        transactionCount: '1,269',
        transactionVolume: '12,790,920'
    },
]

// ============================== PRODUCT TRANSACTIONS ========================

export function productTransactionColumns (handleAction?: any) {
    return [
        {
            name: <p className="table-column-title">Date & Time</p>,
            width: '15rem',
            selector:(row: any) => <AppText textSize='1.4'>{row.date}</AppText>,
        },
        {
            name: <p className="table-column-title">Product</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.product}</AppText>,
        },
        {
            name: <p className="table-column-title">Product No</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.productNo}</AppText>,
        },
        {
            name: <p className="table-column-title">Services</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.services}</AppText>,
        },
        {
            name: <p className="table-column-title">Transaction Count</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.transactionCount}</AppText>,
        },
        {
            name: <p className="table-column-title">Transaction Volume</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.transactionVolume}</AppText>,
        },
        {
            name: <p className="table-column-title"></p>,
            width: '14rem',
            wrap: true,
            selector:(row: any) => 
                <AppLink 
                    color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
                    textSize='1.4' fontWeight='600' 
                    onClick={() => handleAction(row)}
                >
                    View Details
                </AppLink>,
        },
    ]
}


export const productTransactionData = [
    {
        date: '25/10/2022 09:00am',
        product: 'Polaris',
        productNo: '4',
        services: '8',
        transactionCount: '2,150',
        transactionVolume: '12,790,920'
    },
    {
        date: '25/10/2022 09:00am',
        product: 'Access',
        productNo: '4',
        services: '8',
        transactionCount: '1,269',
        transactionVolume: '12,790,920'
    },
    {
        date: '25/10/2022 09:00am',
        product: 'Fidelity',
        productNo: '4',
        services: '8',
        transactionCount: '1,269',
        transactionVolume: '12,790,920'
    },
    {
        date: '25/10/2022 09:00am',
        product: 'Zenith',
        productNo: '4',
        services: '8',
        transactionCount: '1,269',
        transactionVolume: '12,790,920'
    }
]

// ================================ SERVICE APIS ==========================

export const serviceAPIsColumns = [
    {
        name: <p className="table-column-title">S/N</p>,
        selector: (row: any) => row.sn,
        sortable: true,
        width: '8rem'
    },
    {
        name: <p className="table-column-title">Name</p>,
        selector:(row: any) => <AppText textSize='1.4'>{row.name}</AppText>,
        wrap: true
    },
    {
        name: <p className="table-column-title">Type</p>,
        selector:(row: any) => <AppText textSize='1.4'>{row.serviceApiType}</AppText>,
        wrap: true
    },
]

export const serviceAPIsData = [
    {
        sn: 1,
        name: 'Service API 1',
        serviceApiType: 'Direct'
    },
    {
        sn: 2,
        name: 'Service API 2',
        serviceApiType: 'Indirect'
    },
    {
        sn: 3,
        name: 'Service API 3',
        serviceApiType: 'Direct'
    }
]

//====================== APPROVAL CONFIGURATION ===================

export function approvalConfigurationColumns (handleAction: any, handleStatusSwitch: any) {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => row.sn,
            sortable: true,
            //width: '8rem'
        },
        {
            name: <p className="table-column-title">Function Name</p>,
            selector: (row: any) => 
                <>
                    <AppText textSize='1.4'>{row.name}</AppText>
                    <AppSpan textSize='1.2' color='#0D968F'>{row.description}</AppSpan>
                </>,
            wrap: true
        },
        {
            name: <p className="table-column-title">Action</p>,
            selector:(row: any) => (
                <Switch radius='1.2' checkedBgColor='#0D968F' checkedSliderBgColor='#ffffff'>
                    <input 
                        type="checkbox" 
                        checked={row.status === 'Active' ? true : false} 
                        onChange={() => handleStatusSwitch(row)} />
                    <div ></div>
                    <span></span>
                </Switch>
            )
        },
        {
            name: <p className="table-column-title"></p>,
            width: '14rem',
            wrap: true,
            selector:(row: any) => 
                <AppLink 
                    color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
                    textSize='1.4' fontWeight='600' 
                    onClick={() => handleAction(row)}
                >
                    {row.status === 'Active' ? 'Edit Rule' : 'Set Rule'}
                </AppLink>,
        },
    ]
}

export const approvalConfigurationData = [
    {
        sn: 1,
        functionName: 'Add User',
        description: 'Lorem Ipsum',
        status: 'Inactive',
    },
    {
        sn: 2,
        functionName: 'Add Bank',
        description: 'Lorem Ipsum',
        status: 'Active',
    },
    {
        sn: 3,
        functionName: 'Add Product',
        description: 'Lorem Ipsum',
        status: 'Active',
    }
]


// ===================== BILLING CONFIGURATIONS =====================

export function billingConfigurationColumns (handleAction?: any) {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => row.sn,
            sortable: true,
            width: '13rem'
        },
        {
            name: <p className="table-column-title">Item</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.item}</AppText>,
            wrap: true,
        },
        {
            name: <p className="table-column-title">Fee Type</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.feeType}</AppText>,
            wrap: true,
        },
        {
            name: <p className="table-column-title">Fee Configuration</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.feeConfiguration}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title">Service Provider Fee</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.providerFee}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title"></p>,
            width: '14rem',
            wrap: true,
            selector:(row: any) => 
                <AppLink 
                    color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
                    textSize='1.4' fontWeight='600' 
                    onClick={() => handleAction(row, 'edit')}
                >
                    Edit Details
                </AppLink>,
        },
    ]
}

export const billingConfigurationData = [
    {
        sn: 1,
        item: 'Standard Flat Fee',
        feeType: 'Flat Fee',
        feeConfiguration: 'N100 per API Call', 
        providerFee: '25',
    },
    {
        sn: 2,
        item: 'Standard Variable Fee',
        feeType: 'Flat Fee',
        feeConfiguration: 'N100 per API Call', 
        providerFee: '25%',
    },
    {
        sn: 3,
        item: 'Standard Flat Fee',
        feeType: 'Variable Fee',
        feeConfiguration: 'N100 per API Call', 
        providerFee: '3%',
    },
    {
        sn: 4,
        item: 'Standard Variable Fee',
        feeType: 'Variable Fee',
        feeConfiguration: 'N100 per API Call', 
        providerFee: '12%',
    },
    {
        sn: 5,
        item: 'Standard Flat Fee',
        feeType: 'Variable Fee',
        feeConfiguration: 'N100 per API Call', 
        providerFee: '25%',
    }
]


//==========BUSINESS PARTNER ===================================

export function businessPartnerColumns (handleAction?: any) {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => row.sn,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Partner Name</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.partnerName}</AppText>,
            minWidth: '20rem',
            wrap: true,
        },
        {
            name: <p className="table-column-title">CBN Code</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.cbnCode}</AppText>,
            minWidth: '15rem',
        },
        {
            name: <p className="table-column-title">Address</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.address}</AppText>,
            wrap: true,
            minWidth: '22rem'
        },
        {
            name: <p className="table-column-title">Email</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.email}</AppText>,
            wrap: true,
            minWidth: '20rem'
        },
        {
            name: <p className="table-column-title">Status</p>,
            minWidth: '15rem',
            selector:(row: any) => (
                <FlexRow 
                    width='auto' bgColor={row.status === 'Active' ? '#ECFDF3' : '#FEF3F2'} 
                    topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
                >
                    <AppSpan 
                        // className='fa fa-check' 
                        color={row.status === 'Active' ? '#12B76A' : '#B42318'} 
                        textSize='1.5' topMargin='-0.2'
                    >
                        { row.status === 'Active' ? <BiCheck/> : <i className='fa fa-times-thin' />}
                    </AppSpan>
                    <AppSpan 
                        color={row.status === 'Active' ? '#12B76A' : '#B42318'} 
                        textSize='1.2' leftPadding='0.5' fontWeight='600'
                    >
                        {row.status || 'Successful'}
                    </AppSpan>
                </FlexRow>
            ),
        },
        {
            name: <p className="table-column-title"></p>,
            width: '14rem',
            wrap: true,
            selector:(row: any) => 
                <AppLink 
                    color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
                    textSize='1.4' fontWeight='600' 
                    onClick={() => handleAction(row, 'view')}
                >
                    View Details
                </AppLink>,
        },
    ]
}

export const businessPartnerData = [
    {
        sn: 1,
        partnerName: 'Qore',
        address: 'Ga-akanbi, olorunsogo',
        cbnCode: '1234',
        email: 'newcore@gmail.com',
        status: 'Active',
        adminUsername: 'Elnino',
        adminFirstName: 'John',
        adminLastName: 'Doe',
        adminEmail: 'hydra@appzone.com',
        adminPhoneNo: '07023456712'
    },
    {
        sn: 2,
        partnerName: 'Crendly',
        address: '12b, Admiralty way, Lekki Zone 1, Abuja',
        cbnCode: '1234',
        email: 'newcore@gmail.com',
        status: 'Inactive',
        adminUsername: 'Elnino',
        adminFirstName: 'John',
        adminLastName: 'Doe',
        adminEmail: 'hydra@appzone.com',
        adminPhoneNo: '07023456712'
    },
    {
        sn: 3,
        partnerName: 'Facebook',
        address: 'Ga-akanbi, olorunsogo',
        cbnCode: '1234',
        email: 'newcore@gmail.com',
        status: 'Active',
        adminUsername: 'Elnino',
        adminFirstName: 'John',
        adminLastName: 'Doe',
        adminEmail: 'hydra@appzone.com',
        adminPhoneNo: '07023456712'
    },
    {
        sn: 4,
        partnerName: 'Whatsapp',
        address: 'Ga-akanbi, olorunsogo',
        cbnCode: '1234',
        email: 'newcore@gmail.com',
        status: 'Active',
        adminUsername: 'Elnino',
        adminFirstName: 'John',
        adminLastName: 'Doe',
        adminEmail: 'hydra@appzone.com',
        adminPhoneNo: '07023456712'
    },
    {
        sn: 5,
        partnerName: 'Twitter',
        address: 'Ga-akanbi, olorunsogo',
        cbnCode: '1234',
        email: 'newcore@gmail.com',
        status: 'Inactive',
        adminUsername: 'Elnino',
        adminFirstName: 'John',
        adminLastName: 'Doe',
        adminEmail: 'hydra@appzone.com',
        adminPhoneNo: '07023456712'
    },
]


// ======================== BANK PARTNER ===================

export function bankPartnerColumns (handleAction?: any) {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => row.sn,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Partner Name</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.name}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title">Address</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.address}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title">Email</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.email}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title">Status</p>,
            minWidth: '15rem',
            selector:(row: any) => (
                <FlexRow 
                    width='auto' bgColor={row.status === 'Active' ? '#ECFDF3' : '#FEF3F2'} 
                    topPadding='0' height='2.2' bottomPadding='0' padding='0.8' radius='1.6'
                >
                    <AppSpan 
                        color={row.status === 'Active' ? '#12B76A' : '#B42318'} 
                        textSize='1.5' topMargin='-0.2'
                    >
                        { row.status === 'Active' ? <BiCheck/> : <i className='fa fa-times-thin' />}
                    </AppSpan>
                    <AppSpan 
                        color={row.status === 'Active' ? '#12B76A' : '#B42318'} 
                        textSize='1.2' leftPadding='0.5' fontWeight='600'
                    >
                        {row.status}
                    </AppSpan>
                </FlexRow>
            ),
        },
        {
            name: <p className="table-column-title"></p>,
            width: '14rem',
            wrap: true,
            selector:(row: any) => 
                <AppLink 
                    color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
                    textSize='1.4' fontWeight='600' 
                    onClick={() => handleAction(row)}
                >
                    View Details
                </AppLink>,
        },
    ]
}


// ================== APPROVAL REQUEST =================

export function serviceApprovalColumns (handleAction?: any) {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => row.sn,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Initiating User</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.initiator}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title">Services</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.service}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title">Bank</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.bank}</AppText>
        },
        {
            name: <p className="table-column-title">Control Mode</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.controlMode}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title">Business</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.business}</AppText>
        },
        {
            name: <p className="table-column-title"></p>,
            width: '14rem',
            wrap: true,
            selector:(row: any) => 
                <AppLink 
                    color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
                    textSize='1.4' fontWeight='600' 
                    onClick={() => handleAction(row)}
                >
                    View Details
                </AppLink>,
        },
    ]
}

export const serviceApprovalData = [
    {
        sn: 1,
        initiator: 'Khaleel Dalegan',
        service: 'Direct Intrabank',
        bank: 'Zenith',
        controlMode: 'Flexible',
        business: 'Qore',
        accountName: 'Khaleel Dalegan',
        accountNumber: '0014273819',
        isAccountValidated: false
    },
    {
        sn: 2,
        initiator: 'Khaleel Dalegan',
        service: 'Bank Transfer',
        bank: 'FCMB',
        controlMode: 'Constrained',
        business: 'Zone',
        accountName: 'Khaleel Dalegan',
        accountNumber: '0014273819',
        isAccountValidated: true
    },
    {
        sn: 3,
        initiator: 'Khaleel Dalegan',
        service: 'Bank Transfer',
        bank: 'First Bank',
        controlMode: 'Flexible',
        business: 'Recova',
        accountName: 'Khaleel Dalegan',
        accountNumber: '0014273819',
        isAccountValidated: false
    },
    {
        sn: 4,
        initiator: 'Khaleel Dalegan',
        service: 'Direct Intrabank',
        bank: 'GTBank',
        controlMode: 'Constrained',
        business: 'Lend',
        accountName: 'Khaleel Dalegan',
        accountNumber: '0014273819',
        isAccountValidated: true
    }
]


// ======================= BILLING =====================

export function billingColumns (handleAction?: any) {
    return [
        {
            name: <p className="table-column-title">S/N</p>,
            selector: (row: any) => row.sn,
            sortable: true,
            width: '8rem'
        },
        {
            name: <p className="table-column-title">Payment History</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.paymentHistory}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title">Period</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.period}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title">Total Charges</p>,
            selector:(row: any) => <AppText textSize='1.4'>{row.totalCharges}</AppText>,
            wrap: true
        },
        {
            name: <p className="table-column-title"></p>,
            width: '14rem',
            wrap: true,
            selector:(row: any) => 
                <AppLink 
                    color='#1E1E1E' decoration='none' hoverColor='#12B76A' 
                    textSize='1.4' fontWeight='600' 
                    onClick={() => handleAction(row)}
                >
                    View Details
                </AppLink>,
        },
    ]
}

export const billingData = [
    {
        sn: 1,
        paymentHistory: 'March 2023 Billing Report',
        period: '1 March 2023 -31 March 2023',
        totalCharges: 'N110,223',
        apiCharge: 'N100',
        overdraftUsed: 'N25,000',
        overdraftCharge: 'N250'
    },
    {
        sn: 2,
        paymentHistory: 'March 2023 Billing Report',
        period: '1 March 2023 -31 March 2023',
        totalCharges: 'N110,223',
        apiCharge: 'N100',
        overdraftUsed: 'N25,000',
        overdraftCharge: 'N250'
    },
    {
        sn: 3,
        paymentHistory: 'March 2023 Billing Report',
        period: '1 March 2023 -31 March 2023',
        totalCharges: 'N120,223',
        apiCharge: 'N100',
        overdraftUsed: 'N25,000',
        overdraftCharge: 'N250'
    },
]
