import { useState, useContext, useEffect } from "react"
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import { FiChevronDown, FiChevronLeft, FiChevronUp } from "react-icons/fi"
import { PopupUserIcon } from "src/assets/icons"
import { AppSpan, AppText, Button, CustomContainer, Divider, FlexRow, SidePopup, SidePopupContainer } from "src/style"
import { CheckboxSelection } from "src/component"
import helpers from "src/utils/helpers"

export const ViewRole: React.FC<any> = ({data, close, showEdit, setEdit}) => {
    const {notifier} = useContext(VisibilityContext)
    const [manageAccordion, setManageAccordion] = useState<any>({})
    const [tasks, setTasks] = useState<any>(null)
    const [assignedTaskIDs, setAssignedTaskIDs] = useState<number[]>([])

    useEffect(() => {
        if (data) {
            const ids: number[] = []
            for (let item of data?.assignedTasks) ids.push(item.id)

            setAssignedTaskIDs(ids)
            setTasks(helpers.groupTasks(data?.assignedTasks))
        }
    }, [data])


    function handleEdit() {
        setEdit(true)
        showEdit()
        close()
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                     <PopupUserIcon/> 
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <AppText color='#101828' textSize="1.8" fontWeight="700" bottomMargin="1.5">{data?.name}</AppText>
                    <AppSpan textSize="1.6" fontWeight="300" color='#101828' bottomMargin="1">
                        {data?.description}
                    </AppSpan>
                    <CustomContainer topMargin="1">
                        <Divider margin='1.5' borderWidth='0.6' />
                        
                        {
                            tasks?.map((data: any, index: number) => {
                                return (
                                    <CustomContainer key={index}>
                                        <FlexRow justifyContent='space-between' onClick={() => setManageAccordion({...manageAccordion, [data.groupName]: !manageAccordion[data.groupName]})}>
                                            <AppText color='#101828' textSize='1.6' fontWeight='400' cursor='pointer'>
                                                {data.groupName}
                                            </AppText>
                                            <AppSpan 
                                                color='#98A2B3' textSize="2.5" fontWeight="500" 
                                                leftMargin='2'
                                            >
                                                { manageAccordion[data.groupName] ? <FiChevronUp /> : <FiChevronDown />}
                                            </AppSpan> 
                                        </FlexRow>
                                        { manageAccordion[data.groupName] &&
                                            <CustomContainer topMargin='0.7'>
                                                <FlexRow justifyContent="space-between" wrap="wrap">
                                                    {
                                                        data.items?.map((item: any, subIndex: number) => {
                                                            return (
                                                                <CheckboxSelection 
                                                                    key={subIndex}
                                                                    width='49' 
                                                                    name={item.displayName}
                                                                    fontWeight='500'
                                                                    customClass='checkbox-selection-mobile'
                                                                    disabled
                                                                    value={assignedTaskIDs.includes(item.id)}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </FlexRow>
                                            </CustomContainer>
                                        }
                                        <Divider margin='1.5' borderWidth='0.6' />
                                    </CustomContainer>
                                )
                            })
                        }
                        <FlexRow justifyContent="flex-end" topMargin="3">
                            <Button 
                                width='8'
                                height='4'
                                radius='0.8'
                                hoverBgColor='#ffffff'
                                borderColor='#0D968F'
                                hoverColor='#0D968F'
                                fontWeight='600'
                                onClick={handleEdit}
                            >
                                Edit
                            </Button>
                        </FlexRow>
                    </CustomContainer>
                </CustomContainer>
            </SidePopup>
        </>
    )
}
    