import { AppSpan, AppText, CustomContainer, FlexRow, SidePopup, SidePopupContainer } from "src/style"
import { BiCheck } from "react-icons/bi"

export const TransactionInfo: React.FC<any> = ({close, data}) => {

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    <AppText color='#101828' textSize="1.8" fontWeight="700">Transaction Details</AppText>
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                   <CustomContainer>
                        <CustomContainer>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Product</AppText>
                                <AppSpan color='#333542' fontWeight="300">Lend</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Service</AppText>
                                <AppSpan color='#333542' fontWeight="300">Direct Intrabank customer to customer</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">API</AppText>
                                <AppSpan color='#333542' fontWeight="300">Straight through Intrabank customer to customer</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Route</AppText>
                                <AppSpan color='#333542' fontWeight="300">Lend</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Constrain mode</AppText>
                                <AppSpan color='#333542' fontWeight="300">Flexible</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Source account name</AppText>
                                <AppSpan color='#333542' fontWeight="300">Appzone Limited</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Source account number</AppText>
                                <AppSpan color='#333542' fontWeight="300">0127392093</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Destination account name</AppText>
                                <AppSpan color='#333542' fontWeight="300">Appzone Limited</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Destination account number</AppText>
                                <AppSpan color='#333542' fontWeight="300">0127392093</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Transaction amount</AppText>
                                <AppSpan color='#333542' fontWeight="300">10,000,000</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Status</AppText>
                                <CustomContainer 
                                    width='auto' bgColor={data?.status === 'Successful' ? '#ECFDF3' : '#FEF3F2'} 
                                    topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
                                >
                                    <AppSpan 
                                        color={data?.status === 'Successful' ? '#12B76A' : '#B42318'} 
                                        textSize='1.5'
                                    >
                                        { data?.status === 'Successful' ? <BiCheck/> : <i className='fa fa-times-thin' />}
                                    </AppSpan>
                                    <AppSpan 
                                        color={data?.status === 'Successful' ? '#027A48' : '#B42318'} 
                                        textSize='1.2' leftPadding='0.5' fontWeight='600'
                                    >
                                        {data?.status}
                                    </AppSpan>
                                </CustomContainer>
                            </FlexRow>
                        </CustomContainer>
                   </CustomContainer>
                </CustomContainer>
            </SidePopup>
        </>
    )
}
    