import { useState } from "react"
import utility from "src/utils/utility"
import { ReactComponent as BillingIcon } from "src/assets/svg/billing-icon.svg"
import { SidePopupContainer, SidePopup, FlexRow, AppSpan, CustomContainer, AppText, Button, FlexColumn, Form, FormGroup, GridContainer } from "src/style"
import { RadioSelection } from "src/component"

export const NewBillingPlan: React.FC<any> = ({next, close}) => {
    const [input, setInput] = useState({
        billingPlanName: '', billingType: '', providerFee: '',
        flatType: '', feePerApiCall: '', transactionAmount: '', transactionCap: ''
    })
    const [bonded, setBonded] = useState([{minApiCall: '', maxApiCall: '', feePerApiCall: ''}])

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => setInput({...input, [e.target.name]: e.target.value})
    const handleBonded = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let val = e.target.value
        if (e.target.name === 'feePerApiCall') {
            val = e.target.value.replace(/[,.]/g, '')
            if (isNaN(Number(val))) return false
        }
        let currentInfo = [...bonded]
        currentInfo[index] = {...currentInfo[index], [e.target.name]: val}
        setBonded(currentInfo)
    }

    function handleAddNew(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        const currentInfo = [
            ...bonded,
            {minApiCall: '', maxApiCall: '', feePerApiCall: ''}
        ]
        setBonded(currentInfo)
    }

    function handleSubmit(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        next()
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    <BillingIcon />  
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <AppText color='#101828' textSize="1.8" fontWeight="700">Add New Billing Plan</AppText>
                    <CustomContainer topMargin="2.5">
                        <Form>
                            <FormGroup>
                                <label>Enter Billing Plan Name</label>
                                <input 
                                    placeholder='Billing Plan Name' 
                                    name='billingPlanName'
                                    autoComplete='off'
                                    value={input.billingPlanName}
                                    onChange={handleInput}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Select Billing Type</label>
                                <RadioSelection 
                                    option1='Flat' option2='Bonded' 
                                    output={(val: any) => setInput({...input, billingType: val})} 
                                />
                            </FormGroup>
                            { input.billingType === 'Flat' &&
                                <>
                                    <FormGroup topMargin="-1">
                                        <label>Select Flat Type</label>
                                        <RadioSelection 
                                            option1='Flat Fee' option2='Variable Fee' 
                                            output={(val: any) => setInput({...input, flatType: val})} 
                                        />
                                    </FormGroup>
                                    { input.flatType === 'Flat Fee' &&
                                        <FormGroup topMargin="-1">
                                            <label>Enter Fee Per API Call</label>
                                            <input 
                                                placeholder='Fee Per Call' 
                                                name='feePerApiCall'
                                                value={input.feePerApiCall ? utility.koboFormat(input.feePerApiCall) : ''}
                                                onChange={handleInput}
                                            />
                                        </FormGroup>
                                    }
                                    { input.flatType === 'Variable Fee' &&
                                        <FlexRow gap="2" sizeUnit="%" topMargin="-1">
                                            <FormGroup width="49" sizeUnit="%">
                                                <label>Transaction Amount %</label>
                                                <input 
                                                    type='number'
                                                    placeholder='' 
                                                    name='transactionAmount'
                                                    value={input.transactionAmount}
                                                    onChange={handleInput}
                                                />
                                            </FormGroup>
                                            <FormGroup width="49" sizeUnit="%">
                                                <label>Capped at</label>
                                                <input 
                                                    placeholder='' 
                                                    name='transactionCap'
                                                    value={input.transactionCap ? utility.koboFormat(input.transactionCap) : ''}
                                                    onChange={handleInput}
                                                />
                                            </FormGroup>
                                        </FlexRow>
                                    }
                                </>
                            }
                            {   input.billingType === 'Bonded' &&
                                    <>
                                        {
                                            bonded?.map((item, index) => {
                                                return (
                                                    <FlexRow gap="2" sizeUnit="%" key={index}>
                                                        <FormGroup width="32" sizeUnit="%">
                                                            <label>Min. API Call</label>
                                                            <input 
                                                                type='number'
                                                                placeholder='' 
                                                                name='minApiCall'
                                                                value={item.minApiCall}
                                                                onChange={(e) => handleBonded(e, index)}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup width="32" sizeUnit="%">
                                                            <label>Max. API Call</label>
                                                            <input 
                                                                type='number'
                                                                placeholder='' 
                                                                name='maxApiCall'
                                                                value={item.maxApiCall}
                                                                onChange={(e) => handleBonded(e, index)}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup width="32" sizeUnit="%">
                                                            <label>Fee Per Call</label>
                                                            <input 
                                                                placeholder='' 
                                                                name='feePerApiCall'
                                                                value={item.feePerApiCall ? utility.koboFormat(item.feePerApiCall) : ''}
                                                                onChange={(e) => handleBonded(e, index)}
                                                            />
                                                        </FormGroup>
                                                    </FlexRow>
                                                )
                                            })  
                                        }
                                        <GridContainer alignItems="end">
                                            <Button
                                                width='10.4'
                                                height='4'
                                                radius='0.8'
                                                hoverBgColor='#ffffff'
                                                borderColor='#0D968F'
                                                hoverColor='#0D968F'
                                                fontWeight='600'
                                                onClick={handleAddNew}
                                            >
                                                + Add New
                                            </Button>
                                        </GridContainer>
                                    </>
                            }

                            <AppText color='#101828' textSize="1.8" fontWeight="700" topMargin="2.5">Service Provider Fee</AppText>
                            <FormGroup topMargin="2">
                                <label>Enter Fee (%)</label>
                                <input 
                                    placeholder='Fee (%)' 
                                    type='number'
                                    name='providerFee'
                                    value={input.providerFee}
                                    onChange={handleInput}
                                />
                            </FormGroup>
                            <FlexRow justifyContent="flex-end">
                                <Button
                                    width='8.1'
                                    height='4'
                                    radius='0.8'
                                    bgColor='#ffffff'
                                    hoverBgColor='#ffffff'
                                    borderColor='#D0D5DD'
                                    color='#344054'
                                    hoverColor='#0D968F'
                                    rightMargin='1.5'
                                    topMargin='1'
                                    fontWeight='600'
                                    onClick={close}
                                >
                                    Cancel
                                </Button>
                                <Button 
                                    width='7.1'
                                    height='4'
                                    radius='0.8'
                                    hoverBgColor='#ffffff'
                                    borderColor='#0D968F'
                                    hoverColor='#0D968F'
                                    fontWeight='600'
                                    topMargin="1"
                                    onClick={handleSubmit}
                                >
                                    Save
                                </Button>
                            </FlexRow>
                        </Form>
                    </CustomContainer>
                </CustomContainer>
            </SidePopup>
        </>
    )
}