import { useState, useEffect, useContext } from 'react'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import { AppText, Button, CustomContainer, FlexRow, Input, ScreenContainer } from 'src/style'
import { useNavigate } from 'react-router-dom'
import { ServerSideTable, Table } from 'src/component'
import { usersColumns, usersData } from 'src/utils/tableLayout'
import { BsPlus } from 'react-icons/bs'
import { AddUser } from 'src/popup'
import { FiSearch } from 'react-icons/fi'
import helpers from 'src/utils/helpers'


const Users: React.FC = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const [usersData, setUsersData] = useState<any>(null)
    const [users, setUsers] = useState<any>(null)
    const [showAddUser, setShowAddUser] = useState(false)

    useEffect(() => {
        handleFetchUsers()
    }, [])

    async function handleFetchUsers (pageNumber = 1, pageSize = 10) {
        const response = await API.getUsers(pageNumber, pageSize)
        if (response) {
            setUsersData(response)
            setUsers(helpers.addSerialNumberToItems(response?.items))
        }
    }

    return (
        <>
            <ScreenContainer>
                <AppText textSize='1.4' cursor='pointer'>
                    Users
                </AppText>
                <CustomContainer topMargin='3'>
                    <FlexRow justifyContent='space-between' className='business-title-wrap'>
                        <AppText textSize='3' color='#101828' topMargin='2' fontWeight='600'>Users</AppText>
                        <Button
                            width='13.8'
                            height='4'
                            radius='0.8'
                            hoverBgColor='#ffffff'
                            borderColor='#0D968F'
                            hoverColor='#0D968F'
                            fontWeight='600'
                            topMargin='2'
                            className='add-business-btn'
                            onClick={() => setShowAddUser(true)}
                        >
                            <BsPlus style={{fontSize: '1.5rem'}}/> 
                            Add New User
                        </Button>
                    </FlexRow>
                    <ServerSideTable
                        tableData={users} 
                        tableColumns={usersColumns} 
                        dataFetchMethod={ handleFetchUsers}
                        totalRows={usersData?.totalCount}
                        customStyle
                        canSearch
                    />
                </CustomContainer>
            </ScreenContainer>
            { showAddUser &&
                <AddUser 
                    close={() => setShowAddUser(false)}
                    getUsersMethod={handleFetchUsers}
                />
            }
        </>
    )
}

export default utility.routeData('users', 'Users', <Users/>)