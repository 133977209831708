import { useState, useContext, useEffect } from "react"
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import ApiContext from 'src/provider/API/call-service'
import { AppSpan, AppText, Button, CustomContainer, FlexRow, Form, FormGroup, SidePopup, SidePopupContainer } from "src/style"
import { PopupBankIcon } from "src/assets/icons"


export const AddBankPartnerConfiguration: React.FC<any> = ({data, bankPartnerCode, updatedViewMethod, close}) => {
    const {API} = useContext(ApiContext)
    const {notifier} = useContext(VisibilityContext)
    const [input, setInput] = useState({
        shortName: '', cbnCode: '', recovaCode: '', 
        zoneCode: '', primeCode: '', clusterCode: '', bankOneCode: ''
    })

    useEffect(() => {
        if (data) setInput(data)
    }, [])

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>) => setInput({...input, [e.target.name]: e.target.value})

    async function handleSubmit(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()

        const response = await API.updateBank({...input, bankPartnerCode})
        if (response && updatedViewMethod) updatedViewMethod()
        close()
    }


    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    <PopupBankIcon/>
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <AppText color='#101828' textSize="1.8" fontWeight="700">{!data ? 'Add Configuration' : 'Edit Configuration'}</AppText>
                    <Form topMargin="2" onSubmit={handleSubmit}>
                        <FormGroup>
                            <label>Enter Short Name</label>
                            <input 
                                placeholder="Short Name" 
                                name='shortName'
                                value={input.shortName}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Enter CBN Code</label>
                            <input 
                                placeholder="CBN Code" 
                                name='cbnCode'
                                value={input.cbnCode}
                                onChange={handleInput}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Enter Recova Code</label>
                            <input 
                                placeholder="Recova Code" 
                                name='recovaCode'
                                value={input.recovaCode}
                                onChange={handleInput}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Enter Zone Code</label>
                            <input 
                                placeholder="Zone Code" 
                                name='zoneCode'
                                value={input.zoneCode}
                                onChange={handleInput}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Enter Prime Code</label>
                            <input 
                                placeholder="Prime Code" 
                                name='primeCode'
                                value={input.primeCode}
                                onChange={handleInput}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Enter BankOne Code</label>
                            <input 
                                placeholder="BankOne Code" 
                                name='bankOneCode'
                                value={input.bankOneCode}
                                onChange={handleInput}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Enter Cluster Code</label>
                            <input 
                                placeholder="Cluster Code" 
                                name='clusterCode'
                                value={input.clusterCode}
                                onChange={handleInput}
                            />
                        </FormGroup>
                        <FlexRow justifyContent="flex-end">
                            <Button 
                                width='8'
                                height='4'
                                radius='0.8'
                                hoverBgColor='#ffffff'
                                borderColor='#0D968F'
                                hoverColor='#0D968F'
                                fontWeight='600'
                                topMargin="3"
                            >
                                {!data ? 'Save' : 'Update'}
                            </Button>
                        </FlexRow>
                    </Form>
                </CustomContainer>
            </SidePopup>
        </>
    )
}
    