import './style.scss'
import { useState, useContext, useEffect } from 'react'
import utility from 'src/utils/utility'
import { AppText, Button, CustomContainer, FlexRow, Input, ScreenContainer } from 'src/style'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import { Table } from 'src/component'
import { servicesColumns, servicesData } from 'src/utils/tableLayout'
import { BsPlus } from 'react-icons/bs'
import { AddService } from 'src/popup'
import { FiSearch } from 'react-icons/fi'
import helpers from 'src/utils/helpers'


const Services: React.FC = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {setInfoProperty} = useContext(AppInfoContext)
    const [services, setServices] = useState<any>(null)
    const [showAddService, setShowAddService] = useState(false)
    

    useEffect(() => {
        handleFetchServices()
    }, [])

    async function handleFetchServices() {
        const response = await API.getAllServices()
        if (response) setServices(helpers.addSerialNumberToItems(response))
    }

    async function handleViewDetails (data: any) {
        await setInfoProperty('selectedService', data)
        navigate('/dashboard/services/service-details')
    }

    return (
        <>
            <ScreenContainer>
                <AppText 
                    textSize='1.4' cursor='pointer' 
                    onClick={() => navigate('/dashboard/services')}
                >
                    Services
                </AppText>
                <CustomContainer topMargin='3'>
                    <FlexRow justifyContent='space-between' className='business-title-wrap'>
                        <AppText textSize='3' color='#101828' topMargin='2' fontWeight='600'>Services</AppText>
                        <Button
                            width='15.8'
                            height='4'
                            radius='0.8'
                            hoverBgColor='#ffffff'
                            borderColor='#0D968F'
                            hoverColor='#0D968F'
                            fontWeight='600'
                            topMargin='2'
                            className='add-business-btn'
                            onClick={() => setShowAddService(true)}
                        >
                            <BsPlus style={{fontSize: '1.5rem'}}/> 
                            Add New Service
                        </Button>
                    </FlexRow>
                    {/* <CustomContainer
                        width='40' radius='0.8' borderColor='#D0D5DD' 
                        leftPadding='1.5' rightPadding='1.5' topMargin='2'
                        shadow='0px 1px 2px rgba(16, 24, 40, 0.05)'
                        bottomMargin='2' className='service-search-box'
                    >
                        <FlexRow>
                            <FiSearch style={{fontSize: '2rem'}} />
                            <Input height='4.4' leftPadding='1' placeholder='Search' />
                        </FlexRow>
                    </CustomContainer> */}
                    <Table 
                        tableData={services}
                        tableColumns={servicesColumns(handleViewDetails)}
                        canSearch
                        customStyle
                    />
                </CustomContainer>
            </ScreenContainer>
            { showAddService &&
                <AddService 
                    close={() => setShowAddService(false)}
                    fetchUpdateMethod={handleFetchServices}
                />
            }
        </>
    )
}

export default utility.routeData('services', 'Services', <Services/>)