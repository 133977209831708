import { useContext, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import utility from 'src/utils/utility'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'

export const MainRoutes = () => {
    const navigate = useNavigate()
    const {info} = useContext(AppInfoContext)
    const [isAuth, setIsAuth] = useState<boolean>(!!info.authKey)

    useEffect(() => {
        handleAuth()
    }, [])

    async function handleAuth () {
        const retrievedData = localStorage.getItem('authKey')
        const isAuthenticated = (retrievedData && retrievedData != 'null') ? true : false
        setIsAuth(isAuthenticated)
        if(isAuthenticated) navigate('dashboard')
    }

    
    return (
        <>
            { !isAuth ?
                <Outlet />
                :
                null
            }
        </>
    )
}

export default utility.routeData('/', 'Main', <MainRoutes/>)