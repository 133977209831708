import { useContext, useEffect, useState } from "react"
import ApiContext from "src/provider/API/call-service"
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import { PopupWorldIcon } from "src/assets/icons"
import { SidePopupContainer, SidePopup, AppSpan, FlexRow, AppText, CustomContainer, Form, FormGroupWithIcon, FormGroup, Button } from "src/style"
import { FiChevronDown, FiChevronLeft } from "react-icons/fi"

export const AddBilling: React.FC<any> = ({editData, fetchConfigs, close}) => {
    const {API} = useContext(ApiContext)
    const {notifier} = useContext(VisibilityContext)
    const [input, setInput] = useState({
        billingServiceId: editData?.billingServiceId || '', 
        limit: editData?.limit || '', 
        feePerAPI: editData?.feePerAPI || '', 
        transactionValue: editData?.transactionValue || '', 
        feePerc: editData?.feePerc || '', 
        discount: editData?.discount || ''
    })
    const [selectedService, setSelectedService] = useState<any>(null)
    const [serviceList, setServiceList] = useState<any[]>([])
    const [serviceId, setServiceId] = useState<any>('')
    const [view, setView] = useState('billing')

    useEffect(() => {
        handleInitData()
    }, [])

    async function handleInitData () {
        const response = await API.getAllServices()
        if (response) setServiceList(response)
    }

    function handleInput (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>) {
        setInput({...input, [e.target.name]: e.target.value})
    }

    function handleSelectedService (data: any) {
        data = JSON.parse(data)
        console.log({data})
        setServiceId(data?.id)
        setSelectedService(data)
    }

    function handleProceed (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        if (view === 'billing') setView('wallet')
        else {
            if (editData) notifier.show('Billing Configuration Edited', null, 'success')
            else notifier.show('Billing Configuration Saved', null, 'success')

            if (fetchConfigs) fetchConfigs()
            close()
        }
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    { view === 'billing' ? 
                        <PopupWorldIcon/> 
                        : 
                        <AppSpan 
                            color='#000000' textSize="2.5" fontWeight="500" 
                            hoverColor="#0D968F" onClick={() => setView('billing')}
                        >
                            <FiChevronLeft />
                        </AppSpan> 
                    }
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <AppText color='#101828' textSize="1.8" fontWeight="700">
                        {view === 'billing' ? `${editData ? 'Edit' : 'Add'} Billing Configuration` : `${editData ? 'Edit' : 'Add'} Wallet Configuration`}
                    </AppText>
                    <Form topMargin="2" onSubmit={handleProceed}>
                        { view === 'billing' &&
                            <>
                                { !editData &&
                                    <FormGroupWithIcon bottomMargin='1.8'>
                                        <label>Select Service</label>
                                        <div className="input-with-icon">
                                            <select 
                                                name="services"
                                                value={JSON.stringify(selectedService)} 
                                                onChange={(e) => handleSelectedService(e.target.value)}
                                                style={{color: input.billingServiceId ? '#667085' : '#999'}}
                                                required
                                            >
                                                <option value="">Services</option>
                                                {
                                                    serviceList?.map((item: any, index: number) => {
                                                        return (
                                                            <option value={JSON.stringify(item)} key={index}>{item.friendlyName}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <FiChevronDown className="input-with-icon-tag" />
                                        </div>
                                    </FormGroupWithIcon>
                                }
                                <FormGroup>
                                    <label>Fee Per API Call</label>
                                    <input 
                                        placeholder="Enter Fee" 
                                        name='feePerAPI'
                                        type="number"
                                        value={input.feePerAPI}
                                        onChange={handleInput}
                                    />
                                </FormGroup>
                                <CustomContainer>
                                    <AppText textSize="1.8" fontWeight="700" color="#101828" bottomMargin="2">Discount</AppText>
                                    <FormGroup>
                                        <label>Transaction Value</label>
                                        <input 
                                            placeholder="Enter Transaction Value" 
                                            name='transactionValue'
                                            type="number"
                                            value={input.transactionValue}
                                            onChange={handleInput}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Discount</label>
                                        <input 
                                            placeholder="Enter Discount" 
                                            name='discount'
                                            value={input.discount}
                                            onChange={handleInput}
                                        />
                                    </FormGroup>
                                </CustomContainer>
                                <CustomContainer>
                                    <AppText textSize="1.8" fontWeight="700" color="#101828" bottomMargin="2">Service Provider Fee</AppText>
                                    <FormGroup>
                                        <label>Fee (%)</label>
                                        <input 
                                            placeholder="Enter Fee" 
                                            name='feePerc'
                                            type="number"
                                            value={input.feePerc}
                                            onChange={handleInput}
                                        />
                                    </FormGroup>
                                </CustomContainer>
                            </>
                        }
                        { view === 'wallet' &&
                            <FormGroup>
                                <label>Overdraft Limit</label>
                                <input 
                                    placeholder="Enter Limit" 
                                    name='limit'
                                    type="number"
                                    value={input.limit}
                                    onChange={handleInput}
                                />
                            </FormGroup>
                        }
                        <FlexRow justifyContent="flex-end">
                            <Button
                                width='8.1'
                                height='4'
                                radius='0.8'
                                bgColor='#ffffff'
                                hoverBgColor='#ffffff'
                                borderColor='#D0D5DD'
                                color='#344054'
                                hoverColor='#0D968F'
                                rightMargin='1.5'
                                topMargin='1'
                                fontWeight='600'
                                onClick={close}
                            >
                                Cancel
                            </Button>
                            <Button 
                                width='8'
                                height='4'
                                radius='0.8'
                                hoverBgColor='#ffffff'
                                borderColor='#0D968F'
                                hoverColor='#0D968F'
                                fontWeight='600'
                                topMargin="1"
                            >
                                {view === 'billing' ? 'Next' : 'Save'}
                            </Button>
                        </FlexRow>
                    </Form>
                </CustomContainer>
            </SidePopup>
        </>
    )
}