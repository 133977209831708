import './style.scss'
import React, { useState, useContext, useEffect, useRef } from 'react'
import utility from 'src/utils/utility'
import { AbsoluteContainer, AppLabel, AppSpan, AppText, Button, CustomContainer, Divider, FlexRow, FormGroupWithIcon, GridContainer, Input, ScreenContainer } from 'src/style'
import { useNavigate } from 'react-router-dom'
import { Table } from 'src/component'
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import ApiContext from 'src/provider/API/call-service'
import { apiProductColumns, apiProductData } from 'src/utils/tableLayout'
import { EditBusiness, PayloadStructure } from 'src/popup'
import { BiCheck } from 'react-icons/bi'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { IoCopyOutline } from 'react-icons/io5'
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineQuestionCircle } from 'react-icons/ai'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import helpers from 'src/utils/helpers'


const APIKeys: React.FC = () => {
    const navigate = useNavigate()
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const {info: {selectedBusiness}} = useContext(AppInfoContext)
    const [input, setInput] = useState({service: ''})
    const [services, setServices] = useState<any>(null)
    const [businessProducts, setBusinessProducts] = useState<any>(null)
    //const [selectedServiceCode, setSelectedServiceCode] = useState<any>(null)
    const [isTestKeyVisible, setIsTestKeyVisible] = useState(false)
    const [isLiveKeyVisible, setIsLiveKeyVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [key, setKey] = useState({test: '', live: ''})
    const [url, setUrl] = useState({test: 'https://webadmin.appzone.com/sndk', live: 'https://webadmin.appzone.com/sndk'})
    const [view, setView] = useState('')

    const selectedkey = useRef('')
    const selectedUrl = useRef('')

    useEffect(() => {
        if (selectedBusiness) {
            handleFetchServices()
            handleFetchProducts()
            getKeys(true)
            getUrls(true)
        }
    }, [selectedBusiness])
    async function getKeys (shouldLoad?: boolean) {
        const response = await API.getApiKeys(selectedBusiness?.code, shouldLoad)
        if (response) setKey({test: response.testKey, live: response.liveKey})
    }

    async function getUrls (shouldLoad?: boolean) {
        const response = await API.getWebhookUrls(selectedBusiness?.code, shouldLoad)
        if (response) setUrl({test: response.testUrl, live: response.liveUrl})
    }

    async function handleFetchServices() {
        const response = await API.getAllServices()
        if (response) setServices(response)
    }

    async function handleFetchProducts() {
        const response = await API.getBusinessProducts(selectedBusiness?.code)
        if (response) setBusinessProducts(helpers.addSerialNumberToItems(response))
    }

    async function handleGenerateKey (type: string) {
        selectedkey.current = type
        setIsLoading(true)
        const response = type === 'test' ? await API.generateTestKey(selectedBusiness?.code)
            : await API.generateLiveKey(selectedBusiness?.code)
        if (response) getKeys()
        setIsLoading(false)
        selectedkey.current = ''
    }

    async function handleUpdateUrl (type: string) {
        selectedUrl.current = type
        setIsLoading(true)
        const response = (type === 'test' && url.test) ? await API.updateTestWebhook(selectedBusiness?.code, url.test)
            : (type === 'live' && url.live) ? await API.updateLiveWebhook(selectedBusiness?.code, url.live) : null
        setIsLoading(false)
        selectedUrl.current = ''
    }

    // async function handleSelectedService (code: string) {
    //     setSelectedServiceCode(code)
    //     if (code) {
    //         selectedUrl.current = 'load'
    //         setIsLoading(true)
    //         await getUrls(false)
    //         setIsLoading(false)
    //         selectedkey.current = ''
    //     }
    // }

    function handleViewPayload (data: any) {
        console.log({data})
        setView('payload-structure')
    }

    function handleCopy (text: string, title: string) {
        if (text) {
            //navigator.clipboard.writeText(text)
            notifier.show(`${title} copied successfully!`, '', 'success')
        }
    }

    return (
        <>
            <ScreenContainer bottomPadding='5'>
                <AppText>
                    <AppSpan textSize='1.4' cursor='pointer'  onClick={() => navigate('/dashboard/business')}>Business /</AppSpan>
                    <AppSpan textSize='1.4' cursor='pointer' onClick={() => navigate('/dashboard/business/business-details')}> Business Details /</AppSpan>
                    <AppSpan textSize='1.4' cursor='pointer'> Business Keys & APIs </AppSpan>
                </AppText>
                <CustomContainer topMargin='3'>
                    <FlexRow justifyContent='space-between' className='business-title-wrap'>
                        <AppText textSize='3' color='#101828' topMargin='2' rightMargin='4' fontWeight='600'>
                            {selectedBusiness?.name} - Business Keys & APIs
                        </AppText>
                        <Button
                            width='14'
                            height='4'
                            radius='0.8'
                            hoverBgColor='#ffffff'
                            borderColor='#0D968F'
                            hoverColor='#0D968F'
                            fontWeight='600'
                            topMargin='2'
                            className='add-business-btn'
                            onClick={() => navigate('/dashboard/business')}
                        >
                            View API Docs
                        </Button>
                    </FlexRow>
                    <FlexRow justifyContent='flex-start' wrap='wrap' topMargin='3'>
                        <AppText 
                            color='#101828' textSize='1.8' fontWeight='600' 
                            bottomMargin='1.5' rightMargin='3'
                        >
                            Business Details
                        </AppText>
                        <Button
                            width='8.3' height='4' radius='0.8'
                            hoverBgColor='#ffffff' borderColor='#0D968F'
                            hoverColor='#0D968F'
                            bottomMargin='1.5' fontWeight='600'
                        >
                            Go Live
                        </Button>
                    </FlexRow>

                    <FlexRow
                        minHeight='11.7' borderColor='#EAECF0'
                        shadow='0px 1px 2px rgba(16, 24, 40, 0.06)'
                        radius='0.8' bgColor='#ffffff' padding='2.2'
                        justifyContent='flex-start' wrap='wrap' topMargin='1'
                    >
                        <CustomContainer width='auto' bottomMargin='1.7' rightMargin='7'>
                            <AppText color='#333542' textSize='1.6' fontWeight='700'>Business</AppText>
                            <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{selectedBusiness?.name}</AppSpan>
                        </CustomContainer>
                        <CustomContainer width='auto' bottomMargin='1.7' rightMargin='7'>
                            <AppText color='#333542' textSize='1.6' fontWeight='700'>Email Address</AppText>
                            <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{selectedBusiness?.address}</AppSpan>
                        </CustomContainer>
                        <CustomContainer width='auto' bottomMargin='1.7' rightMargin='7'>
                            <AppText color='#333542' textSize='1.6' fontWeight='700'>Phone Number</AppText>
                            <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{selectedBusiness?.phoneNo}</AppSpan>
                        </CustomContainer>
                        <CustomContainer width='auto' bottomMargin='1.7' rightMargin='7'>
                            <AppText color='#333542' textSize='1.6' fontWeight='700'>Status</AppText>
                            <FlexRow 
                                width='8' bgColor={selectedBusiness?.status === 'Active' ? '#ECFDF3' : '#FEF3F2'}
                                topPadding='0' bottomPadding='0' padding='0.8' radius='1.6'
                            >
                                <AppSpan  
                                    color={selectedBusiness?.status === 'Active' ? '#12B76A' : '#B42318'} 
                                    textSize='1.5' topMargin='-0.2'
                                >
                                    { selectedBusiness?.status === 'Active' ? <BiCheck/> : <i className='fa fa-times-thin' />}
                                </AppSpan>
                                <AppSpan 
                                    color={selectedBusiness?.status === 'Active' ? '#027A48' : '#B42318'}
                                    textSize='1.3' leftPadding='0.5' fontWeight='500'
                                >
                                    {selectedBusiness?.status || 'Inactive'}
                                </AppSpan>
                            </FlexRow>
                        </CustomContainer>
                        <CustomContainer width='auto' bottomMargin='1.7' rightMargin='7'>
                            <AppText color='#333542' textSize='1.6' fontWeight='700'>Business Address</AppText>
                            <AppSpan color='#333542' fontWeight='300' textSize='1.4'>{selectedBusiness?.address}</AppSpan>
                        </CustomContainer>
                    </FlexRow>

                    <CustomContainer topMargin='2.5'>
                        <AppText color='#101828' textSize='1.8' fontWeight='600'>
                            Business Keys
                        </AppText>
                        <FlexRow wrap='wrap' width='auto' justifyContent='flex-start' alignItems='flex-start'>
                            <CustomContainer
                                minHeight='15.7' borderColor='#EAECF0' width='55'
                                shadow='0px 1px 2px rgba(16, 24, 40, 0.06)'
                                radius='0.8' bgColor='#ffffff' padding='2'
                                topMargin='2'  topPadding='2.6'
                                className='business-key-card'
                            >
                                <AppLabel color='#434344'>Test Key</AppLabel>
                                { (selectedkey.current === 'test' && isLoading) &&
                                    <AbsoluteContainer top='0' left='0' zIndex='3' width='100' sizeUnit='%'>
                                        <GridContainer height='15.7' bgColor='rgba(255, 255, 255, 0.5)'>
                                            <AppSpan textSize='4' color='#0D968F' className='fa fa-spinner fa-spin' />
                                        </GridContainer>
                                    </AbsoluteContainer>
                                }
                                <CustomContainer topMargin='1.8'>
                                    <AppLabel color='#344054' fontWeight='600'>Business Secret Keys</AppLabel>
                                    <FlexRow>
                                        <FlexRow 
                                            height='4' borderColor='#D0D5DD' justifyContent='space-between'
                                            style={{borderRightWidth: '0rem', borderRadius: '8px 0px 0px 8px'}}
                                            leftPadding='1' rightPadding='0.7' width='82' sizeUnit='%' className='key-container'
                                        >   
                                            <FlexRow justifyContent='flex-start' rightMargin='1.5'>
                                                <AppText 
                                                    textSize='1.6' fontWeight='700' rightPadding='1.5' 
                                                    cursor='pointer' hoverColor='#0D968F'
                                                    onClick={() => handleGenerateKey('test')}
                                                >
                                                    Refresh
                                                </AppText>
                                                <CustomContainer className='api-keys' overflow='auto'>
                                                    <AppSpan textSize='1.3' topMargin='0.5' fontWeight='700' style={{wordWrap: 'normal'}} >
                                                        {
                                                            isTestKeyVisible ? key.test : (!isTestKeyVisible && key.test) ? '*********************************************' : null
                                                        }
                                                    </AppSpan>
                                                </CustomContainer>
                                            </FlexRow>
                                            <AbsoluteContainer top='0.5' right='0.5' zIndex='2' className='key-explainer'>
                                                <CustomContainer width='auto' leftPadding='1'  bgColor='#ffffff'>
                                                    <AppSpan color='#98A2B3' textSize='1.8' cursor='pointer'>
                                                        { !isTestKeyVisible ?
                                                            <AiOutlineEye onClick={() => setIsTestKeyVisible(!isTestKeyVisible)} />
                                                            : <AiOutlineEyeInvisible onClick={() => setIsTestKeyVisible(!isTestKeyVisible)} />
                                                        }
                                                    </AppSpan>
                                                </CustomContainer>
                                            </AbsoluteContainer>
                                        </FlexRow>
                                        <CopyToClipboard 
                                            text={key.test}
                                            onCopy={() => handleCopy(key.test, 'Test api key')}
                                        >
                                            <FlexRow 
                                                width='18' sizeUnit='%' height='4' borderColor='#D0D5DD'
                                                style={{borderRadius: '0px 8px 8px 0px'}} className='key-copy-container'
                                                //onClick={() => handleCopy(key.test, 'Test api key')}
                                            >
                                                <AppSpan color='#344054' fontWeight='600' textSize='2'>
                                                    <IoCopyOutline />
                                                    <AppSpan color='#344054' fontWeight='600' leftPadding='0.5' cursor='pointer'>Copy</AppSpan>
                                                </AppSpan>
                                            </FlexRow>
                                        </CopyToClipboard>
                                    </FlexRow>
                                </CustomContainer>
                            </CustomContainer>
                            <CustomContainer
                                minHeight='15.7' borderColor='#EAECF0' width='55'
                                shadow='0px 1px 2px rgba(16, 24, 40, 0.06)'
                                radius='0.8' bgColor='#ffffff' padding='2'
                                topMargin='2' topPadding='2.6'
                                className='business-key-card'
                            >
                                <AppLabel color='#434344'>Live Key</AppLabel>
                                { (selectedkey.current === 'live' && isLoading) &&
                                    <AbsoluteContainer top='0' left='0' zIndex='3' width='100' sizeUnit='%'>
                                        <GridContainer height='15.7' bgColor='rgba(255, 255, 255, 0.5)'>
                                            <AppSpan textSize='4' color='#0D968F' className='fa fa-spinner fa-spin' />
                                        </GridContainer>
                                    </AbsoluteContainer>
                                }
                                <CustomContainer topMargin='1.8'>
                                    <AppLabel color='#344054' fontWeight='600'>Business Secret Keys</AppLabel>
                                    <FlexRow>
                                        <FlexRow 
                                            height='4' borderColor='#D0D5DD' justifyContent='space-between'
                                            style={{borderRightWidth: '0rem', borderRadius: '8px 0px 0px 8px'}}
                                            leftPadding='1' rightPadding='0.7' width='82' sizeUnit='%' className='key-container'
                                        >   
                                            <FlexRow justifyContent='flex-start' rightMargin='1.5'>
                                                <AppText 
                                                    textSize='1.6' fontWeight='700' rightPadding='1.5'
                                                    cursor='pointer' hoverColor='#0D968F'
                                                    onClick={() => handleGenerateKey('live')}
                                                >
                                                    Refresh
                                                </AppText>
                                                <CustomContainer className='api-keys' overflow='auto'>
                                                    <AppSpan textSize='1.3' topMargin='0.5' fontWeight='700' style={{wordWrap: 'normal'}} >
                                                        {
                                                            isLiveKeyVisible ? key.live : (!isLiveKeyVisible && key.live) ? '*********************************************' : null
                                                        }
                                                    </AppSpan>
                                                </CustomContainer>
                                            </FlexRow>
                                            <AbsoluteContainer top='0.5' right='0.5' zIndex='4' className='key-explainer'>
                                                <CustomContainer width='auto' leftPadding='1'  bgColor='#ffffff'>
                                                    <AppSpan color='#98A2B3' textSize='1.8' cursor='pointer'>
                                                        { !isLiveKeyVisible ?
                                                            <AiOutlineEye onClick={() => setIsLiveKeyVisible(!isLiveKeyVisible)} />
                                                            : <AiOutlineEyeInvisible onClick={() => setIsLiveKeyVisible(!isLiveKeyVisible)} />
                                                        }
                                                    </AppSpan>
                                                </CustomContainer>
                                            </AbsoluteContainer>
                                        </FlexRow>
                                        <CopyToClipboard 
                                            text={key.live}
                                            onCopy={() => handleCopy(key.live, 'Live api key')}
                                        >
                                            <FlexRow 
                                                width='18' sizeUnit='%' height='4' borderColor='#D0D5DD'
                                                style={{borderRadius: '0px 8px 8px 0px'}} className='key-copy-container'
                                                //onClick={() => handleCopy(key.live, 'Live api key')}
                                            >
                                                <AppSpan color='#344054' fontWeight='600' textSize='2'>
                                                    <IoCopyOutline />
                                                    <AppSpan color='#344054' fontWeight='600' leftPadding='0.5' cursor='pointer'>Copy</AppSpan>
                                                </AppSpan>
                                            </FlexRow>
                                        </CopyToClipboard>
                                    </FlexRow>
                                </CustomContainer>
                            </CustomContainer>
                        </FlexRow>
                    </CustomContainer>
                    
                    <FlexRow wrap='wrap' width='auto' justifyContent='flex-start' alignItems='stretch'>
                        <CustomContainer 
                            width='55' topMargin='3' 
                            className='business-key-card'
                        >
                             <AppText color='#101828' textSize='1.8' fontWeight='600' bottomMargin='2'>
                                My Products
                            </AppText>
                            <Table 
                                tableData={businessProducts}
                                tableColumns={apiProductColumns(handleCopy, handleViewPayload)}
                                customStyle
                            />
                        </CustomContainer>
                        <CustomContainer
                            width='55' topMargin='3'
                            className='business-key-card'
                        >
                            <AppText color='#101828' textSize='1.8' fontWeight='600'>
                                Approved Services
                            </AppText>
                            <CustomContainer
                                minHeight='25.9' //height='100' hUnit='%'
                                shadow='0px 1px 2px rgba(16, 24, 40, 0.06)' borderColor='#EAECF0'
                                radius='0.8' bgColor='#ffffff' padding='2.2' topMargin='2'
                            >
                                { (selectedUrl.current && isLoading) &&
                                    <AbsoluteContainer top='0' left='0' zIndex='3' width='100' sizeUnit='%'>
                                        <GridContainer height='25.9' bgColor='rgba(255, 255, 255, 0.5)'>
                                            <AppSpan textSize='4' color='#0D968F' className='fa fa-spinner fa-spin' />
                                        </GridContainer>
                                    </AbsoluteContainer>
                                }
                                {/* <FormGroupWithIcon bottomMargin='1.8'>
                                    <label>Services</label>
                                    <div className="input-with-icon">
                                        <select 
                                            name="service" 
                                            onChange={(e) => handleSelectedService(e.target.value)}
                                            style={{color: selectedServiceCode ? '#667085' : '#999'}}
                                        >
                                            <option value=''>Select service</option>
                                            {
                                                services?.map((item: any, index: number) => {
                                                    return (
                                                        <option value={item.institutionCode} key={index}>{item?.friendlyName}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <FiChevronDown className="input-with-icon-tag" />
                                    </div>
                                </FormGroupWithIcon> */}
                                <CustomContainer>
                                    <AppLabel color='#344054' fontWeight='600'>Test Webhook / Callback URL</AppLabel>
                                    <FlexRow>
                                        <FlexRow 
                                            height='4' borderColor='#D0D5DD' justifyContent='space-between'
                                            style={{borderRightWidth: '0rem', borderRadius: '8px 0px 0px 8px'}}
                                            leftPadding='1' rightPadding='0.7' width='82' sizeUnit='%' className='key-container'
                                        >   
                                            <FlexRow justifyContent='flex-start' rightMargin='1.5'>
                                                <AppText 
                                                    textSize='1.6' fontWeight='700' rightPadding='1.5'
                                                    cursor='pointer' hoverColor='#0D968F'
                                                    onClick={() => handleUpdateUrl('test')}
                                                >
                                                    Update
                                                </AppText>
                                                <CustomContainer className='api-keys' overflow='auto'>
                                                    <Input
                                                        textSize='1.6'
                                                        onChange={(e) => setUrl({...url, test: e.target.value})}
                                                        value={url?.test}
                                                        style={{wordWrap: 'normal', color: '#667085'}}
                                                    />
                                                </CustomContainer>
                                            </FlexRow>
                                            <AbsoluteContainer top='0.5' right='0.5' zIndex='4' className='key-explainer'>
                                                <CustomContainer width='auto' leftPadding='1'  bgColor='#ffffff'>
                                                    <AppSpan color='#98A2B3' textSize='1.8' cursor='pointer'>
                                                        {/* <AiOutlineQuestionCircle /> */}
                                                    </AppSpan>
                                                </CustomContainer>
                                            </AbsoluteContainer>
                                        </FlexRow>
                                        <CopyToClipboard 
                                            text={url.test}
                                            onCopy={() => handleCopy(url.test, 'Test Webhook Url')}
                                        >
                                            <FlexRow 
                                                width='18' sizeUnit='%' height='4' borderColor='#D0D5DD'
                                                style={{borderRadius: '0px 8px 8px 0px'}} className='key-copy-container'
                                                //onClick={() => handleCopy(url.test, 'Test Webhook Url')}
                                            >
                                                <AppSpan color='#344054' fontWeight='600' textSize='2'>
                                                    <IoCopyOutline />
                                                    <AppSpan color='#344054' fontWeight='600' leftPadding='0.5' cursor='pointer'>Copy</AppSpan>
                                                </AppSpan>
                                            </FlexRow>
                                        </CopyToClipboard>
                                    </FlexRow>
                                </CustomContainer>
                                <CustomContainer topMargin='1.8'>
                                    <AppLabel color='#344054' fontWeight='600'>Live Webhook / Callback URL</AppLabel>
                                    <FlexRow>
                                        <FlexRow 
                                            height='4' borderColor='#D0D5DD' justifyContent='space-between'
                                            style={{borderRightWidth: '0rem', borderRadius: '8px 0px 0px 8px'}}
                                            leftPadding='1' rightPadding='0.7' width='82' sizeUnit='%' className='key-container'
                                        >   
                                            <FlexRow justifyContent='flex-start' rightMargin='1.5'>
                                                <AppText 
                                                    textSize='1.6' fontWeight='700' rightPadding='1.5'
                                                    cursor='pointer' hoverColor='#0D968F'
                                                    onClick={() => handleUpdateUrl('live')}
                                                >
                                                    Update
                                                </AppText>
                                                <CustomContainer className='api-keys' overflow='auto'>
                                                    <Input
                                                        textSize='1.6'
                                                        onChange={(e) => setUrl({...url, live: e.target.value})}
                                                        value={url?.live}
                                                        style={{wordWrap: 'normal', color: '#667085'}}
                                                    />
                                                </CustomContainer>
                                            </FlexRow>
                                            <AbsoluteContainer top='0.5' right='0.5' zIndex='4' className='key-explainer'>
                                                <CustomContainer width='auto' leftPadding='1'  bgColor='#ffffff'>
                                                    <AppSpan color='#98A2B3' textSize='1.8' cursor='pointer'>
                                                        {/* <AiOutlineQuestionCircle /> */}
                                                    </AppSpan>
                                                </CustomContainer>
                                            </AbsoluteContainer>
                                        </FlexRow>
                                        <CopyToClipboard 
                                            text={url.live}
                                            onCopy={() => handleCopy(url.live, 'Live Webhook Url')}
                                        >
                                            <FlexRow 
                                                width='18' sizeUnit='%' height='4' borderColor='#D0D5DD'
                                                style={{borderRadius: '0px 8px 8px 0px'}} className='key-copy-container'
                                                //onClick={() => handleCopy(url.live, 'Live Webhook Url')}
                                            >
                                                <AppSpan color='#344054' fontWeight='600' textSize='2'>
                                                    <IoCopyOutline />
                                                    <AppSpan color='#344054' fontWeight='600' leftPadding='0.5' cursor='pointer'>Copy</AppSpan>
                                                </AppSpan>
                                            </FlexRow>
                                        </CopyToClipboard>
                                    </FlexRow>
                                </CustomContainer>
                            </CustomContainer>
                        </CustomContainer>
                    </FlexRow>
                </CustomContainer>
            </ScreenContainer>
            { view === 'payload-structure' &&
                <PayloadStructure 
                    close={() => setView('')}
                />
            }
        </>
    )
}

export default utility.routeData('business-keys-apis', 'Business Keys and APIs', <APIKeys/>)