import React, { useState, useEffect } from 'react'
import helpers from 'src/utils/helpers';
import DataTable from 'react-data-table-component';
import {customStyles, customHeadStyles} from './style'
import './style.scss'
import { Button, CustomContainer, FlexRow, Input } from 'src/style';
import { FiSearch } from 'react-icons/fi';
import {FiDownloadCloud} from 'react-icons/fi'

export const Table: React.FC<any> = React.memo(({
    tableData, tableColumns, filter, resetPagination, 
    customStyle, hasRowSelection, onRowSelectedMethod,
    noRadius, canSearch, canDownload
}) => {
    const [mockTableData, setMockTableData] = useState<any[]>([])

    useEffect(() => {
        setMockTableData(tableData)
    }, [tableData])

    function handleDataSearching (e: React.ChangeEvent<HTMLInputElement>) {
        const filteredData = helpers.searchTableData(tableData, e.target.value)
        console.log({filteredData})
        setMockTableData(filteredData)
    }

  return (
    <>
       { tableData ?
            <>
                { canSearch &&
                    <FlexRow topMargin='2' bottomMargin='2' justifyContent='space-between' gap='2' wrap='wrap'>
                        <CustomContainer
                            width='40' radius='0.8' borderColor='#D0D5DD' 
                            leftPadding='1.5' rightPadding='1.5'
                            shadow='0px 1px 2px rgba(16, 24, 40, 0.05)'
                            className='service-search-box'
                        >
                            <FlexRow>
                                <FiSearch style={{fontSize: '2rem'}} />
                                <Input 
                                    height='4' leftPadding='1' 
                                    placeholder='Search' 
                                    onChange={handleDataSearching} 
                                />
                            </FlexRow>
                        </CustomContainer>
                        { canDownload &&
                            <Button 
                                width='17.9' height='4' radius='0.8'
                                fontWeight='600' bgColor="#ffffff" borderColor='#D0D5DD'
                                hoverBgColor='#F2F4F7' color='#344054'
                            >
                                <FiDownloadCloud style={{marginRight: '1.5rem'}} />
                                Download Report
                            </Button>
                        }
                    </FlexRow>
                }
                <div className={ noRadius ? "table-wrapper-no-radius" : "table-wrapper"} >
                    { filter && tableData.length ? 
                        <div className="filter-search-wrapper">
                            <label htmlFor="filter-input">Search:</label>
                            <input 
                                id="filter-input" 
                                type="text" 
                                className="filter-input" 
                                onChange={handleDataSearching} 
                            />
                        </div>
                        : null
                    }
                    <DataTable
                        columns={tableColumns}
                        data={mockTableData?.length ? mockTableData : tableData}
                        paginationResetDefaultPage={resetPagination}
                        //onRowClicked={(row) => alert(JSON.stringify(row))}
                        striped = {true}
                        selectableRows={hasRowSelection}
                        onSelectedRowsChange={hasRowSelection ? onRowSelectedMethod : null}
                        pagination
                        customStyles={customStyle ? customStyles  : undefined}
                    />
                </div>
            </>
            : null
        }
    </>
  )
})