import { useState, useContext, useEffect } from "react"
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import ApiContext from "src/provider/API/call-service"
import { PopupProductIcon } from "src/assets/icons"
import { AppSpan, AppText, Button, CustomContainer, FlexRow, Form, FormGroup, FormGroupWithIcon, RoundImageContainer, SidePopup, SidePopupContainer } from "src/style"
import { CheckboxSelection } from "src/component"
import { FiChevronDown } from "react-icons/fi"


export const AddBank: React.FC<any> = ({productId, services, close}) => {
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const [selectedService, setSelectedService] = useState<any>(null)
    const [productServiceId, setProductServiceId] = useState<number>(0)
    const [bankData, setBankData] = useState<any>({})
    const [banks, setBanks] = useState<any[]>([])
    const [isConstrained, setIsConstrained] = useState(false)
    const [supportingBanks, setSupportingBanks] = useState<any>([])
    const [input, setInput] = useState<any>({api: '', mode: 'flexible'})

    useEffect(() => {
        handleInitData()
    }, [])

    async function handleInitData () {
        const response = await API.getBanks()
        if (response) setBanks(response)
    }

    function handleSelectedService (data: any) {
        data = JSON.parse(data)
        console.log({data})
        setProductServiceId(data?.productServiceId)
        setSelectedService(data)
        setIsConstrained(data?.controlMode != 'Flexible')
    }

    function handleSupportingBanks (val: boolean, id: number) {
        if (val) setSupportingBanks([...supportingBanks, id])
        else {
            let chosenBanks = [...supportingBanks].filter((item) => item != id)
            setSupportingBanks(chosenBanks)
        }
    }

    function handleBankInfo (bankId: number, e:React.ChangeEvent<HTMLInputElement>) {
        setBankData({...bankData, [bankId]: {...bankData[bankId], [e.target.name]: e.target.value}})
    }

    async function handleSubmit(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()

        if (!supportingBanks?.length) {
            return notifier.show('Kindly provide banks to be added', 'Validation Response')
        }

        const banksInfo = supportingBanks.map((item: number) => {
            return {
                bankId: item,
                sourceAccountName: bankData[item]?.sourceAccountName,
                sourceAccountNo: bankData[item]?.sourceAccountNo,
                destinationAccountName: bankData[item]?.destinationAccountName,
                destinationAccountNo: bankData[item]?.destinationAccountNo,
            }
        })

        const payload = {
            banks: banksInfo,
            productServiceId: productServiceId,
            productId
        }
        
        const response = await API.addProductServiceBank(payload)
        if (response) API.getProductById(productId)
        close()
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    <PopupProductIcon/> 
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <AppText color='#101828' textSize="1.8" fontWeight="700">Add Bank</AppText>
                    <Form topMargin="2" onSubmit={handleSubmit}>
                        <FormGroupWithIcon bottomMargin='1.8'>
                                <label>Select Service</label>
                                <div className="input-with-icon">
                                    <select 
                                        name="services"
                                        value={JSON.stringify(selectedService)} 
                                        onChange={(e) => handleSelectedService(e.target.value)}
                                        style={{color: productServiceId ? '#667085' : '#999'}}
                                        required
                                    >
                                        <option value="">Services</option>
                                        {
                                            services?.map((item: any, index: number) => {
                                                return (
                                                    <option value={JSON.stringify(item)} key={index} onClick={() => handleSelectedService(item)}>{item.friendlyName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <FiChevronDown className="input-with-icon-tag" />
                                </div>
                            </FormGroupWithIcon>
                            <CustomContainer topMargin="2">
                                <AppText fontWeight="600" color="#344054" bottomMargin="1">Select Supporting Banks</AppText>
                                { !isConstrained &&
                                    <FlexRow justifyContent="space-between" wrap="wrap">
                                        {
                                            banks?.map((item: any, index: number) => {
                                                return (
                                                    <CheckboxSelection 
                                                        width='49' name={item?.name}
                                                        customClass='checkbox-selection-mobile'
                                                        key={index} 
                                                        output={(val: boolean) => handleSupportingBanks(val, item?.id)}
                                                    />
                                                )
                                            })
                                        }
                                    </FlexRow>
                                }
                                { isConstrained &&
                                    banks?.map((item: any, index: number) => {
                                        return (
                                            <CustomContainer key={index}>
                                                <CheckboxSelection 
                                                    name={item?.name}
                                                    customClass='checkbox-selection-mobile'
                                                    output={(val: boolean) => handleSupportingBanks(val, item?.id)}
                                                />
                                                { supportingBanks.includes(item?.id) &&
                                                    <Form topMargin="1">
                                                        <FormGroup>
                                                            <label>Source Account Name</label>
                                                            <input 
                                                                placeholder="Account Name" 
                                                                name='sourceAccountName'
                                                                value={bankData[item?.id]?.sourceAccountName || ''}
                                                                onChange={(e) => handleBankInfo(item?.id, e)}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <label>Source Account Number</label>
                                                            <input 
                                                                placeholder="Account Number" 
                                                                name='sourceAccountNo'
                                                                value={bankData[item?.id]?.sourceAccountNo || ''}
                                                                onChange={(e) => handleBankInfo(item?.id, e)}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <label>Destination Account Name</label>
                                                            <input 
                                                                placeholder="Account Name" 
                                                                name='destinationAccountName'
                                                                value={bankData[item?.id]?.destinationAccountName || ''}
                                                                onChange={(e) => handleBankInfo(item?.id, e)}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <label>Destination Account Number</label>
                                                            <input 
                                                                placeholder="Account Number" 
                                                                name='destinationAccountNo'
                                                                value={bankData[item?.id]?.destinationAccountNo || ''}
                                                                onChange={(e) => handleBankInfo(item?.id, e)}
                                                            />
                                                        </FormGroup>
                                                    </Form>
                                                }
                                            </CustomContainer>
                                        )
                                    })
                                }
                            </CustomContainer>
                        <FlexRow justifyContent="flex-end">
                            <Button 
                                width='15'
                                height='4'
                                radius='0.8'
                                hoverBgColor='#ffffff'
                                borderColor='#0D968F'
                                hoverColor='#0D968F'
                                fontWeight='600'
                                topMargin="3"
                            >
                                Request Approval
                            </Button>
                        </FlexRow>
                    </Form>
                </CustomContainer>
            </SidePopup>
        </>
    )
}
    