import { useState, useContext } from "react"
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import ApiContext from "src/provider/API/call-service"
import { FiChevronLeft } from "react-icons/fi"
import { PopupStackIcon } from "src/assets/icons"
import { AppSpan, AppText, Button, CustomContainer, FlexRow, Form, FormGroup, SidePopup, SidePopupContainer } from "src/style"
import helpers from "src/utils/helpers"
import { appSettings } from "src/provider/config/constant"

export const AddBusinessPartner: React.FC<any> = ({fetchBusinessPartner, editData, close}) => {
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const [input, setInput] = useState({
        partnerName: editData?.partnerName || '', cbnCode: editData?.cbnCode || '', institutionEmail: editData?.email || '',
        institutionAddress: editData?.address || '', adminUsername: editData?.adminUsername || '', adminFirstName: editData?.adminFirstName || '', 
        adminLastName: editData?.adminLastName || '', adminEmail: editData?.adminEmail || '', adminPhoneNo: editData?.adminPhoneNo || ''
    })
    const [isFillAdmin, setIsFillAdmin] = useState(false)

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>) => {
        if (e.target.name === 'institutionAddress' && e.target.value.length > appSettings.ADDRESS_CHARACTERS_LENGTH) return null
        if (e.target.name === 'institutionEmail' || e.target.name === 'adminEmail') {
            if (e.target.value.includes(' ')) e.target.value = e.target.value.replace(/ /g, '')
        }
        setInput({...input, [e.target.name]: e.target.value})
    }

    function handleNext(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        setIsFillAdmin(true)
    }

    async function handleSubmit(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        if (!helpers.validateEmail(input.institutionEmail)) return notifier.show('Invalid business email provided')
        else if (!helpers.validateEmail(input.adminEmail)) return notifier.show('Invalid admin email provided')
        else if (!helpers.validatePhoneNo(input.adminPhoneNo)) return notifier.show('Invalid admin phone number provided')
        
        // const response = editData ? await API.updateBusinessPartner(input) :  await API.createNewBusinessPartner(input)
        // if (response && fetchBusinessPartner) fetchBusinessPartner() 
        notifier.show('Your changes have been saved and your Business Partner is live.', null, 'success') // to be deleted after api integration
        close()
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    { !isFillAdmin ? 
                        <PopupStackIcon/> 
                        : 
                        <AppSpan 
                            color='#000000' textSize="2.5" fontWeight="500" 
                            hoverColor="#0D968F" onClick={() => setIsFillAdmin(false)}
                        >
                            <FiChevronLeft />
                        </AppSpan> 
                    }
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                {   !isFillAdmin &&
                    <CustomContainer topMargin="3">
                        <AppText color='#101828' textSize="1.8" fontWeight="700">{editData ? 'Edit' : 'Add New'} Business Partner</AppText>
                        <Form topMargin="2" onSubmit={handleNext}>
                            <FormGroup>
                                <label>Enter Partner Name</label>
                                <input 
                                    placeholder="Partner Name" 
                                    name='partnerName'
                                    value={input.partnerName}
                                    onChange={handleInput}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Enter CBN Code</label>
                                <input 
                                    type='number' 
                                    placeholder="CBN Code" 
                                    name='cbnCode'
                                    value={input.cbnCode}
                                    onChange={handleInput}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Enter Business Address</label>
                                <textarea 
                                    placeholder="Business Address"
                                    name='institutionAddress'
                                    value={input.institutionAddress}
                                    onChange={handleInput}
                                    required
                                />
                                <AppSpan textSize="1.2" color='#B42318'>
                                    Remaining {appSettings.ADDRESS_CHARACTERS_LENGTH - input.institutionAddress?.length} characters of {appSettings.ADDRESS_CHARACTERS_LENGTH}
                                </AppSpan>
                            </FormGroup>
                            <FormGroup>
                                <label>Enter Email Address</label>
                                <input 
                                    type='email' 
                                    placeholder="Email Address" 
                                    name='institutionEmail'
                                    value={input.institutionEmail}
                                    onChange={handleInput}
                                    required
                                />
                            </FormGroup>
                            <FlexRow justifyContent="flex-end">
                                <Button 
                                    width='8'
                                    height='4'
                                    radius='0.8'
                                    hoverBgColor='#ffffff'
                                    borderColor='#0D968F'
                                    hoverColor='#0D968F'
                                    fontWeight='600'
                                    topMargin="1"
                                >
                                    Next
                                </Button>
                            </FlexRow>
                        </Form>
                    </CustomContainer>
                }
                { isFillAdmin &&
                    <CustomContainer topMargin="3" data-aos="zoom-in">
                        <AppText color='#101828' textSize="1.8" fontWeight="700">{editData ? 'Edit' : 'Add'} Admin Details</AppText>
                        <Form topMargin="2" onSubmit={handleSubmit}>
                            <FormGroup>
                                <label>Enter First Name</label>
                                <input 
                                    placeholder="First Name" 
                                    name='adminFirstName'
                                    value={input.adminFirstName}
                                    onChange={handleInput}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Enter Last Name</label>
                                <input 
                                    placeholder="Last Name" 
                                    name='adminLastName'
                                    value={input.adminLastName}
                                    onChange={handleInput}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Enter Email Address</label>
                                <input 
                                    type='email' 
                                    placeholder="Email Address" 
                                    name='adminEmail'
                                    value={input.adminEmail}
                                    onChange={handleInput}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Enter Phone Number</label>
                                <input 
                                    type='number' 
                                    placeholder="Phone Number" 
                                    name='adminPhoneNo'
                                    value={input.adminPhoneNo}
                                    onChange={handleInput}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Enter Username</label>
                                <input 
                                    placeholder="Username" 
                                    name='adminUsername'
                                    value={input.adminUsername}
                                    onChange={handleInput}
                                    required
                                />
                            </FormGroup>
                            <FlexRow justifyContent="flex-end">
                                <Button 
                                    width='8'
                                    height='4'
                                    radius='0.8'
                                    hoverBgColor='#ffffff'
                                    borderColor='#0D968F'
                                    hoverColor='#0D968F'
                                    fontWeight='600'
                                    topMargin="1"
                                >
                                    Save
                                </Button>
                            </FlexRow>
                        </Form>
                    </CustomContainer>
                }
            </SidePopup>
        </>
    )
}
    