import './style.scss'
import { useState, useEffect, useContext } from 'react'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import UserContext from 'src/provider/state-manager/userProvider'
import { AppText, Button, CustomContainer, FlexRow, ScreenContainer } from 'src/style'
import { useNavigate } from 'react-router-dom'
import { Table } from 'src/component'
import { businessPartnerColumns, businessPartnerData } from 'src/utils/tableLayout'
import { BsPlus } from 'react-icons/bs'
import { AddBusinessPartner, BusinessPartnerDetails } from 'src/popup'
import helpers from 'src/utils/helpers'


const BusinessPartner: React.FC = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {user : {userInfo}} = useContext(UserContext)
    const [businessPartner, setBusinessPartner] = useState<any>(null)
    const [selectedBusinessPartner, setSelectedBusinessPartner] = useState<any>(null)
    const [view, setView] = useState('')
    const userType = userInfo?.user?.institution?.institutionCategory.institutionCategoryType

    // useEffect(() => {
    //     if (userType) {
    //         if (userType != 'ServiceProvider') handleFetchBusinessByInstitutionCode()
    //         else handleFetchBusinessPartners()
    //     }
    // }, [userType])

    // async function handleFetchBusinessPartners() {
    //     const response = await API.getBusinessPartners()
    //     if (response) setBusinessPartner(helpers.addSerialNumberToItems(response))
    // }

    // async function handleFetchBusinessByInstitutionCode () {
    //     const response = API.getBusinesseByInstitutionCode()
    //     if (response) navigate('/dashboard/business-details')
    // }

    function handleView (data: any, type: string) {
        setSelectedBusinessPartner(data)
        setView(type === 'add' ? 'add_edit-partner' : 'partner-details')
    }

    return (
        <>
            <ScreenContainer>
                <AppText 
                    textSize='1.4' cursor='pointer' 
                    onClick={() => navigate('/dashboard/business')}
                >
                    Business Partner
                </AppText>
                <CustomContainer topMargin='3'>
                    <FlexRow justifyContent='space-between' className='business-title-wrap'>
                        <AppText textSize='3' color='#101828' topMargin='2' fontWeight='600'>Business Partner</AppText>
                        { userType === 'ServiceProvider' &&
                            <Button
                                width='18.8'
                                height='4'
                                radius='0.8'
                                hoverBgColor='#ffffff'
                                borderColor='#0D968F'
                                hoverColor='#0D968F'
                                fontWeight='600'
                                topMargin='2'
                                className='add-business-btn'
                                onClick={() => handleView(null, 'add')}
                            >
                                <BsPlus style={{fontSize: '1.5rem'}}/> 
                                Add Business Partner
                            </Button>
                        }
                    </FlexRow>
                    <AppText 
                        textSize='1.8' fontWeight='600' color='#101828' 
                        topMargin='2' bottomMargin='1'
                    >
                        View Business Partner List
                    </AppText>
                    <Table 
                        tableData={businessPartnerData}
                        tableColumns={businessPartnerColumns(handleView)}
                        customStyle
                        keyField='sn'
                    />
                </CustomContainer>
            </ScreenContainer>
            { view === 'add_edit-partner' &&
                <AddBusinessPartner 
                    close={() => setView('')}
                    editData={selectedBusinessPartner}
                    //fetchBusinessPartner={handleFetchBusinessPartners}
                />
            }
            { view === 'partner-details' &&
                <BusinessPartnerDetails
                    data={selectedBusinessPartner} 
                    handleEditAction={() => setView('add_edit-partner')}
                    close={() => setView('')}
                />
            }
        </>
    )
}

export default utility.routeData('business-partner', 'Business Partner', <BusinessPartner/>)