import { NavLink } from "react-router-dom"

export const MenuWrapper: React.FC<any> = ({children, data, clickHandle}) => {

    function handleClick () {
        clickHandle()
    }
    return (
        <>
            { data?.path ?
                <NavLink
                    to={data?.path}
                    className={({ isActive }) => `default-menu ${isActive && 'active-menu'}`}
                    onClick={handleClick}

                >
                    {children}
                </NavLink>
                :
                <div 
                    className="default-menu"
                    onClick={handleClick}
                >
                    {children}
                </div>
            }
        </>
    )
}