export const serviceApiTx = [
    {
        transactionType: 'Intrabank Transfer',
        count: 1,
        value: '1,000,000',
        status: 'success'
    },
    {
        transactionType: 'Intrabank Transfer',
        count: 2,
        value: '1,000,000',
        status: 'failed'
    },
    {
        transactionType: 'Intrabank Transfer',
        count: 3,
        value: '1,000,000',
        status: 'success'
    },
    {
        transactionType: 'Intrabank Transfer',
        count: 4,
        value: '1,000,000',
        status: 'failed'
    },
    {
        transactionType: 'Intrabank Transfer',
        count: 5,
        value: '1,000,000',
        status: 'failed'
    },
    {
        transactionType: 'Intrabank Transfer',
        count: 6,
        value: '1,000,000',
        status: 'success'
    },
    {
        transactionType: 'Intrabank Transfer',
        count: 7,
        value: '1,000,000',
        status: 'success'
    },
    {
        transactionType: 'Intrabank Transfer',
        count: 8,
        value: '1,000,000',
        status: 'failed'
    },
    {
        transactionType: 'Intrabank Transfer',
        count: 9,
        value: '1,000,000',
        status: 'failed'
    },
    {
        transactionType: 'Intrabank Transfer',
        count: 10,
        value: '1,000,000',
        status: 'success'
    },
    {
        transactionType: 'Intrabank Transfer',
        count: 11,
        value: '1,000,000',
        status: 'failed'
    },
    {
        transactionType: 'Intrabank Transfer',
        count: 12,
        value: '1,000,000',
        status: 'success'
    },
    {
        transactionType: 'Intrabank Transfer',
        count: 13,
        value: '1,000,000',
        status: 'success'
    },
    {
        transactionType: 'Intrabank Transfer',
        count: 14,
        value: '1,000,000',
        status: 'success'
    }
]

export const bankPartners = [
    {
        id: 1,
        partnerName: 'Lend',
        address: '12b, Admiralty way, LekkiZone 1, Abuja',
        email: 'Zone@appzone.com',
        status: 'Active'
    },
    {
        id: 2,
        partnerName: 'Qore',
        address: '12b, Admiralty way, LekkiZone 1, Abuja',
        email: 'Zone@appzone.com',
        status: 'Inactive'
    },
    {
        id: 3,
        partnerName: 'Appzone',
        address: '12b, Admiralty way, LekkiZone 1, Abuja',
        email: 'Zone@appzone.com',
        status: 'Inactive'
    },
    {
        id: 4,
        partnerName: 'Zenith',
        address: '12b, Admiralty way, LekkiZone 1, Abuja',
        email: 'Zone@appzone.com',
        status: 'Active'
    }
]

export const bankPartnerAdminData = {
    firstName: 'John',
    lastName: 'Doe',
    phoneNo: '08025126712',
    username: 'Appzone',
    email: 'hydra@appzone.com'
}

export const bankPartnerConfiguration = {
    shortName: 'FBN',
    cbnCode: '001',
    recovaCode: 'ABC',
    primeCode: 'FBN',
    zoneCode: 'ZON',
    bankOneCode: 'BAN',
    clusterCode: '00291'
}

export const fundHistoryData = [
    {
        transactionType: 'Card Transaction',
        amount: '244,000.00',
        date: '12/04/2023'
    },
    {
        transactionType: 'Card Transaction',
        amount: '244,000.00',
        date: '12/04/2023'
    },
    {
        transactionType: 'Card Transaction',
        amount: '300,000.00',
        date: '12/04/2023'
    },
    {
        transactionType: 'Card Transaction',
        amount: '244,000.00',
        date: '12/04/2023'
    },
    {
        transactionType: 'Card Transaction',
        amount: '244,000.00',
        date: '12/04/2023'
    },
    {
        transactionType: 'Card Transaction',
        amount: '244,000.00',
        date: '12/04/2023'
    },
]