import { useState } from "react"
import utility from "src/utils/utility"
import { FiChevronLeft } from "react-icons/fi"
import { SidePopupContainer, SidePopup, FlexRow, AppSpan, CustomContainer, AppText, GridContainer, Form, FormGroup, Button } from "src/style"


export const WalletConfig: React.FC<any> = ({back, next, close}) => {
    const [input, setInput] = useState('')

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value.replace(/[,.]/g, '')
        if (isNaN(Number(val))) return false
        setInput(val)
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    <AppSpan 
                        color='#000000' textSize="2.5" fontWeight="500" 
                        hoverColor="#0D968F" onClick={back}
                    >
                        <FiChevronLeft />
                    </AppSpan>  
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <AppText color='#101828' textSize="1.8" fontWeight="700">Wallet Configuration</AppText>
                    <CustomContainer topMargin="2.5">
                        <Form onSubmit={next}>
                            <FormGroup>
                                <label>Enter Overdraft Limit</label>
                                <input 
                                    placeholder='Overdraft Limit' 
                                    name='overdraft'
                                    value={input ? utility.koboFormat(input) : ''}
                                    onChange={handleInput}
                                />
                            </FormGroup>
                            <FlexRow justifyContent="flex-end">
                                <Button
                                    width='8.1'
                                    height='4'
                                    radius='0.8'
                                    bgColor='#ffffff'
                                    hoverBgColor='#ffffff'
                                    borderColor='#D0D5DD'
                                    color='#344054'
                                    hoverColor='#0D968F'
                                    rightMargin='1.5'
                                    topMargin='1'
                                    fontWeight='600'
                                    onClick={close}
                                >
                                    Cancel
                                </Button>
                                <Button 
                                    width='7.1'
                                    height='4'
                                    radius='0.8'
                                    hoverBgColor='#ffffff'
                                    borderColor='#0D968F'
                                    hoverColor='#0D968F'
                                    fontWeight='600'
                                    topMargin="1"
                                >
                                    Save
                                </Button>
                            </FlexRow>
                        </Form>
                    </CustomContainer>
                </CustomContainer>
            </SidePopup>
        </>
    )
}