import './style.scss'
import { useContext, useState } from 'react';
import utility from "src/utils/utility"
import VisibilityContext from 'src/provider/state-manager/visibilityProvider';
import { ScreenContainer, AppText, CustomContainer, FlexRow, AppSpan, AppLink, GridContainer, Button } from "src/style"
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import {ReactComponent as WalletImg} from 'src/assets/svg/wallet.svg'
import { Table } from 'src/component';
import { billingColumns, billingData} from 'src/utils/tableLayout'
import { BillingHistoryDetails, CardPayment, FundHistory, OtpValidation, PaymentMethod, ReceiverAccountInfo, TransferPayment } from 'src/popup';
import { fundHistoryData } from 'src/utils/mockedData';

const BillingProfile: React.FC = () => {
    const {notifier} = useContext(VisibilityContext)
    const [selectedBilling, setSelectedBilling] = useState<any>(null)
    const [view, setView] = useState('')

    function handleViewDetails (data: any) {
        setSelectedBilling(data)
        setView('billing-history-details')
    }
    

    function handleComplete (type: string) {
        notifier.show(`${type === 'card' ? 'Card Payment' : 'Bank Transfer'} Successful`, null, 'success')
    }

    return (
        <>
            <ScreenContainer>
                <AppText 
                    textSize='1.4' cursor='pointer' 
                >
                    Billing
                </AppText>
                <CustomContainer topMargin='3'>
                    <FlexRow justifyContent='space-between' wrap='wrap' alignItems='flex-end'>
                        <AppText 
                            textSize='3' color='#101828' topMargin='2' 
                            fontWeight='600' rightMargin='4'
                        >
                            Billing
                        </AppText>
                        <CustomContainer 
                            width='auto' sizeUnit='' topMargin='2' 
                            className='billing-circle-container'
                        >
                            <AppText fontWeight='500' align='right'>Current Billing Cycle</AppText>
                            <AppText color='#101828' textSize='1.8' fontWeight='600'>1 March 2023 - 31 March 2023</AppText>
                        </CustomContainer>
                    </FlexRow>
                    <FlexRow justifyContent='space-between' alignItems='stretch' topMargin='2' wrap='wrap'>
                        <CustomContainer 
                            width='49.25' sizeUnit='%' 
                            className='billing-splitted-card'
                        >
                            <CustomContainer
                                minHeight='19.4' bgColor='#ffffff' padding='2'
                                radius='0.8' borderColor='#EAECF0'
                                shadow='0px 1px 2px 0px #1018280F'
                            >
                                <FlexRow justifyContent='space-between' alignItems='flex-start'>
                                    <FlexRow width='auto' sizeUnit='' alignItems='flex-start'>
                                        <WalletImg />
                                        <CustomContainer width='auto' sizeUnit='' leftMargin='2'>
                                            <AppSpan fontWeight='600'>Wallet Balance</AppSpan>
                                            <AppText fontWeight='600' textSize='3' color='#101828' topMargin='1'>&#8358;40,206.20</AppText>
                                            <Button
                                                width='14.3'
                                                height='4'
                                                radius='0.8'
                                                hoverBgColor='#ffffff'
                                                borderColor='#0D968F'
                                                hoverColor='#0D968F'
                                                fontWeight='600'
                                                topMargin='2'
                                                onClick={() => setView('payment-method')}
                                            >
                                                + Fund Wallet
                                            </Button>
                                        </CustomContainer>
                                    </FlexRow>
                                    <GridContainer width='6.8' height='2.4' bgColor='#ECFDF3' radius='1.6'>
                                        <FlexRow>
                                            <AppSpan textSize='1.2' className='fa fa-arrow-up' color='#12B76A'/>
                                            <AppSpan fontWeight='600' color='#027A48' leftMargin='0.4'>3.4%</AppSpan>
                                        </FlexRow>
                                    </GridContainer>
                                </FlexRow>
                            </CustomContainer>
                            <CustomContainer
                                minHeight='19.5' topMargin='1.5' padding='2'
                                bgColor='#ffffff' radius='0.8' borderColor='#EAECF0'
                                shadow='0px 1px 2px 0px #1018280F'
                            >
                                <FlexRow 
                                    justifyContent='flex-start' wrap='wrap' gap='2.5'
                                    className='billing-utilisation'
                                >
                                    <CustomContainer width='14.5'>
                                        <CircularProgressbarWithChildren 
                                            value={65} 
                                            strokeWidth={9.5}
                                            styles={{
                                                root: {},
                                                path: {
                                                    stroke: `#0E9384`,
                                                    strokeLinecap: 'round',
                                                    transition: 'stroke-dashoffset 0.5s ease 0s',
                                                    transform: 'rotate(1turn)',
                                                    transformOrigin: 'center center',
                                                },
                                                trail: {
                                                    stroke: '#E7F6F5',
                                                    strokeLinecap: 'butt',
                                                    transform: 'rotate(0.25turn)',
                                                    transformOrigin: 'center center',
                                                },
                                                text: {
                                                    fill: '#101828',
                                                    fontSize: '1.5rem',
                                                    fontWeight: 600,
                                                    textAlign: 'center'
                                                }
                                            }} 
                                        >
                                            <GridContainer width='auto' sizeUnit=''>
                                                <AppSpan textSize='1.1' fontWeight='600'>Utilisation</AppSpan>
                                                <AppText fontWeight='600' fontFamily='Inter' color='#101828' textSize='2'>&#8358;12,000</AppText>
                                            </GridContainer>
                                        </CircularProgressbarWithChildren>
                                    </CustomContainer>
                                    <FlexRow justifyContent='flex-start' width='auto' sizeUnit='' gap='4' wrap='wrap'>
                                        <CustomContainer width='auto' sizeUnit=''>
                                            <AppSpan fontWeight='600'>Overdraft</AppSpan>
                                            <AppText fontWeight='600' fontFamily='Inter' color='#101828' textSize='1.6'>&#8358;25,000</AppText>
                                        </CustomContainer>
                                        <CustomContainer width='auto' sizeUnit=''>
                                            <AppSpan fontWeight='600'>Percentage</AppSpan>
                                            <AppText fontWeight='600' fontFamily='Inter' color='#101828' textSize='1.6'>65%</AppText>
                                        </CustomContainer>
                                        <CustomContainer width='auto' sizeUnit=''>
                                            <AppSpan fontWeight='600'>Overdraft Utilisation</AppSpan>
                                            <AppText fontWeight='600' fontFamily='Inter' color='#101828' textSize='1.6'>&#8358;12,000</AppText>
                                        </CustomContainer>
                                    </FlexRow>
                                </FlexRow>
                            </CustomContainer>
                        </CustomContainer>
                        <CustomContainer
                            width='49.25' sizeUnit='%' bgColor='#ffffff'
                            radius='0.8' borderColor='#EAECF0' padding='2'
                            shadow='0px 1px 2px 0px #1018280F'
                            className='billing-splitted-card'
                        >
                            <AppSpan textSize='1.8' fontWeight='600' color='#101828'>Wallet Funding History</AppSpan>
                            <CustomContainer topMargin='2'>
                                {
                                    fundHistoryData?.slice(0, 4)?.map((item: any, index: number) => {
                                        return (
                                            <FlexRow 
                                                justifyContent='space-between' topPadding='1.2'
                                                bottomPadding='1.2' borderColor='#EAECF0' key={index}
                                                style={{borderLeft: 'none', borderRight: 'none'}}
                                            >
                                                <CustomContainer width='auto' sizeUnit=''>
                                                    <AppSpan color='#101828' fontWeight='600'>{item.transactionType}</AppSpan>
                                                    <AppText color='#101828'>{item.date}</AppText>
                                                </CustomContainer>
                                                <AppSpan color='#039855'>+ N{item.amount}</AppSpan>
                                            </FlexRow>
                                        )
                                    })
                                }
                            </CustomContainer>
                            <GridContainer topMargin='1.3' alignItems='end'>
                                <AppLink 
                                    color='#0A756F' fontWeight='600'
                                    hoverColor='#039855' decoration='none'
                                    onClick={() => setView('fund-history')}
                                >
                                    Show more
                                </AppLink>
                            </GridContainer>
                        </CustomContainer>
                    </FlexRow>
                    <CustomContainer topMargin='2' bottomMargin='3'>
                        <AppSpan color='#101828' textSize='1.8' fontFamily='Inter' fontWeight='500'>Billing History</AppSpan>
                        <Table
                            tableColumns={billingColumns(handleViewDetails)}
                            tableData={billingData}
                            customStyle
                            canSearch
                        />
                    </CustomContainer>
                </CustomContainer>
            </ScreenContainer>
            { view === 'fund-history' &&
                <FundHistory
                    data={fundHistoryData}
                    close={() => setView('')}
                />
            }
            { view === 'billing-history-details' &&
                <BillingHistoryDetails
                    data={selectedBilling}
                    close={() => setView('')}
                />
            }
            { view === 'payment-method' &&
                <PaymentMethod
                    next={(val: string) => setView(val)}
                    close={() => setView('')} 
                />
            }
            { view === 'card-payment' &&
                <CardPayment
                    back={() => setView('payment-method')}
                    next={() => setView('otp-validation')}
                    close={() => setView('')} 
                />
            }
            { view === 'otp-validation' &&
                <OtpValidation
                    back={() => setView('card-payment')}
                    next={() => handleComplete('card')}
                    close={() => setView('')} 
                />
            }
            { view === 'bank-transfer' &&
                <TransferPayment
                    back={() => setView('payment-method')}
                    next={() => setView('account-display')}
                    close={() => setView('')} 
                />
            }
            { view === 'account-display' &&
                <ReceiverAccountInfo
                    back={() => setView('bank-transfer')}
                    next={() => handleComplete('bank-transfer')}
                    close={() => setView('')} 
                />
            }
        </>
    )
}

export default utility.routeData('billing-profile', 'Billing Profile', <BillingProfile/>)