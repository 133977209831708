import { PopupWorldIcon } from "src/assets/icons"
import { AppSpan, AppText, Button, CustomContainer, FlexRow, SidePopup, SidePopupContainer } from "src/style"
import helpers from "src/utils/helpers"

export const BusinessPartnerDetails: React.FC<any> = ({data, handleEditAction, close}) => {

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    <PopupWorldIcon />  
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <CustomContainer>
                        <AppText color='#101828' textSize="1.8" fontWeight="700">Business Partner Details</AppText>
                        <CustomContainer topMargin="2.5">
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Partner Name</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.partnerName}</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">CBN Code</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.cbnCode}</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Address</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.address}</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Email</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.email}</AppSpan>
                            </FlexRow>
                        </CustomContainer>
                    </CustomContainer>
                    <CustomContainer topMargin="1">
                        <AppText color='#101828' textSize="1.8" fontWeight="700">Admin Details</AppText>
                        <CustomContainer topMargin="2.5">
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">First Name</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.adminFirstName}</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Last Name</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.adminLastName}</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Username</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.adminUsername}</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Email</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.adminEmail}</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Phone Number</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.adminPhoneNo}</AppSpan>
                            </FlexRow>
                        </CustomContainer>
                    </CustomContainer>
                    <FlexRow justifyContent="flex-end" gap="2">
                        <Button 
                            width='8.1' height='4' radius='0.8'
                            fontWeight='600' bgColor="transparent" borderColor='#D0D5DD'
                            hoverColor='#0D968F' color='#344054'
                            onClick={close}
                        >
                                Cancel
                            </Button>
                        <Button 
                            width='11' height='4' radius='0.8'
                            hoverBgColor='#ffffff' borderColor='#0D968F' hoverColor='#0D968F'
                            fontWeight='600'
                            onClick={handleEditAction}
                        >
                            Edit Details
                        </Button>
                    </FlexRow>
                </CustomContainer>
            </SidePopup>
        </>
    )
}
    