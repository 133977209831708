import { useState, useContext, useEffect } from "react"
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import UserContext from 'src/provider/state-manager/userProvider'
import ApiContext from 'src/provider/API/call-service'
import { FiChevronDown, FiChevronUp } from "react-icons/fi"
import { PopupWorldIcon } from "src/assets/icons"
import { AppSpan, AppText, Button, CustomContainer, Divider, FlexRow, FormGroup, SidePopup, SidePopupContainer } from "src/style"
import { CheckboxSelection } from "src/component"
import helpers from "src/utils/helpers"

export const Set_EditRule: React.FC<any> = ({data, close, fetchUpdatedView, isEdit}) => {
    const {API} = useContext(ApiContext)
    const {user: {userInfo}} = useContext(UserContext)
    const {notifier} = useContext(VisibilityContext)
    const [manageAccordion, setManageAccordion] = useState<any>({})
    const [levels, setLevels] = useState<any>([])
    const [functions, setFunctions] = useState<any>([])
    const [selectedFunctions, setSelectedFunctions] = useState<any>([])
 
    useEffect(() => {
        if (userInfo) handleFetchRoles()
    }, [userInfo])

    useEffect(() => {
        let customLevels = []
        let alreadySelectedFunction = []

        if (data?.userRoles) {
            console.log({roles: data?.userRoles})
            let index = 0
            for (let item of data?.userRoles) {
                const info = {
                    name: item.name,
                    id: item?.id,
                    levelId: index
                }

                customLevels.push({
                    groupName: `Level ${item.level}`,
                    info
                })

                alreadySelectedFunction.push(info)

                index++
            }
        }
        else {
            customLevels = [
                {groupName: 'Level 1', info: null},
                {groupName: 'Level 2', info: null},
                {groupName: 'Level 3', info: null}
            ]
        }

        setLevels(customLevels)
        setSelectedFunctions(alreadySelectedFunction)
    }, [])

    async function handleFetchRoles () {
        const response = await API.getRoles()
        if (response) {
            let extractedFunctionsInfo = []
            for (let item of response) {
                extractedFunctionsInfo.push({
                    name: item.name,
                    id: item.id
                })
            }
            setFunctions(extractedFunctionsInfo)
        }
    }

    function handleSelectedFunction (val: boolean, levelId: number, data: any) {
        data.levelId = levelId
        let allLevels = [...levels]

        if (val) {
            allLevels[levelId].info = data
            setSelectedFunctions([...selectedFunctions, data])
        }
        else {
            let outputFunctions = []
            for (let item of selectedFunctions) {
                if (item.levelId != levelId) outputFunctions.push(item)
            }
            setSelectedFunctions(outputFunctions)
            allLevels[levelId].info = null
        }

        setLevels(allLevels)
    }

    function handleFunctionsFilter (levelId: number) {
        const result = []
        for (let x of functions) {
            let isAccessible = true
            for (let y of selectedFunctions) {
                if (x.id === y.id && y.levelId != levelId) {
                    isAccessible = false;
                    break
                }
            }
            if (isAccessible) result.push(x)
        }

        return result
    }

    function handleAddNewLevel () {
        //const levelsWithFunction = levels.filter((item: any) => item.info != null)
        if (levels.length != selectedFunctions.length) {
            return notifier.show("Kindly select function for the levels you have already added.", 'Validation Response')
        }

        if (levels.length === functions.length) {
            return notifier.show("You cannot add a new level as there are no functions left to assign to the level.", 'Validation Response')
        }

        setLevels([
            ...levels,
            {groupName: `Level ${levels.length + 1}`, info: null}
        ])
    }

    async function handleSave () {
        let roleIDs = ""
        for (let item of levels) {
            if (item?.info?.id) roleIDs += `${item?.info?.id},`
        }

        if (!roleIDs.length) return notifier.show("Kindly select function for a level", 'Validation Response')
        
        if (roleIDs.endsWith(',')) roleIDs = roleIDs.substring(0, roleIDs.length - 1)

        const response = await API.updateApprovalConfig({roleIDs, id: data.id})
        if (response) {
            fetchUpdatedView()
            close()
        }

    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                     <PopupWorldIcon/> 
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <AppText color='#101828' textSize="1.8" fontWeight="700" bottomMargin="1.5">{isEdit ? 'Edit' : 'Set'} Configuration Rule</AppText>
                    <AppSpan textSize="1.6" fontWeight="300" color='#101828' bottomMargin="1">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore m
                    </AppSpan>
                    <CustomContainer topMargin="2">
                        <FormGroup>
                            <label>Rule</label>
                            <input 
                                placeholder="Service API Name" 
                                name='name'
                                value={data?.name}
                                disabled
                            />
                        </FormGroup>
                    </CustomContainer>
                    <CustomContainer>
                        <Divider margin='1.5' borderWidth='0.6' />
                        
                        {
                            levels?.map((data: any, index: number) => {
                                let levelFunctions = handleFunctionsFilter(index) || []
                                return (
                                    <CustomContainer key={index}>
                                        <FlexRow justifyContent='space-between' onClick={() => setManageAccordion({...manageAccordion, [data.groupName]: !manageAccordion[data.groupName]})}>
                                            <AppText color='#101828' textSize='1.6' fontWeight='400' cursor='pointer'>
                                                {data.groupName}
                                            </AppText>
                                            <AppSpan 
                                                color='#98A2B3' textSize="2.5" fontWeight="500" 
                                                leftMargin='2'
                                            >
                                                { manageAccordion[data.groupName] ? <FiChevronUp /> : <FiChevronDown />}
                                            </AppSpan> 
                                        </FlexRow>
                                        { manageAccordion[data.groupName] &&
                                            <CustomContainer topMargin='0.7'>
                                                <FlexRow justifyContent="space-between" wrap="wrap">
                                                    {
                                                        levelFunctions?.map((item: any, subIndex: number) => {
                                                            return (
                                                                <CheckboxSelection 
                                                                    key={subIndex}
                                                                    width='49' 
                                                                    name={item?.name}
                                                                    fontWeight='500'
                                                                    customClass='checkbox-selection-mobile'
                                                                    value={levels[index].info?.id === item?.id}
                                                                    disabled={levels[index].info?.id && levels[index].info?.id != item?.id}
                                                                    output={(val: any) => handleSelectedFunction(val, index, item)}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </FlexRow>
                                            </CustomContainer>
                                        }
                                        <Divider margin='1.5' borderWidth='0.6' />
                                    </CustomContainer>
                                )
                            })
                        }
                        <FlexRow justifyContent="flex-end" topMargin="3">
                            <Button 
                                width='13' height='4' radius='0.8'
                                fontWeight='600' bgColor="transparent" borderColor='#D0D5DD'
                                hoverColor='#0D968F' color='#344054' rightMargin="2"
                                onClick={handleAddNewLevel}
                            >
                                Add New Level
                            </Button>
                            <Button 
                                width='8'
                                height='4'
                                radius='0.8'
                                hoverBgColor='#ffffff'
                                borderColor='#0D968F'
                                hoverColor='#0D968F'
                                fontWeight='600'
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </FlexRow>
                    </CustomContainer>
                </CustomContainer>
            </SidePopup>
        </>
    )
}
    