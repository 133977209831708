import { useState, useContext, useEffect } from "react"
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import ApiContext from "src/provider/API/call-service"
import { FiChevronDown, FiChevronLeft } from "react-icons/fi"
import { PopupBankIcon } from "src/assets/icons"
import { AppSpan, AppText, Button, CustomContainer, FlexRow, Form, FormGroup, FormGroupWithIcon, SidePopup, SidePopupContainer } from "src/style"
import helpers from "src/utils/helpers"
import { appSettings } from "src/provider/config/constant"

export const AddBankPartner: React.FC<any> = ({fetchBankPartner, data, close}) => {
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const [input, setInput] = useState<any>({
        institutionName: '', institutionEmail: '', institutionPhoneNo: '',
        institutionAddress: '', adminUsername: '', adminFirstName: '', adminLastName: '',
        adminEmail: '', adminPhoneNo: ''
    })
    const [isFillAdmin, setIsFillAdmin] = useState(false)

    useEffect(() => {
        if (data) setInput({
            institutionName: data.name, institutionEmail: data.email,
            institutionAddress: data.address, institutionPhoneNo: data.phoneNo
        })
    }, [])

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>) => {
        if (e.target.name === 'institutionAddress' && e.target.value.length > appSettings.ADDRESS_CHARACTERS_LENGTH) return null
        if (e.target.name === 'institutionEmail' || e.target.name === 'adminEmail') {
            if (e.target.value.includes(' ')) e.target.value = e.target.value.replace(/ /g, '')
        }
        setInput({...input, [e.target.name]: e.target.value})
    }

    async function handleNext(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        if (!data) setIsFillAdmin(true)
        else {
            if (!helpers.validateEmail(input.institutionEmail)) return notifier.show('Invalid partner email provided')
            else if (!helpers.validatePhoneNo(input.institutionPhoneNo)) return notifier.show('Invalid institution phone number provided')
            const response = await API.updateBankPartner({...input, id: data.id})
            if (response && fetchBankPartner) fetchBankPartner()
            close() 
        }
    }

    async function handleSubmit(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        if (!helpers.validateEmail(input.institutionEmail)) return notifier.show('Invalid partner email provided')
        else if (!helpers.validateEmail(input.adminEmail)) return notifier.show('Invalid admin email provided')
        else if (!helpers.validatePhoneNo(input.adminPhoneNo)) return notifier.show('Invalid admin phone number provided')
        else if (!helpers.validatePhoneNo(input.institutionPhoneNo)) return notifier.show('Invalid institution phone number provided')
        
        const response = await API.createBankPartner(input)
        if (response && fetchBankPartner) fetchBankPartner() 
        close()
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    { !isFillAdmin ? 
                        <PopupBankIcon/>
                        : 
                        <AppSpan 
                            color='#000000' textSize="2.5" fontWeight="500" 
                            hoverColor="#0D968F" onClick={() => setIsFillAdmin(false)}
                        >
                            <FiChevronLeft />
                        </AppSpan> 
                    }
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                {   !isFillAdmin &&
                    <CustomContainer topMargin="3">
                        <AppText color='#101828' textSize="1.8" fontWeight="700">Add New Bank Partner</AppText>
                        <Form topMargin="2" onSubmit={handleNext}>
                            <FormGroup>
                                <label>Enter Institution Name</label>
                                <input 
                                    placeholder="Institution Name" 
                                    name='institutionName'
                                    value={input.institutionName}
                                    onChange={handleInput}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Enter Institution Address</label>
                                <textarea 
                                    placeholder="Institution Address"
                                    name='institutionAddress'
                                    value={input.institutionAddress}
                                    onChange={handleInput}
                                    required
                                />
                                <AppSpan textSize="1.2" color='#B42318'>
                                    Remaining {appSettings.ADDRESS_CHARACTERS_LENGTH - input.institutionAddress?.length} characters of {appSettings.ADDRESS_CHARACTERS_LENGTH}
                                </AppSpan>
                            </FormGroup>
                            <FormGroup>
                                <label>Enter Phone Number</label>
                                <input 
                                    type='number' 
                                    placeholder="Phone Number" 
                                    name='institutionPhoneNo'
                                    value={input.institutionPhoneNo}
                                    onChange={handleInput}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Enter Email Address</label>
                                <input 
                                    type='email' 
                                    placeholder="Email Address" 
                                    name='institutionEmail'
                                    value={input.institutionEmail}
                                    onChange={handleInput}
                                    required
                                />
                            </FormGroup>
                            <FlexRow justifyContent="flex-end">
                                <Button 
                                    width='8'
                                    height='4'
                                    radius='0.8'
                                    hoverBgColor='#ffffff'
                                    borderColor='#0D968F'
                                    hoverColor='#0D968F'
                                    fontWeight='600'
                                    topMargin="1"
                                >
                                    {!data ? 'Next': 'Update'}
                                </Button>
                            </FlexRow>
                        </Form>
                    </CustomContainer>
                }
                { isFillAdmin &&
                    <CustomContainer topMargin="3" data-aos="zoom-in">
                        <AppText color='#101828' textSize="1.8" fontWeight="700">Add Admin Details</AppText>
                        <Form topMargin="2" onSubmit={handleSubmit}>
                            <FormGroup>
                                <label>Enter First Name</label>
                                <input 
                                    placeholder="First Name" 
                                    name='adminFirstName'
                                    value={input.adminFirstName}
                                    onChange={handleInput}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Enter Last Name</label>
                                <input 
                                    placeholder="Last Name" 
                                    name='adminLastName'
                                    value={input.adminLastName}
                                    onChange={handleInput}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Enter Username</label>
                                <input 
                                    placeholder="Username" 
                                    name='adminUsername'
                                    value={input.adminUsername}
                                    onChange={handleInput}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Enter Email Address</label>
                                <input 
                                    type='email' 
                                    placeholder="Email Address" 
                                    name='adminEmail'
                                    value={input.adminEmail}
                                    onChange={handleInput}
                                    required
                                />
                            </FormGroup>
                            {/* <FormGroupWithIcon bottomMargin='1.8'>
                                <label>Enter Gender</label>
                                <div className="input-with-icon">
                                    <select 
                                        name="adminGender" 
                                        value={input.adminGender}
                                        onChange={handleInput}
                                        style={{color: input.adminGender ? '#667085' : '#999'}}
                                        required
                                    >
                                        <option value="">Gender</option>
                                        <option value='Male'>Male</option>
                                        <option value='Female'>Female</option>
                                    </select>
                                    <FiChevronDown className="input-with-icon-tag" />
                                </div>
                            </FormGroupWithIcon> */}
                            <FormGroup>
                                <label>Enter Phone Number</label>
                                <input 
                                    type='number' 
                                    placeholder="Phone Number" 
                                    name='adminPhoneNo'
                                    value={input.adminPhoneNo}
                                    onChange={handleInput}
                                    required
                                />
                            </FormGroup>
                            <FlexRow justifyContent="flex-end">
                                <Button 
                                    width='8'
                                    height='4'
                                    radius='0.8'
                                    hoverBgColor='#ffffff'
                                    borderColor='#0D968F'
                                    hoverColor='#0D968F'
                                    fontWeight='600'
                                    topMargin="1"
                                >
                                    Save
                                </Button>
                            </FlexRow>
                        </Form>
                    </CustomContainer>
                }
            </SidePopup>
        </>
    )
}
    