import './style.scss'
import { useContext, useEffect } from "react"
import { AbsoluteContainer, AppText, Button, FlexColumn, FlexRow, SidePopupContainer } from "src/style"
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import { DecisionRemoveIcon } from 'src/assets/icons'
import { RiCloseFill } from 'react-icons/ri'

export const DecisionBox: React.FC = () => {
    const {visibility: {decision}, decisionBox} = useContext(VisibilityContext)

    useEffect(() => {
        if (decision.status) document.body.style.overflow = 'hidden'
        else document.body.style.overflow = 'auto'
    }, [decision.status])

    function executeMethod (type: string) {
        if (type === 'Yes' && decision.yesMethod) decision?.yesMethod()
        else if (type === 'No' && decision.noMethod) decision?.noMethod()
        closeDecisionBox()
    }

    const closeDecisionBox = () => decisionBox.hide()

    return (
        <>
            { decision.status &&
                <>
                    <SidePopupContainer zIndex="9" onClick={closeDecisionBox} />
                    <FlexColumn
                        className="decision-box-container"
                        justifyContent="flex-start" 
                    >
                        <AbsoluteContainer top='1' right='1.5'>
                            <Button 
                                titleSize="2" fontWeight="500"
                                width='3.4' shadow='none'
                                height='3.4'
                                radius='0.4'
                                bgColor='#ffffff'
                                hoverBgColor='#FEF3F2'
                                borderColor='none'
                                color='#000000'
                                hoverColor='#B42318'
                                onClick={closeDecisionBox}
                            >
                                <RiCloseFill />
                            </Button> 
                        </AbsoluteContainer>
                        <DecisionRemoveIcon/>
                        <AppText fontWeight='400' textSize='1.6' color='#101828' topMargin='1.8'>
                            {decision.message}
                        </AppText>
                        <FlexRow justifyContent='space-between' topMargin='3' gap='1' wrap='wrap' className='decision-box-btn-wrapper'>
                            <Button
                                width='17'
                                height='4.4'
                                radius='0.8'
                                bgColor='#ffffff'
                                hoverBgColor='#ffffff'
                                borderColor='#D0D5DD'
                                color='#344054'
                                hoverColor='#0D968F'
                                topMargin='1'
                                fontWeight='500'
                                onClick={() => executeMethod('No')}
                            >
                                {decision.cancelBtnText}
                            </Button>
                            <Button
                                width='17'
                                height='4.4'
                                radius='0.8'
                                hoverBgColor='#ffffff'
                                borderColor='#0D968F'
                                hoverColor='#0D968F'
                                fontWeight='500'
                                topMargin='1'
                                className='add-business-btn'
                                onClick={() => executeMethod('Yes')}
                            >
                                {decision.yesBtnText}
                            </Button>
                        </FlexRow>  
                    </FlexColumn>
                </>
            }
        </>
    )
}