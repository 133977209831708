import { ReactComponent as BillingIcon } from "src/assets/svg/billing-icon.svg"
import {ReactComponent as CardIcon } from "src/assets/svg/debit-card-icon.svg"
import {ReactComponent as TransferIcon } from "src/assets/svg/bank-transfer-icon.svg"
import { SidePopupContainer, SidePopup, FlexRow, AppSpan, CustomContainer, AppText, GridContainer } from "src/style"

export const PaymentMethod: React.FC<any> = ({next, close}) => {

    function handleType(type: string) {
        next(type)
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    <BillingIcon />  
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <AppText color='#101828' textSize="1.8" fontWeight="700">Choose your payment method</AppText>
                    <CustomContainer topMargin="2.5">
                        <GridContainer 
                            height="6.7" borderColor="#EAECF0" radius="0.8"
                            leftPadding="1.5" rightPadding="1.5"
                            className="payment-method-type"
                            onClick={() => handleType('card-payment')}
                        >
                            <FlexRow justifyContent="flex-start">
                                <CardIcon />
                                <AppSpan fontWeight="700" color="#344054" cursor="pointer" leftMargin="1.5">Card Payment</AppSpan>
                            </FlexRow>
                        </GridContainer>
                        <GridContainer 
                            height="6.7" borderColor="#EAECF0" radius="0.8"
                            leftPadding="1.5" rightPadding="1.5" topMargin="2"
                            className="payment-method-type"
                            onClick={() => handleType('bank-transfer')}
                        >
                            <FlexRow justifyContent="flex-start">
                                <TransferIcon />
                                <AppSpan fontWeight="700" color="#344054" cursor="pointer" leftMargin="1.5">Bank Transfer</AppSpan>
                            </FlexRow>
                        </GridContainer>
                    </CustomContainer>
                </CustomContainer>
            </SidePopup>
        </>
    )
}