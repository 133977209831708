import './style.scss'
import React, { useState, useContext } from 'react'
//import { useLocation } from 'react-router-dom'
import { IconContext } from 'react-icons/lib'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import UserContext from 'src/provider/state-manager/userProvider'
import { 
    SideBarLink, 
    SideBarLinkWrapper, 
    SideBarLabel, 
    ArrowIconWrapper, 
    SubNavWrapper 
} from './style'
import { NavLink } from 'react-router-dom'
import { MenuWrapper } from './menuWrapper'

export const SideBarMenu: React.FC<any> = ({item}) => {
    //const location = useLocation()
    const {visibility, showSideBar, updateSelectedMenu} = useContext(VisibilityContext) 
    const {user: {userInfo}} = useContext(UserContext)
    const {clearAppData, } = useContext(AppInfoContext)
    const [subNav, setSubNav] = useState(false)
    const userType = userInfo?.user?.institution?.institutionCategory.institutionCategoryType
    
    const showSubNav = () => setSubNav(!subNav)

    const handleSideBarShow = async (val: string) => {
    //   if (val === '/') {
    //     await clearAppData()
    //     //return localStorage.clear()
    //   }
    //   if(val) await updateSelectedMenu(val)
      if(window.matchMedia('(max-width: 770px)').matches) {
       await showSideBar(false)
      }

    }

  return (
    <>
    <IconContext.Provider value={{}}>
        {/* SideBarLink */}
        <MenuWrapper 
            data={item}
            clickHandle={item.subNav ? showSubNav : () => handleSideBarShow(item.path)}
        >
            <SideBarLinkWrapper className="nav-option" >
                {item.icon}
                <SideBarLabel>{item.title}</SideBarLabel>
            </SideBarLinkWrapper>
            <ArrowIconWrapper>
                {item.subNav && subNav 
                ? item.iconOpened 
                : item.subNav 
                ? item.iconClosed 
                : null}
            </ArrowIconWrapper>
        </MenuWrapper>
        {
            subNav && item.subNav.map((item: any, index: number) => {
                if ((item.accessBy === 'ApiConsumer' && userType != 'ServiceProvider') || 
                    userType === item.accessBy || item.accessBy === 'all'
                ) {
                    return (
                        <NavLink 
                            //isclicked={visibility.selectedMenu === item.path ? 'true' : ''} 
                            key={index} 
                            to={item.path} 
                            className={({ isActive }) => `default-menu ${isActive && 'active-menu'}`}
                            onClick={() => handleSideBarShow(item.path)}
                        >
                            <SubNavWrapper  isclicked={visibility.selectedMenu === item.path ? 'true' : ''} >
                                <SideBarLabel>{item.title}</SideBarLabel>
                            </SubNavWrapper>
                        </NavLink>
                    )
                }
            })
        }
        </IconContext.Provider>
    </>
  )
}