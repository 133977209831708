import React, { useContext } from 'react'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import UserContext from 'src/provider/state-manager/userProvider'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import authService from 'src/provider/API/interceptor/auth'
import switchService from 'src/provider/API/interceptor/switch'
import auditService from 'src/provider/API/interceptor/audit'
import approvalService from 'src/provider/API/interceptor/approval'
import analyticsService from 'src/provider/API/interceptor/analytics'
import utility, { catchErrMsg, erroMessage, loglite, requestMessage } from 'src/utils/utility'
import helpers from 'src/utils/helpers'


const ApiContext = React.createContext<any>(null)

export const ApiProvider = (props: any) => {
    const {loader, notifier} = useContext(VisibilityContext)
    const {user, setUserProperty} = useContext(UserContext)
    const {info, setInfoProperty} = useContext(AppInfoContext)
   

    async function login ({email, password}: any) {
        try {
            loader(true)
            const {data, status} = await authService.get(`Membership/ValidateUser`, {
                params: { email, password }
            })
            loader(false)
            console.log({data})
            if (!data.isSuccessful) {
                notifier.show(data?.message || 'Invalid username or password')
                return false
            }
            else {
                helpers.setSessionExpiryTime()
                await setUserProperty('userInfo', {...data})
                await setInfoProperty('authKey', data.authKey)
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getCodeForPasswordReset (email = user.userPreAuthInfo?.email) {
        if (email) setUserProperty('userPreAuthInfo', {...user.userPreAuthInfo, email})
        
        try {
            loader(true)
            const {data, status} = await authService.post(`Membership/GenerateCodeForResetPassword`, null, {
                params: {email}
            })
            loader(false)

            console.log({data})
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data, 'Verification code could not be sent'))
                return false
            }
            else return true
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function resetPassword (resetPasswordCode: string, newPassword: string) {
        try {
            loader(true)
            const {data, status} = await authService.post(`Membership/CompleteActionForGenerateCodeForResetPassword`, null, {
                params: {
                    email: user.userPreAuthInfo?.email,
                    resetPasswordCode,
                    newPassword
                }
            })
            loader(false)

            console.log({data})
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data, 'Password reset failed'))
                return false
            }
            else {
                notifier.show(data.message || 'Password Reset Successful', '', 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getBusinesses () {
        try {
            loader(true)
            const {data, status} = await authService.get(`Business/GetBusinesses`)
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getBusinesseByInstitutionCode () {
        try {
            loader(true)
            const {data, status} = await authService.get(`Business/GetBusinessByInstitutionCode`, {
                params: {institutionCode: user.userInfo?.user?.institution?.code}
            })
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                await setInfoProperty('selectedBusiness', data)
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function createNewBusiness (payload: any) {
        try {
            loader(true)
            const {data, status} = await authService.post(`Business/CreateBusiness`, payload)
            loader(false)

            console.log({data})
            if (!data.isSuccessful) {
                notifier.show(data.message || 'New Business could not be created')
                return false
            }
            else {
                notifier.show(data.message || 'New Business successfully created', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function editBusiness (payload: any) {
        try {
            loader(true)
            const {data, status} = await authService.post(`Business/UpdateBusiness`, payload)
            loader(false)

            console.log({data})
            if (!data.isSuccessful) {
                notifier.show(data?.message || 'Business could not be updated')
                return false
            }
            else {
                notifier.show(data?.message || 'Your business detail have been updated successfully', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }


    async function getBusinessById (id: number) {
        try {
            loader(true)
            const {data, status} = await authService.get(`Business/GetBusinessById`, {
                params: {id}
            })
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                await setInfoProperty('selectedBusiness', data)
                return data
            }
        }
        catch (err) {
            loader(false)
            //notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getBusinessAdmin (id: number) {
        try {
            loader(true)
            const {data, status} = await authService.get(`Business/GetBusinessAdmin`, {
                params: {id}
            })
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else return data
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getBusinessProducts (institutionCode: number) {
        try {
            loader(true)
            const {data, status} = await switchService.get(`Products/GetProductsByInstitutionCode`, {
                params: {institutionCode}
            })
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else return data
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function activateBusiness (businessId: number) {
        try {
            loader(true)
            const {data, status} = await authService.post(`Business/ActivateBusiness`, {
                businessId, 
                initiator: user.userInfo?.user?.email
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message)
                return false
            }
            else {
                notifier.show(data.message || 'Business successfully activated', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function deactivateBusiness (businessId: number) {
        try {
            loader(true)
            const {data, status} = await authService.post(`Business/DeactivateBusiness`, {
                businessId, 
                initiator: user.userInfo?.user?.email
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message)
                return false
            }
            else {
                notifier.show(data.message || 'Business successfully deactivated', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getAllServices () {
        try {
            loader(true)
            const {data, status} = await switchService.get(`Services/GetAllServices`)
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getServiceById (id: number) {
        try {
            loader(true)
            const {data, status} = await switchService.get(`Services/GetServiceById`, {
                params: {id}
            })
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                await setInfoProperty('selectedService', data)
                return data
            }
        }
        catch (err) {
            loader(false)
            //notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getProcessingRoutes () {
        try {
            loader(true)
            const {data, status} = await switchService.get(`Services/GetProcessingRoutes`)
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getBanks () {
        try {
            loader(true)
            const {data, status} = await switchService.get(`Banks/GetAllBanks`)
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getServiceAPIs () {
        try {
            loader(true)
            const {data, status} = await switchService.get(`ServiceAPIs/GetAllServiceAPIs`)
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function createNewService (payload: any) {
        try {
            loader(true)
            const {data, status} = await switchService.post(`Services/CreateService`, {
                ...payload, 
                initiator: user.userInfo?.user?.email
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message || 'New Service could not be created')
                return false
            }
            else {
                notifier.show(data.message || 'Your update has been saved and your Service is live', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }


    async function createNewProduct (payload: any) {
        try {
            loader(true)
            const {data, status} = await switchService.post(`Products/CreateProduct`, {
                ...payload, 
                initiator: user.userInfo?.user?.email
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message || 'New Product could not be created')
                return false
            }
            else {
                notifier.show(data.message || 'Product has been successfully created', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getProductById (id: number) {
        try {
            loader(true)
            const {data, status} = await switchService.get(`Products/GetProductById`, {
                params: {id}
            })
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                await setInfoProperty('selectedProduct', data)
                return data
            }
        }
        catch (err) {
            loader(false)
            //notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getProducts (pageNumber: number, pageSize: number) {
        try {
            loader(true)
            const {data, status} = await switchService.get(`Products/SearchProducts`, {
                params: {pageNumber, pageSize}
            })
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function activateProduct (productId: number) {
        try {
            loader(true)
            const {data, status} = await switchService.post(`Products/ActivateProduct`, {
                productId, 
                initiator: user.userInfo?.user?.email
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message)
                return false
            }
            else {
                notifier.show(data.message || 'Product successfully activated', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function deactivateProduct (productId: number) {
        try {
            loader(true)
            const {data, status} = await switchService.post(`Products/DeactivateProduct`, {
                productId, 
                initiator: user.userInfo?.user?.email
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message)
                return false
            }
            else {
                notifier.show(data.message || 'Product successfully deactivated', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function editProduct (payload: any) {
        try {
            loader(true)
            const {data, status} = await switchService.post(`Products/UpdateProduct`, {
                ...payload,
                initiator: user.userInfo?.user?.email
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data?.message || 'Product could not be updated')
                return false
            }
            else {
                notifier.show(data?.message || 'Your product detail have been updated successfully', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function addProductService (payload: any) {
        try {
            loader(true)
            const {data, status} = await switchService.post(`Products/AddServiceToProduct`, {
                ...payload,
                initiator: user.userInfo?.user?.email
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data?.message || 'Service could not be added to product succesfully')
                return false
            }
            else {
                notifier.show(data?.message || 'Service has been successfully added to product', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function activateProductService (productId: number, productServiceId: number) {
        try {
            loader(true)
            const {data, status} = await switchService.post(`Products/ActivateProductService`, {
                productId, 
                productServiceId,
                initiator: user.userInfo?.user?.email
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message)
                return false
            }
            else {
                notifier.show(data.message || 'Product service successfully activated', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function deactivateProductService (productId: number, productServiceId: number) {
        try {
            loader(true)
            const {data, status} = await switchService.post(`Products/DeactivateProductService`, {
                productId, 
                productServiceId,
                initiator: user.userInfo?.user?.email
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message)
                return false
            }
            else {
                notifier.show(data.message || 'Product service successfully deactivated', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function addProductServiceBank (payload: any) {
        try {
            loader(true)
            const {data, status} = await switchService.post(`Products/AddBanksToProductService`, {
                ...payload,
                initiator: user.userInfo?.user?.email
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data?.message || 'Bank could not be added to product service successfully.')
                return false
            }
            else {
                notifier.show(data?.message || 'Your update has been saved and your Bank is live.', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    
    async function activateProductServiceBank (productId: number, productServiceId: number, productServiceBankId: number) {
        try {
            loader(true)
            const {data, status} = await switchService.post(`Products/ActivateProductServiceBank`, {
                productId, 
                productServiceId,
                productServiceBankId,
                initiator: user.userInfo?.user?.email
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message)
                return false
            }
            else {
                notifier.show(data.message || 'Product service bank successfully activated', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function deactivateProductServiceBank (productId: number, productServiceId: number, productServiceBankId: number) {
        try {
            loader(true)
            const {data, status} = await switchService.post(`Products/DeactivateProductServiceBank`, {
                productId, 
                productServiceId,
                productServiceBankId,
                initiator: user.userInfo?.user?.email
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message)
                return false
            }
            else {
                notifier.show(data.message || 'Product service bank successfully deactivated', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getUsers (pageNumber: number, pageSize: number, institutionCode? : number|string) {

        try {
            loader(true)
            const {data, status} = await authService.get(`Users/SearchUsers`, {
                params: {
                    pageNumber,
                    pageSize,
                    institutionCode: institutionCode || user.userInfo?.user?.institution?.code
                }
            })
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getUserByEmail (email: string) {

        try {
            loader(true)
            const {data, status} = await authService.get(`Users/GetUserByEmail`, {
                params: { email }
            })
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getRoles () {

        try {
            loader(true)
            const {data, status} = await authService.get(`UserRoles/GetRoles`, {
                params: {institutionCode: user.userInfo?.user?.institution?.code}
            })
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function createNewUser (payload: any) {
        try {
            loader(true)
            const {data, status} = await authService.post(`Users/CreateUser`, {
                ...payload, 
                initiator: user.userInfo?.user?.email,
                institutionCode: user.userInfo?.user?.institution?.code
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message || 'New User could not be created')
                return false
            }
            else {
                notifier.show(data.message || 'Your update has been saved and the user is now active.', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getTasks () {

        try {
            loader(true)
            const {data, status} = await authService.get(`UserRoles/GetAllTasks`, {
                params: {institutionCode: user.userInfo?.user?.institution?.code}
            })
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function createNewRole (payload: any) {
        try {
            loader(true)
            const {data, status} = await authService.post(`UserRoles/CreateRole`, {
                ...payload, 
                initiator: user.userInfo?.user?.email,
                institutionCode: user.userInfo?.user?.institution?.code
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message || 'New Role could not be created')
                return false
            }
            else {
                notifier.show(data.message || 'New role successfully added.', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function updateRole (payload: any) {
        try {
            loader(true)
            const {data, status} = await authService.put(`UserRoles/UpdateRole`, {
                ...payload, 
                initiator: user.userInfo?.user?.email,
                institutionCode: user.userInfo?.user?.institution?.code
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message || 'Role could not be updated')
                return false
            }
            else {
                notifier.show(data.message || 'Role successfully updated.', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getApiKeys (institutionCode: string, shouldLoad?: boolean) {

        try {
            if (shouldLoad) loader(true)
            const {data, status} = await switchService.get(`BusinessKeys/GetKeys`, {
                params: {institutionCode}
            })
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function generateTestKey (institutionCode: string) {
        try {
            //loader(true)
            const {data} = await switchService.post(`BusinessKeys/GenerateTestKey`, {}, {
                params: {institutionCode}
            })
            //loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message || 'Test key could not be generated')
                return false
            }
            else {
                return true
            }
        }
        catch (err) {
            //loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function generateLiveKey (institutionCode: string) {
        try {
            //loader(true)
            const {data} = await switchService.post(`BusinessKeys/GenerateLiveKey`, {}, {
                params: {institutionCode}
            })
            //loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message || 'Live key could not be generated')
                return false
            }
            else {
                return true
            }
        }
        catch (err) {
            //loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getWebhookUrls (institutionCode: string, shouldLoad?: boolean) {

        try {
            if (shouldLoad) loader(true)
            const {data, status} = await switchService.get(`Webhooks/GetWebhookUrls`, {
                params: {institutionCode}
            })
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function updateTestWebhook (institutionCode: string, webhookUrl: string) {
        try {
            //loader(true)
            const {data} = await switchService.post(`Webhooks/UpdateTestWebhook`, {
                institutionCode,
                webhookUrl
            })
            //loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message || 'Test webhook could not be updated')
                return false
            }
            else {
                notifier.show(data.message || 'Test webhook url successfully updated.', null, 'success')
                return true
            }
        }
        catch (err) {
            //loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function updateLiveWebhook (institutionCode: string, webhookUrl: string) {
        try {
            //loader(true)
            const {data} = await switchService.post(`Webhooks/UpdateLiveWebhook`, {
                institutionCode,
                webhookUrl
            })
            //loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message || 'Live webhook could not be updated')
                return false
            }
            else {
                notifier.show(data.message || 'Live webhook url successfully updated.', null, 'success')
                return true
            }
        }
        catch (err) {
            //loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getAuditLogs (pageNumber: number, pageSize: number, institutionCode? : number|string) {

        try {
            loader(true)
            const {data, status} = await auditService.get(`AuditLogs/SearchAuditLogs`, {
                params: {
                    pageNumber,
                    pageSize,
                    institutionCode: institutionCode || user.userInfo?.user?.institution?.code
                }
            })
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getAuditItemById (ID: number) {

        try {
            loader(true)
            const {data, status} = await auditService.get(`AuditLogs/GetAuditItemById`, {
                params: { ID }
            })
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getAppBanks () {
        try {
            loader(true)
            const {data, status} = await switchService.get(`Banks/GetAllBanks`)
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getBankByCode (code: string) {
        try {
            loader(true)
            const {data, status} = await switchService.get(`banks/GetByCode`, {
                params: {code}
            })
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                await setInfoProperty('selectedBank', data)
                return data
            }
        }
        catch (err) {
            loader(false)
            return null
        }
    }

    async function activateBank (code: string) {
        try {
            loader(true)
            const {data, status} = await switchService.post(`Banks/ActivateBank`, {
                code, 
                initiator: user.userInfo?.user?.email
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message)
                return false
            }
            else {
                notifier.show(data.message || 'Bank successfully activated', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function deactivateBank (code: string) {
        try {
            loader(true)
            const {data, status} = await switchService.post(`Banks/DeactivateBank`, {
                code, 
                initiator: user.userInfo?.user?.email
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message)
                return false
            }
            else {
                notifier.show(data.message || 'Bank successfully deactivated', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function createNewBank (payload: any) {
        try {
            loader(true)
            const {data, status} = await switchService.post(`Banks/CreateBank`, {
                ...payload,
                initiator: user.userInfo?.user?.email
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message || 'New Bank could not be created')
                return false
            }
            else {
                notifier.show(data.message || 'New Bank successfully created', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function updateBank (payload: any) {
        try {
            loader(true)
            const {data, status} = await switchService.post(`Banks/UpdateBank`, {
                ...payload,
                initiator: user.userInfo?.user?.email
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message || 'New Bank could not be created')
                return false
            }
            else {
                notifier.show(data.message || 'New Bank successfully created', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getAllServiceAPIs () {
        try {
            loader(true)
            const {data, status} = await switchService.get(`ServiceAPIs/GetAllServiceApis`)
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getAllServiceAPITypes () {
        try {
            loader(true)
            const {data, status} = await switchService.get(`ServiceAPIs/GetServiceApiTypes`)
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function createServiceAPI (payload: any) {
        try {
            loader(true)
            const {data, status} = await switchService.post(`ServiceAPIs/CreateServiceApi`, {
                ...payload,
                initiator: user.userInfo?.user?.email
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message || 'New Service API could not be created')
                return false
            }
            else {
                notifier.show(data.message || 'New Service API successfully created', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getBanksByProcessingRoutes (processingRoute: string, shouldLoad = false) {
        try {
            if (shouldLoad) loader(true)
            const {data, status} = await switchService.get(`Services/GetBanksByProcessingRoutes`, {
                params: {processingRoute}
            })
            if (shouldLoad) loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            if (shouldLoad) loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getApprovalConfigs (institutionCode: string) {
        try {
            loader(true)
            const {data, status} = await approvalService.get(`ApprovalConfigs/GetApprovalConfigs`, {
                params: {institutionCode}
            })
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function activateApprovalConfig (ID: number) {
        try {
            loader(true)
            const {data, status} = await approvalService.post(`ApprovalConfigs/ActivateApprovalConfig`, {}, {
                params: {ID}
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message)
                return false
            }
            else {
                notifier.show(data.message || 'Approval Configuration successfully activated', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function deactivateApprovalConfig (ID: number) {
        try {
            loader(true)
            const {data, status} = await approvalService.post(`ApprovalConfigs/DeactivateApprovalConfig`, {}, {
                params: {ID}
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message)
                return false
            }
            else {
                notifier.show(data.message || 'Approval Configuration successfully deactivated', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function updateApprovalConfig (payload: any) {
        try {
            loader(true)
            const {data, status} = await approvalService.post(`ApprovalConfigs/UpdateApprovalConfig`, payload)
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message)
                return false
            }
            else {
                notifier.show(data.message || 'Approval Configuration successfully updated', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getApprovalRequests (pageNumber: number, pageSize: number, institutionCode: string) {
        try {
            loader(true)
            const {data, status} = await approvalService.get(`Approvals/SearchMyApprovalRequests`, {
                params: {pageNumber, pageSize, institutionCode}
            })
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getApprovalTimelines (pageNumber: number, pageSize: number, institutionCode: string) {
        try {
            loader(true)
            const {data, status} = await approvalService.get(`Approvals/SearchUserApprovalTimeline`, {
                params: {pageNumber, pageSize, institutionCode}
            })
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getApprovalPermission (approvalID: number) {
        try {
            loader(true)
            const {data, status} = await approvalService.get(`Approvals/GetApprovalPermission`, {
                params: {
                    email: user.userInfo?.user?.email,
                    institutionCode: user.userInfo?.user?.institution?.code,
                    approvalID
                }
            })
            loader(false)

            console.log({data})
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function acceptApprovalRequest (approvalID: number, comment: string) {
        try {
            loader(true)
            const {data, status} = await approvalService.post(`Approvals/UpdateApproval`, {
                approvalID,
                comment,
                initiator: user.userInfo?.user?.email
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message)
                return false
            }
            else {
                notifier.show(data.message || 'Approval request successfully approved', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function declineApprovalRequest (approvalID: number, comment: string) {
        try {
            loader(true)
            const {data, status} = await approvalService.post(`Approvals/DeclineApproval`, {
                approvalID,
                comment,
                initiator: user.userInfo?.user?.email
            })
            loader(false)

            console.log({data})
            if (data.status?.toLowerCase() != 'success') {
                notifier.show(data.message)
                return false
            }
            else {
                notifier.show(data.message || 'Approval request successfully declined', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getServiceApiTransactions () {
        try {
            loader(true)
            const {data, status} = await analyticsService.get(`ServiceApis/GetServiceApiStatsToday`, {
                params: {
                    email: user.userInfo?.user?.email,
                    //day: utility.getCurrentDate()
                }
            })
            loader(false)

            loglite(data, 'getServiceApiTransactions API')
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data.data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getBankPartnersTransactions () {
        try {
            loader(true)
            const {data, status} = await analyticsService.get(`BankPartners/GetBankPartnerStatsToday`, {
                params: {
                    email: user.userInfo?.user?.email,
                    //day: utility.getCurrentDate()
                }
            })
            loader(false)

            loglite(data, 'getBankPartnersTransactions API')
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data.data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getBusinessTransactions () {
        try {
            loader(true)
            const {data, status} = await analyticsService.get(`Businesses/GetBusinessStatsToday`, {
                params: {
                    email: user.userInfo?.user?.email,
                    //day: utility.getCurrentDate()
                }
            })
            loader(false)

            loglite(data, 'getBusinessTransactions API')
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data.data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getServiceTransactions () {
        try {
            loader(true)
            const {data, status} = await analyticsService.get(`Services/GetServiceStatsToday`, {
                params: {
                    email: user.userInfo?.user?.email,
                    //day: utility.getCurrentDate()
                }
            })
            loader(false)

            loglite(data, 'getServiceTransactions API')
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data.data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getBankPartners () {
        try {
            loader(true)
            const {data, status} = await authService.get(`BankPartners/GetBankPartners`)
            loader(false)

            loglite(data, 'getBankPartners API')
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getBankPartnerById (id: number) {
        try {
            loader(true)
            const {data, status} = await authService.get(`BankPartners/GetBankPartnerById`, {
                params: {id}
            })
            loader(false)

            loglite(data, 'getBankPartnerById API')
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function createBankPartner (payload: any) {
        try {
            loader(true)
            const {data, status} = await authService.post(`BankPartners/CreateBankPartner`, {
                ...payload,
                //initiator: user.userInfo?.user?.email
            })
            loader(false)

            loglite(data, 'createBankPartner API')
            if (status != 200 && status != 201) {
                notifier.show(data.message || 'New Bank Partner could not be created')
                return false
            }
            else {
                notifier.show(data.message || 'New Bank Partner successfully created', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function updateBankPartner (payload: any) {
        try {
            loader(true)
            const {data, status} = await authService.post(`BankPartners/UpdateBankPartner`, {
                ...payload,
                //initiator: user.userInfo?.user?.email
            })
            loader(false)

            loglite(data, 'updateBankPartner API')
            if (status != 200 && status != 201) {
                notifier.show(data.message || 'Bank Partner could not be updated')
                return false
            }
            else {
                notifier.show(data.message || 'Bank Partner successfully updated', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getBankPartnerAdminById (id: number) {
        try {
            loader(true)
            const {data, status} = await authService.get(`BankPartners/GetBankPartnerAdmin`, {
                params: {id}
            })
            loader(false)

            loglite(data, 'getBankPartnerAdminById API')
            if (status != 200 && status != 201) {
                notifier.show(data.message)
                return false
            }
            else {
                return data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    const callActions = {
        login,
        getCodeForPasswordReset,
        resetPassword,
        getBusinesses,
        getBusinesseByInstitutionCode,
        getBusinessById,
        getBusinessAdmin,
        createNewBusiness,
        getBusinessProducts,
        editBusiness,
        getAllServices,
        getServiceById,
        getProcessingRoutes,
        getBanks,
        getServiceAPIs,
        createNewService,
        createNewProduct,
        getProductById,
        getProducts,
        activateBusiness,
        deactivateBusiness,
        activateProduct,
        deactivateProduct,
        editProduct,
        addProductService,
        activateProductService,
        deactivateProductService,
        activateProductServiceBank,
        deactivateProductServiceBank,
        addProductServiceBank,
        getUsers,
        getUserByEmail,
        getRoles,
        createNewUser,
        getTasks,
        createNewRole,
        updateRole,
        getApiKeys,
        generateTestKey,
        generateLiveKey,
        getWebhookUrls,
        updateTestWebhook,
        updateLiveWebhook,
        getAuditLogs,
        getAuditItemById,
        getAppBanks,
        getBankByCode,
        activateBank,
        deactivateBank,
        createNewBank,
        updateBank,
        getAllServiceAPIs,
        getAllServiceAPITypes,
        createServiceAPI,
        getBanksByProcessingRoutes,
        getApprovalConfigs,
        activateApprovalConfig,
        deactivateApprovalConfig,
        updateApprovalConfig,
        getApprovalRequests,
        getApprovalTimelines,
        getApprovalPermission,
        acceptApprovalRequest,
        declineApprovalRequest,
        getServiceApiTransactions,
        getBankPartnersTransactions,
        getBusinessTransactions,
        getServiceTransactions,
        getBankPartners,
        getBankPartnerById,
        createBankPartner,
        updateBankPartner,
        getBankPartnerAdminById
    }

    return (
        <ApiContext.Provider value={{API: callActions}} >
            {props.children}
        </ApiContext.Provider>
    )
}

export default ApiContext