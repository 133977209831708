import { useState, useContext, useEffect } from "react"
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import ApiContext from "src/provider/API/call-service"
import { PopupWorldIcon } from "src/assets/icons"
import { AppSpan, AppText, Button, CustomContainer, FlexRow, FormGroup, SidePopup, SidePopupContainer } from "src/style"
import helpers from "src/utils/helpers"

export const ApprovalAction: React.FC<any> = ({data, close}) => {
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const [permission, setPermission] = useState<any>({CanUserApprove: false, CanUserDecline: false})
    const [comment, setComment] = useState('')

    useEffect(() => {
        handleGetPermission()
    }, [])
    async function handleGetPermission () {
        const response = await API.getApprovalPermission(data?.id)
        if (response) {
            setPermission(response)
            if (!response?.CanUserApprove || !response?.CanUserDecline) {
                notifier.show('You do not have access to either approve or decline this request', 'Permission Validation')
            }
        }
        else notifier.show('Approval permission could not be fetched', 'Permission Validation')
    }

    async function handleAction(type: string) {
        type === 'approve' ? await API.acceptApprovalRequest(data?.id, comment)
            :  await API.declineApprovalRequest(data?.id, comment)
        close()
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    <PopupWorldIcon />  
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <CustomContainer>
                        <AppText color='#101828' textSize="1.8" fontWeight="700">Approval Request</AppText>
                        <CustomContainer topMargin="2.5">
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Initaiting user</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.initiator}</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Entity Name</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.entityName}</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Approval Type</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.approvalType}</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Approval Status</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.approvalStatus}</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Date requested</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.requestedOn ? helpers.handleDate(data?.requestedOn) : ''}</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Current Approver Email</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.currentApproverEmail}</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Redirect URL</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.redirectUri}</AppSpan>
                            </FlexRow>
                        </CustomContainer>
                    </CustomContainer>
                    <CustomContainer topMargin="1">
                        <AppText color='#101828' textSize="1.8" fontWeight="700">Service Threshold Configuration</AppText>
                        <CustomContainer topMargin="2.5">
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Amount</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.amount || 'N6000'}</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Frequency</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.frequency || 'Weekly'}</AppSpan>
                            </FlexRow>
                        </CustomContainer>
                    </CustomContainer>
                    <CustomContainer topMargin="1">
                        <AppText color='#101828' textSize="1.8" fontWeight="700">Account Threshold Configuration</AppText>
                        <CustomContainer topMargin="2.5">
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Amount</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.amount || 'N6000'}</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Frequency</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.frequency || 'Weekly'}</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                                <AppText color='#333542' fontWeight="700" rightMargin="2">Limit Type</AppText>
                                <AppSpan color='#333542' fontWeight="300">{data?.limitType || 'Debit'}</AppSpan>
                            </FlexRow>
                        </CustomContainer>
                    </CustomContainer>
                    { (permission?.CanUserDecline || permission?.CanUserApprove) &&
                        <FormGroup>
                            <label>Comment</label>
                            <textarea 
                                placeholder="Enter Comment"
                                name='comment'
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                required
                            />
                        </FormGroup>
                    }
                    <FlexRow justifyContent="flex-end" topMargin="2" gap="2">
                        { (permission?.CanUserDecline && data?.approvalStatus?.toLowercase() === 'pending') &&
                            <Button 
                                width='8.9' height='4' radius='0.8'
                                fontWeight='600' bgColor="transparent" borderColor='#D0D5DD'
                                hoverColor='#0D968F' color='#344054'
                                onClick={() => handleAction('decline')}
                            >
                                Decline
                            </Button>
                        }
                        { (permission?.CanUserApprove && data?.approvalStatus?.toLowercase() === 'pending') &&
                            <Button 
                                width='8.9' height='4' radius='0.8'
                                hoverBgColor='#ffffff' borderColor='#0D968F' hoverColor='#0D968F'
                                fontWeight='600'
                                onClick={() => handleAction('approve')}
                            >
                                Approve
                            </Button>
                        }
                    </FlexRow>
                </CustomContainer>
            </SidePopup>
        </>
    )
}
    