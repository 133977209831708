import { useState, useContext, useEffect } from "react"
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import ApiContext from "src/provider/API/call-service"
import { PopupProductIcon } from "src/assets/icons"
import { AppSpan, AppText, Button, CustomContainer, FlexRow, Form, FormGroup, FormGroupWithIcon, SidePopup, SidePopupContainer } from "src/style"
import { ServiceSetup } from "src/popup"
import { FiChevronDown } from "react-icons/fi"
import { RadioSelection } from "src/component"
import helpers from "src/utils/helpers"

export const AddProduct: React.FC<any> = ({fetchUpdateMethod, close}) => {
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const [businesses, setBusinesses] = useState<any>(null)
    const [input, setInput] = useState({institutionCode: '', name: '', productPersonEmail: '', description: ''})
    const [users, setUsers] = useState<any>(null)
    const [isSetup, setIsSetup] = useState(false)

    useEffect(() => {
        handleFetchBusinesses()
    }, [])

    async function handleFetchBusinesses () {
        const response = await API.getBusinesses()
        if (response) setBusinesses(response)
    }

    function handleInput (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>) {
        setInput({...input, [e.target.name]: e.target.value})

        if (e.target.name === 'institutionCode') getBusinessUsers(e.target.value)
    }

    async function getBusinessUsers (code: string) {
        const response = await API.getUsers(0, 0, code)
        if (response) {
            setUsers(helpers.addSerialNumberToItems(response?.items))
        }
    }

    async function handleSubmit(payload: any) {
        const response = await API.createNewProduct(payload)
        if (response && fetchUpdateMethod) fetchUpdateMethod()
        close()
    }

    function handleNext (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        setIsSetup(true)
    }

    return (
        <>
            { !isSetup &&
                <>
                    <SidePopupContainer onClick={close} />
                    <SidePopup data-aos="slide-left">
                        <FlexRow justifyContent="space-between" alignItems="flex-start">
                            <PopupProductIcon />  
                            <AppSpan 
                                color='#000000' textSize="3" fontWeight="500" 
                                className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                                onClick={close}
                            />
                        </FlexRow>
                        <CustomContainer topMargin="3">
                            <AppText color='#101828' textSize="1.8" fontWeight="700">Add New Product</AppText>
                            <Form topMargin="2" onSubmit={handleNext}>
                                <FormGroupWithIcon bottomMargin='1.8'>
                                    <label>Select Business</label>
                                    <div className="input-with-icon">
                                        <select 
                                            name="institutionCode" 
                                            value={input.institutionCode}
                                            onChange={handleInput}
                                            style={{color: input.institutionCode ? '#667085' : '#999'}}
                                            required
                                        >
                                            <option value="">Business</option>
                                            {
                                                businesses?.map((item: any, index: number) => {
                                                    return (
                                                        <option value={item.code} key={index}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <FiChevronDown className="input-with-icon-tag" />
                                    </div>
                                </FormGroupWithIcon>
                                <FormGroup>
                                    <label>Enter Product Name</label>
                                    <input 
                                        placeholder="Product Name"
                                        name='name'
                                        value={input.name}
                                        onChange={handleInput}
                                        required
                                    />
                                </FormGroup>
                                <FormGroupWithIcon bottomMargin='1.8'>
                                    <label>Enter Product VP</label>
                                    <div className="input-with-icon">
                                        <select 
                                            name="productPersonEmail" 
                                            value={input.productPersonEmail}
                                            onChange={handleInput}
                                            style={{color: input.productPersonEmail ? '#667085' : '#999'}}
                                            required
                                        >
                                            <option value="">Select product vp</option>
                                            {
                                                users?.map((item: any, index: number) => {
                                                    return (
                                                        <option value={item?.email} key={index}>{item?.firstName} {item?.lastName}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <FiChevronDown className="input-with-icon-tag" />
                                    </div>
                                </FormGroupWithIcon>
                                <FormGroup>
                                    <label>Product Description</label>
                                    <textarea 
                                        placeholder="Product Description" 
                                        name='description'
                                        value={input.description}
                                        onChange={handleInput}
                                        required 
                                    />
                                </FormGroup>
                            
                                <FlexRow justifyContent="flex-end" topMargin="1">
                                    <Button 
                                        width='8'
                                        height='4'
                                        radius='0.8'
                                        hoverBgColor='#ffffff'
                                        borderColor='#0D968F'
                                        hoverColor='#0D968F'
                                        fontWeight='600'
                                    >
                                        Next
                                    </Button>
                                </FlexRow>
                            </Form>
                        </CustomContainer>
                    </SidePopup>
                </>
            }
            { isSetup &&
                <ServiceSetup 
                    close={close} 
                    submit={handleSubmit}
                    formData={input}
                    back={() => setIsSetup(false)}
                />
            }
        </>
    )
}
    