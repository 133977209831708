import './style.scss'
import { useState, useContext, useEffect } from 'react'
import utility from 'src/utils/utility'
import { AppText, Button, CustomContainer, FlexRow, ScreenContainer } from 'src/style'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { Table } from 'src/component'
import { billingConfigurationColumns, billingConfigurationData } from 'src/utils/tableLayout'
import { BsPlus } from 'react-icons/bs'
import { AddBilling, AddService, NewBillingPlan, WalletConfig } from 'src/popup'
import helpers from 'src/utils/helpers'


const BillingConfiguration: React.FC = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {notifier} = useContext(VisibilityContext)
    const {setInfoProperty} = useContext(AppInfoContext)
    const [billingConfigs, setBillingConfigs] = useState<any>(null)
    const [dataToEdit, setDataToEdit] = useState<any>(null)
    const [showAddBilling, setShowAddBilling] = useState(false)
    const [view, setView] = useState('')
    

    // useEffect(() => {
    //     handleFetchBillingConfigs()
    // }, [])

    // async function handleFetchBillingConfigs() {
    //     const response = await API.getBillingConfigs()
    //     if (response) setBillingConfigs(helpers.addSerialNumberToItems(response))
    // }

    async function handleBilling (data: any, type: string) {
        setDataToEdit(data)
        setView('add-billing')
    }

    function handleSubmit(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        notifier.show('Billing Configuration Saved', null, 'success')
        setView('')
    }

    return (
        <>
            <ScreenContainer>
                <AppText 
                    textSize='1.4' cursor='pointer' 
                >
                    Billing Configuration
                </AppText>
                <CustomContainer topMargin='3'>
                    <FlexRow justifyContent='space-between' className='business-title-wrap'>
                        <AppText textSize='3' color='#101828' topMargin='2' fontWeight='600'>Billing Configuration</AppText>
                        <Button
                            width='13.8'
                            height='4'
                            radius='0.8'
                            hoverBgColor='#ffffff'
                            borderColor='#0D968F'
                            hoverColor='#0D968F'
                            fontWeight='600'
                            topMargin='2'
                            className='add-business-btn'
                            onClick={() => setView('new-billing-plan')}
                        >
                            <BsPlus style={{fontSize: '1.5rem'}}/> 
                            Add New Plan
                        </Button>
                    </FlexRow>
                    <Table 
                        tableData={billingConfigurationData}
                        tableColumns={billingConfigurationColumns(handleBilling)}
                        canSearch
                        customStyle
                    />
                </CustomContainer>
            </ScreenContainer>
            { view === 'add-billing' &&
                <AddBilling
                    close={() => setView('')}
                    editData={dataToEdit}
                    //fetchConfigs={handleFetchBillingConfigs}
                />
            }
            { view === 'new-billing-plan' &&
                <NewBillingPlan
                    next={() => setView('wallet-config')}
                    close={() => setView('')} 
                />
            }
            { view === 'wallet-config' &&
                <WalletConfig
                    back={() => setView('new-billing-plan')}
                    next={handleSubmit}
                    close={() => setView('')} 
                />
            }
        </>
    )
}

export default utility.routeData('billing-configuration', 'Billing Configuration', <BillingConfiguration/>)