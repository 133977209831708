import { EnvironmentMode, SideBar } from 'src/component'
import { useContext, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import './dashboard.scss'
import { FixedContainer, MenuIcon } from 'src/style'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import utility from 'src/utils/utility'
import { useIdleTimer } from 'react-idle-timer'
import { appSettings } from 'src/provider/config/constant'
import helpers from 'src/utils/helpers'


export const DashboardRoutes = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const {info, recoverStatesData, logout} = useContext(AppInfoContext)
    const {visibility, showSideBar} = useContext(VisibilityContext)
    const [isAuth, setIsAuth] = useState<boolean>(!!info.authKey)

    useEffect(() => {
        recoverStatesData()
    }, [])

    useEffect(() => {
        handleAuth()
    }, [])

    useEffect(() => {
        document.title = utility.getPageTitle();

        return () => { document.title = 'Hydra' }
    }, [location])

    async function handleAuth () {
        const [authKey, sessionExpiryTime, isRememberMe] = await Promise.all([
            localStorage.getItem('authKey'), 
            localStorage.getItem('session-expiry-time'),
            localStorage.getItem('isRememberMe')
        ])
        let isSessionExpire = false
        const isAuthenticated = (authKey && authKey != 'null') ? true : false

        const currentDate = new Date()
        //currentDate.setTime( currentDate.getTime() - new Date().getTimezoneOffset() * 60 * 1000 );

        if (sessionExpiryTime) {
            if ((currentDate > new Date(sessionExpiryTime)) && !isRememberMe) isSessionExpire = true
        }

        setIsAuth(isAuthenticated)

        if(!isAuthenticated || isSessionExpire) handleLogout()
    }

    const onAction = () => helpers.setSessionExpiryTime()
    const onActive = () => {}
    const onIdle = () => handleLogout()

    const idleTimer = useIdleTimer({
        timeout: appSettings.INACTIVITY_TIME,
        onIdle,
        onActive,
        onAction,
        debounce: 500
    })

    async function handleLogout() {
        await logout()
        navigate('/login')
    }


    
    return (
        <>
            { isAuth &&
                <div className="dashboard-container">
                    <SideBar/>
                    <div className="dashboard-modules">
                        <EnvironmentMode />
                        <FixedContainer 
                            bgColor='#F9FAFB' top='0' left='0' zIndex='3' 
                            className='menubar-hack'
                        />
                        <MenuIcon onClick={() => showSideBar(!visibility.isSideBar)} />
                        <Outlet/>
                    </div>
                </div>
            }
        </>
    )
}

export default {
    routeProps: {
        path: 'dashboard',
        element: <DashboardRoutes/>
    },
    name: 'Dashboard'
}