import { useEffect, useState } from "react"
import { AppText, Circle, CustomContainer, FlexRow, RoundImageContainer } from "src/style"

export const RadioSelection: React.FC<any> = ({option1, option2, output}) => {
    const [input, setInput] = useState(option1)

    useEffect(() => {
        handleSelection(option1)
    }, [])
    
    function handleSelection (option: any) {
        setInput(option)
        output(option)
    }

    return (
        <>
            <FlexRow justifyContent="space-between" wrap='wrap'>
                <CustomContainer
                    width="49" sizeUnit="%" minHeight="5.2" bottomMargin="1" 
                    className="radio-selection-container"
                    borderColor={input === option1 ? '#0D968F' : '#EAECF0'}
                    bgColor={input === option1 ? '#E7F6F5' : 'transparent'}
                    onClick={() => handleSelection(option1)}
                >
                    <FlexRow justifyContent="flex-start">
                        <RoundImageContainer 
                            size="1.7" 
                            borderColor={input === option1 ? '#0D968F' : '#EAECF0'}
                            bgColor={input === option1 ? '#E7F6F5' : 'transparent'}
                            rightMargin="1.5" 
                        >
                            { input === option1 && <Circle bgColor="#0D968F" size='0.8' /> }
                        </RoundImageContainer>
                        <AppText 
                            textSize="1.4" fontWeight="600" cursor="pointer"
                            color={input === option1 ? '#085B56' : '#344054'}
                        >
                            {option1}
                        </AppText>
                    </FlexRow>
                </CustomContainer>
                <CustomContainer
                        width="49" sizeUnit="%" minHeight="5.2" 
                        className="radio-selection-container" bottomMargin="1"
                        borderColor={input === option2 ? '#0D968F' : '#EAECF0'} 
                        bgColor={input === option2 ? '#E7F6F5' : 'transparent'}
                        onClick={() => handleSelection(option2)}
                    >
                    <FlexRow justifyContent="flex-start">
                        <RoundImageContainer 
                            size="1.7" 
                            borderColor={input === option2 ? '#0D968F' : '#EAECF0'}
                            bgColor={input === option2 ? '#E7F6F5' : 'transparent'}
                            rightMargin="1.5" 
                        >
                            { input === option2 && <Circle bgColor="#0D968F" size='0.8' /> }
                        </RoundImageContainer>
                        <AppText 
                            textSize="1.4" fontWeight="600" cursor="pointer"
                            color={input === option2 ? '#085B56' : '#344054'}
                        >
                            {option2}
                        </AppText>
                    </FlexRow>
                </CustomContainer>
            </FlexRow>
        </>
    )
}