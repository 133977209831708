export const customStyles = {
    rows: {
        style: {
            minHeight: '72px',
         // backgroundColor: "#D6DFFB"
        }
    },
    cells: {
        style: {
            fontWeight: '600'
        }
    },
    headRow: {
		style: {
            minHeight: '4.4rem'
		},
	},
    headCells: {
        style: {
            //backgroundColor: "#D6DFFB",
        }
    }
}

export const customHeadStyles = {
    headCells: {
        style: {
            backgroundColor: '#1b2e73',
            color: '#fff',
            fontWeight: 'bold'}
        }
    }