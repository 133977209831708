import { useState, useContext, useEffect } from "react"
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import ApiContext from "src/provider/API/call-service"
import { FiChevronDown, FiChevronLeft } from "react-icons/fi"
import { PopupWorldIcon } from "src/assets/icons"
import { AbsoluteContainer, AppSpan, AppText, Button, CustomContainer, FlexRow, Form, FormGroup, FormGroupWithIcon, GridContainer, RoundImageContainer, SidePopup, SidePopupContainer } from "src/style"
import { RadioSelection, CheckboxSelection } from "src/component"


const initialServiceLimitData = {apiCallDailyThreshold: '', apiCallWeeklyThreshold: '', apiCallMonthlyThreshold: ''}
const initialAccountTxLimitData = {cumulativeTransactionDailyThreshold: '', cumulativeTransactionWeeklyThreshold: '', cumulativeTransactionMonthlyThreshold: ''}

export const AddService: React.FC<any> = ({fetchUpdateMethod, close}) => {
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const [isLoading, setIsLoading] = useState(false)
    const [serviceAPIs, setServiceAPIs] = useState<any[]>([])
    const [banks, setBanks] = useState<any[]>([])
    const [allProcessingRoutes, setAllProcessingRoutes] = useState<any[]>([])
    const [input, setInput] = useState({
        serviceApiId: 0, friendlyName: '', 
        description: '', controlMode: 'Flexible', 
        singleTransactionThreshold: '',
        ...initialServiceLimitData, 
        ...initialAccountTxLimitData
    })
    const [supportingBanks, setSupportingBanks] = useState<any>([])
    const [constrainMode, setConstrainMode] = useState<any>([])
    const [processingRoute, setProcessingRoute] = useState('')
    const [processingConfigurations, setProcessingConfigurations] = useState<any>([])
    const [bankData, setBankData] = useState<any>({})
    const [reset, setReset] = useState(false)
    const [thresholdTypes, setThresholdTypes] = useState<Array<string>>([])
    const [serviceLimitPeriod, setServiceLimitPeriod] = useState({day: false, week: false, month: false})
    const [accountLimitPeriod, setAccountLimitPeriod] = useState({day: false, week: false, month: false})
    const [view, setView] = useState('new-service')

    useEffect(() => {
        handleInitData()
    }, [])

    async function handleInitData () {
        const response1 = await API.getBanks()
        if (response1) setBanks(response1)
        const response2 = await API.getServiceAPIs()
        if (response2) setServiceAPIs(response2)
        const response3 = await API.getProcessingRoutes()
        if (response3) setAllProcessingRoutes(response3)
    }

    async function handleGetBanksByProcessingRoute (route: string) {
        setProcessingRoute(route)
        if (route) {
            setIsLoading(true)
            const response = await API.getBanksByProcessingRoutes(route)
            if (response) {
                setBanks(response)
                setSupportingBanks([])
            }
            setIsLoading(false)
        }
    }

    function handleInput (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) {
        setInput({...input, [e.target.name]: e.target.value})
    }

    function handleBankInfo (val: boolean, bankId: number, type: string) {
        if (val) setBankData({...bankData, [bankId]: bankData[bankId] ? [...bankData[bankId], type] : [type]})
        else {
            let constrainModes = bankData[bankId] ? [...bankData[bankId]]?.filter((item) => item != type) : []
            setBankData({...bankData, [bankId]: constrainModes})
        }
    }

    function handleSupportingBanks (val: boolean, id: number) {
        if (val) setSupportingBanks([...supportingBanks, id])
        else {
            let chosenBanks = [...supportingBanks].filter((item) => item != id)
            setSupportingBanks(chosenBanks)
        }
    }

    // function handleConstrainedMode (val: boolean, type: string) {
    //     if (val) setConstrainMode([...constrainMode, type])
    //     else {
    //         let constrainModes = [...constrainMode].filter((item) => item != type)
    //         setConstrainMode(constrainModes)
    //     }
    // }

    function handleNext (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        setView(view === 'new-service' ? 'processing-route' : 'threshold')
    }

    function addBankData () {
        let validationError = false
        const result = supportingBanks.map((item: number) => {
            if (input.controlMode === 'Constrained' && !bankData[item]?.length) validationError = true
            return {
                bankId: item,
                constrainMode: bankData[item]?.join(', ')
            }
        })
        return {result, validationError}
    }

    function handleAddAnother (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()

        if (!supportingBanks.length || !processingRoute) {
            return notifier.show('Kindly provide all required information', 'Validation Response')
        }

        const {result, validationError} = addBankData()
        if (validationError) return notifier.show('Kindly provide constrain mode for all selected banks', 'Validation Response')
        
        setProcessingConfigurations([
            ...processingConfigurations, 
            {
                processingRoute,
                supportingBanks: result
            }
        ])
        setReset(true)
        setConstrainMode([])
        setProcessingRoute('')
        setSupportingBanks([])
        setBankData({})
    }

    async function handleSubmit(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()

        if (!supportingBanks.length || !processingRoute) {
            return notifier.show('Kindly provide all required information', 'Validation Response')
        }
        const {result, validationError} = addBankData()
        if (validationError) return notifier.show('Kindly provide constrain mode for all selected banks', 'Validation Response')
        
        const payload = {
            ...input,
            apiCallDailyThreshold: Number(input.apiCallDailyThreshold) || 0,
            apiCallWeeklyThreshold: Number(input.apiCallWeeklyThreshold) || 0,
            apiCallMonthlyThreshold: Number(input.apiCallMonthlyThreshold) || 0,
            cumulativeTransactionDailyThreshold: Number(input.cumulativeTransactionDailyThreshold) || 0,
            cumulativeTransactionWeeklyThreshold: Number(input.cumulativeTransactionWeeklyThreshold) || 0,
            cumulativeTransactionMonthlyThreshold: Number(input.cumulativeTransactionMonthlyThreshold) || 0,
            processingConfigurations: [
                ...processingConfigurations,
                {
                    processingRoute,
                    supportingBanks: result
                }
            ]
        }

        console.log({payload})
        const response = await API.createNewService(payload)
        if (response && fetchUpdateMethod) fetchUpdateMethod()
        close()
    }

    function handleThresholdTypes (val: boolean, name: string) {
        if (val) setThresholdTypes([...thresholdTypes, name])
        else {
            let data = {}
            let types = [...thresholdTypes].filter((item) => item != name)
            setThresholdTypes(types)
            
            if (name === 'service-limit') data = initialServiceLimitData
            else if (name === 'service-tx-limit') data = {singleTransactionThreshold: ''}
            else if (name === 'account-tx-limit') data = initialAccountTxLimitData
            setInput({...input, ...data})
        }
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                { (isLoading) &&
                    <AbsoluteContainer top='0' left='0' zIndex='3' width='100' sizeUnit='%' height="full">
                        <GridContainer height='100' hUnit="%" bgColor='rgba(255, 255, 255, 0.5)'>
                            <AppSpan textSize='4' color='#0D968F' className='fa fa-spinner fa-spin' />
                        </GridContainer>
                    </AbsoluteContainer>
                }
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    { view === 'new-service' ? 
                        <PopupWorldIcon/> 
                        : 
                        <AppSpan 
                            color='#000000' textSize="2.5" fontWeight="500" 
                            hoverColor="#0D968F" onClick={() => setView(view === 'processing-route' ? 'new-service' : 'processing-route')}
                        >
                            <FiChevronLeft />
                        </AppSpan> 
                    }
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                {   view === 'new-service' &&
                    <CustomContainer topMargin="3">
                        <AppText color='#101828' textSize="1.8" fontWeight="700">Add New Service</AppText>
                        <Form topMargin="2" onSubmit={handleNext}>
                            <FormGroup>
                                <label>Enter Service</label>
                                <input 
                                    placeholder="Service" 
                                    name='friendlyName'
                                    value={input.friendlyName}
                                    onChange={handleInput}
                                    required
                                />
                            </FormGroup>
                            <FormGroupWithIcon bottomMargin='1.8'>
                                <label>Select API</label>
                                <div className="input-with-icon">
                                    <select 
                                        name="serviceApiId" 
                                        value={input.serviceApiId}
                                        onChange={(e) => setInput({...input, serviceApiId: Number(e.target.value)})}
                                        style={{color: input.serviceApiId ? '#667085' : '#999'}}
                                        required
                                    >
                                        <option value="">Select API</option>
                                        {
                                            serviceAPIs?.map((item: any, index: number) => {
                                                return (
                                                    <option value={item.id} key={index}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <FiChevronDown className="input-with-icon-tag" />
                                </div>
                            </FormGroupWithIcon>
                            <FormGroup>
                                <label>Enter Description</label>
                                <textarea 
                                    placeholder="Description" 
                                    name='description'
                                    value={input.description}
                                    onChange={handleInput}
                                    required
                                />
                            </FormGroup>
                            <FormGroup topMargin="-1">
                                <label>Select Control Mode</label>
                                <RadioSelection 
                                    option1='Flexible' option2='Constrained' 
                                    output={(val: any) => setInput({...input, controlMode: val})} 
                                />
                            </FormGroup>
                            
                            <FlexRow justifyContent="flex-end">
                                <Button 
                                    width='8'
                                    height='4'
                                    radius='0.8'
                                    hoverBgColor='#ffffff'
                                    borderColor='#0D968F'
                                    hoverColor='#0D968F'
                                    fontWeight='600'
                                    topMargin="3"
                                >
                                    Next
                                </Button>
                            </FlexRow>
                        </Form>
                    </CustomContainer>
                }
                { view === 'processing-route' &&
                    <CustomContainer topMargin="3" data-aos="zoom-in">
                        <AppText color='#101828' textSize="1.8" fontWeight="700">Process Configuration</AppText>
                        <Form topMargin="2" onSubmit={handleNext}>
                            <FormGroupWithIcon bottomMargin='1.8'>
                                <label>Select Processing Route</label>
                                <div className="input-with-icon">
                                    <select 
                                        name="processingRoute" 
                                        onChange={(e) => handleGetBanksByProcessingRoute(e.target.value)}
                                        style={{color: processingRoute ? '#667085' : '#999'}}
                                        value={processingRoute}
                                        required
                                    >
                                        <option value="">Processing Route</option>
                                        {
                                            allProcessingRoutes?.map((item: any, index: number) => {
                                                return (
                                                    <option value={item.value} key={index}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <FiChevronDown className="input-with-icon-tag" />
                                </div>
                            </FormGroupWithIcon>
                            <CustomContainer>
                                <AppText fontWeight="600" color="#344054" bottomMargin="1">Select Supporting Banks</AppText>
                                <FlexRow justifyContent="space-between" wrap="wrap">
                                    {
                                        banks?.map((item: any, index: number) => {
                                            return (
                                                <CustomContainer key={index} >
                                                    <CheckboxSelection 
                                                        name={item.name}
                                                        customClass='checkbox-selection-mobile'
                                                        output={(val: boolean) => handleSupportingBanks(val, item.id)}
                                                        disabled={!item.isActive}
                                                        reset={reset}
                                                    />

                                                    { (input.controlMode === 'Constrained' && supportingBanks.includes(item?.id)) &&
                                                        <CustomContainer topMargin="0.8" bottomMargin="2">
                                                            <AppText fontWeight="500" color="#344054" bottomMargin="1">Constraint Mode</AppText>
                                                            <FlexRow justifyContent="space-between" wrap="wrap">
                                                                <CheckboxSelection 
                                                                    width='49' name='Source'
                                                                    customClass='checkbox-selection-mobile'
                                                                    output={(val: boolean) => handleBankInfo(val, item?.id, 'Source')}
                                                                    //output={(val: boolean) => handleConstrainedMode(val, 'Source')}
                                                                    //onChange={(e) => handleBankInfo(item?.id, e)}
                                                                    reset={reset}
                                                                />
                                                                <CheckboxSelection 
                                                                    width='49' name='Destination'
                                                                    customClass='checkbox-selection-mobile'
                                                                    output={(val: boolean) => handleBankInfo(val, item?.id, 'Destination')}
                                                                    reset={reset}
                                                                />
                                                            </FlexRow>
                                                        </CustomContainer>
                                                    }
                                                </CustomContainer>
                                            )
                                        })
                                    }
                                </FlexRow>
                            </CustomContainer>
                            
                            <FlexRow justifyContent="flex-end" topMargin="2">
                                <Button
                                    width='12'
                                    height='4'
                                    radius='0.8'
                                    bgColor='#ffffff'
                                    hoverBgColor='#ffffff'
                                    borderColor='#D0D5DD'
                                    color='#344054'
                                    hoverColor='#0D968F'
                                    rightMargin='1.5'
                                    topMargin='1'
                                    fontWeight='600'

                                    onClick={handleAddAnother}
                                >
                                    Add Another
                                </Button>
                                <Button 
                                    width='8'
                                    height='4'
                                    radius='0.8'
                                    hoverBgColor='#ffffff'
                                    borderColor='#0D968F'
                                    hoverColor='#0D968F'
                                    fontWeight='600'
                                    topMargin="1"
                                >
                                    Next
                                </Button>
                            </FlexRow>
                        </Form>
                    </CustomContainer>
                }
                { view === 'threshold' &&
                    <CustomContainer topMargin="3">
                        <AppText color='#101828' textSize="1.8" fontWeight="700">Threshold Configuration</AppText>
                        <Form topMargin="2" onSubmit={handleSubmit}>
                            <CustomContainer >
                                <CheckboxSelection 
                                    name='Service Limit'
                                    customClass='checkbox-selection-mobile'
                                    output={(val: boolean) => handleThresholdTypes(val, 'service-limit')}
                                    reset={reset}
                                />
                                { thresholdTypes.includes('service-limit') &&
                                    <CustomContainer topMargin="1">
                                        {/* <FormGroupWithIcon bottomMargin="2">
                                            <label>Select Frequency</label>
                                            <div className="input-with-icon">
                                                <select 
                                                    name="serviceLimitFrequency" 
                                                    value={input.serviceLimitFrequency}
                                                    onChange={(e) => setInput({...input, serviceLimitFrequency: e.target.value})}
                                                    style={{color: input.serviceLimitFrequency ? '#667085' : '#999'}}
                                                    required
                                                >
                                                    <option value="">Frequency</option>
                                                    <option value="Daily">Daily</option>
                                                    <option value="Weekly">Weekly</option>
                                                    <option value="Monthly">Monthly</option>
                                                </select>
                                                <FiChevronDown className="input-with-icon-tag" />
                                            </div>
                                        </FormGroupWithIcon> */}
                                        {/* <FormGroup>
                                            <label>Enter API Call Count</label>
                                            <input 
                                                placeholder="API Call Count" 
                                                name='serviceLimitCallCount'
                                                type='number'
                                                value={input.serviceLimitCallCount}
                                                onChange={handleInput}
                                                required
                                            />
                                        </FormGroup> */}
                                        <AppText color="#344054" fontWeight="600" bottomMargin="1">Select Frequency</AppText>
                                        <FlexRow justifyContent="space-between" wrap='wrap'>
                                            <CheckboxSelection 
                                                name='Daily'
                                                width='49'
                                                customClass='checkbox-selection-mobile'
                                                output={(val: boolean) => setServiceLimitPeriod({...serviceLimitPeriod, day: val})}
                                            />
                                            { serviceLimitPeriod.day &&
                                                <FormGroup 
                                                    width="49" sizeUnit="%" 
                                                    height="5.2" bottomMargin="1"
                                                    className="checkbox-selection-mobile"
                                                >
                                                    <input 
                                                        placeholder="Enter API Call Count" 
                                                        name='apiCallDailyThreshold'
                                                        type='number'
                                                        value={input.apiCallDailyThreshold}
                                                        onChange={handleInput}
                                                        required
                                                    />
                                                </FormGroup>
                                            }
                                        </FlexRow>
                                        <FlexRow justifyContent="space-between" wrap='wrap'>
                                            <CheckboxSelection 
                                                name='Weekly'
                                                width='49'
                                                customClass='checkbox-selection-mobile'
                                                output={(val: boolean) => setServiceLimitPeriod({...serviceLimitPeriod, week: val})}
                                            />
                                            { serviceLimitPeriod.week &&
                                                <FormGroup 
                                                    width="49" sizeUnit="%" 
                                                    height="5.2" bottomMargin="1"
                                                    className="checkbox-selection-mobile"
                                                >
                                                    <input 
                                                        placeholder="Enter API Call Count" 
                                                        name='apiCallWeeklyThreshold'
                                                        type='number'
                                                        value={input.apiCallWeeklyThreshold}
                                                        onChange={handleInput}
                                                        required
                                                    />
                                                </FormGroup>
                                            }
                                        </FlexRow>
                                        <FlexRow justifyContent="space-between" wrap='wrap'>
                                            <CheckboxSelection 
                                                name='Monthly'
                                                width='49'
                                                customClass='checkbox-selection-mobile'
                                                output={(val: boolean) => setServiceLimitPeriod({...serviceLimitPeriod, month: val})}
                                            />
                                            { serviceLimitPeriod.month &&
                                                <FormGroup 
                                                    width="49" sizeUnit="%" 
                                                    height="5.2" bottomMargin="1"
                                                    className="checkbox-selection-mobile"
                                                >
                                                    <input 
                                                        placeholder="Enter API Call Count" 
                                                        name='apiCallMonthlyThreshold'
                                                        type='number'
                                                        value={input.apiCallMonthlyThreshold}
                                                        onChange={handleInput}
                                                        required
                                                    />
                                                </FormGroup>
                                            }
                                        </FlexRow>
                                    </CustomContainer>
                                }
                            </CustomContainer>
                            <CustomContainer >
                                <CheckboxSelection 
                                    name='Service Transaction Limit'
                                    customClass='checkbox-selection-mobile'
                                    output={(val: boolean) => handleThresholdTypes(val, 'service-tx-limit')}
                                    reset={reset}
                                />
                                { thresholdTypes.includes('service-tx-limit') &&
                                    <CustomContainer topMargin="1">
                                        <FormGroup>
                                            <label>Enter Single Transaction Limit</label>
                                            <input 
                                                placeholder="Single Transaction Limit" 
                                                name='singleTransactionThreshold'
                                                type='number'
                                                value={input.singleTransactionThreshold}
                                                onChange={handleInput}
                                                required
                                            />
                                        </FormGroup>
                                        {/* <FormGroupWithIcon bottomMargin="2">
                                            <label>Select Cummulative Transaction Limit Frequency</label>
                                            <div className="input-with-icon">
                                                <select 
                                                    name="serviceTxLimitFrequency" 
                                                    value={input.serviceTxLimitFrequency}
                                                    onChange={(e) => setInput({...input, serviceTxLimitFrequency: e.target.value})}
                                                    style={{color: input.serviceTxLimitFrequency ? '#667085' : '#999'}}
                                                    required
                                                >
                                                    <option value="">Frequency</option>
                                                    <option value="Daily">Daily</option>
                                                    <option value="Weekly">Weekly</option>
                                                    <option value="Monthly">Monthly</option>
                                                </select>
                                                <FiChevronDown className="input-with-icon-tag" />
                                            </div>
                                        </FormGroupWithIcon> */}
                                        {/* <FormGroup>
                                            <label>Enter Amount</label>
                                            <input 
                                                placeholder="Amount" 
                                                name='serviceTxLimitAmount'
                                                type='number'
                                                value={input.serviceTxLimitAmount}
                                                onChange={handleInput}
                                                required
                                            />
                                        </FormGroup> */}
                                    </CustomContainer>
                                }
                            </CustomContainer>
                            { input.controlMode === 'Constrained' &&
                                <CustomContainer >
                                    <CheckboxSelection 
                                        name='Account Transaction Limit'
                                        customClass='checkbox-selection-mobile'
                                        output={(val: boolean) => handleThresholdTypes(val, 'account-tx-limit')}
                                        reset={reset}
                                    />
                                    { thresholdTypes.includes('account-tx-limit') &&
                                        <CustomContainer topMargin="1">
                                            <AppText color="#344054" fontWeight="600" bottomMargin="1">Select Frequency</AppText>
                                            <FlexRow justifyContent="space-between" wrap='wrap'>
                                                <CheckboxSelection 
                                                    name='Daily'
                                                    width='49'
                                                    customClass='checkbox-selection-mobile'
                                                    output={(val: boolean) => setAccountLimitPeriod({...accountLimitPeriod, day: val})}
                                                />
                                                { accountLimitPeriod.day &&
                                                    <FormGroup 
                                                        width="49" sizeUnit="%" 
                                                        height="5.2" bottomMargin="1"
                                                        className="checkbox-selection-mobile"
                                                    >
                                                        <input 
                                                            placeholder="Enter Threshold" 
                                                            name='cumulativeTransactionDailyThreshold'
                                                            type='number'
                                                            value={input.cumulativeTransactionDailyThreshold}
                                                            onChange={handleInput}
                                                            required
                                                        />
                                                    </FormGroup>
                                                }
                                            </FlexRow>
                                            <FlexRow justifyContent="space-between" wrap='wrap'>
                                                <CheckboxSelection 
                                                    name='Weekly'
                                                    width='49'
                                                    customClass='checkbox-selection-mobile'
                                                    output={(val: boolean) => setAccountLimitPeriod({...accountLimitPeriod, week: val})}
                                                />
                                                { accountLimitPeriod.week &&
                                                    <FormGroup 
                                                        width="49" sizeUnit="%" 
                                                        height="5.2" bottomMargin="1"
                                                        className="checkbox-selection-mobile"
                                                    >
                                                        <input 
                                                            placeholder="Enter Threshold" 
                                                            name='cumulativeTransactionWeeklyThreshold'
                                                            type='number'
                                                            value={input.cumulativeTransactionWeeklyThreshold}
                                                            onChange={handleInput}
                                                            required
                                                        />
                                                    </FormGroup>
                                                }
                                            </FlexRow>
                                            <FlexRow justifyContent="space-between" wrap='wrap'>
                                                <CheckboxSelection 
                                                    name='Monthly'
                                                    width='49'
                                                    customClass='checkbox-selection-mobile'
                                                    output={(val: boolean) => setAccountLimitPeriod({...accountLimitPeriod, month: val})}
                                                />
                                                { accountLimitPeriod.month &&
                                                    <FormGroup 
                                                        width="49" sizeUnit="%" 
                                                        height="5.2" bottomMargin="1"
                                                        className="checkbox-selection-mobile"
                                                    >
                                                        <input 
                                                            placeholder="Enter Threshold" 
                                                            name='cumulativeTransactionMonthlyThreshold'
                                                            type='number'
                                                            value={input.cumulativeTransactionMonthlyThreshold}
                                                            onChange={handleInput}
                                                            required
                                                        />
                                                    </FormGroup>
                                                }
                                            </FlexRow>
                                        </CustomContainer>
                                    }
                                </CustomContainer>
                            }
                            <FlexRow justifyContent="flex-end" topMargin="1">
                                <Button
                                    width='8.1'
                                    height='4'
                                    radius='0.8'
                                    bgColor='#ffffff'
                                    hoverBgColor='#ffffff'
                                    borderColor='#D0D5DD'
                                    color='#344054'
                                    hoverColor='#0D968F'
                                    rightMargin='1.5'
                                    topMargin='1'
                                    fontWeight='600'

                                    onClick={close}
                                >
                                    Cancel
                                </Button>
                                <Button 
                                    width='6.7'
                                    height='4'
                                    radius='0.8'
                                    hoverBgColor='#ffffff'
                                    borderColor='#0D968F'
                                    hoverColor='#0D968F'
                                    fontWeight='600'
                                    topMargin="1"
                                >
                                    Save
                                </Button>
                            </FlexRow>
                        </Form>
                    </CustomContainer>
                }
            </SidePopup>
        </>
    )
}
    