import { images } from "src/provider/config/constant"
import { FixedContainer, Icon } from "src/style"

export const MainBgImage: React.FC = () => {
    return (
        <>
            <FixedContainer top='-3' right='-16'>
                <Icon src={images.TRANS_BG_LOGO} />
            </FixedContainer>
            <FixedContainer bottom='-20' left='-8'>
                <Icon src={images.TRANS_BG_LOGO} />
            </FixedContainer>
            <FixedContainer top='-4' left='15' lSizeUnit='%'>
                <Icon src={images.TRANS_BG_LOGO} width='15' />
            </FixedContainer>
        </>
    )
}