import { useEffect, useState, useContext } from 'react'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import UserContext from 'src/provider/state-manager/userProvider'
import { AppSpan, AppText, CustomContainer, ScreenContainer } from 'src/style'
import { ServerSideTable } from 'src/component'
import { approvalRequestsNewColumns, approvalRequestNewData } from 'src/utils/tableLayout'
import { ApprovalAction } from 'src/popup'
import helpers from 'src/utils/helpers'


const ApprovalRequests: React.FC = () => {
    const {API} = useContext(ApiContext)
    const {user: {userInfo}} = useContext(UserContext)
    const [requests, setRequests] = useState<any>(null)
    const [requestItems, setRequestItems] = useState<any>(null)
    const [selectedRequest, setSelectedRequest] = useState<any>(null)
    const [showApprovalAction, setShowApprovalAction] = useState(false)

    useEffect(() => {
        if (userInfo) handleFetchApprovalRequests()
    }, [userInfo])

    async function handleFetchApprovalRequests (pageNumber = 1, pageSize = 10) {
        const response = await API.getApprovalRequests(pageNumber, pageSize, userInfo?.user?.institution?.code)
        if (response) {
            setRequests(response)
            setRequestItems(helpers.addSerialNumberToItems(response?.items))
        }
    }

    function handleViewDetails (data: any) {
        setSelectedRequest(data)
        setShowApprovalAction(true)
    }

    return (
        <>
            <ScreenContainer>
                <AppText textSize='1.4' cursor='pointer' >
                    <AppSpan textSize='1.4' cursor='pointer'>Approval Requests</AppSpan>
                </AppText>
                <CustomContainer topMargin='3'>
                    <AppText textSize='3' color='#101828' topMargin='2' fontWeight='600'>Approval Requests</AppText>
                    <ServerSideTable
                        tableData={approvalRequestNewData}
                        tableColumns={approvalRequestsNewColumns(handleViewDetails)} 
                        dataFetchMethod={handleFetchApprovalRequests}
                        totalRows={requests?.totalCount}
                        customStyle
                        canSearch
                    />
                </CustomContainer>
            </ScreenContainer>
            { showApprovalAction &&
                <ApprovalAction 
                    data={selectedRequest}
                    close={() => setShowApprovalAction(false)}
                />
            }
        </>
    )
}

export default utility.routeData('approval-requests', 'Approval Requests', <ApprovalRequests/>)