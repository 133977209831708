import { ReactComponent as BillingIcon } from "src/assets/svg/billing-icon.svg"
import { SidePopupContainer, SidePopup, FlexRow, AppSpan, CustomContainer, AppText } from "src/style"

export const BillingHistoryDetails: React.FC<any> = ({data, close}) => {

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    <BillingIcon />  
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <AppText color='#101828' textSize="1.8" fontWeight="700">Billing History Details</AppText>
                    <CustomContainer topMargin="2.5">
                        <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                            <AppText color='#333542' fontWeight="700" rightMargin="2">API Charge Per Service</AppText>
                            <AppSpan color='#333542' fontWeight="300">{data?.apiCharge}</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                            <AppText color='#333542' fontWeight="700" rightMargin="2">OverdraftUsed</AppText>
                            <AppSpan color='#333542' fontWeight="300">{data?.overdraftUsed}</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                            <AppText color='#333542' fontWeight="700" rightMargin="2">OverdraftCharge</AppText>
                            <AppSpan color='#333542' fontWeight="300">{data?.overdraftCharge}</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                            <AppText color='#333542' fontWeight="700" rightMargin="2">Total Charge</AppText>
                            <AppSpan color='#333542' fontWeight="300">{data?.totalCharges}</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" wrap='wrap' bottomMargin="2">
                            <AppText color='#333542' fontWeight="700" rightMargin="2">Payment Cycle</AppText>
                            <AppSpan color='#333542' fontWeight="300">{data?.period}</AppSpan>
                        </FlexRow>
                    </CustomContainer>
                </CustomContainer>
            </SidePopup>
        </>
    )
}