let isLive = false;
const authServiceAPI = isLive ? '' : process.env.REACT_APP_AUTH_SERVICE_TEST_API
const switchServiceAPI = isLive ? '' : process.env.REACT_APP_SWITCH_SERVICE_TEST_API
const auditServiceAPI = isLive ? '' : process.env.REACT_APP_AUDIT_SERVICE_TEST_API
const approvalServiceAPI = isLive ? '' : process.env.REACT_APP_APPROVAL_SERVICE_TEST_API
const analyticsServiceAPI = isLive ? '' : process.env.REACT_APP_ANALYTICS_SERVICE_TEST_API

console.log({authServiceAPI, switchServiceAPI, auditServiceAPI, approvalServiceAPI, analyticsServiceAPI})

export const images = {
    TRANS_BG_LOGO: require('../../assets/img/trans-bg-logo.png'),
    HYDRA_LOGO: require('../../assets/img/hydra-logo.png'),
    USER: require('../../assets/img/user.png'),
    LOADER: require('../../assets/gif/loader.gif')
}


export const url = {
    AUTH_BASE_URL: authServiceAPI,
    SWITCH_BASE_URL: switchServiceAPI,
    AUDIT_BASE_URL: auditServiceAPI,
    APPROVAL_BASE_URL: approvalServiceAPI,
    ANALYTICS_BASE_URL: analyticsServiceAPI,
    APP_BASE_URL: 'http://52.226.210.118'
}

export const endpoints = {
   
}

export const appSettings = {
    INACTIVITY_TIME: 1000 * 60 * 5,
    SESSION_EXPIRATION_LENGTH: 5,
    ADDRESS_CHARACTERS_LENGTH: 250
}
