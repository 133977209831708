import { useState } from 'react'
import utility from 'src/utils/utility'
import { AppSpan, AppText, CustomContainer, FlexRow, Input, ScreenContainer } from 'src/style'
import { useNavigate } from 'react-router-dom'
import { Table } from 'src/component'
import { serviceTxColumns, serviceTxData } from 'src/utils/tableLayout'
import { FiSearch } from 'react-icons/fi'


const ServiceTransactions: React.FC = () => {
    const navigate = useNavigate()

    function handleViewDetails (data: any) {
        //alert(JSON.stringify(data))
        navigate('/dashboard/service-transactions/service-tx-details')
    }

    return (
        <>
            <ScreenContainer>
                <AppText textSize='1.4' cursor='pointer' >
                    <AppSpan textSize='1.4' cursor='pointer'> Service Transactions</AppSpan>
                </AppText>
                <CustomContainer topMargin='3'>
                    <AppText textSize='3' color='#101828' topMargin='2' fontWeight='600'>Service Transactions</AppText>
                    <CustomContainer
                            width='40' radius='0.8' borderColor='#D0D5DD' 
                            leftPadding='1.5' rightPadding='1.5' topMargin='2'
                            shadow='0px 1px 2px rgba(16, 24, 40, 0.05)'
                            bottomMargin='2' className='service-search-box'
                        >
                        <FlexRow>
                            <FiSearch style={{fontSize: '2rem'}} />
                            <Input height='4.4' leftPadding='1' placeholder='Search' />
                        </FlexRow>
                    </CustomContainer>
                    <Table 
                        tableData={serviceTxData}
                        tableColumns={serviceTxColumns(handleViewDetails)}
                        customStyle
                    />
                </CustomContainer>
            </ScreenContainer>
        </>
    )
}

export default utility.routeData('service-transactions', 'Service Transactions', <ServiceTransactions/>)