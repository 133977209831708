import { useContext } from "react";
import { PopupCodeIcon } from "src/assets/icons"
import { AppSpan, AppText, Button, CustomContainer, FlexRow, GridContainer, SidePopup, SidePopupContainer } from "src/style"
import { CodeBlock, CopyBlock } from "react-code-blocks";
import VisibilityContext from "src/provider/state-manager/visibilityProvider";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { IoCopyOutline } from "react-icons/io5";
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const PayloadStructure: React.FC<any> = ({close}) => {
    const {notifier} = useContext(VisibilityContext)

    let codeString = `{
        "phoneNumber": "070879941274",
        "message": "string",
        "institutionCode": "string",
        "loanReferences": "string"
    }`

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    <PopupCodeIcon />  
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <AppText color='#101828' textSize="1.8" fontWeight="700">Payload Structure</AppText>
                    <CustomContainer topMargin="2">
                        <CustomContainer maxHeight="23.5" overflow="auto">
                            {/* <CodeBlock
                                text={codeString}
                                language='json'
                                showLineNumbers={true}
                                //theme={yourTheme}
                            /> */}
                            <SyntaxHighlighter 
                                language="json" style={docco}
                                showLineNumbers 
                                wrapLines
                            >
                                {codeString}                             
                            </SyntaxHighlighter>
                        </CustomContainer>
                        <GridContainer topMargin='1.5' alignItems="end">
                            <CopyToClipboard 
                                text={codeString}
                                onCopy={() => notifier.show(`Payload Successfully Copied`, '', 'success')}
                            >
                                <Button
                                    width='9.6'
                                    height='4'
                                    radius='0.8'
                                    bgColor='#ffffff'
                                    hoverBgColor='#ffffff'
                                    borderColor='#D0D5DD'
                                    color='#344054'
                                    hoverColor='#0D968F'
                                    fontWeight='600'
                                >
                                    <IoCopyOutline style={{marginRight: '1rem'}} /> Copy
                                </Button>
                            </CopyToClipboard>
                        </GridContainer>
                    </CustomContainer>
                </CustomContainer>
            </SidePopup>
        </>
    )
}
    