import { useState } from 'react'
import utility from 'src/utils/utility'
import { AppSpan, AppText, CustomContainer, FlexRow, ScreenContainer } from 'src/style'
import { useNavigate } from 'react-router-dom'
import { Table } from 'src/component'
import { serviceRouteTxColumn, serviceRouteTxData, serviceBusinessTxColumns, serviceBusinessTxData } from 'src/utils/tableLayout'
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md'

const ServiceTxDetails: React.FC = () => {
    const navigate = useNavigate()

    return (
        <>
            <ScreenContainer>
                <AppText textSize='1.4' cursor='pointer' >
                    <AppSpan textSize='1.4' cursor='pointer' hoverColor='#0D968F' onClick={() => navigate('/dashboard/business-transactions')}>Service Transactions </AppSpan>
                    <AppSpan textSize='1.4' cursor='pointer'>/ Transaction Details</AppSpan>
                </AppText>
                <CustomContainer topMargin='3'>
                    <FlexRow width='auto' sizeUnit=' ' justifyContent='flex-start' topMargin='2'>
                        <MdOutlineKeyboardArrowLeft 
                            style={{fontSize: '3rem', color: '#101828'}}
                            onClick={() => navigate(-1)} 
                        />
                        <AppText textSize='3' color='#101828' fontWeight='600' leftMargin='0.5'>
                            Service Transaction Details
                        </AppText>
                    </FlexRow>

                    <FlexRow
                        minHeight='11.7' borderColor='#EAECF0'
                        shadow='0px 1px 2px rgba(16, 24, 40, 0.06)'
                        radius='0.8' bgColor='#ffffff' padding='2.2'
                        justifyContent='flex-start' wrap='wrap' topMargin='2'
                    >
                        <CustomContainer width='20' bottomMargin='1.7' rightMargin='7'>
                            <AppText color='#333542' textSize='1.6' fontWeight='700'>Service</AppText>
                            <AppSpan color='#333542' fontWeight='300' textSize='1.4'>Direct intrabank customer to customer</AppSpan>
                        </CustomContainer>
                        <CustomContainer width='20' bottomMargin='1.7' rightMargin='7'>
                            <AppText color='#333542' textSize='1.6' fontWeight='700'>API</AppText>
                            <AppSpan color='#333542' fontWeight='300' textSize='1.4'>Straight intrabank through Customer to customer</AppSpan>
                        </CustomContainer>
                        <CustomContainer width='20' bottomMargin='1.7' rightMargin='7'>
                            <AppText color='#333542' textSize='1.6' fontWeight='700'>Transaction Count</AppText>
                            <AppSpan color='#333542' fontWeight='300' textSize='1.4'>87,000</AppSpan>
                        </CustomContainer>
                        <CustomContainer width='20' bottomMargin='1.7' rightMargin='7'>
                            <AppText color='#333542' textSize='1.6' fontWeight='700'>Transaction Volume</AppText>
                            <AppSpan color='#333542' fontWeight='300' textSize='1.4'>12,903,456</AppSpan>
                        </CustomContainer>
                    </FlexRow>

                    <CustomContainer topMargin='2'>
                        <AppText color='#101828' textSize='1.8' fontWeight='600' bottomMargin='1'>Route Transaction Volume</AppText>
                        <Table 
                            tableData={serviceRouteTxData}
                            tableColumns={serviceRouteTxColumn}
                            customStyle
                        />
                    </CustomContainer>

                    <CustomContainer topMargin='2'>
                        <AppText color='#101828' textSize='1.8' fontWeight='600' bottomMargin='1'>Business Transactions</AppText>
                        <Table 
                            tableData={serviceBusinessTxData}
                            tableColumns={serviceBusinessTxColumns}
                            customStyle
                        />
                    </CustomContainer>
                </CustomContainer>
            </ScreenContainer>
        </>
    )
}

export default utility.routeData('service-transactions/service-tx-details', 'Service Transaction Details', <ServiceTxDetails/>)