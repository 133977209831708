import { useState, useContext, useEffect } from "react"
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import ApiContext from "src/provider/API/call-service"
import { FiChevronDown, FiChevronLeft } from "react-icons/fi"
import { AppSpan, AppText, Button, CustomContainer, FlexRow, Form, FormGroup, FormGroupWithIcon, RoundImageContainer, SidePopup, SidePopupContainer } from "src/style"
import { CheckboxSelection } from "src/component"
import { FaLaptopHouse } from "react-icons/fa"
import helpers from "src/utils/helpers"


export const ServiceSetup: React.FC<any> = ({formData, submit, close, isProductService, back}) => {
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const [banks, setBanks] = useState<any[]>([])
    const [serviceId, setServiceId] = useState<any>('')
    const [reset, setReset] = useState(false)
    const [supportingBanks, setSupportingBanks] = useState<any>([])
    const [serviceList, setServiceList] = useState<any[]>([])
    const [selectedService, setSelectedService] = useState<any>(null)
    const [services, setServices] = useState<any[]>([])
    const [bankData, setBankData] = useState<any>({})
    const [isConstrained, setIsConstrained] = useState(false)

    useEffect(() => {
        handleInitData()
    }, [])

    async function handleInitData () {
        // const response1 = await API.getBanks()
        // if (response1) setBanks(response1)
        const response2 = await API.getAllServices()
        if (response2) setServiceList(response2)
    }

    function handleSelectedService (data: any) {
        data = JSON.parse(data)
        console.log({data})
        setServiceId(data?.id)
        setSelectedService(data)
        setIsConstrained(data?.controlMode != 'Flexible')
        setBankData({})
        const {banks} = helpers.getServiceRoute_Banks(data?.processingConfigurations)
        setBanks(banks)
    }

    function handleBankInfo (bankId: number, e:React.ChangeEvent<HTMLInputElement>) {
        setBankData({...bankData, [bankId]: {...bankData[bankId], [e.target.name]: e.target.value}})
    }

    function handleSupportingBanks (val: boolean, id: number) {
        if (val) setSupportingBanks([...supportingBanks, id])
        else {
            let chosenBanks = [...supportingBanks].filter((item) => item != id)
            setSupportingBanks(chosenBanks)
        }
    }

    function addBankData () {
        let validationError = false
        let isInvalidAcctNo = false
        const result = supportingBanks.map((item: number) => {
            if ((!bankData[item]?.sourceAccountName || !bankData[item]?.sourceAccountNo) && (!bankData[item]?.destinationAccountName || !bankData[item]?.destinationAccountNo) && isConstrained) validationError = true
            if ((bankData[item]?.sourceAccountNo && bankData[item]?.sourceAccountNo?.length != 10) || (bankData[item]?.destinationAccountNo && bankData[item]?.destinationAccountNo?.length != 10) && isConstrained) isInvalidAcctNo = true
            
            return {
                bankId: item,
                sourceAccountName: bankData[item]?.sourceAccountName,
                sourceAccountNo: bankData[item]?.sourceAccountNo,
                destinationAccountName: bankData[item]?.destinationAccountName,
                destinationAccountNo: bankData[item]?.destinationAccountNo,
            }
        })
        return {result, validationError, isInvalidAcctNo}
    }

    function handleAddAnother (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()

        if (!supportingBanks?.length || !serviceId) {
            return notifier.show('Kindly provide all required information', 'Validation Response')
        }

        const {result, validationError, isInvalidAcctNo} = addBankData()
        if (validationError) return notifier.show('Kindly provide selected bank account details', 'Validation Response')
        if (isInvalidAcctNo) return notifier.show('Kindly provide a valid account number', 'Validation Response')

        setServices([
            ...services, 
            {
                serviceId: serviceId,
                serviceBanks: result
            }
        ])
        setReset(true)
        setSupportingBanks([])
        setBankData({})
        setSelectedService({})
        setServiceId('')
    }

    function handleSubmit (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        let payload;

        if (!supportingBanks?.length || !serviceId) {
            return notifier.show('Kindly provide all required information', 'Validation Response')
        }
        const {result, validationError, isInvalidAcctNo} = addBankData()

        if (validationError) return notifier.show('Kindly provide selected bank account details', 'Validation Response')
        if (isInvalidAcctNo) return notifier.show('Kindly provide a valid account number', 'Validation Response')

        if (!isProductService) {
            payload = {
                ...formData,
               services: [
                    ...services,
                    {
                        serviceId,
                        serviceBanks: result
                    }
                ]
            }
        }
        else {
            payload = {
                serviceId,
                banks: result
            }
        }

        console.log({payload})
        submit(payload)
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    <AppSpan 
                        color='#000000' textSize="2.5" fontWeight="500" 
                        hoverColor="#0D968F" onClick={back}
                    >
                        <FiChevronLeft />
                    </AppSpan> 
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <AppText color='#101828' textSize="1.8" fontWeight="700">Service Setup</AppText>
                    <Form topMargin="2" onSubmit={handleSubmit}>
                        <FormGroupWithIcon bottomMargin='1.8'>
                            <label>Select Service</label>
                            <div className="input-with-icon">
                                <select 
                                    name="services"
                                    value={JSON.stringify(selectedService)} 
                                    onChange={(e) => handleSelectedService(e.target.value)}
                                    style={{color: serviceId ? '#667085' : '#999'}}
                                    required
                                >
                                    <option value="">Services</option>
                                    {
                                        serviceList?.map((item: any, index: number) => {
                                            return (
                                                <option value={JSON.stringify(item)} key={index} onClick={() => handleSelectedService(item)}>{item.friendlyName}</option>
                                            )
                                        })
                                    }
                                </select>
                                <FiChevronDown className="input-with-icon-tag" />
                            </div>
                        </FormGroupWithIcon>
                        <CustomContainer>
                            { (!isConstrained && serviceId) &&
                                <>
                                    <AppText fontWeight="500" color="#344054" bottomMargin="1">Select Supporting Banks</AppText>
                                    <FlexRow justifyContent="space-between" wrap="wrap">
                                    {
                                            banks?.map((item: any, index: number) => {
                                                return (
                                                    <CheckboxSelection 
                                                        width='49' name={item?.bank?.name}
                                                        customClass='checkbox-selection-mobile'
                                                        key={index} 
                                                        output={(val: boolean) => handleSupportingBanks(val, item?.bank?.id)}
                                                        reset={reset}
                                                    />
                                                )
                                            })
                                        }
                                    </FlexRow>
                                </>
                            }
                            { isConstrained &&
                                banks?.map((item: any, index: number) => {
                                    let constrainMode = item?.constrainMode?.replace(/ /g, '').split(',')
                                    
                                    return (
                                        <CustomContainer key={index}>
                                            <CheckboxSelection 
                                                name={item?.bank?.name}
                                                customClass='checkbox-selection-mobile'
                                                output={(val: boolean) => handleSupportingBanks(val, item?.bank?.id)}
                                                reset={reset}
                                            />
                                            { supportingBanks.includes(item?.bank?.id) &&
                                                <Form topMargin="1">
                                                    { constrainMode.includes('Source') &&
                                                        <>
                                                            <FormGroup>
                                                                <label>Source Account Name</label>
                                                                <input 
                                                                    placeholder="Account Name" 
                                                                    name='sourceAccountName'
                                                                    value={bankData[item?.bank?.id]?.sourceAccountName || ''}
                                                                    onChange={(e) => handleBankInfo(item?.bank?.id, e)}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <label>Source Account Number</label>
                                                                <input 
                                                                    placeholder="Account Number" 
                                                                    name='sourceAccountNo'
                                                                    type="number"
                                                                    value={bankData[item?.bank?.id]?.sourceAccountNo || ''}
                                                                    onChange={(e) => handleBankInfo(item?.bank?.id, e)}
                                                                />
                                                            </FormGroup>
                                                        </>
                                                    }
                                                    { constrainMode.includes('Destination') &&
                                                        <>
                                                            <FormGroup>
                                                                <label>Destination Account Name</label>
                                                                <input 
                                                                    placeholder="Account Name" 
                                                                    name='destinationAccountName'
                                                                    value={bankData[item?.bank?.id]?.destinationAccountName || ''}
                                                                    onChange={(e) => handleBankInfo(item?.bank?.id, e)}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <label>Destination Account Number</label>
                                                                <input 
                                                                    placeholder="Account Number" 
                                                                    name='destinationAccountNo'
                                                                    type="number"
                                                                    value={bankData[item?.bank?.id]?.destinationAccountNo || ''}
                                                                    onChange={(e) => handleBankInfo(item?.bank?.id, e)}
                                                                />
                                                            </FormGroup>
                                                        </>
                                                    }
                                                </Form>
                                            }
                                            
                                        </CustomContainer>
                                    )
                                })
                            }
                        </CustomContainer>
                        <FlexRow justifyContent="flex-end" topMargin="2">
                            { !isProductService &&
                                <Button
                                    width='12'
                                    height='4'
                                    radius='0.8'
                                    bgColor='#ffffff'
                                    hoverBgColor='#ffffff'
                                    borderColor='#D0D5DD'
                                    color='#344054'
                                    hoverColor='#0D968F'
                                    rightMargin='1.5'
                                    topMargin='1'
                                    fontWeight='600'
                                    onClick={handleAddAnother}
                                >
                                    Add Another
                                </Button>
                            }
                            <Button 
                                width='8'
                                height='4'
                                radius='0.8'
                                hoverBgColor='#ffffff'
                                borderColor='#0D968F'
                                hoverColor='#0D968F'
                                fontWeight='600'
                                topMargin="1"
                            >
                                Save
                            </Button>
                        </FlexRow>
                    </Form>
                </CustomContainer>
            </SidePopup>
        </>
    )
}
    