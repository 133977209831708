import { useContext, useState } from "react"
import utility from "src/utils/utility"
import { FiChevronLeft } from "react-icons/fi"
import { SidePopupContainer, SidePopup, FlexRow, AppSpan, CustomContainer, AppText, GridContainer, Form, FormGroup, Button } from "src/style"

export const TransferPayment: React.FC<any> = ({back, next, close}) => {
    const [amount, setAmount] = useState('')

    const handleAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value.replace(/[,.]/g, '')
        if (!isNaN(Number(val))) {
            setAmount(val)
        }
    }

    function handleNext(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        next()
    }

    return (
        <>
            <SidePopupContainer onClick={close} />
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" alignItems="flex-start">
                    <AppSpan 
                        color='#000000' textSize="2.5" fontWeight="500" 
                        hoverColor="#0D968F" onClick={back}
                    >
                        <FiChevronLeft />
                    </AppSpan> 
                    <AppSpan 
                        color='#000000' textSize="3" fontWeight="500" 
                        className="fa fa-times-thin fa-2x" hoverColor="#B42318"
                        onClick={close}
                    />
                </FlexRow>
                <CustomContainer topMargin="3">
                    <AppText color='#101828' textSize="1.8" fontWeight="700" bottomMargin="2">Pay With Bank Transfer</AppText>
                    <CustomContainer topMargin="2.5">
                        <Form onSubmit={handleNext}>
                            <FormGroup>
                                <label>Enter Amount</label>
                                <input 
                                    placeholder='Amount' 
                                    name='amount'
                                    autoComplete='off'
                                    value={amount ? utility.koboFormat(amount) : ''}
                                    onChange={handleAmount}
                                />
                            </FormGroup>
                            <GridContainer alignItems="end" topMargin="1">
                                <Button
                                    width='9.5'
                                    height='4'
                                    radius='0.8'
                                    hoverBgColor='#ffffff'
                                    borderColor='#0D968F'
                                    hoverColor='#0D968F'
                                    fontWeight='600'
                                >
                                    Continue
                                </Button>
                            </GridContainer>
                        </Form>
                    </CustomContainer>
                </CustomContainer>
            </SidePopup>
        </>
    )
}